// Imports
import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Space,
  Tooltip,
  Button,
  Spin,
  Row,
  Col,
  Card,
  Badge,
  Popconfirm,
} from 'antd';
import {
  PlusOutlined,
  RedoOutlined,
  ImportOutlined,
  ExportOutlined,
} from '@ant-design/icons';

// App Imports
import Spinner from '../../components/common/Spinner';
import GraphQLServices from '../../graphql/services';
import WorkbookCreateModal from '../../components/modal/WorkbookCreateModal';
import WorkbookImportModal from '../../components/modal/WorkbookImportModal';
import { NAV_ROUTE_DATAEXPLORE_WORKBOOK } from '../../constants';
import { formatTimestampDistance } from '../../formatter';
import WorkbookSplash from '../../images/workbook_splash.png';
import { useExportAllJsonWorkbooks } from './utils';
import useAnalytics from '../../hooks/useAnalytics';

const WorkbookCatalog = ({ workbooks, loading, refetch }) => {
  const { data: { userMe = {} } = {} } =
    GraphQLServices.Users.useGetLocalUserMe();
  const [showWorkbookCreateModal, setShowWorkbookCreateModal] = useState(false);
  const [showWorkbookImportModal, setShowWorkbookImportModal] = useState(false);
  const exportAllWorkbooks = useExportAllJsonWorkbooks();

  const history = useHistory();

  const analytics = useAnalytics();

  const handleAddWorkbook = e => {
    setShowWorkbookCreateModal(true);
  };

  const handleWorkbookSelect = workbook => e => {
    if (workbook.is_example) {
      analytics.track(analytics.EVENT_TYPES.OPENED_EXAMPLE_WORKBOOK)({
        title: workbook.name,
      });
    }
    history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${workbook.id}`);
  };

  const handleWorkbookCreateCallback = (err, resp) => {
    if (resp) {
      const block = resp?.data?.blockCreate;
      const { worksheet_id } = block;
      refetch().then(resp => {
        const currentWorkbook = resp?.data?.workbooks.find(workbook =>
          workbook.worksheets.find(worksheet => worksheet.id === worksheet_id)
        );
        setShowWorkbookCreateModal(false);

        analytics.track(analytics.EVENT_TYPES.CREATED_WORKBOOK)({});

        history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${currentWorkbook.id}`);
      });
    } else {
      console.error(err);
    }
  };

  const handleImportWorkbook = _ => {
    setShowWorkbookImportModal(true);
  };

  const handleWorkbookImportCallback = (err, resp) => {
    if (resp) {
      refetch().then(_ => {
        setShowWorkbookImportModal(false);
      });
    } else {
      console.error(err);
    }
  };

  const featureStyle = {
    fontSize: '15px',
    padding: '8px 20px',
    margin: '10px 0px 10px 0px',
    backgroundColor: '#f6f6f6',
    borderRadius: '5px',
    borderLeft: '5px solid #dddddd',
  };

  const fillers = useMemo(
    _ => {
      if (workbooks.length % 3 > 0) {
        return Array.from(Array(3 - (workbooks.length % 3)).keys());
      }
      return [];
    },
    [workbooks]
  );

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  return (
    <div className="workbook_catalog">
      <div style={{ float: 'right' }}>
        <Space>
          <Tooltip title="Import Workbook (JSON/SQL)">
            <Button icon={<ImportOutlined />} onClick={handleImportWorkbook}>
              Import
            </Button>
          </Tooltip>
          <Tooltip title="Export All Workbooks JSON">
            <Popconfirm
              title="Are you sure you want to export all workbooks?"
              onConfirm={exportAllWorkbooks}
            >
              <Button icon={<ExportOutlined />}>Export All</Button>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Refresh">
            <Button
              icon={<RedoOutlined spin={loading} />}
              onClick={() => refetch()}
            />
          </Tooltip>
          <Tooltip title="Create">
            <Button icon={<PlusOutlined />} onClick={handleAddWorkbook} />
          </Tooltip>
        </Space>
      </div>
      <h2>Workbooks</h2>
      <Spin indicator={<Spinner />} spinning={loading}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          {workbooks.length <= 9 && (
            <div
              style={{
                backgroundColor: '#ffffff',
                padding: '20px 70px',
                width: '100%',
              }}
            >
              <Row gutter={40}>
                <Col span={12}>
                  <h1
                    style={{
                      fontSize: '30px',
                      color: '#3700b399',
                      marginTop: '10px',
                    }}
                  >
                    Key Features
                  </h1>
                  <ul
                    style={{
                      listStyle: 'none',
                      margin: '0px',
                      padding: '0px',
                    }}
                  >
                    <li style={featureStyle}>
                      Keep all your analytics work organized
                    </li>
                    <li style={featureStyle}>
                      Visualize results using charts and maps
                    </li>
                    <li style={featureStyle}>
                      Add insight by annotating your findings
                    </li>
                    <li>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={handleAddWorkbook}
                        size="large"
                        style={{ marginTop: '10px' }}
                        block
                      >
                        Create New Workbook
                      </Button>
                    </li>
                  </ul>
                </Col>
                <Col span={12} style={{ textAlign: 'center' }}>
                  <img
                    src={WorkbookSplash}
                    style={{ height: '250px', marginTop: '30px' }}
                    alt="Workbook Splash"
                  />
                </Col>
              </Row>
            </div>
          )}
          <Row gutter={16}>
            {workbooks.map(workbook => {
              return (
                <Col
                  key={workbook.id}
                  xl={8}
                  xxl={6}
                  style={{ marginBottom: '16px' }}
                >
                  <ConditionalWrapper
                    condition={
                      workbook.is_shared && workbook?.user?.id !== userMe?.id
                    }
                    wrapper={children => (
                      <Badge.Ribbon text="Shared" color="blue">
                        {children}
                      </Badge.Ribbon>
                    )}
                  >
                    <ConditionalWrapper
                      condition={
                        workbook.is_example &&
                        (!workbook.is_shared ||
                          workbook?.user?.id === userMe?.id)
                      }
                      wrapper={children => (
                        <Badge.Ribbon text="Example" color="magenta">
                          {children}
                        </Badge.Ribbon>
                      )}
                    >
                      <Card
                        title={workbook.name}
                        onClick={handleWorkbookSelect(workbook)}
                        bordered={false}
                        style={{ cursor: 'pointer' }}
                      >
                        <div
                          style={{
                            height: '64px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            lineHeight: '20px',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            display: '-webkit-box',
                          }}
                        >
                          {workbook.description}
                        </div>
                        <div style={{ fontSize: '12px', color: '#cccccc' }}>
                          Updated:{' '}
                          {`${formatTimestampDistance(
                            workbook.last_update
                          )} ago`}
                        </div>
                      </Card>
                    </ConditionalWrapper>
                  </ConditionalWrapper>
                </Col>
              );
            })}
            {fillers.map((filler, idx) => {
              return (
                <Col
                  key={`filler_${idx}`}
                  xl={8}
                  xxl={6}
                  style={{ marginBottom: '16px' }}
                >
                  <div
                    style={{
                      height: '190px',
                      border: '2px dashed #e6e6e6',
                      backgroundColor: '#ffffff33',
                    }}
                  ></div>
                </Col>
              );
            })}
          </Row>
        </Space>
      </Spin>
      {showWorkbookCreateModal && (
        <WorkbookCreateModal
          visible={showWorkbookCreateModal}
          close={_ => {
            setShowWorkbookCreateModal(false);
          }}
          callback={handleWorkbookCreateCallback}
        />
      )}
      <WorkbookImportModal
        visible={showWorkbookImportModal}
        close={_ => {
          setShowWorkbookImportModal(false);
        }}
        callback={handleWorkbookImportCallback}
      />
    </div>
  );
};

export default WorkbookCatalog;
