// Imports
import React, { useMemo } from 'react';
import {
  Layout,
  Descriptions,
  Divider,
  Space,
  Form,
  Select,
  Button,
  Popconfirm,
  notification,
  Tag,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../graphql/services';
import { formatK8sTimestamp } from '../../formatter';
import useAnalytics from '../../hooks/useAnalytics';

const { Content } = Layout;
const { Option } = Select;

const Upgrade = ({
  cluster,
  upgrades,
  // releaseVersions = [],
  pendingUpgrades,
  refresh,
}) => {
  const { data: { k8s_kineticaworkbenches } = {} } =
    GraphQLServices.K8sKineticaWorkbenches.useGetK8sKineticaWorkbenches();
  const [createK8SKineticaOperatorUpgrade, { loading: isOperatorUpgrading }] =
    GraphQLServices.K8sKineticaOperatorUpgrades.useCreateK8SKineticaOperatorUpgrade();
  const [
    createK8SKineticaWorkbenchOperatorUpgrade,
    { loading: isWorkbenchUpgrading },
  ] =
    GraphQLServices.K8sKineticaWorkbenchOperatorUpgrades.useCreateK8SKineticaWorkbenchOperatorUpgrade();

  const formRefs = [];

  const analytics = useAnalytics();

  const workbench = useMemo(
    _ => {
      if (k8s_kineticaworkbenches) {
        return k8s_kineticaworkbenches[0];
      }
      return null;
    },
    [k8s_kineticaworkbenches]
  );

  const handleUpgrade = idx => _ => {
    const selectedUpgradeVersion = formRefs[idx].getFieldValue(
      'selectedUpgradeVersion'
    );

    const operatorPayload = {
      variables: {
        name: 'kineticaoperatorupgrade',
        body: {
          spec: {
            upgradeToVersion: `${selectedUpgradeVersion}`,
          },
        },
      },
    };

    const workbenchOperatorPayload = {
      variables: {
        name: 'workbenchoperatorupgrade',
        body: {
          spec: {
            upgradeToVersion: `${selectedUpgradeVersion}`,
          },
        },
      },
    };

    Promise.all([
      createK8SKineticaOperatorUpgrade(operatorPayload),
      createK8SKineticaWorkbenchOperatorUpgrade(workbenchOperatorPayload),
    ]).then(responses => {
      analytics.track(analytics.EVENT_TYPES.UPGRADE_INITIATED)({
        version: `${selectedUpgradeVersion}`,
      });
      notification.info({
        message: 'Upgrade initiated.',
      });
      refresh();
    });
  };

  // const clusterName = useMemo(
  //   _ => {
  //     const { clusterName } = cluster?.spec?.gpudbCluster;
  //     return clusterName;
  //   },
  //   [cluster]
  // );

  return (
    <Content
      style={{
        backgroundColor: '#ffffff',
        padding: '20px',
        height: 'calc(100vh - 250px)',
        overflow: 'auto',
      }}
    >
      {upgrades.map((upgrade, idx) => {
        const version = [...upgrade?.availableUpgradeVersions];
        return (
          // <div key={clusterName}>
          <div key={upgrade?.clusterName}>
            <Descriptions title="Current" size="small" column={1} bordered>
              <Descriptions.Item
                label="Version"
                labelStyle={{ width: '200px' }}
              >
                {upgrade?.currentVersion?.version ?? 'Unavailable'}
              </Descriptions.Item>
              {cluster?.status?.phase.includes('Upgrading') && (
                <Descriptions.Item
                  label="Upgrade Status"
                  labelStyle={{ width: '200px' }}
                >
                  <Tag
                    color="#1890ff"
                    style={{
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    <LoadingOutlined
                      style={{ color: '#ffffff', marginRight: '15px' }}
                    />
                    {cluster?.status?.phase
                      .replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ')
                      .toUpperCase()}
                  </Tag>
                </Descriptions.Item>
              )}
            </Descriptions>
            {pendingUpgrades.length > 0 && (
              <>
                <Divider dashed />
                <Descriptions
                  title="Pending Status"
                  size="small"
                  column={1}
                  bordered
                >
                  {pendingUpgrades.map((pendingUpgrade, idx) => {
                    return (
                      <Descriptions.Item
                        key={idx}
                        label={pendingUpgrade?.spec?.upgradeToVersion}
                        labelStyle={{ width: '200px' }}
                      >
                        <label
                          style={{ width: '90px', display: 'inline-block' }}
                        >
                          {pendingUpgrade?.status?.phase}
                        </label>
                        <i style={{ color: '#cccccc' }}>
                          Initiated{' '}
                          {formatK8sTimestamp(
                            pendingUpgrade?.metadata?.creationTimestamp
                          )}
                        </i>
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
                <Divider dashed />
              </>
            )}
            <Divider dashed />
            <Descriptions
              title="Available Upgrade"
              size="small"
              column={1}
              bordered
            >
              <Descriptions.Item
                label="Versions"
                labelStyle={{ width: '200px' }}
              >
                <Space>
                  <Form
                    ref={ref => formRefs.push(ref)}
                    onFinish={handleUpgrade(idx)}
                  >
                    <Space>
                      <Form.Item
                        name="selectedUpgradeVersion"
                        initialValue={version.length > 0 ? version[0].tag : ''}
                        // initialValue={
                        //   releaseVersions.length > 0
                        //     ? releaseVersions[0].tag
                        //     : ''
                        // }
                        style={{
                          marginBottom: '0px',
                        }}
                      >
                        <Select
                          name="selectedUpgradeVersion"
                          style={{ width: '280px' }}
                          disabled={
                            !workbench ||
                            version.length === 0 ||
                            // releaseVersions.length === 0 ||
                            cluster?.status?.phase.includes('Upgrading')
                          }
                        >
                          {version.length === 0 && (
                            <Option value="">No upgrade available</Option>
                          )}
                          {version.map((upgradeVersion, idx) => {
                            return (
                              <Option
                                key={upgradeVersion.tag}
                                value={upgradeVersion.tag}
                              >
                                {upgradeVersion.tag} {idx === 0 && '(Latest)'}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Popconfirm
                        title="Workbench may be restarted and login will be required. Are you sure you want to upgrade?"
                        onConfirm={_ => {
                          formRefs[idx].submit();
                        }}
                        style={{ float: 'left' }}
                        disabled={
                          !workbench ||
                          version.length === 0 ||
                          // releaseVersions.length === 0 ||
                          cluster?.status?.phase.includes('Upgrading')
                        }
                      >
                        <Button
                          type="primary"
                          loading={isOperatorUpgrading || isWorkbenchUpgrading}
                          disabled={
                            !workbench ||
                            version.length === 0 ||
                            // releaseVersions.length === 0 ||
                            cluster?.status?.phase.includes('Upgrading')
                          }
                        >
                          Upgrade
                        </Button>
                      </Popconfirm>
                    </Space>
                  </Form>
                </Space>
              </Descriptions.Item>
            </Descriptions>
          </div>
        );
      })}
    </Content>
  );
};

export default Upgrade;
