// Imports
import React, { useState } from 'react';
import {
  Drawer,
  Button,
  Form,
  Select,
  Input,
  Row,
  Col,
  Tooltip,
  Slider,
  Radio,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// App Imports
import { NIVO_COLOR_SCHEMES } from '../../constants';

const { Option } = Select;
const { TextArea } = Input;

const VizConfigDrawer = ({
  title = 'Configuration',
  config,
  fields,
  options,
  isOpen,
  handleClose,
  handleUpdate,
  handleOnChange,
  width = 660,
  columns = 3,
  disabledFields = [],
}) => {
  const initialValues = fields.reduce((acc, cur) => {
    acc[cur.name] = config[cur.name] || cur.defaultValue || '';
    return acc;
  }, {});

  const [form] = Form.useForm();

  const [dynamicDisabledFields, setDynamicDisabledFields] = useState(
    fields.reduce((acc, cur) => {
      if (cur.disable) {
        acc[cur.name] =
          disabledFields.includes(cur.name) ||
          Object.keys(cur.disable).every(
            key => initialValues[key] === cur.disable[key]
          );
      }
      return acc;
    }, {})
  );

  const onFinish = values => {
    handleUpdate(values);
  };

  const update = _ => {
    form.submit();
  };

  const buildOptions = field => {
    return {
      ...options,
      colorSchemes: NIVO_COLOR_SCHEMES,
    }[field.source].map(option => {
      if (typeof option === 'object') {
        return (
          <Option key={option?.value} value={option?.value}>
            {option?.label}
          </Option>
        );
      }
      return (
        <Option key={option} value={option}>
          {option}
        </Option>
      );
    });
  };

  const renderInput = field => {
    switch (field.type) {
      case 'select':
        return (
          <Select
            showSearch
            placeholder="Select one"
            optionFilterProp="children"
            filterOption={true}
            disabled={disabledFields.includes(field.name)}
          >
            <Option value=""></Option>
            {buildOptions(field)}
          </Select>
        );
      case 'radio':
        return (
          <Radio.Group options={field.options} style={{ margin: '0px 8px' }} />
        );
      case 'range':
        return (
          <Slider
            min={field.start}
            max={field.end}
            marks={{
              [field.start]: {
                label: field.start,
                style: { fontSize: '11px ' },
              },
              [field.end]: {
                label: field.end,
                style: { fontSize: '11px' },
              },
            }}
            step={field.step || 1}
            style={{ margin: '0px 5px' }}
            trackStyle={[
              {
                backgroundColor: '#3700B333',
                height: 12,
                marginTop: '-4px',
              },
            ]}
            handleStyle={[
              {
                backgroundColor: '#ffffff',
                borderColor: '#dddddd',
                borderWidth: '1px',
                height: 16,
                width: 10,
                borderRadius: '2px',
                marginTop: '-6px',
              },
            ]}
            railStyle={{ height: 12, marginTop: '-4px' }}
            dotStyle={{ display: 'none' }}
            disabled={
              disabledFields.includes(field.name) ||
              dynamicDisabledFields[field.name]
            }
          />
        );
      case 'html':
        return (
          <TextArea
            rows={5}
            placeholder={
              field.placeholder != null
                ? form.getFieldValue(field.placeholder)
                : ''
            }
            disabled={disabledFields.includes(field.name)}
            style={{
              fontFamily: 'monospace',
              fontSize: '13px',
            }}
            spellCheck={false}
          />
        );
      default:
        return (
          <Input
            placeholder={
              field.placeholder != null
                ? form.getFieldValue(field.placeholder)
                : ''
            }
            disabled={disabledFields.includes(field.name)}
          />
        );
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    fields.forEach(field => {
      if (
        field.dynamicDefaultValue &&
        Object.keys(changedValues).includes(field.dynamicDefaultValue) &&
        form.getFieldValue(field.name) === ''
      ) {
        form.setFieldsValue({
          [field.name]: form.getFieldValue(field.dynamicDefaultValue),
        });
      }
    });

    // Disable fields based on rules
    const dynamicDisabledFieldsUpdate = {};
    fields.forEach(field => {
      if (
        field.disable &&
        Object.keys(field.disable).every(key => {
          return form.getFieldValue(key) === field.disable[key];
        })
      ) {
        dynamicDisabledFieldsUpdate[field.name] = true;
      } else if (field.disable && !disabledFields.includes(field.name)) {
        dynamicDisabledFieldsUpdate[field.name] = false;
      }
    });
    setDynamicDisabledFields({
      ...dynamicDisabledFields,
      ...dynamicDisabledFieldsUpdate,
    });

    if (handleOnChange) {
      handleOnChange(changedValues, allValues);
    }
  };

  return (
    <Drawer
      title={title}
      placement="right"
      onClose={handleClose}
      visible={isOpen}
      getContainer={false}
      closable={true}
      width={width}
      style={{ position: 'absolute' }}
      headerStyle={{ height: '43px' }}
      bodyStyle={{ padding: '10px 20px' }}
      maskStyle={{ backgroundColor: '#f1edf8cc' }}
      footer={[
        <Button
          key="update"
          onClick={update}
          type="primary"
          size="small"
          style={{
            float: 'right',
          }}
        >
          Update
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="config"
        layout="vertical"
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        colon={false}
        preserve={false}
        size="small"
      >
        <Row gutter={16}>
          {fields?.map(field => {
            return (
              <Col key={field.name} span={field.span || 24 / columns}>
                <Form.Item
                  label={
                    field.description ? (
                      <>
                        {field.label}
                        <Tooltip title={field.description}>
                          <InfoCircleOutlined
                            style={{
                              color: '#bbbbbb',
                              marginLeft: '5px',
                              marginBottom: '-2px',
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      field.label
                    )
                  }
                  name={field.name}
                  required={
                    field.required && !disabledFields.includes(field.name)
                  }
                  shouldUpdate
                >
                  {renderInput(field)}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </Form>
    </Drawer>
  );
};

export default VizConfigDrawer;
