// Imports
import React, { useMemo } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// App Imports
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const TypeObject = ({ nodeData, handleAdd }) => {
  const type = useMemo(
    _ => {
      return nodeData?.key;
    },
    [nodeData]
  );

  const addMenu = (
    <Menu onClick={handleAdd}>
      <Menu.Item key="schema">Add New Schema</Menu.Item>
      <Menu.Item key="table">Add New Table</Menu.Item>
      <Menu.Item key="externaltable">Add New External Table</Menu.Item>
    </Menu>
  );

  const getAddButton = key => {
    return (
      <Button
        icon={<PlusOutlined style={{ fontSize: '12px' }} />}
        onClick={_ => handleAdd({ key })}
        size="small"
        style={{ float: 'right', height: '24px', width: '24px' }}
      />
    );
  };

  return (
    <div
      style={{
        fontWeight: 'bold',
        width: `${EXPLORER_PANEL_WIDTH - 55}px`,
        backgroundColor: '#f6f6f6',
        paddingLeft: 5,
      }}
    >
      {type === 'data.datasources' && getAddButton('datasource')}
      {type === 'data.datasinks' && getAddButton('datasink')}
      {type === 'data.graphs' && (
        <Button
          icon={<PlusOutlined style={{ fontSize: '12px' }} />}
          onClick={_ => handleAdd({ key: 'graph' })}
          size="small"
          style={{ float: 'right', height: '24px', width: '24px' }}
        />
      )}
      {type === 'data.container_registries' && (
        <Button
          icon={<PlusOutlined style={{ fontSize: '12px' }} />}
          onClick={_ => handleAdd({ key: 'container_registry' })}
          size="small"
          style={{ float: 'right', height: '24px', width: '24px' }}
        />
      )}
      {type === 'data.models' && (
        <Button
          icon={<PlusOutlined style={{ fontSize: '12px' }} />}
          onClick={_ => handleAdd({ key: 'model' })}
          size="small"
          style={{ float: 'right', height: '24px', width: '24px' }}
        />
      )}
      {type === 'data.streams' && (
        <Button
          icon={<PlusOutlined style={{ fontSize: '12px' }} />}
          onClick={_ => handleAdd({ key: 'stream' })}
          size="small"
          style={{ float: 'right', height: '24px', width: '24px' }}
        />
      )}
      {type === 'data.tables' && (
        <span style={{ float: 'right' }}>
          <Dropdown overlay={addMenu}>
            <Button
              icon={<PlusOutlined style={{ fontSize: '12px' }} />}
              size="small"
              style={{ float: 'right', height: '24px', width: '24px' }}
            />
          </Dropdown>
        </span>
      )}
      {nodeData.title}
    </div>
  );
};

export default TypeObject;
