// Imports
import React, { useMemo, useState, useCallback } from 'react';
import { Table, Space, Button, Tag } from 'antd';
import { ClearOutlined, ExportOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';

// App Imports
import { processExecuteSqlResults, isNumericColumnType } from '../../helper';
import { EXECUTE_SQL_LIMIT } from '../../setup/config';
import { formatFriendlyNumberWhole } from '../../formatter';

const truncate = (text, truncateLength) => {
  const regex = /\[(\d* characters truncated)\]/gi;
  const match = regex.exec(text);
  if (match) {
    return (
      <>
        {text.split(match[0])[0]}
        <Tag color="#f6f6f6" style={{ color: '#cccccc', marginLeft: '5px' }}>
          {match[1]}
        </Tag>
      </>
    );
  } else if (truncateLength > -1 && text.length > truncateLength) {
    const truncated = text.substring(0, truncateLength);
    const truncatedSize = text.length - truncated.length;
    return truncate(`${truncated}... [${truncatedSize} characters truncated]`);
  }
  return text;
};

const ResultTable = ({
  clearResult,
  queryResponse,
  defaultPageSize = 5,
  pageSizeOptions = ['1', '5', '10', '25', '50', '100'],
  showSizeChanger = true,
  showQuickJumper = true,
  clearLabel = 'Clear',
  clearIcon = <ClearOutlined />,
  truncateLength = -1,
  onChange = _ => {},
}) => {
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const handleResultTableChange = pagination => {
    setPageSize(pagination.pageSize);
    onChange(pagination);
  };

  const renderColumn = useCallback(
    type => text => {
      return text !== null ? (
        !isNumericColumnType(type) ? (
          <div
            style={{
              textAlign: text.toString().length === 1 ? 'center' : 'left',
            }}
          >
            {truncate(text, truncateLength)}
          </div>
        ) : (
          <div
            style={{
              textAlign: 'right',
            }}
          >
            {text}
          </div>
        )
      ) : (
        <Tag color="#f6f6f6" style={{ color: '#cccccc' }}>
          null
        </Tag>
      );
    },
    [truncateLength]
  );

  const columns = useMemo(
    _ => {
      if (queryResponse?.response?.data) {
        return queryResponse.response.data.column_headers.map((column, idx) => {
          return {
            key: column,
            title: column,
            dataIndex: column,
            render: renderColumn(
              queryResponse.response.data.column_datatypes[idx]
            ),
          };
        });
      }
      return [];
    },
    [queryResponse, renderColumn]
  );

  const dataSource = useMemo(
    _ => {
      if (queryResponse?.response?.data) {
        return processExecuteSqlResults(queryResponse.response.data);
      }
      return [];
    },
    [queryResponse]
  );

  const csvData = useMemo(
    _ => {
      return dataSource.map(({ key, ...rest }) => ({ ...rest }));
    },
    [dataSource]
  );

  const pagination = useMemo(
    _ => {
      if (dataSource?.length > pageSize) {
        return {
          pageSize,
          pageSizeOptions,
          showSizeChanger,
          showQuickJumper,
        };
      } else {
        return false;
      }
    },
    [dataSource, pageSize, pageSizeOptions, showSizeChanger, showQuickJumper]
  );

  const scroll = useMemo(_ => {
    return {
      x: 'max-content',
    };
  }, []);

  return (
    <div>
      <div style={{ height: 30 }}>
        <Space style={{ float: 'right' }}>
          <CSVLink data={csvData} filename={'results.csv'} target="_blank">
            <Button icon={<ExportOutlined />} size="small">
              Export CSV
            </Button>
          </CSVLink>
          <Button icon={clearIcon} size="small" onClick={clearResult}>
            {clearLabel}
          </Button>
        </Space>
        <label style={{ fontSize: '12px', color: '#bbbbbb' }}>
          {formatFriendlyNumberWhole(EXECUTE_SQL_LIMIT)} Row Limit
        </label>
      </div>
      <div>
        <Table
          className="result-table-fit"
          rowKey="__key"
          columns={columns}
          dataSource={dataSource}
          onChange={handleResultTableChange}
          pagination={pagination}
          scroll={scroll}
          size="small"
        />
      </div>
    </div>
  );
};

export default ResultTable;
