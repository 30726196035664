// Imports
import React, { useMemo, useState } from 'react';
import { Space, Button, Popconfirm, Alert, Empty } from 'antd';
import { SettingOutlined, CloseOutlined } from '@ant-design/icons';
import { ResponsiveBar } from '@nivo/bar';

// App Imports
import VizConfigDrawer from './VizConfigDrawer';
import { useIsReadOnly } from './utils';
import { VIZ_X_AXIS_DATA_LIMIT } from '../../constants';

const VizBarChart = ({
  viz,
  data,
  columns,
  handleUpdate,
  handleRemove,
  minHeight = 180,
}) => {
  const { config } = viz;

  const [isVizConfigOpen, setIsVizConfigOpen] = useState(false);
  const readOnly = useIsReadOnly();

  const chartData = useMemo(
    _ => {
      return data.column_1
        .map((column_1, colIdx) => {
          return data.column_headers.reduce((acc, cur, headerIdx) => {
            if (cur === config.x_axis_column) {
              acc['id'] = data[`column_${headerIdx + 1}`][colIdx];
            } else if (cur === config.y_axis_column) {
              acc['value'] = data[`column_${headerIdx + 1}`][colIdx];
            }
            return acc;
          }, {});
        })
        .map(record => {
          return {
            id: record.id || '',
            value: record.value || 0,
          };
        });
    },
    [data, config]
  );

  const handleOpenVizConfig = _ => {
    setIsVizConfigOpen(true);
  };

  const handleCloseVizConfig = _ => {
    setIsVizConfigOpen(false);
  };

  const handleUpdateVizConfig = values => {
    handleUpdate(values, _ => {
      setIsVizConfigOpen(false);
    });
  };

  const limitExceeded = useMemo(
    _ => {
      return chartData.length > VIZ_X_AXIS_DATA_LIMIT;
    },
    [chartData]
  );

  return (
    <div style={{ position: 'relative', minHeight }}>
      {!readOnly && (
        <div style={{ height: 30 }}>
          <Space style={{ float: 'right' }}>
            <Button
              icon={<SettingOutlined />}
              onClick={handleOpenVizConfig}
              size="small"
            >
              Configure
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this visualization?"
              onConfirm={handleRemove}
            >
              <Button icon={<CloseOutlined />} size="small"></Button>
            </Popconfirm>
          </Space>
          {limitExceeded && (
            <Alert
              message={`X-Axis data points limit exceeded. Only ${VIZ_X_AXIS_DATA_LIMIT} will be displayed.`}
              style={{
                padding: '3px 10px',
                fontSize: '12px',
                width: 'calc(100% - 150px)',
              }}
              banner
            />
          )}
        </div>
      )}
      <div className="bar-chart" style={{ height: minHeight - 20 }}>
        {config?.x_axis_column && config?.y_axis_column ? (
          <ResponsiveBar
            data={chartData.slice(0, VIZ_X_AXIS_DATA_LIMIT)}
            margin={{ top: 15, right: 25, bottom: 55, left: 70 }}
            padding={0.1}
            enableGridX={true}
            colors={{ scheme: config.color_scheme || 'spectral' }}
            colorBy="index"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 25,
              legend: config.x_axis_label || '',
              legendPosition: 'middle',
              legendOffset: 45,
              renderTick: props => {
                const {
                  opacity,
                  textAnchor,
                  textBaseline,
                  textX,
                  textY,
                  lineX,
                  lineY,
                  value,
                  rotate,
                  x,
                  y,
                } = props;
                return (
                  <g transform={`translate(${x},${y})`} style={{ opacity }}>
                    <line
                      x1="0"
                      x2={lineX}
                      y1="0"
                      y2={lineY}
                      style={{
                        stroke: 'rgb(119, 119, 119)',
                        strokeWidth: '1px',
                      }}
                    ></line>
                    <text
                      dominantBaseline={textBaseline}
                      textAnchor={textAnchor}
                      transform={`translate(${textX},${textY}) rotate(${rotate})`}
                      style={{ fontSize: '11px' }}
                    >
                      <title>{value}</title>
                      {value.length > 10 ? `${value.substring(0, 8)}..` : value}
                    </text>
                  </g>
                );
              },
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: config.y_axis_label || '',
              legendOffset: -65,
              legendPosition: 'middle',
            }}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Please Configure Chart"
          />
        )}
        {!readOnly && (
          <VizConfigDrawer
            title="Bar Chart Configuration"
            fields={viz?.visualization_type?.params?.fields}
            config={viz?.config}
            options={{
              columns,
            }}
            isOpen={isVizConfigOpen}
            handleClose={handleCloseVizConfig}
            handleUpdate={handleUpdateVizConfig}
          />
        )}
      </div>
    </div>
  );
};

export default VizBarChart;
