// Imports
import React, { useMemo } from 'react';
import { Form, Input, Checkbox, Row, Col, Select, Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../graphql/services';

const { Option, OptGroup } = Select;

const DEFAULT_FORM_ITEM_PROPS = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

export default function SourceStep({ form, isEditing }) {
  const {
    loading: objectsLoading,
    data,
    error,
  } = GraphQLServices.DataObjects.useGetDataObjects();

  const datasources = useMemo(
    _ => {
      if (objectsLoading || !data?.datasources) {
        return [];
      } else {
        return data.datasources.map(datasource => ({
          label: datasource.datasource_name,
          value: datasource.datasource_name,
        }));
      }
    },
    [objectsLoading, data]
  );

  let datasourceNotFoundText = 'No data sources available';
  if (objectsLoading) {
    datasourceNotFoundText = 'Retrieving...';
  } else if (error) {
    datasourceNotFoundText = 'Error while retrieving';
    console.error(error);
  }

  const openDelimiterHelp = _ => {
    Modal.info({
      title: 'Help',
      content: (
        <div>
          <p style={{ marginBottom: '10px' }}>
            The default delimiter is a comma, unless a source file has one of
            these extensions:
          </p>
          <ul style={{ paddingLeft: '20px' }}>
            <li>.psv - will cause | to be the delimiter</li>
            <li>
              .tsv - will cause the tab character to be the delimiter{' '}
              <i>
                (If your file is not .tsv and you want to specify a tab, enter
                \t)
              </i>
            </li>
          </ul>
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <div id="source_form">
      <Form.Item
        {...DEFAULT_FORM_ITEM_PROPS}
        name={['options', 'datasource_name']}
        label="Data Source"
        initialValue=""
      >
        <Select disabled={isEditing} notFoundContent={datasourceNotFoundText}>
          <Option value="">Kinetica Filesystem</Option>
          {datasources.length > 0 && (
            <OptGroup label="Data Sources">
              {datasources.map(datasource => (
                <Option key={datasource.value} value={datasource.value}>
                  {datasource.label}
                </Option>
              ))}
            </OptGroup>
          )}
        </Select>
      </Form.Item>
      <Form.Item
        {...DEFAULT_FORM_ITEM_PROPS}
        name="filepath"
        label="File Path"
        rules={[
          {
            required: true,
            message: 'Please enter a data source file path.',
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        {...DEFAULT_FORM_ITEM_PROPS}
        name={['options', 'external_table_type']}
        label="Table Type"
        initialValue="materialized"
      >
        <Select>
          <Option value="materialized">
            <span>Materialized</span>
            <div>
              Loads a copy of the external data into the database, refreshed on
              demand
            </div>
          </Option>
          <Option value="logical">
            <span>Logical</span>
            <div>
              External data will not be loaded into the database; the data will
              be retrieved from the source upon servicing each query against the
              external table
            </div>
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={['options', 'file_type']}
        label="Format"
        rules={[
          {
            required: true,
            message: 'Please select a format for the data.',
          },
        ]}
        {...DEFAULT_FORM_ITEM_PROPS}
      >
        <Select>
          <Option value="delimited_text">CSV/TSV/PSV</Option>
          <Option value="parquet">Parquet</Option>
          <Option value="shapefile">Shapefile</Option>
          <Option value="json">JSON</Option>
        </Select>
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, values) =>
          prevValues?.options?.file_type !== values?.options?.file_type
        }
        style={{ marginBottom: '0px' }}
      >
        {_ => {
          const values = form.getFieldsValue();
          const fileType = values?.options?.file_type;
          return (
            fileType === 'delimited_text' && (
              <div>
                <Row gutter={0}>
                  <Col span={12}>
                    <Form.Item
                      name={['options', 'text_delimiter']}
                      label="Delimiter"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 14 }}
                    >
                      <Input
                        maxLength={4}
                        addonAfter={
                          <InfoCircleOutlined onClick={openDelimiterHelp} />
                        }
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['options', 'text_escape_character']}
                      label="Escape Character"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <Input maxLength={4}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['options', 'text_comment_string']}
                      label="Comment Identifier"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 14 }}
                      initialValue={'#'}
                    >
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['options', 'text_quote_character']}
                      label="Quote Character"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 12 }}
                      initialValue={'"'}
                    >
                      <Input maxLength={4}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['options', 'text_null_string']}
                      label="Null Identifier"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 14 }}
                      initialValue={'\\N'}
                    >
                      <Input maxLength={4}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['options', 'text_has_header']}
                      label="Has Header"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 12 }}
                      valuePropName="checked"
                      colon={false}
                      initialValue={true}
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )
          );
        }}
      </Form.Item>
    </div>
  );
}
