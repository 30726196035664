// Imports
import React, { useCallback, useMemo, useState } from 'react';
import {
  Modal,
  Button,
  Table,
  Spin,
  Space,
  Tooltip,
  Popconfirm,
  Menu,
  Dropdown,
  Typography,
  message,
} from 'antd';
import { ControlOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';
import {
  formatFriendlyNumberWhole,
  formatFriendlyNumberDecimal,
  formatFriendlyColumnName,
} from '../../formatter';
import {
  COLUMN_TYPES,
  DICT_ENCODING_SUBTYPES,
  CHAR_TYPE_LEN,
} from '../../constants';

const { info } = Modal;

const SUGGESTIONS = {
  [COLUMN_TYPES.DICT]: 'Dict Encode',
};

const TableStatsModal = ({
  table,
  visible,
  setVisible,
  width,
  height,
  pageSize,
}) => {
  const { schema, name } = table;
  const [alterColumns] = GraphQLServices.Tables.useTableAlterColumns();
  const { loading, data, refetch } =
    GraphQLServices.Tables.useGetTableColumnStats({
      variables: {
        schema,
        name,
      },
    });
  const [executeSql, { loading: queryLoading }] =
    GraphQLServices.SqlQueries.useExecuteSql();

  const [applying, setApplying] = useState(false);

  const findCommon = (arr1, arr2) => {
    return arr1.some(item => arr2.includes(item));
  };

  const checkCanDictEncode = useCallback(column => {
    const isValidSubtype = findCommon(
      DICT_ENCODING_SUBTYPES,
      column.properties
    );
    const isLong = column.properties.includes(COLUMN_TYPES.LONG);
    const isNotTimestamp = !column.properties.includes(COLUMN_TYPES.TIMESTAMP);
    const isValidInt =
      column.type === COLUMN_TYPES.INT &&
      !column.properties.includes(COLUMN_TYPES.INT8) &&
      !column.properties.includes(COLUMN_TYPES.INT16);
    const notChar1 = !column.properties.includes(COLUMN_TYPES.CHAR1);
    return (
      (isValidSubtype || (isLong && isNotTimestamp) || isValidInt) && notChar1
    );
  }, []);

  const getOptimalCharN = maxLen => {
    for (var type in CHAR_TYPE_LEN) {
      if (CHAR_TYPE_LEN[type] >= maxLen) {
        return type;
      }
    }
    return '';
  };

  const columns = useMemo(
    _ => {
      return data?.table?.columns.map(column => {
        const { name, type, properties, column_stats } = column;
        const {
          count,
          max,
          mean,
          min,
          estimated_cardinality,
          repeated_records,
          stdv,
          min_length,
          max_length,
        } = column_stats.stats;

        // Determine possible suggestions
        const suggestions = [];

        // Can be dict encoded?
        const canDictEncode = checkCanDictEncode(column);
        const isDictEncoded = properties.includes(COLUMN_TYPES.DICT);
        const isInt = properties.includes(COLUMN_TYPES.INT);
        if (
          canDictEncode &&
          !isDictEncoded &&
          ((isInt && estimated_cardinality <= 10000) ||
            estimated_cardinality <= 100000)
        ) {
          suggestions.push(COLUMN_TYPES.DICT);
        }

        // Can be smaller char?
        const charType = properties.find(prop => prop.includes('char'));
        const charTypeLen = charType ? CHAR_TYPE_LEN[charType] : 0;
        const isKey = properties.some(
          item => item === 'primary_key' || item === 'shard_key'
        );
        const hasMaxLength = max_length !== null;
        if (
          charType &&
          !isKey &&
          hasMaxLength &&
          charTypeLen > 1 &&
          max_length <= charTypeLen / 2
        ) {
          suggestions.push(getOptimalCharN(max_length));
        }

        return {
          key: name,
          column: name,
          type: [type].concat(properties).join(','),
          suggestions,
          max,
          mean,
          min,
          est_cardinality: estimated_cardinality,
          repeated_pct:
            count > 0
              ? Math.round(((100 * repeated_records) / count) * 100) / 100
              : undefined,
          standard_dev: stdv,
          min_len: min_length,
          max_len: max_length,
        };
      });
    },
    [data, checkCanDictEncode]
  );

  const handleApplyAllSuggestion = async _ => {
    const action = 'change_column';
    const alterations = [];
    columns
      .filter(column => column.suggestions.length > 0)
      .forEach(column => {
        const { column: column_name } = column;
        const types = column.type.split(',');
        const column_type = types[0];
        let column_properties = [...types.slice(1, types.length)];
        column.suggestions.forEach(rec => {
          if (rec === 'dict') {
            column_properties = [...column_properties, rec];
          } else if (rec.includes('char')) {
            column_properties = [
              rec,
              ...column_properties.filter(type => !type.includes('char')),
            ];
          }
        });
        alterations.push({
          action,
          column_name,
          column_type,
          column_properties: column_properties.join(','),
        });
      });
    if (alterations.length > 0) {
      setApplying(true);
      await alterColumns({
        variables: {
          schema,
          name,
          alterations,
        },
      });
      setApplying(false);
      refetch();
    }
  };

  const handleApplySuggestion = (column, rec) => async _ => {
    const alterations = [];
    const types = column.type.split(',');
    if (rec === 'dict') {
      alterations.push({
        action: 'change_column',
        column_name: column.column,
        column_type: types[0],
        column_properties: [...types.slice(1, types.length), rec].join(','),
      });
    } else if (rec.includes('char')) {
      alterations.push({
        action: 'change_column',
        column_name: column.column,
        column_type: types[0],
        column_properties: [
          rec,
          ...types
            .slice(1, types.length)
            .filter(type => !type.includes('char')),
        ].join(','),
      });
    }
    if (alterations.length > 0) {
      setApplying(true);
      await alterColumns({
        variables: {
          schema,
          name,
          alterations,
        },
      });
      setApplying(false);
      refetch();
    }
  };

  const buildSQL = async (row, rec) => {
    const quotedTable = `"${schema}"."${name}"`;
    const resp = await executeSql({
      variables: {
        statement: `SHOW ${quotedTable};`,
      },
    });
    const { data } = resp?.data?.executeSql?.responses[0];
    const ddl = data?.column_1[0].replace(/(\r\n|\n|\r)/gm, '\n');

    const getCharLen = char => {
      return char.split('char')[1];
    };

    const addRec = (properties, rec) => {
      if (rec.includes('char')) {
        const charLen = getCharLen(rec);
        return properties
          .split(',')
          .map(i => i.trim())
          .map(i => (!isNaN(i) ? charLen : i));
      } else {
        return properties
          .split(',')
          .map(i => i.trim())
          .concat([rec]);
      }
    };

    const propRe = new RegExp(`"${row.column}" (\\w*) \\((.*)\\) (.*)`, 'gim');
    const propOnlyRe = new RegExp(`"${row.column}" (\\w*) \\((.*)\\)`, 'gim');
    const noPropRe = new RegExp(`"${row.column}" (\\w*) (.*)`, 'gim');
    const noPropOnlyRe = new RegExp(`"${row.column}" (\\w*)`, 'gim');

    const matches =
      propRe.exec(ddl) ||
      propOnlyRe.exec(ddl) ||
      noPropRe.exec(ddl) ||
      noPropOnlyRe.exec(ddl);

    const genStatement = (properties = [], optionals = '') => {
      const statement = `ALTER TABLE ${quotedTable}
ALTER COLUMN "${row.column}" ${matches[1]}${
        properties.length > 0 ? ` (${properties.join(', ')})` : ''
      }${optionals !== '' ? ` ${optionals.replace(/,/, '')}` : ''}`;
      return `${statement.trim()};`;
    };

    if (rec === 'dict') {
      if (matches.length === 2) {
        const properties = [rec];
        return genStatement(properties);
      } else if (matches.length === 3) {
        if (ddl.match(propOnlyRe)) {
          const properties = addRec(matches[2], rec);
          return genStatement(properties);
        } else {
          return genStatement([rec], matches[2]);
        }
      } else if (matches.length === 4) {
        const properties = addRec(matches[2], rec);
        return genStatement(properties, matches[3]);
      }
    } else if (rec.includes('char')) {
      if (matches.length === 3) {
        const properties = addRec(matches[2], rec);
        return genStatement(properties);
      } else if (matches.length === 4) {
        const properties = addRec(matches[2], rec);
        return genStatement(properties, matches[3]);
      }
    }

    return 'Unable to determine SQL';
  };

  const handleSqlPreview = (row, rec) => async _ => {
    const sql = await buildSQL(row, rec);
    const modal = info({
      title: `Column: ${row.column}`,
      icon: <ConsoleSqlOutlined />,
      content: (
        <div>
          <Typography.Paragraph>
            <pre style={{ fontSize: '13px', padding: '20px' }}>{sql}</pre>
          </Typography.Paragraph>
          <CopyToClipboard
            key="copytoclipboard"
            text={sql}
            onCopy={() => {
              message.success('Copied to clipboard!');
              modal.destroy();
            }}
          >
            <Button key="submit" type="primary" size="small" ghost>
              Copy To Clipboard
            </Button>
          </CopyToClipboard>
        </div>
      ),
      okText: 'Done',
      width: 800,
      centered: true,
    });
  };

  const buildMenu = (row, rec) => {
    return (
      <Menu>
        <Menu.Item key="apply">
          <Popconfirm
            title="Are you sure you want to apply?"
            onConfirm={handleApplySuggestion(row, rec)}
          >
            Apply Change
          </Popconfirm>
        </Menu.Item>
        <Menu.Item key="sql">
          <div onClick={handleSqlPreview(row, rec)}>SQL Preview</div>
        </Menu.Item>
      </Menu>
    );
  };

  const columnValueRenderer = column => (text, row, index) => {
    if (text === undefined) {
      return (
        <div
          style={{
            textAlign: 'right',
            fontSize: '12px',
            lineHeight: '10px',
            padding: '4px 0px 0px',
            color: '#dddddd',
          }}
        >
          N/A
        </div>
      );
    }
    if (['suggestions'].includes(column)) {
      return (
        <div
          style={{
            fontSize: '13px',
            lineHeight: '10px',
          }}
        >
          {text.length > 0 ? (
            <Space>
              {text.map(rec => {
                return (
                  <Dropdown
                    key={rec}
                    overlay={buildMenu(row, rec)}
                    trigger={['click']}
                  >
                    <Button type="primary" size="small" ghost>
                      {SUGGESTIONS[rec] || rec}
                    </Button>
                  </Dropdown>
                );
              })}
            </Space>
          ) : (
            <Button size="small" disabled>
              None
            </Button>
          )}
        </div>
      );
    } else if (isNaN(text) && ['type'].includes(column)) {
      return (
        <div>
          {text
            .split(',')
            .filter(i => i !== 'data')
            .join(',')}
        </div>
      );
    } else if (isNaN(text)) {
      return text;
    } else if (['max', 'mean', 'min', 'standard_dev'].includes(column)) {
      return (
        <div
          style={{
            textAlign: 'right',
            fontFamily: 'monospace',
            fontSize: '13px',
            lineHeight: '10px',
            padding: '4px 0px 0px',
          }}
        >
          {text}
        </div>
      );
    } else if (['repeated_pct'].includes(column)) {
      return (
        <div
          style={{
            textAlign: 'right',
            fontFamily: 'monospace',
            fontSize: '13px',
            lineHeight: '10px',
            padding: '4px 0px 0px',
          }}
        >
          {Number(text)}%
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: 'right',
            fontFamily: 'monospace',
            fontSize: '13px',
            lineHeight: '10px',
            padding: '4px 0px 0px',
          }}
        >
          {formatFriendlyNumberDecimal(text)}
        </div>
      );
    }
  };

  return (
    <Modal
      title={`Table Statistics: ${table.full}`}
      visible={visible || loading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <div style={{ float: 'right', marginBottom: '20px' }}>
        <Tooltip text="Apply Suggestion">
          <Popconfirm
            title="Are you sure you want to apply all?"
            onConfirm={handleApplyAllSuggestion}
          >
            <Button icon={<ControlOutlined />} type="primary" ghost>
              Apply All Suggestions
            </Button>
          </Popconfirm>
        </Tooltip>
      </div>
      <h3>
        Record Count: {data && formatFriendlyNumberWhole(data?.table?.size)}
      </h3>
      <div style={{ display: 'block', clear: 'both' }}>
        <Spin
          indicator={<Spinner />}
          spinning={loading || applying || queryLoading}
        >
          <div
            style={{
              maxHeight: height - 100,
              minHeight: '200px',
              overflow: 'scroll',
            }}
          >
            {columns && (
              <Table
                columns={Object.keys(columns[0])
                  .filter(column => column !== 'key')
                  .map(column => {
                    return {
                      key: column,
                      title: formatFriendlyColumnName(column),
                      dataIndex: column,
                      render: columnValueRenderer(column),
                      fixed: column === 'column' ? 'left' : undefined,
                    };
                  })}
                dataSource={columns}
                pagination={{
                  pageSize,
                }}
                scroll={{
                  x: 'max-content',
                }}
                size="small"
              />
            )}
          </div>
        </Spin>
      </div>
    </Modal>
  );
};

export default TableStatsModal;
