import { useState, useEffect } from 'react';
import { Modal } from "antd";
import DSFileBrowser from "../import/DataSourceFileBrowser";

const DataSourceFileBrowserModal = ({visible, toggleFn, form}) => {
  const [dsData, setDsData] = useState({filePath: ''});

  // update from when dsData changes
  useEffect(() => {
    // console.log('setting form');
    form.setFieldsValue(dsData);
  }, [dsData, form]);

  const handleSelect = (path) => {
    // console.log('handle select', {path});
    setDsData({
      filePath: `${(form.getFieldValue('type') === 'kifs' || form.getFieldValue('type') === 'upload') ? 'kifs://': ''}${path}`,
      tableName: (path ?? '').split('/').pop().split('.').reverse().pop()
    });
  }

  return (
    <Modal
      title="Select File"
      width={'80%'}
      visible={visible}
      onOk={toggleFn}
      onCancel={toggleFn}
      footer={null}
    >
      <div>
        <DSFileBrowser form={form} path={dsData.filePath} onSelect={handleSelect} />
      </div>
    </Modal>
  )
};

export default DataSourceFileBrowserModal;