// Helper
const url = value => value.replace(/\*/, window.location.hostname);

// URLs
export const APP_URL = url(window.WORKBENCH_ENV.REACT_APP_URL);
export const APP_CONTEXT = window.WORKBENCH_ENV.REACT_APP_CONTEXT;
export const APP_URL_API = url(window.WORKBENCH_ENV.REACT_APP_API_URL);
export const APP_URL_GRAPHQL = url(window.WORKBENCH_ENV.REACT_APP_GRAPHQL_URL);
export const APP_URL_SEGMENT = url(window.WORKBENCH_ENV.REACT_APP_SEGMENT_URL ?? '');
export const API_URL = url(window.WORKBENCH_ENV.API_URL);

export const USER_CREATE_TYPE = window.WORKBENCH_ENV.USER_CREATE_TYPE ?? 'external_user';

export const K8S_STATUS_POLL_INTERVAL = window.WORKBENCH_ENV.K8S_STATUS_POLL_INTERVAL ?? 10000;
export const K8S_BACKUPS_POLL_INTERVAL = window.WORKBENCH_ENV.K8S_BACKUPS_POLL_INTERVAL ?? 10000;
export const K8S_EXTERNAL_USERNAME_PREFIX = window.WORKBENCH_ENV.K8S_EXTERNAL_USERNAME_PREFIX ?? '';

export const EXECUTE_SQL_LIMIT = window.WORKBENCH_ENV.EXECUTE_SQL_LIMIT ?? 10000;
export const USER_IDLE_TIMEOUT = window.WORKBENCH_ENV.USER_IDLE_TIMEOUT ?? 60;

export const INTERCOM_APP_ID = window.WORKBENCH_ENV.INTERCOM_APP_ID ?? '';
export const SEGMENT_API_KEY = window.WORKBENCH_ENV.SEGMENT_API_KEY ?? '';
export const CUSTOMER_INFO_JSON = window.WORKBENCH_ENV.CUSTOMER_INFO_JSON ?? '{}';

export const DEPLOYMENT_TYPE = window.WORKBENCH_ENV.DEPLOYMENT_TYPE ?? 'cloud';
export const FREE_SAAS = window.WORKBENCH_ENV.FREE_SAAS ?? false;
export const KIFS_DOWNLOAD_LIMIT = window.WORKBENCH_ENV.KIFS_DOWNLOAD_LIMIT ?? 200000000;

export const JEST_API_URL = process.env.JEST_APP_API_URL;
export const JEST_API_USERNAME = process.env.JEST_APP_API_USERNAME;
export const JEST_API_PASSWORD = process.env.JEST_APP_API_PASSWORD;
