// App Imports
import { APP_CONTEXT } from '../setup/config';

// APP Routes
export const routes = {
  context: `/${APP_CONTEXT}`,
  root: '/',
  login: '/login',
  logout: '/logout',
  home: '/home',
  dataexplore: {
    home: '/dataexplore',
    createtable: '/createtable',
    edittable: '/edittable/:schemaName/:tableName',
    workbook: '/dataexplore/workbook/:workbookId',
    embed: '/dataexplore/embed/:workbookId',
    creategraph: '/creategraph',
    solvegraph: '/solvegraph',
    createexternaltable: '/createexternaltable',
    importmodel: '/importmodel',
    configuremodel: '/configuremodel/:modelName',
    executemodel: '/executemodel/:modelName',
    createstream: '/createstream',
  },
  importexport: {
    home: '/importexport',
    source: '/importexport/:sourceKey',
  },
  jobs: {
    home: '/jobs',
    tab: '/jobs/:tabKey',
  },
  warehouse: {
    home: '/warehouse',
    tab: '/warehouse/:tabKey',
  },
  metrics: {
    home: '/metrics',
  },
  users: {
    home: '/users',
    tab: '/users/:tabKey',
  },
  preferences: {
    home: '/preferences',
  },
  debug: {
    home: '/debug',
  },
};
