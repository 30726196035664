// Imports
import React, { useMemo } from 'react';
import { Layout, Breadcrumb, Col, Row, Space } from 'antd';

// App Imports
import NavBar from '../../components/navbar/NavBar';
import Footer from '../../components/footer/Footer';
import ExplorerSider from '../common/ExplorerSider';
import Workbooks from './Workbooks';
import Import from './Import';
import Connect from './Connect';
import Reveal from './Reveal';
import Resources from './Resources';
import GraphQLServices from '../../graphql/services';
import { DEPLOYMENT_TYPE } from '../../setup/config';
import { FREE_SAAS } from '../../setup/config';

const { Header, Content } = Layout;

// Component
const Landing = () => {
  const { data: { k8s_kineticaclusters = [] } = {}, loading: clustersLoading } =
    GraphQLServices.K8sKineticaClusters.useGetK8sKineticaClusters({
      variables: {
        deployment_type: DEPLOYMENT_TYPE,
      },
    });
  const { data: { warehouses = [] } = {} } =
    GraphQLServices.Warehouses.useGetWarehouses();

  const clusters = useMemo(
    _ => {
      if (k8s_kineticaclusters) {
        return k8s_kineticaclusters;
      }
      return [];
    },
    [k8s_kineticaclusters]
  );

  const isCloud = DEPLOYMENT_TYPE === 'cloud';

  const revealEnabled = useMemo(() => {
    if (!FREE_SAAS && warehouses && warehouses.length > 0) {
      return isCloud && warehouses[0].reveal_enabled;
    }
    return false;
  }, [isCloud, warehouses]);

  return (
    <Layout className="landing" style={{ height: '100vh' }}>
      <Header className="header" style={{ padding: '0px', minWidth: '1280px' }}>
        <NavBar />
      </Header>
      <Layout hasSider>
        <ExplorerSider />
        <Content>
          <Layout style={{ height: 'calc(100vh - 55px)', minWidth: '930px' }}>
            <Content
              style={{
                padding: '0px 20px',
                height: 'calc(100vh - 210px)',
                overflowY: 'scroll',
              }}
            >
              <Breadcrumb style={{ margin: '9px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
              </Breadcrumb>
              <h2>Welcome to Workbench</h2>
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Workbooks />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={14}>
                    <Import />
                  </Col>
                  <Col span={10}>
                    <Connect
                      clusters={clusters}
                      clustersLoading={clustersLoading}
                      warehouses={warehouses}
                    />
                  </Col>
                </Row>
                {revealEnabled ? (
                  <Row gutter={16}>
                    <Col span={14}>
                      <Reveal />
                    </Col>
                    <Col span={10}>
                      <Resources />
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Resources />
                    </Col>
                  </Row>
                )}
                <div style={{ height: '4px', width: '100%' }}></div>
              </Space>
            </Content>
            <Footer />
          </Layout>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Landing;
