// Imports
import React, { useCallback, useState, useMemo } from 'react';
import { Modal, Spin, Form, Input, InputNumber, Button, Alert } from 'antd';

// App Imports
import Spinner from '../common/Spinner';
import { NUMERIC_TYPES, NULLABLE_TYPE } from '../../constants';
import GraphQLServices from '../../graphql/services';

const TableAddRecordModal = ({ table, visible, setVisible, width, height }) => {
  const { schema, name } = table;
  const {
    loading: tableLoading,
    data,
    // refetch: refetchTable,
  } = GraphQLServices.Tables.useGetTableByName({
    variables: {
      schema,
      name,
    },
  });
  const [tableInsertRecords, { error }] =
    GraphQLServices.Tables.useTableInsertRecords();

  const [isInserting, setIsInserting] = useState(false);

  const [form] = Form.useForm();

  const columns = useMemo(
    _ => {
      if (data?.table?.columns) {
        return data?.table?.columns.map(column => {
          const {
            index,
            name,
            type,
            properties,
            compression,
            memory,
            indexMemory,
          } = column;
          return {
            key: name,
            id: name,
            name,
            type,
            properties,
            compression,
            memory,
            indexMemory,
            index,
          };
        });
      }
      return [];
    },
    [data]
  );

  const onReset = useCallback(
    _ => {
      form.resetFields();
    },
    [form]
  );

  const onFinish = values => {
    const records = [values].map(record => {
      return columns.reduce((acc, cur) => {
        const isNumeric = NUMERIC_TYPES.includes(cur.type);
        const isNullable = cur.properties.includes(NULLABLE_TYPE);

        if (isNullable && isNumeric) {
          acc[cur.name] =
            record[cur.name] != null && record[cur.name] !== ''
              ? record[cur.name]
              : null;
        } else if (isNullable && !record[cur.name]) {
          acc[cur.name] = null;
        } else {
          acc[cur.name] = record[cur.name] != null ? record[cur.name] : '';
        }

        return acc;
      }, {});
    });

    setIsInserting(true);
    tableInsertRecords({
      variables: {
        schema: table.schema,
        name: table.name,
        records,
      },
    })
      .then(resp => {
        setIsInserting(false);
        if (resp?.data?.tableInsertRecords) {
          setVisible(false);
        }
      })
      .catch(err => {
        setIsInserting(false);
      });
  };

  const create = _ => {
    form.submit();
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };

  const buildRules = column => {
    const rules = [];
    if (NUMERIC_TYPES.includes(column.type)) {
      rules.push({ type: 'number' });
    }
    if (!column.properties.includes(NULLABLE_TYPE)) {
      rules.push({ required: true });
    }
    return rules;
  };

  const buildInput = column => {
    const placeholder = `${column.type} | ${column.properties.join(' | ')}`;
    return NUMERIC_TYPES.includes(column.type) ? (
      <InputNumber placeholder={placeholder} style={{ width: '100%' }} />
    ) : (
      <Input placeholder={placeholder} />
    );
  };

  return (
    <Modal
      title={`Table Add Record: ${table.full}`}
      visible={visible || tableLoading}
      footer={[
        <Button key="cancel" onClick={_ => setVisible(false)}>
          Cancel
        </Button>,
        <Button key="reset" onClick={onReset}>
          Reset
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={create}
          loading={isInserting}
        >
          Add Record
        </Button>,
      ]}
      width={width}
      onCancel={_ => setVisible(false)}
      destroyOnClose
      centered
    >
      {error && (
        <Alert
          message={error.message}
          type="error"
          style={{ marginBottom: '20px' }}
          closable
        />
      )}
      <Spin indicator={<Spinner />} spinning={isInserting}>
        <div
          style={{ maxHeight: height, minHeight: '200px', overflow: 'scroll' }}
        >
          <Form
            form={form}
            name="addrecord"
            layout="horizontal"
            initialValues={{}}
            onFinish={onFinish}
            colon={false}
            preserve={false}
            {...layout}
          >
            {columns.map(column => {
              return (
                <Form.Item
                  key={column.name}
                  label={column.name}
                  name={column.name}
                  rules={buildRules(column)}
                >
                  {buildInput(column)}
                </Form.Item>
              );
            })}
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default TableAddRecordModal;
