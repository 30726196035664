// Imports
import React, { useMemo } from 'react';
import { Modal, Table, Button, Spin, Tooltip } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';
import {
  formatK8sTimestamp,
  formatCapitalizeFirstLetter,
} from '../../formatter';

const AdminHistoryModal = ({ visible, pageSize, width, height, close }) => {
  const {
    loading,
    data: { k8s_kineticaclusteradmins = [] } = {},
    refetch,
  } = GraphQLServices.K8sKineticaClusterAdmins.useGetK8sKineticaClusterAdmins();

  const columns = [
    {
      key: 'type',
      title: 'Activity',
      dataIndex: 'type',
      render: text => {
        return formatCapitalizeFirstLetter(text);
      },
    },
    {
      key: 'creationTimestamp',
      title: 'Initiated',
      dataIndex: 'creationTimestamp',
      width: 200,
      render: text => {
        return formatK8sTimestamp(text);
      },
    },
  ];

  const crs = useMemo(
    _ => {
      return k8s_kineticaclusteradmins
        .map(cr => {
          return {
            name: cr?.metadata?.name,
            cluster: cr?.spec?.kineticaClusterName,
            type: cr?.spec?.offline?.offline ? 'suspend' : 'resume',
            creationTimestamp: cr?.metadata?.creationTimestamp,
            source: cr,
          };
        })
        .sort((a, b) => {
          if (a.creationTimestamp > b.creationTimestamp) return -1;
          if (a.creationTimestamp < b.creationTimestamp) return 1;
          return 0;
        });
    },
    [k8s_kineticaclusteradmins]
  );

  return (
    <Modal
      title="Suspend/Resume History"
      visible={visible}
      width={width}
      footer={[
        <Button key="close" onClick={close}>
          Close
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <div style={{ display: 'block', clear: 'both' }}>
        <Spin indicator={<Spinner />} spinning={loading}>
          <div style={{ right: '0px', position: 'absolute', zIndex: 1 }}>
            <Tooltip title="Refresh List">
              <Button
                icon={<RedoOutlined spin={loading} />}
                onClick={() => refetch()}
              />
            </Tooltip>
          </div>
          <div
            style={{
              paddingTop: '45px',
              height,
              minHeight: '200px',
              overflow: 'scroll',
            }}
          >
            <Table
              columns={columns}
              dataSource={crs}
              rowKey="name"
              pagination={{
                pageSize,
              }}
              scroll={{
                x: 'max-content',
              }}
              size="small"
            />
          </div>
        </Spin>
      </div>
    </Modal>
  );
};

export default AdminHistoryModal;
