import React, { useCallback, useMemo } from 'react';
import { Button, Dropdown, Menu, Popconfirm, Tooltip, Divider } from 'antd';
import {
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  UpOutlined,
  ApartmentOutlined,
  DoubleRightOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ShrinkOutlined,
  ExpandAltOutlined,
  FullscreenOutlined,
  // FormatPainterOutlined,
} from '@ant-design/icons';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import { BLOCK_TYPES } from '../../constants';
import { useIsReadOnly } from './utils';
import { sleep } from '../../helper';

export default function BlockToolbar({
  element,
  blocks,
  showExplainPlan,
  // formatSQL,
  runAllFromBlock,
  handleFullscreen,
  toggleSqlVisibility,
  toggleBlockVisibility = element => {},
  top = '-16px',
  setLoading = loading => {},
}) {
  const editor = useSlateStatic();
  const readOnly = useIsReadOnly();

  const handleAdd = useCallback(
    node => {
      const path = ReactEditor.findPath(editor, element);
      const afterPath = [path[0] + 1];
      Transforms.insertNodes(editor, node, { at: afterPath });
    },
    [editor, element]
  );

  const handleAddSQL = useCallback(
    _ => {
      handleAdd({
        type: BLOCK_TYPES.SQL,
        content: '',
        children: [{ text: '' }],
      });
    },
    [handleAdd]
  );

  const handleAddText = useCallback(
    _ => {
      handleAdd({
        type: BLOCK_TYPES.TEXT,
        content: [{ type: 'paragraph', children: [{ text: '' }] }],
        children: [{ text: '' }],
      });
    },
    [handleAdd]
  );

  const handleAddImage = useCallback(
    _ => {
      handleAdd({
        type: BLOCK_TYPES.IMAGE,
        content: {},
        children: [{ text: '' }],
      });
    },
    [handleAdd]
  );

  const handleAddHtml = useCallback(
    _ => {
      handleAdd({
        type: BLOCK_TYPES.HTML,
        content: {},
        children: [{ text: '' }],
      });
    },
    [handleAdd]
  );

  const handleAddMap = useCallback(
    _ => {
      handleAdd({
        type: BLOCK_TYPES.MAP,
        content: {},
        children: [{ text: '' }],
      });
    },
    [handleAdd]
  );

  const handleDelete = useCallback(
    async _ => {
      setLoading(true);
      await sleep(500);
      const path = ReactEditor.findPath(editor, element);
      Transforms.delete(editor, { at: path });
      Transforms.select(editor, {
        anchor: { path: [0], offset: 0 },
        focus: { path: [0], offset: 0 },
      });
      setLoading(false);
    },
    [editor, element, setLoading]
  );

  const handleMoveUp = useCallback(
    async _ => {
      setLoading(true);
      const path = ReactEditor.findPath(editor, element);
      const newPath = [Math.max(0, path[0] - 1)];
      Transforms.moveNodes(editor, { at: path, to: newPath });
      await sleep(500);
      setLoading(false);
    },
    [editor, element, setLoading]
  );

  const handleMoveDown = useCallback(
    async _ => {
      setLoading(true);
      const path = ReactEditor.findPath(editor, element);
      const newPath = [path[0] + 1];
      Transforms.moveNodes(editor, { at: path, to: newPath });
      await sleep(500);
      setLoading(false);
    },
    [editor, element, setLoading]
  );

  const handleExplainPlan = _ => {
    showExplainPlan(false);
  };

  const handleRunAnalyzePlan = _ => {
    showExplainPlan(true);
  };

  // const handleFormatSQL = _ => {
  //   formatSQL();
  // };

  const addMenu = (
    <Menu>
      <Menu.Item key="sql" onClick={handleAddSQL}>
        Add SQL Below
      </Menu.Item>
      <Menu.Item key="text" onClick={handleAddText}>
        Add Text Below
      </Menu.Item>
      <Menu.Item key="image" onClick={handleAddImage}>
        Add Image Below
      </Menu.Item>
      <Menu.Item key="html" onClick={handleAddHtml}>
        Add HTML Below
      </Menu.Item>
      <Menu.Item key="map" onClick={handleAddMap}>
        Add Map Below
      </Menu.Item>
    </Menu>
  );

  const explainPlanMenu = (
    <Menu>
      <Menu.Item key="explain" onClick={handleExplainPlan}>
        Explain Plan
      </Menu.Item>
      <Menu.Item key="analyze" onClick={handleRunAnalyzePlan}>
        Run and Analyze
      </Menu.Item>
    </Menu>
  );

  const buttonStyle = {
    marginRight: '5px',
    height: '24px',
    lineHeight: '20px',
  };

  const isCodeBlock = useMemo(
    _ => {
      return element?.type === BLOCK_TYPES.SQL;
    },
    [element]
  );

  const { isSqlVisible, isBlockVisible } = useMemo(
    _ => {
      const { isSqlVisible = true, isBlockVisible = true } =
        element?.config ?? {};
      return { isSqlVisible, isBlockVisible };
    },
    [element]
  );

  const displaySqlVisibility = visible => {
    return visible ? <ExpandAltOutlined /> : <ShrinkOutlined />;
  };
  const displayBlockVisilityIcon = visible => {
    return visible ? <EyeOutlined /> : <EyeInvisibleOutlined />;
  };

  return (
    <div
      style={{
        position: 'absolute',
        right: '5px',
        pointerEvents: 'all',
      }}
    >
      <div
        style={{
          position: 'relative',
          float: 'right',
          top,
          zIndex: 1,
        }}
      >
        {!readOnly && (
          <>
            {isCodeBlock && (
              <Tooltip title="Expand/Collapse SQL in Share/Embed Mode">
                <Button
                  icon={displaySqlVisibility(isSqlVisible)}
                  size="small"
                  style={
                    !isSqlVisible
                      ? { ...buttonStyle, color: 'red' }
                      : buttonStyle
                  }
                  onClick={toggleSqlVisibility(element)}
                ></Button>
              </Tooltip>
            )}
            <Tooltip title="Show/Hide Block in Share/Embed Mode">
              <Button
                icon={displayBlockVisilityIcon(isBlockVisible)}
                size="small"
                style={
                  !isBlockVisible
                    ? { ...buttonStyle, color: 'red' }
                    : buttonStyle
                }
                onClick={toggleBlockVisibility(element)}
              ></Button>
            </Tooltip>
            <Divider type="vertical" />
          </>
        )}
        {isCodeBlock && (
          <>
            {/* <Tooltip title="Format SQL">
                  <Button
                    icon={<FormatPainterOutlined />}
                    size="small"
                    style={buttonStyle}
                    onClick={handleFormatSQL}
                  ></Button>
                </Tooltip> */}
            <Tooltip title="Explain Plan">
              <Dropdown overlay={explainPlanMenu} trigger={['click']}>
                <Button
                  icon={<ApartmentOutlined />}
                  size="small"
                  style={buttonStyle}
                ></Button>
              </Dropdown>
            </Tooltip>
            <Tooltip title="Run All From Here">
              <Button
                icon={<DoubleRightOutlined />}
                size="small"
                style={buttonStyle}
                onClick={runAllFromBlock(element.id)}
              ></Button>
            </Tooltip>
            <Divider type="vertical" />
          </>
        )}
        {!readOnly && (
          <>
            <Tooltip title="Add New Block">
              <Dropdown overlay={addMenu} trigger={['click']}>
                <Button
                  icon={<PlusOutlined />}
                  size="small"
                  style={buttonStyle}
                ></Button>
              </Dropdown>
            </Tooltip>
            {handleFullscreen && (
              <Tooltip title="Fullscreen Mode">
                <Button
                  icon={<FullscreenOutlined />}
                  size="small"
                  style={buttonStyle}
                  onClick={handleFullscreen}
                ></Button>
              </Tooltip>
            )}
            <Tooltip title="Move Up">
              <Button
                icon={<UpOutlined />}
                size="small"
                onClick={handleMoveUp}
                style={buttonStyle}
              ></Button>
            </Tooltip>
            <Tooltip title="Move Down">
              <Button
                icon={<DownOutlined />}
                size="small"
                onClick={handleMoveDown}
                style={buttonStyle}
              ></Button>
            </Tooltip>
            <Popconfirm
              title="Are you sure you want to delete this block?"
              onConfirm={handleDelete}
              disabled={blocks.length <= 1}
            >
              <Tooltip title="Delete Block">
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  style={buttonStyle}
                  disabled={blocks.length <= 1}
                ></Button>
              </Tooltip>
            </Popconfirm>
          </>
        )}
      </div>
    </div>
  );
}
