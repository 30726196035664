// Imports
import React, { useState } from 'react';
import { Space, Button, Empty } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

// App Imports
import VizConfigDrawer from './VizConfigDrawer';
import { useIsReadOnly } from './utils';

const VizHtml = ({ viz, handleUpdate, minHeight = 180 }) => {
  const { config } = viz;

  const [isVizConfigOpen, setIsVizConfigOpen] = useState(false);
  const readOnly = useIsReadOnly();

  const handleOpenVizConfig = _ => {
    setIsVizConfigOpen(true);
  };

  const handleCloseVizConfig = _ => {
    setIsVizConfigOpen(false);
  };

  const handleUpdateVizConfig = values => {
    handleUpdate(values, _ => {
      setIsVizConfigOpen(false);
    });
  };

  return (
    <div style={{ position: 'relative', minHeight }}>
      {!readOnly && (
        <div style={{ height: 30 }}>
          <Space style={{ float: 'right' }}>
            <Button
              icon={<SettingOutlined />}
              onClick={handleOpenVizConfig}
              size="small"
            >
              Configure
            </Button>
          </Space>
        </div>
      )}
      <div className="image" style={{ minHeight: minHeight - 20 }}>
        {config?.html_source ? (
          <div dangerouslySetInnerHTML={{ __html: config?.html_source }} />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Please Configure HTML"
          />
        )}
        {!readOnly && (
          <VizConfigDrawer
            title="HTML Configuration"
            fields={viz?.visualization_type?.params?.fields}
            config={viz?.config}
            options={{}}
            isOpen={isVizConfigOpen}
            handleClose={handleCloseVizConfig}
            handleUpdate={handleUpdateVizConfig}
            columns={4}
          />
        )}
      </div>
    </div>
  );
};

export default VizHtml;
