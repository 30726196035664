// Imports
import React, { useMemo } from 'react';
import { Modal, Button, Descriptions } from 'antd';

// App Imports
import { formatFriendlyNumberWhole, formatMemory } from '../../formatter';

const GraphInfoModal = ({ graph, visible, setVisible, width, height }) => {
  const originalRequest = useMemo(
    _ => {
      try {
        return JSON.stringify(graph?.original_request, null, 4);
      } catch (error) {
        return graph?.original_request;
      }
    },
    [graph]
  );

  return (
    <Modal
      title={`Graph Info: ${graph.graph_name}`}
      visible={visible}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <div
        style={{
          maxHeight: height + 20,
          minHeight: '200px',
          overflow: 'scroll',
        }}
      >
        <Descriptions title="Summary" size="small" bordered>
          <Descriptions.Item label="Server ID">
            {graph?.graph_server_id}
          </Descriptions.Item>
          <Descriptions.Item label="Node Count">
            {formatFriendlyNumberWhole(graph?.num_nodes)}
          </Descriptions.Item>
          <Descriptions.Item label="Edge Count">
            {formatFriendlyNumberWhole(graph?.num_edges)}
          </Descriptions.Item>
          <Descriptions.Item label="Memory">
            {formatMemory(graph?.num_bytes)}
          </Descriptions.Item>
          <Descriptions.Item label="Directed">
            {graph?.directed ? 'True' : 'False'}
          </Descriptions.Item>
          <Descriptions.Item label="Persisted">
            {graph?.is_persisted ? 'True' : 'False'}
          </Descriptions.Item>
          <Descriptions.Item label="Partitioned">
            {graph?.is_partitioned ? 'True' : 'False'}
          </Descriptions.Item>
          <Descriptions.Item label="Sync DB">
            {graph?.is_sync_db ? 'True' : 'False'}
          </Descriptions.Item>
          <Descriptions.Item label="Insert Table Monitor">
            {graph?.has_insert_table_monitor ? 'True' : 'False'}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <h3>Original Request</h3>
        <pre className="console">{originalRequest}</pre>
      </div>
    </Modal>
  );
};

export default GraphInfoModal;
