// Imports
import React, { useMemo, useState } from 'react';
import { Modal, Button, Table, Spin, Tag } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import { formatFriendlyNumberWhole } from '../../formatter';
import { EXECUTE_SQL_LIMIT } from '../../setup/config';
import Spinner from '../common/Spinner';

const LENGTH_LIMIT = 64;

const truncate = text => {
  const regex = /\[(\d* characters truncated)\]/gi;
  const match = regex.exec(text);
  if (match) {
    return (
      <>
        {text.split(match[0])[0]}
        <Tag color="#f6f6f6" style={{ color: '#cccccc', marginLeft: '5px' }}>
          {match[1]}
        </Tag>
      </>
    );
  }
  return text;
};

const TablePreviewModal = ({
  table,
  visible,
  setVisible,
  width,
  height,
  pageSize,
}) => {
  const { schema, name } = table;

  const [offset] = useState(0);
  const [limit] = useState(1000);

  const { data: tableResp } = GraphQLServices.Tables.useGetTableByName({
    variables: {
      schema,
      name,
    },
  });
  const {
    data: { table_records } = {},
    loading,
    error,
  } = GraphQLServices.Tables.useGetTableRecordsByName({
    variables: {
      schema,
      name,
      offset,
      limit,
      max_json_string_length: LENGTH_LIMIT,
    },
  });

  const renderTitle = (name, type, properties) => {
    return (
      <>
        {name}
        <br />
        <span
          style={{
            fontWeight: 300,
            fontSize: '12px',
            color: '#3700b3',
          }}
        >
          {[type]
            .concat(properties.filter(prop => !['data'].includes(prop)))
            .join(', ')}
        </span>
      </>
    );
  };

  const renderColumn = (type, properties) => text => {
    return text !== undefined && text !== null ? (
      type === 'string' ? (
        <div
          style={{
            textAlign: text.toString().length === 1 ? 'center' : 'left',
          }}
        >
          {text !== '' ? (
            truncate(text)
          ) : (
            <Tag color="#f6f6f6" style={{ color: '#cccccc' }}>
              empty
            </Tag>
          )}
        </div>
      ) : type === 'long' && properties.includes('timestamp') ? (
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {new Date(text).toISOString().replace(/T|Z/g, ' ').trim()}
        </div>
      ) : (
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {text}
        </div>
      )
    ) : type === 'string' ? (
      <Tag color="#f6f6f6" style={{ color: '#cccccc', margin: '0px' }}>
        null
      </Tag>
    ) : (
      <Tag
        color="#f6f6f6"
        style={{ color: '#cccccc', margin: '0px', float: 'right' }}
      >
        null
      </Tag>
    );
  };

  const columns = useMemo(
    _ => {
      if (tableResp) {
        const columns = tableResp?.table?.columns ?? [];
        return columns.map(column => {
          const { name, type, properties } = column;
          return {
            key: name,
            title: renderTitle(name, type, properties),
            dataIndex: name,
            render: renderColumn(type, properties),
          };
        });
      }
      return [];
    },
    [tableResp]
  );

  return (
    <Modal
      title={
        <>
          {`Table Preview: ${schema}.${name}`}{' '}
          <label
            style={{
              fontWeight: 300,
              color: '#bbbbbb',
              float: 'right',
              marginRight: '20px',
              fontSize: '14px',
            }}
          >
            Limit: {formatFriendlyNumberWhole(EXECUTE_SQL_LIMIT)} Records
          </label>
        </>
      }
      visible={visible || loading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={loading}>
        <div
          style={{
            height,
            overflow: 'scroll',
          }}
        >
          {table_records && (
            <Table
              rowKey="__record_id"
              columns={columns}
              dataSource={table_records.data}
              pagination={{
                pageSize,
                pageSizeOptions: [pageSize],
                showSizeChanger: false,
              }}
              scroll={{
                x: 'max-content',
              }}
              size="small"
            />
          )}
          {error && (
            <pre className="console">Query failed: {error.message}</pre>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default TablePreviewModal;
