// Imports
import React from 'react';
import { Dropdown, Menu, Modal } from 'antd';
import {
  FileTextOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../../graphql/services';
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const { confirm } = Modal;

const WorksheetObject = ({ nodeData, refresh, editClick }) => {
  const [removeWorksheetById] =
    GraphQLServices.Worksheets.useRemoveWorksheetById();

  const handleWorksheetContextDelete = worksheet => e => {
    confirm({
      title: `Do you want to delete worksheet ${worksheet.name}?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        removeWorksheetById({
          variables: {
            id: worksheet.id,
          },
        })
          .then(resp => {
            refresh();
          })
          .catch(err => {
            console.error(err);
          });
      },
      onCancel() {
        // Do nothing
      },
      width: 600,
      centered: true,
    });
  };

  const worksheetContextMenu = node => {
    return (
      <Menu>
        <Menu.Item key="3" onClick={editClick(node.source)}>
          <EditOutlined /> Edit
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={handleWorksheetContextDelete(node.source)}
          style={{ color: '#C62828' }}
        >
          <DeleteOutlined /> Delete
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Dropdown
      overlay={worksheetContextMenu(nodeData)}
      trigger={['click', 'contextMenu']}
    >
      <div>
        <FileTextOutlined />{' '}
        <span
          style={{
            width: `${EXPLORER_PANEL_WIDTH - 125}px`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            lineHeight: '16px',
            verticalAlign: 'text-top',
          }}
        >
          {nodeData.title}
        </span>
      </div>
    </Dropdown>
  );
};

export default WorksheetObject;
