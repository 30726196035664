// Imports
import React from 'react';
import ReactJson from 'react-json-view';
import {
  Button,
  Layout,
  Breadcrumb,
  Space,
  Tooltip,
  Spin,
  Collapse,
  Descriptions,
  Divider,
} from 'antd';
import { RedoOutlined } from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../graphql/services';
import NavBar from '../../components/navbar/NavBar';
import Footer from '../../components/footer/Footer';
import Spinner from '../../components/common/Spinner';

const { Header, Content } = Layout;
const { Panel } = Collapse;

// Component
const Debug = () => {
  const {
    loading: debugLoading,
    data,
    refetch,
  } = GraphQLServices.Debug.useDebug();

  return (
    <Layout style={{ height: '100vh' }}>
      <Header className="header" style={{ padding: '0px', minWidth: '1280px' }}>
        <NavBar />
      </Header>
      <Layout>
        <Content>
          <Layout style={{ height: 'calc(100vh - 55px)', minWidth: '930px' }}>
            <Content
              style={{
                padding: '0px 20px',
                height: 'calc(100vh - 210px)',
                overflowY: 'scroll',
              }}
            >
              <Breadcrumb style={{ margin: '9px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Debug</Breadcrumb.Item>
              </Breadcrumb>
              <div style={{ float: 'right' }}>
                <Space>
                  <Tooltip title="Refresh">
                    <Button
                      icon={<RedoOutlined spin={debugLoading} />}
                      onClick={() => {
                        refetch();
                      }}
                    >
                      Refresh
                    </Button>
                  </Tooltip>
                </Space>
              </div>
              <h2>Debug</h2>
              <Content
                style={{
                  padding: '20px 20px 0px',
                  backgroundColor: '#ffffff',
                  height: 'calc(100vh - 210px)',
                  overflow: 'auto',
                }}
              >
                <Spin indicator={<Spinner />} spinning={false}>
                  <div
                    style={{
                      minHeight: window.innerHeight - 250,
                      marginBottom: '20px',
                    }}
                  >
                    <Descriptions
                      title="Versions"
                      size="small"
                      column={1}
                      bordered
                    >
                      {data?.k8s_kineticaclusters.map(cluster => {
                        return (
                          <>
                            <Descriptions.Item label="Cluster Image">
                              {cluster?.spec?.gpudbCluster?.image}
                            </Descriptions.Item>
                            <Descriptions.Item label="Cluster HM Version">
                              {cluster?.status?.hmStatus?.version}
                            </Descriptions.Item>
                          </>
                        );
                      })}
                      {data?.k8s_kineticaworkbenches.map(workbench => {
                        return (
                          <>
                            <Descriptions.Item label="Workbench Image">
                              {workbench?.spec?.image}
                            </Descriptions.Item>
                          </>
                        );
                      })}
                      {data?.app && (
                        <>
                          <Descriptions.Item label="Workbench Version">
                            {data?.app?.app_version}
                          </Descriptions.Item>
                          <Descriptions.Item label="Workbench Commit Hash">
                            {data?.app?.app_commit_hash}
                          </Descriptions.Item>
                          <Descriptions.Item label="Workbench Operator Version">
                            {data?.app?.operator_version}
                          </Descriptions.Item>
                          <Descriptions.Item label="Workbench Operator Build Date">
                            {data?.app?.operator_build_date}
                          </Descriptions.Item>
                          <Descriptions.Item label="Workbench Operator Commit Hash">
                            {data?.app?.operator_commit_hash}
                          </Descriptions.Item>
                        </>
                      )}
                    </Descriptions>
                    <Divider dashed />
                    <Collapse defaultActiveKey={[]} ghost>
                      {data &&
                        Object.keys(data).map(key => {
                          const src = data[key] || [];
                          return (
                            <Panel header={key} key={key}>
                              <ReactJson
                                src={src}
                                collapsed={3}
                                iconStyle="square"
                                indentWidth={2}
                                displayDataTypes={false}
                                style={{
                                  fontSize: '12px',
                                  lineHeight: '13px',
                                  margin: '0px 0px 0px 20px',
                                }}
                                enableClipboard={false}
                                sortKeys
                                theme={{
                                  base00: 'white',
                                  base01: '#ddd',
                                  base02: '#ddd',
                                  base03: '#444',
                                  base04: 'purple',
                                  base05: '#444',
                                  base06: '#444',
                                  base07: '#444',
                                  base08: '#444',
                                  base09: 'rgba(70, 70, 230, 1)',
                                  base0A: 'rgba(70, 70, 230, 1)',
                                  base0B: 'rgba(70, 70, 230, 1)',
                                  base0C: 'rgba(70, 70, 230, 1)',
                                  base0D: 'rgba(70, 70, 230, 1)',
                                  base0E: 'rgba(70, 70, 230, 1)',
                                  base0F: 'rgba(70, 70, 230, 1)',
                                }}
                              />
                            </Panel>
                          );
                        })}
                    </Collapse>
                  </div>
                </Spin>
              </Content>
            </Content>
            <Footer />
          </Layout>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Debug;
