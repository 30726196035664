// Imports
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {} from 'react-router-dom';
import { Button, Form, notification, Popconfirm, Steps, Tooltip } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { gql, useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import SourceStep from './SourceStep';
import DestinationStep from './DestinationStep';
import TableConfigureStep from './TableConfigureStep';
import SummaryStep from './SummaryStep';
import {
  extractFields,
  extractProperties,
  extractColumnFormats,
} from '../createtable/utils';
import { useCreateImportJob } from './utils';
import {
  DATASOURCE_LOCATION_AZURE,
  DATASOURCE_LOCATION_HDFS,
  DATASOURCE_LOCATION_JDBC,
  DATASOURCE_LOCATION_KAFKA,
  DATASOURCE_LOCATION_S3,
} from '../../constants';
import { FREE_SAAS } from '../../setup/config';

const { Step } = Steps;

const MAX_STEPS = 4;
const DESTINATION_STEP = 1;

const KINETICA_SUBTYPES = [
  'int8',
  'int16',
  'char1',
  'char2',
  'char4',
  'char8',
  'char16',
  'char32',
  'char64',
  'char128',
  'char256',
  'date',
  'datetime',
  'time',
  'ipv4',
  'decimal',
  'ulong',
  'uuid',
  'wkt',
  'timestamp',
];

const GET_TABLE_INFO = gql`
  query GetTableByName($schema: String!, $name: String!) {
    table(schema: $schema, name: $name) {
      schema
      name
      additional_info
      full
      size
      columns {
        name
        type
        table_name
        properties
      }
    }
  }
`;

export default function ImportForm({
  children,
  setActiveTabKey,
  cdata_drivers = [],
}) {
  const { data: { userMe = {} } = {} } =
    GraphQLServices.Users.useGetUserMeKineticaOnly();

  const [insertRecordsFromFiles, { loading: loadingFilesPreview }] =
    GraphQLServices.Inserts.useInsertRecordsFromFiles();
  const [insertRecordsFromQuery, { loading: loadingQueryPreview }] =
    GraphQLServices.Inserts.useInsertRecordsFromQuery();

  const [step, setStep] = useState(0);
  const [columns, setColumns] = useState([]);
  const [tableExists, setTableExists] = useState(false);
  const [form] = Form.useForm();
  const [setupForm] = Form.useForm();
  const apolloClient = useApolloClient();
  const [createImportJob, { loading: creatingImportJob }] =
    useCreateImportJob();
  const location = useLocation();
  const history = useHistory();
  const analytics = useAnalytics();

  const uploaderRef = useRef(null);
  const [files, setFiles] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [formValues, setFormValues] = useState({});

  const cdata_types = useMemo(() => {
    return cdata_drivers.map(driver => driver.type);
  }, [cdata_drivers]);

  useEffect(() => {
    if (FREE_SAAS) {
      const { schemaName = '' } = form.getFieldsValue();
      if (
        schemaName === '' &&
        userMe &&
        userMe?.kinetica_user?.default_schema
      ) {
        form.setFieldsValue({
          schemaName: userMe?.kinetica_user?.default_schema,
          badRecordSchema: userMe?.kinetica_user?.default_schema,
        });
      }
    }
  }, [userMe, form]);

  useEffect(
    _ => {
      form.setFieldsValue({
        type:
          location.pathname.split('/').length === 3
            ? location.pathname.split('/')[2]
            : undefined,
      });

      if (location.state) {
        if (location.state.datasource) {
          form.setFieldsValue({
            dataSource: location.state.datasource.datasource_name,
          });
        }
        if (location.state.filepath) {
          form.setFieldsValue({
            filePath: location.state.filepath,
          });
        }
        if (location.state.fileformat) {
          form.setFieldsValue({
            fileFormat: location.state.fileformat,
          });
        }
        if (location.state.tableName) {
          form.setFieldsValue({
            tableName: location.state.tableName,
          });
        }
        if (location.state.remoteQuery) {
          form.setFieldsValue({
            remoteQuery: location.state.remoteQuery,
          });
        }
      }
    },
    [location, form]
  );

  const stepOffset = useMemo(
    _ => {
      return children ? 1 : 0;
    },
    [children]
  );

  const handlePreviewColumns = useCallback(
    _ => {
      const values = form.getFieldsValue();
      const {
        type,
        tableName,
        filePath = '',
        dataSource,
        remoteQuery,
      } = values;

      // Check if CDATA type
      const isCDATA = cdata_types.includes(type);

      if (tableName) {
        if ((type === DATASOURCE_LOCATION_JDBC || isCDATA) && remoteQuery) {
          insertRecordsFromQuery({
            variables: {
              table_name: uuid(),
              remote_query: remoteQuery,
              modify_columns: {},
              create_table_options: {},
              options: {
                ingestion_mode: 'type_inference_only',
                datasource_name: dataSource,
              },
            },
          })
            .then(resp => {
              if (resp?.errors?.length === 0 || !resp?.errors) {
                const initialColumns =
                  resp.data.insertRecordsFromQuery.columns.map(col => {
                    const { name, properties, field_desc } = col;
                    const subtype =
                      properties.find(prop =>
                        KINETICA_SUBTYPES.includes(prop)
                      ) || '';
                    const storage = properties.includes('data')
                      ? 'data'
                      : 'store_only';
                    const id = uuid();
                    return {
                      key: id,
                      id,
                      columnName: name,
                      nullable: properties.includes('nullable'),
                      properties: properties.filter(
                        prop =>
                          !KINETICA_SUBTYPES.includes(prop) &&
                          prop !== 'data' &&
                          prop !== 'store_only'
                      ),
                      storage,
                      type: [
                        Array.isArray(col.type) ? col.type[0] : col.type,
                        subtype,
                      ],
                      field_desc,
                    };
                  });
                setColumns(initialColumns);
              }
            })
            .catch(err => {
              console.error(err);
            });
        } else {
          insertRecordsFromFiles({
            variables: {
              table_name: uuid(),
              filepaths: [filePath],
              modify_columns: {},
              create_table_options: {},
              options: {
                ingestion_mode: 'type_inference_only',
                datasource_name: dataSource,
              },
            },
          })
            .then(resp => {
              if (resp?.errors?.length === 0 || !resp?.errors) {
                const initialColumns =
                  resp.data.insertRecordsFromFiles.columns.map(col => {
                    const { name, properties, field_desc } = col;
                    const subtype =
                      properties.find(prop =>
                        KINETICA_SUBTYPES.includes(prop)
                      ) || '';
                    const storage = properties.includes('data')
                      ? 'data'
                      : 'store_only';
                    const id = uuid();
                    return {
                      key: id,
                      id,
                      columnName: name,
                      nullable: properties.includes('nullable'),
                      properties: properties.filter(
                        prop =>
                          !KINETICA_SUBTYPES.includes(prop) &&
                          prop !== 'data' &&
                          prop !== 'store_only'
                      ),
                      storage,
                      type: [
                        Array.isArray(col.type) ? col.type[0] : col.type,
                        subtype,
                      ],
                      field_desc,
                    };
                  });
                setColumns(initialColumns);
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      }
    },
    [insertRecordsFromFiles, insertRecordsFromQuery, form, cdata_types]
  );

  const handleAddColumn = useCallback(
    _ => {
      const id = uuid();
      setColumns(prevColumns => [...prevColumns, { id, key: id }]);
    },
    [setColumns]
  );

  const handleRemoveColumn = useCallback(
    id => {
      setColumns(prevColumns => [...prevColumns].filter(col => col.id !== id));
    },
    [setColumns]
  );

  const handleUpdateColumn = useCallback(
    (id, payload) => {
      setColumns(prevColumns => {
        const columns = [...prevColumns];
        const index = prevColumns.findIndex(col => col.id === id);
        columns[index] = { ...columns[index], ...payload };
        return columns;
      });
    },
    [setColumns]
  );

  const handleComplete = useCallback(
    _ => {
      setActiveTabKey('history');
    },
    [setActiveTabKey]
  );

  const handleClear = useCallback(
    _ => {
      const type = form.getFieldValue('type');
      form.resetFields();
      setupForm.resetFields();

      if (uploaderRef.current) {
        const fileIds = uploaderRef.current.getFiles().map(file => file.id);
        fileIds.forEach(id => {
          uploaderRef.current.removeFile(id);
        });
        setFiles([]);
      }

      form.setFieldsValue({ type });
      setColumns([]);
      setTableExists(false);
      setStep(0);
    },
    [form, setupForm]
  );

  const handleImport = useCallback(
    _ => {
      form
        .validateFields()
        .then(values => {
          let createOptions = null;
          if (!tableExists && columns.length > 0) {
            createOptions = {
              fields: extractFields(columns),
              properties: extractProperties(columns),
            };
          }

          analytics.track(analytics.EVENT_TYPES.IMPORTED_DATA)({
            type: values.type,
          });

          return createImportJob({
            variables: {
              type: values.type,
              isCDATA: cdata_types.includes(values.type),
              table: values.tableName,
              schema: values.schemaName ?? '',
              createOptions,
              datasource: values.dataSource,
              filePath: values.filePath,
              fileFormat: values.fileFormat,
              remoteQuery: values.remoteQuery,
              formatOptions: values.formatOptions,
              abortOnError: values.abortOnError,
              storePointsAsXY: values.storePointsAsXY,
              batchSize: values.batchSize,
              createSchema: true,
              badRecordSchema: values.badRecordSchema,
              badRecordTable: values.badRecordTable,
              columnFormats: extractColumnFormats(columns),
            },
          });
        })
        .then(_ => {
          handleClear();
          handleComplete();
        })
        .catch(err => {
          if (err) {
            if (err.errorFields) {
              err.errorFields
                .map(e => e.errors)
                .forEach(e =>
                  e.forEach(msg =>
                    notification.open({
                      message: 'Validation Error Occurred',
                      description: msg,
                      type: 'error',
                      duration: 0,
                    })
                  )
                );

              console.log(
                err.errorFields.map(e => e.errors.join('\n')).join('\n')
              );
            } else if (err.title && err.details) {
              notification.open({
                message: err.title,
                description: err.details,
                type: 'error',
                duration: 0,
              });
            } else {
              console.error(err);
              notification.open({
                message: 'Error Occurred',
                description: err.toString(),
                type: 'error',
                duration: 0,
              });
            }
          }
        });
    },
    [
      form,
      columns,
      tableExists,
      createImportJob,
      handleClear,
      handleComplete,
      cdata_types,
      analytics,
    ]
  );

  const handleNextTransition = useCallback(
    _ => {
      const values = form.getFieldsValue();
      if (
        (!values.tableName && !values.schemaName) ||
        step !== DESTINATION_STEP + stepOffset
      ) {
        // Nothing to set up if table name hasn't been chosen.
        setStep(Math.min(step + 1, MAX_STEPS + stepOffset - 1));
      } else {
        apolloClient
          .query({
            query: GET_TABLE_INFO,
            variables: {
              schema: values.schemaName ?? '',
              name: values.tableName,
            },
          })
          .then(resp => {
            if (resp?.errors?.length === 0 || !resp?.errors) {
              const initialColumns = resp.data.table.columns.map(col => {
                const properties = col.properties;
                const subtype =
                  properties.find(prop => KINETICA_SUBTYPES.includes(prop)) ||
                  '';
                const storage = properties.includes('data')
                  ? 'data'
                  : 'store_only';
                const id = uuid();
                return {
                  key: id,
                  id,
                  columnName: col.name,
                  nullable: properties.includes('nullable'),
                  properties: properties.filter(
                    prop =>
                      !KINETICA_SUBTYPES.includes(prop) &&
                      prop !== 'data' &&
                      prop !== 'store_only'
                  ),
                  storage,
                  type: [
                    Array.isArray(col.type) ? col.type[0] : col.type,
                    subtype,
                  ],
                };
              });
              setTableExists(true);
              setColumns(initialColumns);
            } else {
              setTableExists(false);
            }
            setStep(Math.min(step + 1, MAX_STEPS - 1));
          })
          .catch(err => {
            console.error(err);
            setTableExists(false);
            setStep(Math.min(step + 1, MAX_STEPS - 1));
          });
      }
    },
    [form, step, apolloClient, stepOffset]
  );

  const handleCancel = e => {
    history.push('/importexport');
  };

  const handleStepClick = step => {
    if (stepOffset) {
      setStep(stepOffset + step - 1);
    } else {
      setStep(step);
    }
  };

  const validateSetup = useCallback(
    form => {
      let valid = false;
      const type =
        location.pathname.split('/').length === 3
          ? location.pathname.split('/')[2]
          : undefined;

      // Check if CDATA type
      const isCDATA = cdata_types.includes(type);
      const typeCheck = isCDATA ? DATASOURCE_LOCATION_JDBC : type;

      switch (typeCheck) {
        case DATASOURCE_LOCATION_AZURE:
        case DATASOURCE_LOCATION_S3:
        case DATASOURCE_LOCATION_KAFKA:
        case DATASOURCE_LOCATION_JDBC:
        case DATASOURCE_LOCATION_HDFS:
          valid =
            form.getFieldValue('datasource') !== undefined &&
            form.getFieldValue('datasource') !== '';
          return {
            valid,
            error: !valid
              ? 'Data source must be created and/or selected'
              : undefined,
          };
        case 'upload':
          valid =
            form.getFieldValue('filePath') !== undefined &&
            form.getFieldValue('filePath') !== '';
          return {
            valid,
            error: !valid ? 'File must be uploaded' : undefined,
          };
        case 'kifs':
          valid =
            form.getFieldValue('file') !== undefined &&
            form.getFieldValue('file').length > 0;
          return {
            valid,
            error: !valid ? 'File must be selected' : undefined,
          };
        default:
          return { valid: true };
      }
    },
    [location, cdata_types]
  );

  return (
    <div>
      <Steps
        current={step}
        onChange={handleStepClick}
        style={{ marginBottom: '20px' }}
      >
        {children && <Step title="Setup"></Step>}
        <Step title="Source"></Step>
        <Step title="Destination"></Step>
        <Step title="Configure"></Step>
        <Step title="Summary"></Step>
      </Steps>
      <div
        style={{
          height: 'calc(100vh - 430px)',
          margin: '30px 0px',
          overflow: 'auto',
        }}
      >
        {children && (
          <div style={{ display: step === 0 ? 'block' : 'none' }}>
            {typeof children === 'function'
              ? children({
                  form,
                  setupForm,
                  setFormValues,
                  setStep,
                  uploaderRef,
                  files,
                  setFiles,
                  cdata_types,
                })
              : children}
          </div>
        )}
        <div style={{ display: step === 0 + stepOffset ? 'block' : 'none' }}>
          <SourceStep
            form={form}
            showDatasource={!children}
            cdata_types={cdata_types}
          ></SourceStep>
        </div>
        <div style={{ display: step === 1 + stepOffset ? 'block' : 'none' }}>
          <DestinationStep
            form={form}
            cdata_types={cdata_types}
          ></DestinationStep>
        </div>
        <div style={{ display: step === 2 + stepOffset ? 'block' : 'none' }}>
          <TableConfigureStep
            form={form}
            columns={columns}
            addColumn={handleAddColumn}
            removeColumn={handleRemoveColumn}
            updateColumn={handleUpdateColumn}
            tableExists={tableExists}
            previewColumns={handlePreviewColumns}
            loadingPreview={loadingFilesPreview || loadingQueryPreview}
            cdata_types={cdata_types}
          ></TableConfigureStep>
        </div>
        <div style={{ display: step === 3 + stepOffset ? 'block' : 'none' }}>
          <SummaryStep
            form={form}
            columns={columns}
            extractFields={extractFields}
            extractColmnFormats={extractColumnFormats}
            extractProperties={extractProperties}
            cdata_types={cdata_types}
          ></SummaryStep>
        </div>
      </div>
      <div>
        <Button
          icon={<LeftOutlined />}
          onClick={_ => setStep(Math.max(step - 1, 0))}
          disabled={step === 0}
          style={{ float: 'left' }}
        >
          Back
        </Button>
        <Tooltip
          title={
            !validateSetup(setupForm).valid && validateSetup(setupForm).error
          }
        >
          <Button
            onClick={handleNextTransition}
            disabled={
              step === MAX_STEPS - 1 + stepOffset ||
              (children && !validateSetup(setupForm).valid)
            }
            style={{ float: 'right' }}
          >
            Next <RightOutlined />
          </Button>
        </Tooltip>
        <div style={{ float: 'right', marginRight: '10px' }}>
          <Form form={form} layout="inline">
            <Form.Item
              shouldUpdate={(prevValues, values) =>
                prevValues.tableName !== values.tableName ||
                prevValues.schemaName !== values.schemaName ||
                prevValues.filePath !== values.filePath ||
                prevValues.fileFormat !== values.fileFormat ||
                prevValues.remoteQuery !== values.remoteQuery
              }
              style={{ marginRight: '0px' }}
            >
              {_ => {
                const values = form.getFieldsValue();
                const isValid =
                  (values.fileFormat || values.remoteQuery) && values.tableName;
                return (
                  <Tooltip
                    title={
                      !isValid ? 'Please complete all required fields' : ''
                    }
                  >
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      onClick={handleImport}
                      disabled={!isValid}
                      loading={creatingImportJob}
                    >
                      Import
                    </Button>
                  </Tooltip>
                );
              }}
            </Form.Item>
          </Form>
        </div>
        {(!children || step > 0) && (
          <Popconfirm
            title="Are you sure you want to clear all your input?"
            onConfirm={handleClear}
          >
            <Button
              icon={<DeleteOutlined />}
              style={{ float: 'right', marginRight: '10px' }}
            >
              Clear
            </Button>
          </Popconfirm>
        )}
        <Popconfirm
          title="Are you sure you want to cancel?"
          onConfirm={handleCancel}
        >
          <Button style={{ float: 'right', marginRight: '10px' }} danger>
            Cancel
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
