// Imports
import React from 'react';
import { Modal, Button, Spin, Descriptions } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';

const DatasinkInfoModal = ({
  datasink,
  visible,
  setVisible,
  width,
  height,
}) => {
  const { datasink_name } = datasink;
  const { loading: datasinkLoading, data } =
    GraphQLServices.Datasinks.useGetDatasinkByName({
      variables: {
        datasink_name,
      },
    });

  return (
    <Modal
      title={`Data Sink Info: ${datasink_name}`}
      visible={visible || datasinkLoading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={datasinkLoading}>
        <div
          style={{
            maxHeight: height + 20,
            minHeight: '200px',
            overflow: 'scroll',
          }}
        >
          {data?.datasink && (
            <Descriptions title="Summary" size="small" column={1} bordered>
              <Descriptions.Item label="Destination Type">
                {data?.datasink?.destination_type}
              </Descriptions.Item>
              <Descriptions.Item label="Destination">
                {data?.datasink?.additional_info?.destination}
              </Descriptions.Item>
            </Descriptions>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default DatasinkInfoModal;
