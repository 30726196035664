//Imports
import React, { useMemo, useCallback } from 'react';
import { Tabs, Button, Row, Col } from 'antd';
import {
  TableOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  LineChartOutlined,
  PieChartOutlined,
  DotChartOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';

// App Imports
import GraphQLServices from '../../graphql/services';
import { VIZ_TYPES } from '../../constants';
import VizBarChart from './VizBarChart';
import VizLineChart from './VizLineChart';
import VizPieChart from './VizPieChart';
import VizScatterPlotChart from './VizScatterPlotChart';
import ResultTable from './ResultTable';
import ResultInfo from './ResultInfo';
import ResultError from './ResultError';
import { addOrUpdateBlockResult } from '../../store/worksheet/actions';

const RESULT_PANEL_MIN_HEIGHT = 260;
const { TabPane } = Tabs;

export default function ResultView({
  element: { id: block_id },
  queryResponse,
  queryError,
  readOnly = false,
}) {
  const {
    data: { visualizationsByBlockIds: visualizations } = [],
    refetch: refetchVisualizations,
  } = GraphQLServices.Visualizations.useGetVisualizationsByBlockIds({
    variables: {
      block_ids: [block_id],
    },
  });
  const [createVisualization] =
    GraphQLServices.Visualizations.useCreateVisualization();
  const [updateVisualizationById] =
    GraphQLServices.Visualizations.useUpdateVisualizationById();
  const [removeVisualizationById] =
    GraphQLServices.Visualizations.useRemoveVisualizationById();
  const dispatch = useDispatch();

  const handleAddBarChartViz = useCallback(
    _ => {
      const { BAR_CHART: visualization_type_id } = VIZ_TYPES;
      createVisualization({
        variables: {
          name: 'Bar Chart Visualization',
          description: 'Description for bar chart visualization',
          config: { type: 'bar_chart' },
          block_id,
          visualization_type_id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [block_id, createVisualization, refetchVisualizations]
  );

  const handleAddLineChartViz = useCallback(
    _ => {
      const { LINE_CHART: visualization_type_id } = VIZ_TYPES;
      createVisualization({
        variables: {
          name: 'Line Chart Visualization',
          description: 'Description for line chart visualization',
          config: { type: 'line_chart' },
          block_id,
          visualization_type_id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [block_id, createVisualization, refetchVisualizations]
  );

  const handleAddScatterPlotChartViz = useCallback(
    _ => {
      const { SCATTER_PLOT_CHART: visualization_type_id } = VIZ_TYPES;
      createVisualization({
        variables: {
          name: 'Scatter Plot Chart Visualization',
          description: 'Description for scatter plot chart visualization',
          config: { type: 'scatter_plot_chart' },
          block_id,
          visualization_type_id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [block_id, createVisualization, refetchVisualizations]
  );

  const handleAddPieChartViz = useCallback(
    _ => {
      const { PIE_CHART: visualization_type_id } = VIZ_TYPES;
      createVisualization({
        variables: {
          name: 'Pie Chart Visualization',
          description: 'Description for pie chart visualization',
          config: { type: 'pie_chart' },
          block_id,
          visualization_type_id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [block_id, createVisualization, refetchVisualizations]
  );

  const handleUpdateViz = useCallback(
    (config, callback) => {
      updateVisualizationById({
        variables: {
          id: visualizations?.length > 0 && visualizations[0].id,
          config,
        },
      }).then(resp => {
        refetchVisualizations();
        if (callback) {
          callback();
        }
      });
    },
    [updateVisualizationById, refetchVisualizations, visualizations]
  );

  const handleRemoveViz = useCallback(
    _ => {
      removeVisualizationById({
        variables: {
          id: visualizations?.length > 0 && visualizations[0].id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [visualizations, removeVisualizationById, refetchVisualizations]
  );

  const clearResult = useCallback(
    _ => {
      dispatch(
        addOrUpdateBlockResult(block_id, {
          queryError: null,
          queryResponse: null,
          queryRunning: false,
        })
      );
    },
    [block_id, dispatch]
  );

  const chartData = useMemo(
    _ => {
      if (queryResponse?.response?.data) {
        const { data } = queryResponse?.response;
        return data;
      }
      return {};
    },
    [queryResponse]
  );

  const columns = useMemo(
    _ => {
      if (queryResponse?.response?.data) {
        const { data } = queryResponse?.response;
        return data.column_headers;
      }
      return [];
    },
    [queryResponse]
  );

  const dataTab = useMemo(_ => {
    return (
      <span>
        <TableOutlined />
        Data
      </span>
    );
  }, []);

  const vizTab = useMemo(_ => {
    return (
      <span>
        <AreaChartOutlined />
        Visualization
      </span>
    );
  }, []);

  const resultViz = useMemo(
    _ => {
      if (
        visualizations &&
        queryResponse?.response?.total_number_of_records >= 0
      ) {
        return (
          <div
            style={{
              padding: '1px',
              backgroundColor: '#fcfcfc',
              margin: '10px 0px 0px',
            }}
          >
            <Tabs
              defaultActiveKey={visualizations?.length > 0 ? 'viz' : 'data'}
              size="small"
              type="card"
            >
              <TabPane tab={dataTab} key="data">
                <div style={{ padding: '10px' }}>
                  <ResultTable
                    clearResult={clearResult}
                    queryResponse={queryResponse}
                  />
                </div>
              </TabPane>
              {(!readOnly || visualizations.length > 0) && (
                <TabPane tab={vizTab} key="viz">
                  <div style={{ padding: '10px', overflow: 'hidden' }}>
                    {visualizations?.length === 0 && (
                      <Row gutter={20}>
                        <Col span={6}>
                          <Button
                            onClick={handleAddBarChartViz}
                            size="large"
                            type="dashed"
                            block
                          >
                            <BarChartOutlined /> Bar Chart
                          </Button>
                        </Col>
                        <Col span={6}>
                          <Button
                            onClick={handleAddLineChartViz}
                            size="large"
                            type="dashed"
                            block
                          >
                            <LineChartOutlined /> Line Chart
                          </Button>
                        </Col>
                        <Col span={6}>
                          <Button
                            onClick={handleAddPieChartViz}
                            size="large"
                            type="dashed"
                            block
                          >
                            <PieChartOutlined />
                            Pie Chart
                          </Button>
                        </Col>
                        <Col span={6}>
                          <Button
                            onClick={handleAddScatterPlotChartViz}
                            size="large"
                            type="dashed"
                            block
                          >
                            <DotChartOutlined />
                            Scatter Plot
                          </Button>
                        </Col>
                      </Row>
                    )}
                    {visualizations?.length > 0 &&
                      visualizations?.map(visualization => {
                        if (
                          visualization.visualization_type.id ===
                          VIZ_TYPES.BAR_CHART
                        ) {
                          return (
                            <VizBarChart
                              key={visualization.id}
                              viz={visualization}
                              data={chartData}
                              columns={columns}
                              handleUpdate={handleUpdateViz}
                              handleRemove={handleRemoveViz}
                              minHeight={RESULT_PANEL_MIN_HEIGHT}
                            />
                          );
                        } else if (
                          visualization.visualization_type.id ===
                          VIZ_TYPES.LINE_CHART
                        ) {
                          return (
                            <VizLineChart
                              key={visualization.id}
                              viz={visualization}
                              data={chartData}
                              columns={columns}
                              handleUpdate={handleUpdateViz}
                              handleRemove={handleRemoveViz}
                              minHeight={RESULT_PANEL_MIN_HEIGHT}
                            />
                          );
                        } else if (
                          visualization.visualization_type.id ===
                          VIZ_TYPES.PIE_CHART
                        ) {
                          return (
                            <VizPieChart
                              key={visualization.id}
                              viz={visualization}
                              data={chartData}
                              columns={columns}
                              handleUpdate={handleUpdateViz}
                              handleRemove={handleRemoveViz}
                              minHeight={RESULT_PANEL_MIN_HEIGHT}
                            />
                          );
                        } else if (
                          visualization.visualization_type.id ===
                          VIZ_TYPES.SCATTER_PLOT_CHART
                        ) {
                          return (
                            <VizScatterPlotChart
                              key={visualization.id}
                              viz={visualization}
                              data={chartData}
                              columns={columns}
                              handleUpdate={handleUpdateViz}
                              handleRemove={handleRemoveViz}
                              minHeight={RESULT_PANEL_MIN_HEIGHT}
                            />
                          );
                        }
                        return '';
                      })}
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        );
      }

      return null;
    },
    [
      visualizations,
      queryResponse,
      chartData,
      clearResult,
      columns,
      dataTab,
      handleAddBarChartViz,
      handleAddLineChartViz,
      handleAddPieChartViz,
      handleAddScatterPlotChartViz,
      handleRemoveViz,
      handleUpdateViz,
      vizTab,
      readOnly,
    ]
  );

  const selectViz = useMemo(
    _ => {
      if (
        queryResponse &&
        queryResponse?.response?.total_number_of_records >= 0
      ) {
        return (
          <ResultInfo
            key="select"
            clearResult={clearResult}
            queryResponse={queryResponse}
          />
        );
      } else {
        return null;
      }
    },
    [queryResponse, clearResult]
  );

  const infoViz = useMemo(
    _ => {
      if (
        queryResponse &&
        queryResponse?.response?.total_number_of_records === -1
      ) {
        return (
          <ResultInfo
            key="info"
            clearResult={clearResult}
            queryResponse={queryResponse}
          />
        );
      } else {
        return null;
      }
    },
    [queryResponse, clearResult]
  );

  const errorViz = useMemo(
    _ => {
      if (queryError && queryError?.message) {
        return (
          <ResultError
            key="error"
            clearResult={clearResult}
            queryError={queryError}
          />
        );
      } else {
        return null;
      }
    },
    [queryError, clearResult]
  );

  return (
    <div>
      {selectViz}
      {resultViz}
      {infoViz}
      {errorViz}
    </div>
  );
}
