import React, { useCallback, useMemo, useState } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate, ReactEditor } from 'slate-react';
import { Editor, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Button } from 'antd';
import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
} from '@ant-design/icons';
import ConfigToolbar from './ConfigToolbar';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

// const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const RichTextEditor = ({ parentElement, readOnly }) => {
  const parentSlateEditor = useSlate();
  const [value, setValue] = useState(parentElement.content);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const buttonStyle = {
    border: '1px solid #dddddd',
    backgroundColor: '#fcfcfc',
    marginRight: '3px',
    padding: '0px',
    height: 'unset',
  };

  const iconStyle = {
    fontSize: '14px',
  };

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={value => {
        setValue(value);
        const path = ReactEditor.findPath(parentSlateEditor, parentElement);
        Transforms.setNodes(
          parentSlateEditor,
          { content: value },
          { at: path }
        );
      }}
    >
      {!readOnly && (
        <ConfigToolbar>
          <div
            style={{
              position: 'absolute',
              pointerEvents: 'all',
              width: '100px',
              marginLeft: '0px',
              top: '0px',
            }}
          >
            <Button
              type="text"
              icon={<BoldOutlined style={iconStyle} />}
              onClick={_ => toggleMark(editor, 'bold')}
              style={buttonStyle}
              size="small"
            ></Button>
            <Button
              type="text"
              icon={<ItalicOutlined style={iconStyle} />}
              onClick={_ => toggleMark(editor, 'italic')}
              style={buttonStyle}
              size="small"
            ></Button>
            <Button
              type="text"
              icon={<UnderlineOutlined style={iconStyle} />}
              onClick={_ => toggleMark(editor, 'underline')}
              style={buttonStyle}
              size="small"
            ></Button>
          </div>
        </ConfigToolbar>
      )}
      <div
        style={{
          pointerEvents: 'all',
        }}
      >
        <Editable
          style={{
            padding: '8px',
            paddingTop: '15px',
            paddingLeft: '35px',
          }}
          readOnly={readOnly}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          // autoFocus
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </div>
    </Slate>
  );
};

// const toggleBlock = (editor, format) => {
//   const isActive = isBlockActive(editor, format);
//   const isList = LIST_TYPES.includes(format);

//   Transforms.unwrapNodes(editor, {
//     match: n => LIST_TYPES.includes(n.type),
//     split: true,
//   });

//   Transforms.setNodes(editor, {
//     type: isActive ? 'paragraph' : isList ? 'list-item' : format,
//   });

//   if (!isActive && isList) {
//     const block = { type: format, children: [] };
//     Transforms.wrapNodes(editor, block);
//   }
// };

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

// const isBlockActive = (editor, format) => {
//   const [match] = Editor.nodes(editor, {
//     match: n => n.type === format,
//   });

//   return !!match;
// };

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export default RichTextEditor;
