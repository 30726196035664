// Imports
import { useMutation, gql } from '@apollo/client';

export const EXECUTE_SQL = gql`
  mutation ExecuteSql($statement: String!, $limit: Int) {
    executeSql(statement: $statement, limit: $limit) {
      statement
      response
      responses
    }
  }
`;

export const EXPLAIN_PLAN = gql`
  mutation ExplainPlan($statement: String!, $limit: Int, $run: Boolean) {
    explainPlan(statement: $statement, limit: $limit, run: $run) {
      statement
      response
    }
  }
`;

export const useExecuteSql = () => {
  return useMutation(EXECUTE_SQL);
};

export const useExplainPlan = () => {
  return useMutation(EXPLAIN_PLAN);
};
