// Imports
import React from 'react';
import { Dropdown, Menu, notification } from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  ApartmentOutlined,
  InfoCircleOutlined,
  CalculatorOutlined,
} from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import { GET_TABLE_BY_NAME } from '../../../graphql/schema/tables';
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const GRAPH_TABLE_REGEX = /(?:graph_table|'graph_table')\s*=\s*'([^']+)'/;
const WKT_NAMES = ['kigeometry', 'wkt', 'geo', 'shape', 'isochrone', 'path'];

const GraphObject = ({
  nodeData,
  tables,
  handleGraphInfo,
  handleGraphWms,
  handleGraphSolve,
  handleGraphDelete,
  handleMultiSelect,
}) => {
  const graphqlClient = useApolloClient();

  const handleGraphContextPreview = graph => async e => {
    const { is_partitioned, graph_server_id, original_request } = graph?.source;

    let graphTable = null;

    if (original_request?.statement) {
      const graphTableMatch = GRAPH_TABLE_REGEX.exec(
        original_request?.statement
      );
      graphTable =
        graphTableMatch && graphTableMatch.length > 1 && graphTableMatch[1];
    } else {
      graphTable = original_request?.options?.graph_table;
    }

    if (graphTable && graphTable !== '') {
      const split = graphTable.split('.');
      const serverIds = graph_server_id.split(',');
      const table =
        is_partitioned && serverIds.length > 0
          ? {
              full: `${graphTable}_${serverIds[0]}`,
              schema: split.length > 1 ? split[0] : '',
              name:
                split.length > 1
                  ? `${split[1]}_${serverIds[0]}`
                  : `${split[0]}_${serverIds[0]}`,
            }
          : {
              full: graphTable,
              schema: split.length > 1 ? split[0] : '',
              name: split.length > 1 ? split[1] : split[0],
            };

      const tableResp = await graphqlClient.query({
        query: GET_TABLE_BY_NAME,
        variables: {
          ...table,
        },
      });

      if (tableResp.errors) {
        notification.open({
          message: 'Graph Preview Error Occurred',
          description: tableResp.errors.map(e => e.message).join('\n'),
          type: 'error',
        });
        return;
      }

      const { schema, name } = tableResp?.data?.table;
      handleGraphWms({
        full: `${schema}.${name}`,
        schema,
        name,
      });
    }
  };

  const handleGraphContextInfo = graph => e => {
    handleGraphInfo(graph.source);
  };

  const handleGraphContextDelete = graph => e => {
    handleGraphDelete(graph.source);
  };

  const handleGraphContextSolve = graph => e => {
    handleGraphSolve(graph.source);
  };

  const graphContextMenu = node => {
    const { original_request } = node?.source;

    let graphTable = null;

    if (original_request?.statement) {
      const graphTableMatch = GRAPH_TABLE_REGEX.exec(
        original_request?.statement
      );
      graphTable =
        graphTableMatch && graphTableMatch.length > 1 && graphTableMatch[1];
    } else {
      graphTable = original_request?.options?.graph_table;
    }

    const table =
      graphTable &&
      tables.find(table => {
        if (graphTable.indexOf('.') > -1) {
          return table.full === graphTable;
        }
        return table.name === graphTable;
      });

    let isPreviewAvailable = false;
    if (table) {
      isPreviewAvailable =
        WKT_NAMES.reduce(function (acc, cur) {
          if (!acc) {
            acc = table?.columns.find(function (column) {
              return column.name.toLowerCase().includes(cur.toLowerCase());
            });
          }
          return acc;
        }, null) !== null;
    }

    return (
      <Menu>
        <Menu.Item key="info" onClick={handleGraphContextInfo(node)}>
          <InfoCircleOutlined /> Info
        </Menu.Item>
        <Menu.Item
          key="preview"
          onClick={handleGraphContextPreview(node)}
          disabled={!isPreviewAvailable}
        >
          <EyeOutlined /> Preview
        </Menu.Item>
        <Menu.Item key="solve" onClick={handleGraphContextSolve(node)}>
          <CalculatorOutlined /> Solve
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="delete"
          onClick={handleGraphContextDelete(node)}
          style={{ color: '#C62828' }}
        >
          <DeleteOutlined /> Delete
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          overlay={graphContextMenu(nodeData)}
          trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
        >
          <div>
            <ApartmentOutlined />{' '}
            <span
              style={{
                width: `${
                  EXPLORER_PANEL_WIDTH - 105 - (isMultiSelect ? 24 : 0)
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              {nodeData.title}
            </span>
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default GraphObject;
