// Imports
import React, { useState } from 'react';
import { Space, Button, Empty } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

// App Imports
import VizConfigDrawer from './VizConfigDrawer';
import { useIsReadOnly } from './utils';

const VizImage = ({ viz, handleUpdate, minHeight = 180 }) => {
  const { config } = viz;

  const [isVizConfigOpen, setIsVizConfigOpen] = useState(false);
  const readOnly = useIsReadOnly();

  const handleOpenVizConfig = _ => {
    setIsVizConfigOpen(true);
  };

  const handleCloseVizConfig = _ => {
    setIsVizConfigOpen(false);
  };

  const handleUpdateVizConfig = values => {
    handleUpdate(values, _ => {
      setIsVizConfigOpen(false);
    });
  };

  return (
    <div style={{ position: 'relative', minHeight }}>
      {!readOnly && (
        <div style={{ height: 30 }}>
          <Space style={{ float: 'right' }}>
            <Button
              icon={<SettingOutlined />}
              onClick={handleOpenVizConfig}
              size="small"
            >
              Configure
            </Button>
          </Space>
        </div>
      )}
      <div className="image" style={{ minHeight: minHeight - 20 }}>
        {config?.image_url ? (
          <div style={{ textAlign: config?.alignment || 'center' }}>
            <img
              src={config?.image_url}
              style={{
                height: config?.height
                  ? isNaN(config?.height)
                    ? config?.height
                    : `${config?.height}px`
                  : undefined,
                width: config?.width
                  ? isNaN(config?.width)
                    ? config?.width
                    : `${config?.width}px`
                  : undefined,
                maxWidth: '100%',
              }}
              alt="Block"
            />
          </div>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Please Configure Image"
          />
        )}
        {!readOnly && (
          <VizConfigDrawer
            title="Image Configuration"
            fields={viz?.visualization_type?.params?.fields}
            config={viz?.config}
            options={{}}
            isOpen={isVizConfigOpen}
            handleClose={handleCloseVizConfig}
            handleUpdate={handleUpdateVizConfig}
            columns={4}
          />
        )}
      </div>
    </div>
  );
};

export default VizImage;
