// Imports
import React from 'react';
import { Modal, Button, Spin, Descriptions } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';

const ModelInfoModal = ({ model, visible, setVisible, width, height }) => {
  const { model_name } = model;
  const { loading: modelLoading, data } =
    GraphQLServices.Models.useGetModelByName({
      variables: {
        model_name,
      },
    });

  return (
    <Modal
      title={`Model Info: ${model_name}`}
      visible={visible || modelLoading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={modelLoading}>
        <div
          style={{
            maxHeight: height + 20,
            minHeight: '200px',
            overflow: 'scroll',
          }}
        >
          {data?.model && (
            <Descriptions title="Summary" size="small" column={1} bordered>
              <Descriptions.Item label="Container Registry">
                {data?.model?.registry_name}
              </Descriptions.Item>
              <Descriptions.Item label="Container Path">
                {data?.model?.path_to_container}
              </Descriptions.Item>
              <Descriptions.Item label="Run Function">
                {data?.model?.model_run_function}
              </Descriptions.Item>
            </Descriptions>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default ModelInfoModal;
