// Imports
import React, { useMemo, useState } from 'react';
import {
  Layout,
  Descriptions,
  Divider,
  Tag,
  Button,
  Tooltip,
  Select,
  Modal,
  Form,
  Menu,
  Dropdown,
} from 'antd';
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  SettingOutlined,
  DownOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import addYears from 'date-fns/addYears';

// App Imports
import Permission from '../../components/security/Permission';
import {
  AUTO_PAUSE_DURATIONS,
  WAREHOUSE_SIZES,
  WAREHOUSE_SIZES_PAYG,
  WAREHOUSE_SIZE_TYPES,
  WAREHOUSE_TYPES,
} from '../../constants';
import { displayError, displaySuccess, getWarehouseStatus } from '../../helper';
import { useUpdateK8SKineticaClusterByName } from '../../graphql/schema/k8s_kineticaclusters';
import {
  useCreateK8SKineticaClusterElasticity,
  useGetK8sKineticaClusterElasticities,
} from '../../graphql/schema/k8s_kineticaclusterelasticities';
import useCloudProvider from '../../hooks/useCloudProvider';
import Spinner from '../../components/common/Spinner';
import { FREE_SAAS } from '../../setup/config';

const { Content } = Layout;

const DEFAULT_AUTO_PAUSE_STATUS = false;
const DEFAULT_AUTO_PAUSE_DURATION = '30m';

const WarningTitle = text => (
  <span>
    {text}
    <Tag
      icon={<ExclamationCircleOutlined />}
      color="warning"
      style={{
        marginLeft: '10px',
      }}
    >
      Warning
    </Tag>
  </span>
);

const AutoPauseModal = ({
  cluster,
  visible: autoPauseModalVisible,
  toggleModal: setAutoPauseModalVisible,
}) => {
  const [updateKineticaCR] = useUpdateK8SKineticaClusterByName();
  const clusterName = cluster?.spec?.gpudbCluster?.clusterName;
  const currentAutoPause =
    cluster?.spec?.autoSuspend?.enabled || DEFAULT_AUTO_PAUSE_STATUS;
  const currentAutoPauseDuration =
    cluster?.spec?.autoSuspend?.inactivityDuration ||
    DEFAULT_AUTO_PAUSE_DURATION;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedAutoPause, setSelectedAutopause] = useState(currentAutoPause);
  const [selectedAutoPauseDuration, setSelectedAutopauseDuration] = useState(
    currentAutoPauseDuration
  );

  const handleAutoPauseChange = value => {
    // console.log({
    //   value,
    //   currentAutoPause,
    //   currentAutoPauseDuration,
    //   selectedAutoPause,
    //   selectedAutoPauseDuration,
    // });
    if (value === 'disabled') {
      setSelectedAutopause(false);
      setSelectedAutopauseDuration('disabled');
    } else {
      setSelectedAutopause(true);
      setSelectedAutopauseDuration(value);
    }
  };
  const autoPauseModalHandleOk = async () => {
    setIsLoading(true);
    updateKineticaCR({
      variables: {
        name: clusterName,
        body: {
          spec: {
            autoSuspend: {
              enabled: selectedAutoPause,
              inactivityDuration:
                selectedAutoPauseDuration === 'disabled'
                  ? DEFAULT_AUTO_PAUSE_DURATION
                  : selectedAutoPauseDuration,
            },
          },
        },
      },
    })
      .then(resp => {
        if (resp?.errors?.length > 0) {
          displayError(resp.errors[0].message);
        } else {
          displaySuccess('Auto suspend configuration updated.');
          setAutoPauseModalVisible(false);
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.log({ err });
      });
  };
  const autoPauseModalHandleCancel = () => {
    setAutoPauseModalVisible(false);
  };

  return (
    <Modal
      title={WarningTitle('Confirm Auto Suspend Changes')}
      visible={autoPauseModalVisible}
      // onOk={autoPauseModalHandleOk}
      onCancel={autoPauseModalHandleCancel}
      footer={[
        <Button key="back" onClick={autoPauseModalHandleCancel}>
          Cancel
        </Button>,
        <Button
          disabled={
            selectedAutoPauseDuration === currentAutoPauseDuration &&
            !selectedAutoPause
            // (currentAutoPause && )
          }
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={autoPauseModalHandleOk}
        >
          Submit
        </Button>,
      ]}
      centered
    >
      <p>
        Your auto suspend timeout will be{' '}
        {selectedAutoPause ? 'enabled' : 'disabled'}. Changing your auto suspend
        configuration has an impact on the availability and cost of your
        cluster.
      </p>
      <Form>
        <Form.Item label="Timeout">
          <Select
            defaultValue={
              !currentAutoPause ? 'disabled' : currentAutoPauseDuration
            }
            onSelect={handleAutoPauseChange}
          >
            <Select.Option disabled={!currentAutoPause} key="disabled">
              Disabled
            </Select.Option>
            {AUTO_PAUSE_DURATIONS.map(duration => (
              <Select.Option
                disabled={
                  currentAutoPause && duration === currentAutoPauseDuration
                }
                key={duration}
              >
                {duration}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Overview = ({ cluster, upgrades = [] }) => {
  const history = useHistory();
  const [autoPauseModalVisible, setAutoPauseModalVisible] = useState(false);
  // const [updateKineticaCR] = useUpdateK8SKineticaClusterByName();
  const cloudProvider = useCloudProvider();
  const {
    loading: elasticityLoading = true,
    // data: { k8s_kineticaclustericities: [elasticity] = [] } = {},
  } = useGetK8sKineticaClusterElasticities();
  // console.log({ elasticityStatus });
  const [createElasticity] = useCreateK8SKineticaClusterElasticity();
  // console.log({ createElasticity });

  const versionRegex = /(\d+\.\d+\.\d+\.\d+)\.\d+/gi;
  const versionRegexMatches = versionRegex.exec(
    cluster?.status?.hmStatus?.version
  );
  const version =
    versionRegexMatches && versionRegexMatches.length === 2
      ? versionRegexMatches[1]
      : cluster?.status?.hmStatus?.version ?? 'Unavailable';
  const clusterName = cluster?.spec?.gpudbCluster?.clusterName;
  const upgradeAvailable = upgrades.some(upgrade => {
    return (
      upgrade.clusterName === clusterName &&
      upgrade?.availableUpgradeVersions?.length > 0
    );
  });
  // const upgradeAvailable = releaseVersions.length > 0;

  const handleUpgradeLink = _ => {
    const tab = 'upgrade';
    history.push(`/warehouse/${tab}`);
  };

  const { phase, processing, color } = getWarehouseStatus(cluster);

  const showLicense = useMemo(
    _ => {
      const cutoffDate = addYears(new Date(), 100);
      const expireDate = parseISO(
        cluster?.status?.hmStatus?.license_expiration
      );
      return isBefore(expireDate, cutoffDate);
    },
    [cluster]
  );

  const currentAutoPause =
    cluster?.spec?.autoSuspend?.enabled || DEFAULT_AUTO_PAUSE_STATUS;
  const currentAutoPauseDuration =
    cluster?.spec?.autoSuspend?.inactivityDuration ||
    DEFAULT_AUTO_PAUSE_DURATION;

  const [sizingChanged, setSizingChanged] = useState(false);
  const currentGpu =
    cluster?.spec?.gpudbCluster?.clusterSize?.tshirtType.includes('GPU') ||
    false;
  const [selectedGpu /* setSelectedGpu */] = useState(currentGpu);
  const currentSize =
    WAREHOUSE_SIZES[cluster?.spec?.gpudbCluster?.clusterSize?.tshirtSize] ??
    null;
  const [selectedSize, setSelectedSize] = useState(currentSize);
  const [sizingModalVisible, setSizingModalVisible] = useState(false);
  const sizingModalHandleOk = async () => {
    await createElasticity({
      variables: {
        name: clusterName,
        body: {
          spec: {
            clusterName,
            scaling: {
              clusterSize: {
                ...WAREHOUSE_SIZE_TYPES[selectedSize],
                tshirtType: selectedGpu
                  ? WAREHOUSE_TYPES.GPU
                  : WAREHOUSE_SIZE_TYPES[selectedSize].tshirtType,
              },
            },
          },
        },
      },
    })
      .then(resp => {
        if (resp?.errors?.length > 0) {
          displayError(resp.errors[0].message);
          console.error(resp?.errors[0]?.message);
        } else {
          displaySuccess(
            <>
              Cluster resize initiated.
              <br />
              <br />
              Your reported cluster size will be updated once the process is
              complete.
            </>
          );
        }
        setSizingModalVisible(false);
      })
      .catch(err => {
        console.log({ err });
      });
  };
  const sizingModalHandleCancel = () => {
    setSizingModalVisible(false);
  };
  const checkSizingChange = (selected, gpu) =>
    setSizingChanged(selected !== currentSize || gpu !== currentGpu);
  // const handleGpuChange = () => {
  //   checkSizingChange(selectedSize, !selectedGpu);
  //   setSelectedGpu(!selectedGpu);
  // };
  const handleSizeSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    checkSizingChange(key, selectedGpu);
    setSelectedSize(key);
  };

  const sizingModal = (
    <Modal
      title={WarningTitle('Confirm Sizing Changes')}
      visible={sizingModalVisible}
      onOk={sizingModalHandleOk}
      onCancel={sizingModalHandleCancel}
      centered
    >
      <p>
        The price of your cluster will change when the update takes place.
        Please refer to our{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={
            cloudProvider === 'azure'
              ? 'https://docs.kinetica.com/7.1/azure/provision/installation/#install-sizing-recommendation'
              : 'https://docs.kinetica.com/7.1/aws/provision/installation/#install-hardware-config'
          }
        >
          offering guide
        </a>{' '}
        for more information about the size you have selected.
      </p>
    </Modal>
  );
  const sizeMenu = (
    <Menu
      selectedKeys={[selectedSize]}
      defaultSelectedKeys={[selectedSize]}
      onClick={handleSizeSelect}
    >
      {Object.values(
        // check if we are on payg or byol
        cluster?.spec?.gpudbCluster?.license === 'payg'
          ? // XS, S, and M
            WAREHOUSE_SIZES_PAYG
          : // all sizes
            WAREHOUSE_SIZES
      ).map((size, idx) => (
        <Menu.Item key={size}>{size}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Content
      style={{
        backgroundColor: '#ffffff',
        padding: '20px',
        height: 'calc(100vh - 250px)',
        overflow: 'auto',
      }}
    >
      <Descriptions title="Detail" size="small" column={1} bordered>
        <Descriptions.Item label="Version" labelStyle={{ width: '200px' }}>
          {version}
          {upgradeAvailable && (
            <Permission everyKeys={['manage_warehouse']}>
              <Tag
                icon={<ExclamationCircleOutlined />}
                color="warning"
                onClick={handleUpgradeLink}
                style={{
                  marginLeft: '10px',
                  cursor: 'pointer',
                }}
              >
                Upgrade Available
              </Tag>
            </Permission>
          )}
        </Descriptions.Item>
        {showLicense && (
          <Descriptions.Item
            label="License"
            labelStyle={{ width: '200px', verticalAlign: 'top' }}
          >
            {cluster?.spec?.gpudbCluster?.license}
            {cluster?.spec?.gpudbCluster?.license !== 'payg' && (
              <>
                <br />
                <i style={{ color: '#cccccc' }}>
                  Expires{' '}
                  {cluster?.status?.hmStatus?.license_expiration ??
                    ': Not Available'}
                </i>
              </>
            )}
          </Descriptions.Item>
        )}
      </Descriptions>
      <Divider dashed />
      <Descriptions title="Health" size="small" column={1} bordered>
        <Descriptions.Item label="Status" labelStyle={{ width: '200px' }}>
          <Tag
            color={color}
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            {processing && (
              <LoadingOutlined
                style={{ color: '#ffffff', marginRight: '10px' }}
              />
            )}
            {phase.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ').toUpperCase()}
          </Tag>
        </Descriptions.Item>
      </Descriptions>
      <Divider dashed />
      <Descriptions title="Auto Suspend" size="small" column={1} bordered>
        <Descriptions.Item label="Status" labelStyle={{ width: '200px' }}>
          {currentAutoPause ? 'Enabled -' : 'Disabled'}
          {currentAutoPause ? ` ${currentAutoPauseDuration}` : null}
          <Tooltip
            title="Suspend cluster when no activity has been detected for the selected timeout."
            key="leftButton"
            placement="top"
          >
            <ExclamationCircleOutlined
              style={{
                marginLeft: '8px',
              }}
            />
          </Tooltip>
          <Button
            size="small"
            type="ghost"
            style={{
              marginLeft: '8px',
            }}
            onClick={() => setAutoPauseModalVisible(true)}
            icon={<SettingOutlined />}
          >
            Edit
          </Button>
        </Descriptions.Item>
      </Descriptions>
      {autoPauseModalVisible ? (
        <AutoPauseModal
          cluster={cluster}
          visible={autoPauseModalVisible}
          toggleModal={setAutoPauseModalVisible}
        />
      ) : null}
      {/* <Divider dashed />
      <Descriptions title="Sizing" size="small" column={1} bordered={true}>
        <Descriptions.Item label="Size" labelStyle={{ width: '200px' }}>
          {currentSize || ''}
        </Descriptions.Item>
        <Descriptions.Item
          label="GPU Acceleration"
          labelStyle={{ width: '200px' }}
        >
          {currentGpu ? 'Enabled' : 'Disabled'}
        </Descriptions.Item>
      </Descriptions> */}
      <Divider dashed />
      <Descriptions title="Sizing" size="small" column={1} bordered>
        {/* <Descriptions.Item
          span={1}
          label={
            <span>
              GPU Accelerated
              <span style={{ marginLeft: "1rem" }}>
                <Tooltip
                  title="Add or remove GPU resources to your cluster."
                  key="leftButton"
                  placement="top"
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              </span>
            </span>
          }
          labelStyle={{ width: "200px" }}
        >
          <Switch checked={selectedGpu} onChange={handleGpuChange} />
        </Descriptions.Item> */}
        <Descriptions.Item label="Size" labelStyle={{ width: '200px' }}>
          {elasticityLoading ? (
            <Spinner position="static" fontSize="26px" />
          ) : (
            <>
              <Dropdown
                overlay={sizeMenu}
                trigger={['click']}
                disabled={phase !== 'Running'}
              >
                <Button>
                  {selectedSize} <DownOutlined />
                </Button>
              </Dropdown>
              <Tooltip
                title="Scale your deployment to meet your performance requirements."
                key="leftButton"
                placement="top"
              >
                <ExclamationCircleOutlined
                  style={{
                    marginLeft: '8px',
                  }}
                />
              </Tooltip>
              <Button
                size="small"
                type="ghost"
                style={{
                  marginLeft: '8px',
                }}
                onClick={() => setSizingModalVisible(true)}
                icon={<SwapOutlined />}
                disabled={!sizingChanged || phase !== 'Running'}
              >
                Resize
              </Button>
              {/* {elasticityStatus} */}
            </>
          )}
        </Descriptions.Item>
        {/* <Descriptions.Item span={1} contentStyle={{ width: 200 }}>
          <Button
            type="primary"
            onClick={() => setSizingModalVisible(true)}
            style={{ width: "100%" }}
            disabled={!sizingChanged}
          >
            Update
          </Button>
        </Descriptions.Item> */}
      </Descriptions>
      {sizingModal}
      {cluster?.status?.ingressUrls && (
        <>
          {!FREE_SAAS && (
            <>
              <Divider dashed />
              <Descriptions
                title="Applications"
                size="small"
                column={1}
                bordered
              >
                {cluster?.status?.ingressUrls?.gadmin && (
                  <Descriptions.Item
                    label="GAdmin"
                    labelStyle={{ width: '200px' }}
                  >
                    {cluster?.status?.ingressUrls?.gadmin}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </>
          )}
          <Divider dashed />
          <Descriptions
            title="Table Monitor (ZMQ)"
            size="small"
            column={1}
            bordered
          >
            {cluster?.status?.ingressUrls?.dbTrigger && (
              <Descriptions.Item
                label="Trigger"
                labelStyle={{ width: '200px' }}
              >
                {cluster?.status?.ingressUrls?.dbTrigger}
              </Descriptions.Item>
            )}
            {cluster?.status?.ingressUrls?.dbMonitor && (
              <Descriptions.Item
                label="Table Monitor"
                labelStyle={{ width: '200px' }}
              >
                {cluster?.status?.ingressUrls?.dbMonitor}
              </Descriptions.Item>
            )}
          </Descriptions>
          {cluster?.status?.ingressUrls?.postgresProxy && (
            <>
              <Divider dashed />
              <Descriptions title="Postgres" size="small" column={1} bordered>
                <Descriptions.Item
                  label="Proxy"
                  labelStyle={{ width: '200px' }}
                >
                  {cluster?.status?.ingressUrls?.postgresProxy}
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
        </>
      )}
      <div style={{ height: '20px' }}></div>
    </Content>
  );
};

export default Overview;
