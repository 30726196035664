import { useState } from "react";
import { Row, Col, Breadcrumb, Input, Table, Empty, Checkbox } from "antd";
import { FileFilled, FolderFilled, HomeFilled } from "@ant-design/icons";
import { search } from 'fast-fuzzy';
import GraphQLServices from "../../graphql/services";

const pointerStyle = {
  cursor: 'pointer',
};

const coloredLink = {
  ...pointerStyle,
  color: '#4a00e0',
};

const EmptyTable = (<>
  <Empty description="No files found"/>
</>);

const DataSourceFileBrowser = ({form, path: filePath = '', onSelect}) => {
  // "derived props"
  const sourceType = form.getFieldValue('type') ?? '';
  const dataSourceName = form.getFieldValue('dataSource') ?? '';
  const filePathNoProtocol = filePath.replace(/(^\w+:|^)\/\//, '');
  const filePathParts = filePathNoProtocol.split('/');
  const filePathFile = filePathParts.find((fileOrFolder) => fileOrFolder.includes('.')) ?? '';
  const filePathFolderParts = filePathParts.filter((part) => part !== filePathFile);

  // state
  const [filter, setFilter] = useState('');
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // local variables
  const __gqlPath = /(.*\/).*/g.exec(filePathNoProtocol);
  const _gqlPath = __gqlPath ? __gqlPath[1] : '';
  const gqlPath = isSelectionMode ? _gqlPath.replace(/[^/]+\/?$/,''): _gqlPath;
  // console.log({filePathFolderParts, filePathNoProtocol, filePathFile, __gqlPath, _gqlPath, gqlPath});
  const breadcrumbParts = isSelectionMode ? filePathNoProtocol.replace(/[^/]+\/?$/,'').split('/'): filePathFolderParts;

  // local fns
  const findFileIndex = (file) => files.findIndex((_file) => _file.name === file.name);
  const trimPathTail = (path) => path.replace(/[^/]+\/?$/,'');

  // hooks
  const { loading, data: { files: gqlFiles = [] } = {} } = GraphQLServices.Files.useGetFilesByDataSource({
    variables: {
      source_type: sourceType,
      // if the filePath contains a file then only fetch the folder
      path: gqlPath,
      options: {
        data_source_name: dataSourceName,
        folder_only: false,
      }
    }
  });
  const files = [...(filter ? search(filter, gqlFiles, {keySelector: (x) => x.name}).reverse(): gqlFiles ?? [])].reverse().map((file, key) => {
    return ({
      key,
      name: file.name,
      path: file.path,
      type: (file.folder === 'true' || file.files) ? 'folder' : 'file',
      basename: filePath,
      mode: 777,
      size: undefined,
      lastModified: '-'
    })
  });

  // handlers
  const handleHomeClick = () => {
    // console.log('fetch root', {sourceType: form.getFieldValue('type')});
    if (sourceType !== 'upload' || sourceType !== 'kifs') {
      console.log('not kifs');
      setSelectedRowKeys([]);
      onSelect('');
      // fetchFiles();
    }
  };

  const handleBreadCrumbClick = (e) => {
    // console.log('breadcrumb clicked:', {target: e.target});
    // get path from folder
    const folder = e.target.innerText;
    const path = e.target.getAttribute('data-path');
    console.log({folder, filePathParts, path});
    setSelectedRowKeys([]);
    onSelect(folder === dataSourceName ? '': path);
    // fetchFiles();
  };

  const handleFilterChange = (e) => setFilter(e.target.value);

  const handleSelect = (event) => {
    // console.log('row selected', {event, type: event.type, target: event.target, checked: event.target.checked, dataset: event.target.dataset});
    // const checked = event.target.checked;
    const rowKey = parseInt(event.target.dataset.rowKey);
    const newSelectedRowKeys = selectedRowKeys.includes(rowKey) ? []: [rowKey];
    setIsSelectionMode(event.target.dataset.type === 'folder');
    setSelectedRowKeys(newSelectedRowKeys);
    onSelect(event.target.checked ? event.target.dataset.path: trimPathTail(event.target.dataset.path));
  };

  // handles multi select button
  const handleCheckboxChange = (newSelectedRowKeys, selectedRowKeys) => {
    // console.log('checkbox selected', newSelectedRowKeys, selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleFolderClick = (e) => {
    // console.log('folder clicked:', e, e.target.dataset.path);
    if (e.target.dataset.type === 'folder') {
      setIsSelectionMode(false);
      onSelect(e.target.dataset.path);
      // fetchFiles();
    }
  };

  // render helpers
  const breadcumbDataPath = (folder) => filePathParts.slice(0, filePathParts.indexOf(folder) + 1).reduce((path, folder) => path + folder + '/', '');

  // renderers
  const renderRowCheckbox = (_, node) => (
    <Checkbox
      onClick={handleSelect}
      checked={selectedRowKeys.includes(findFileIndex(node))}
      data-type={node.type}
      data-path={node.path}
      data-row-key={node.key}
    />
  );

  const renderIconColumn = (_, node) => (
    <>
      {
        node.type === 'folder' ?
          <FolderFilled
            className="ds-file-browser-folder-icon"
            style={{marginRight: '0.25rem'}}
          />:
          <FileFilled
            className="ds-file-browser-file-icon"
            style={{marginRight: '0.25rem'}}
          />
      }
    </>
  );

  const renderNameColumn = (_, node) => (
    <>
      {
        node.type === 'folder' ?
          <span
            style={coloredLink}
            onClick={handleFolderClick}
            data-type={node.type}
            data-path={node.path}
          >
            {node.name}
          </span>:
          <span>{node.name}</span>
      }
    </>
  );

  return (
    <div className="ds-file-browser">
      <Row className='ds-file-browser-header' gutter={[16, 16]}>
        <Col span={18}>
          <Row align='middle'>
            <Breadcrumb className='ds-file-browser-breadcrumb'>
              <Breadcrumb.Item onClick={handleHomeClick} style={pointerStyle}>
                <HomeFilled className="ds-file-browser-header-icon" style={{marginRight: '0.25rem'}}/>
                {
                  dataSourceName ?
                    <span>{dataSourceName}</span>:
                    <span>KiFS</span>
                }
              </Breadcrumb.Item>
              {
                breadcrumbParts?.map((folder, idx) => (
                  <Breadcrumb.Item
                    key={`${idx}${folder}`}
                    style={pointerStyle}
                    onClick={handleBreadCrumbClick}
                  >
                    <span data-path={breadcumbDataPath(folder)}>
                      {folder}
                    </span>
                  </Breadcrumb.Item>
                ))
              }
            </Breadcrumb>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Input
              id="importFileBrowserFilter"
              allowClear={true}
              placeholder="Filter"
              style={
                filter && files.length === 0 ?
                  {
                    border: '1px solid red',
                    boxShadow: '0 0 0 2px rgb(255 0 0 / 20%)'
                  }:
                  {}
              }
              onChange={handleFilterChange} >
            </Input>
          </Row>
        </Col>
      </Row>
      <div className="ds-file-browser-body" style={{marginTop: '2rem'}}>
        <Table
          loading={loading}
          dataSource={loading ? []: files}
          dataKey="key"
          pagination={false}
          size="small"
          locale={{ emptyText: EmptyTable }}
          rowSelection={{
            type: 'checkbox',
            renderCell: renderRowCheckbox,
            hideSelectAll: true,
            selectedRowKeys,
            onSelect: handleSelect,
            onChange: handleCheckboxChange
          }}
          columns={[
            {
              key: 'icon',
              render: renderIconColumn,
              width: 1,
              shouldCellUpdate: () => true
            },
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              render: renderNameColumn,
              shouldCellUpdate: () => true
            }
          ]}
        />
      </div>
    </div>
  );
};

export default DataSourceFileBrowser;