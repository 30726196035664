// Imports
import React, { useMemo, useState, useCallback } from 'react';
import { Menu, Badge, Tooltip } from 'antd';
import {
  HomeOutlined,
  BulbOutlined,
  SwapOutlined,
  ControlOutlined,
  LogoutOutlined,
  UserOutlined,
  AppstoreOutlined,
  LockOutlined,
  LoadingOutlined,
  UnorderedListOutlined,
  BarChartOutlined,
  QuestionCircleOutlined,
  SlackOutlined,
  SaveOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

// App Imports
import { useEveryPermission } from '../../context';
import GraphQLServices from '../../graphql/services';
import { logout } from '../../store/auth/actions';
import UserPasswordModal from '../../components/modal/UserPasswordModal';
import UserUsageModal from '../../components/modal/UserUsageModal';
import KineticaLogo from '../../images/logo.svg';
import { NAV_ROUTE_MAPPING, TEST_IDS } from '../../constants';
import { K8S_STATUS_POLL_INTERVAL, DEPLOYMENT_TYPE } from '../../setup/config';
import { GET_LOCAL_USER_ME } from '../../graphql/schema/users';
import { dtId, getWarehouseStatus } from '../../helper';
import useAnalytics from '../../hooks/useAnalytics';
import useCloudProvider from '../../hooks/useCloudProvider';

const { SubMenu } = Menu;

const BRAND_WIDTH = 224;

const NavBar = () => {
  const { data: { userMe = {} } = {} } =
    GraphQLServices.Users.useGetLocalUserMe();
  const { data: { upgrades = [] } = {} } =
    GraphQLServices.Upgrades.useGetUpgrades({
      variables: {
        deployment_type: DEPLOYMENT_TYPE,
      },
    });
  const { data: { k8s_kineticaclusters = [] } = {} } =
    GraphQLServices.K8sKineticaClusters.useGetK8sKineticaClusters({
      variables: {
        deployment_type: DEPLOYMENT_TYPE,
      },
      pollInterval: K8S_STATUS_POLL_INTERVAL,
    });

  const [showUserPasswordModal, setShowUserPasswordModal] = useState(false);
  const [passwordUser, setPasswordUser] = useState(undefined);
  // const [releaseVersions, setReleaseVersions] = useState(null);
  const [showUserUsageModal, setShowUserUsageModal] = useState(false);

  const [hasWarehousePermission] = useEveryPermission(['manage_warehouse']);
  const [hasUpgradePermission] = useEveryPermission(['manage_warehouse']);
  const [hasUserPermission] = useEveryPermission(['manage_user']);

  const location = useLocation();
  const dispatch = useDispatch();
  const graphqlClient = useApolloClient();

  const analytics = useAnalytics();
  const cloudProvider = useCloudProvider();

  const docs = useMemo(() => {
    return !cloudProvider.toLowerCase().includes('onprem')
      ? `${cloudProvider}/`
      : '';
  }, [cloudProvider]);

  const clusters = useMemo(
    _ => {
      if (k8s_kineticaclusters) {
        return k8s_kineticaclusters;
      }
      return [];
    },
    [k8s_kineticaclusters]
  );

  // useEffect(
  //   _ => {
  //     if (
  //       k8s_kineticaclusters &&
  //       k8s_kineticaclusters.length > 0 &&
  //       !releaseVersions
  //     ) {
  //       const get = async _ => {
  //         const promises = k8s_kineticaclusters.map(async cluster => {
  //           const { clusterName: name } = cluster?.spec?.gpudbCluster;
  //           const resp = await graphqlClient.query({
  //             query:
  //               GraphQLServices.K8sKineticaReleaseVersions
  //                 .GET_K8S_KINETICARELEASEVERSIONS,
  //             variables: {
  //               name,
  //               deployment_type: DEPLOYMENT_TYPE,
  //             },
  //           });
  //           return resp?.data?.k8s_kineticareleaseversions?.status
  //             ?.registryRepositoryTag;
  //         });
  //         const versions = await Promise.all(promises);
  //         setReleaseVersions(versions);
  //       };
  //       get();
  //     }
  //   },
  //   [k8s_kineticaclusters, releaseVersions, graphqlClient]
  // );

  const handleLogout = _ => {
    dispatch(logout()).then(success => {
      if (success) {
        analytics.track(analytics.EVENT_TYPES.LOGGED_OUT)(_ => {
          window.location.reload();
        });
      }
    });
  };

  const handleUserUsage = async _ => {
    setShowUserUsageModal(true);
  };

  const handleUserSetPassword = async e => {
    const resp = await graphqlClient.query({
      query: GET_LOCAL_USER_ME,
    });
    setPasswordUser(resp?.data?.userMe);
    setShowUserPasswordModal(true);
  };

  const handleUserPasswordCallback = useCallback(
    (err, resp) => {
      if (resp) {
        setShowUserPasswordModal(false);
      } else {
        console.error(err);
      }
    },
    [setShowUserPasswordModal]
  );

  const iconStyle = {
    fontSize: '18px',
    marginRight: '10px',
  };
  const subIconStyle = {
    fontSize: '18px',
  };

  const menuSelectedKeys = [
    NAV_ROUTE_MAPPING[
      Object.keys(NAV_ROUTE_MAPPING)
        .sort(function (a, b) {
          return b.length - a.length;
        })
        .find(route => {
          return location.pathname.includes(route);
        })
    ],
  ];

  const upgradeAvailable = useMemo(
    _ => {
      return upgrades
        ? upgrades.some(
            upgrade => upgrade?.availableUpgradeVersions?.length > 0
          )
        : false;
      // return (
      //   releaseVersions &&
      //   releaseVersions.length > 0 &&
      //   releaseVersions[0] &&
      //   releaseVersions[0].length > 0
      // );
    },
    [upgrades]
  );

  const managementMenuItems = [];
  if (hasWarehousePermission) {
    managementMenuItems.push(
      <Menu.Item key="/warehouse">
        <Link to="/warehouse" {...dtId(TEST_IDS.NAV_MANAGEMENT_WAREHOUSE)}>
          <AppstoreOutlined style={iconStyle} />
          Cluster
        </Link>
      </Menu.Item>
    );
    managementMenuItems.push(
      <Menu.Item key="/metrics">
        <Link to="/metrics" {...dtId(TEST_IDS.NAV_METRICS)}>
          <BarChartOutlined style={iconStyle} />
          Metrics
        </Link>
      </Menu.Item>
    );
  }
  if (hasUserPermission) {
    managementMenuItems.push(
      <Menu.Item key="/users">
        <Link to="/users">
          <UserOutlined style={iconStyle} />
          Users & Roles
        </Link>
      </Menu.Item>
    );
  }

  const cluster = useMemo(
    _ => {
      if (clusters && clusters.length > 0) {
        return clusters[0];
      }
      return null;
    },
    [clusters]
  );

  const getStatusIndictor = clusters => {
    return (
      clusters &&
      clusters.map((cluster, idx) => {
        const { phase, processing, status, color } =
          getWarehouseStatus(cluster);
        return processing ? (
          <span
            key={`${idx}_${cluster?.spec?.gpudbCluster?.clusterName}`}
            style={{ color }}
          >
            <LoadingOutlined style={{ color, ...iconStyle }} />
            {phase.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ')}
          </span>
        ) : (
          <Badge
            key={`${idx}_${cluster?.spec?.gpudbCluster?.clusterName}`}
            status={status}
            text={phase}
            style={{
              color: status === 'success' ? '#ffffff99' : color,
            }}
          />
        );
      })
    );
  };

  const version = useMemo(() => {
    if (cluster) {
      const versionRegex = /(\d+\.\d+\.\d+\.\d+)\.\d+/gi;
      const versionRegexMatches = versionRegex.exec(
        cluster?.status?.hmStatus?.version
      );
      return versionRegexMatches && versionRegexMatches.length === 2
        ? versionRegexMatches[1]
        : cluster?.status?.hmStatus?.version ?? <i>Unavailable</i>;
    }
    return <i>?</i>;
  }, [cluster]);

  const isCloud = DEPLOYMENT_TYPE === 'cloud';

  return (
    <>
      {clusters &&
        clusters.map((cluster, idx) => {
          const { status, color } = getWarehouseStatus(cluster);
          return status !== 'success' ? (
            <div
              key={`${idx}_${cluster?.spec?.gpudbCluster?.clusterName}`}
              style={{
                height: '5px',
                position: 'absolute',
                zIndex: 1,
                width: '100%',
                backgroundColor: color,
              }}
            ></div>
          ) : null;
        })}
      <div
        style={{
          width: `${BRAND_WIDTH + 20}px`,
          display: 'block',
          float: 'left',
        }}
      >
        <Link to="/" {...dtId(TEST_IDS.NAV_LOGO)}>
          <img
            src={KineticaLogo}
            alt="Kinetica Logo"
            style={{
              height: '28px',
              position: 'relative',
              top: '-3px',
              margin: '0px 20px',
            }}
          />
        </Link>
      </div>
      <div
        style={{
          width: `560px`,
          display: 'block',
          float: 'left',
        }}
      >
        <Menu theme="dark" mode="horizontal" selectedKeys={menuSelectedKeys}>
          <Menu.Item key="/home">
            <Link to="/home" {...dtId(TEST_IDS.NAV_HOME)}>
              <HomeOutlined style={iconStyle} />
              Home
            </Link>
          </Menu.Item>
          <Menu.Item key="/dataexplore">
            <Link to="/dataexplore" {...dtId(TEST_IDS.NAV_DATA_EXPLORATION)}>
              <BulbOutlined style={iconStyle} />
              Explore
            </Link>
          </Menu.Item>
          <Menu.Item key="/importexport">
            <Link to="/importexport" {...dtId(TEST_IDS.NAV_IMPORT)}>
              <SwapOutlined style={iconStyle} />
              Import
            </Link>
          </Menu.Item>
          <Menu.Item key="/jobs">
            <Link to="/jobs" {...dtId(TEST_IDS.NAV_JOBS)}>
              <UnorderedListOutlined style={iconStyle} />
              Jobs
            </Link>
          </Menu.Item>
          {isCloud && (hasWarehousePermission || hasUserPermission) && (
            <SubMenu
              key="/management"
              icon={<ControlOutlined style={subIconStyle} />}
              title="Manage"
              popupOffset={[-20, 0]}
              {...dtId(TEST_IDS.NAV_MANAGEMENT)}
            >
              {managementMenuItems}
            </SubMenu>
          )}
        </Menu>
      </div>
      <div
        style={{
          width: `calc(100% - ${BRAND_WIDTH + 560 + 20}px)`,
          display: 'block',
          float: 'right',
        }}
      >
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={menuSelectedKeys}
          style={{ float: 'right' }}
        >
          {isCloud && upgradeAvailable && hasUpgradePermission ? (
            <Menu.Item key="/warehouse/upgrade">
              <Link to="/warehouse/upgrade">
                <Tooltip title="New Version Available!">
                  <Badge
                    status="warning"
                    text="Upgrade"
                    style={{ color: 'rgba(255, 255, 255, 0.65)' }}
                  />
                </Tooltip>
              </Link>
            </Menu.Item>
          ) : (
            <Menu.Item key="/warehouse/upgrade"></Menu.Item>
          )}
          {isCloud && hasWarehousePermission ? (
            <Menu.Item key="/warehouse/overview">
              <Link to="/warehouse/overview">
                {getStatusIndictor(clusters)}
              </Link>
            </Menu.Item>
          ) : (
            <Menu.Item
              key="/warehouse/overview"
              style={{ cursor: 'default', minWidth: 120 }}
            >
              {getStatusIndictor(clusters)}
            </Menu.Item>
          )}
          <SubMenu
            key="help"
            icon={<QuestionCircleOutlined style={subIconStyle} />}
            title="Help"
            popupOffset={[-20, 0]}
          >
            <Menu.Item key="docs">
              <FileTextOutlined style={iconStyle} />
              <a
                href={`https://docs.kinetica.com/7.1/${docs}`}
                target="_blank"
                rel="noreferrer"
              >
                Documentation
              </a>
            </Menu.Item>
            <Menu.Item key="slack">
              <SlackOutlined style={iconStyle} />
              <a
                href="https://kinetica.com/slack"
                target="_blank"
                rel="noreferrer"
              >
                Kinetica Community Slack
              </a>
            </Menu.Item>
            <Menu.Item key="version" style={{ cursor: 'not-allowed' }}>
              <SaveOutlined style={iconStyle} />
              Version {version}
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="/user"
            icon={<UserOutlined style={subIconStyle} />}
            title={userMe?.username}
            popupOffset={[-20, 0]}
            style={{ minWidth: 116 }}
            {...dtId(TEST_IDS.NAV_USER)}
          >
            <Menu.Item
              key="/usage"
              onClick={handleUserUsage}
              {...dtId(TEST_IDS.NAV_USER_USAGE)}
            >
              <BarChartOutlined style={iconStyle} />
              Usage
            </Menu.Item>
            {isCloud && (
              <Menu.Item
                key="/password"
                onClick={handleUserSetPassword}
                {...dtId(TEST_IDS.NAV_USER_PASSWORD)}
              >
                <LockOutlined style={iconStyle} />
                Password
              </Menu.Item>
            )}
            <Menu.Item
              key="/logout"
              onClick={handleLogout}
              {...dtId(TEST_IDS.NAV_USER_LOGOUT)}
            >
              <LogoutOutlined style={iconStyle} />
              Logout
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
      {showUserPasswordModal && passwordUser && cluster && (
        <UserPasswordModal
          cluster={cluster}
          user={passwordUser}
          mypassword={true}
          visible={showUserPasswordModal}
          close={_ => {
            setShowUserPasswordModal(false);
          }}
          callback={handleUserPasswordCallback}
        />
      )}
      {showUserUsageModal && cluster && (
        <UserUsageModal
          visible={showUserUsageModal}
          close={_ => {
            setShowUserUsageModal(false);
          }}
        />
      )}
    </>
  );
};

export default NavBar;
