// Imports
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Space,
  Button,
  Tree,
  Tooltip,
  Input,
  Spin,
  Dropdown,
  Menu,
  Modal,
  Alert,
  Popconfirm,
  notification,
} from 'antd';
import {
  DownOutlined,
  RedoOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useResizeDetector } from 'react-resize-detector';
import axios from 'axios';

// App Imports
import { setExplorerPanelFilesExpandedKeys } from '../../store/app/actions';
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import TypeObject from './objects/TypeObject';
import FolderObject from './objects/FolderObject';
import FileObject from './objects/FileObject';
import FolderCreateModal from '../modal/FolderCreateModal';
import FileUploadModal from '../modal/FileUploadModal';
import MultiSelectContext from './MultiSelectContext';
import { objHasMatch } from '../../helper';
import Spinner from '../common/Spinner';
import {
  getFileFormat,
  getTableFromFile,
} from '../../containers/importexport/utils';
import { APP_URL_API, FREE_SAAS } from '../../setup/config';
import { FILES_PERMISSION_WRITE, EXPLORER_PANEL_WIDTH } from '../../constants';
import { UserContext } from '../../context';

const { Search } = Input;
const { confirm } = Modal;

const FileExplorer = ({ offset = 0, refetchUsage = () => {} }) => {
  const {
    loading: foldersLoading,
    data: objects,
    refetch: refetchFolders,
  } = GraphQLServices.Files.useGetFolders();
  const [deleteFolderByName] = GraphQLServices.Files.useDeleteFolderByName();
  const [deleteFileByNames, { loading: removingFile }] =
    GraphQLServices.Files.useDeleteByNames();

  const [entityFilterStr, setEntityFilterStr] = useState('');
  const [filesData, setFilesData] = useState([]);

  const { explorerPanelExpandedKeys } = useSelector(state => state.app);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [contextFolder, setContextFolder] = useState(null);

  const [showFolderCreateModal, setShowFolderCreateModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const [isDownloadingFile, setIsDownloadingFile] = useState('');

  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);

  const userMe = useContext(UserContext) ?? {};

  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const history = useHistory();
  const { height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 200,
  });

  const filterFile = (data, filterStr) => {
    if (filterStr === '') {
      return data;
    }
    return data
      .map(item => {
        return Object.keys(item).reduce((acc, cur) => {
          if (Array.isArray(item[cur])) {
            acc[cur] = item[cur].filter(item2 => {
              return objHasMatch(item2, filterStr);
            });
          } else {
            acc[cur] = item[cur];
          }
          return acc;
        }, {});
      })
      .filter(item => objHasMatch(item, filterStr));
  };

  const handleTreeExpand = useCallback(
    (current, { expanded, node }) => {
      if (expanded) {
        // Add to expandedKeys
        const added = [...expandedKeys, node.key];
        const update = [...new Set(added)];
        setExpandedKeys(update);
        dispatch(setExplorerPanelFilesExpandedKeys(update));
      } else {
        // Remove from expandedKeys
        const copy = [...expandedKeys];
        copy.splice(copy.indexOf(node.key), 1);
        const update = [...copy];
        setExpandedKeys(update);
        dispatch(setExplorerPanelFilesExpandedKeys(update));
      }
    },
    [expandedKeys, dispatch]
  );

  const handleTreeSelect = useCallback(
    (current, { selected, node }) => {
      if (selected) {
        // Add to selectedKeys
        const added = [...selectedKeys, node.key];
        const update = [...new Set(added)];
        setSelectedKeys(update);
      } else {
        // Remove from selectedKeys
        const copy = [...selectedKeys];
        copy.splice(copy.indexOf(node.key), 1);
        const update = [...copy];
        setSelectedKeys(update);
      }
    },
    [selectedKeys]
  );

  useEffect(
    _ => {
      const filesData =
        objects && objects.folders
          ? objects.folders
              .reduce((acc, cur) => {
                const children = cur.files
                  .map(file => {
                    const { files, ...folder } = cur;
                    return {
                      id: file.id,
                      title: file.name,
                      key: `file.${cur.name}.${file.id}`,
                      source: file,
                      selectable: false,
                      checkable:
                        folder.permission === FILES_PERMISSION_WRITE.value,
                      folder,
                    };
                  })
                  .sort((file1, file2) => {
                    if (file1.title.toLowerCase() > file2.title.toLowerCase())
                      return 1;
                    if (file1.title.toLowerCase() < file2.title.toLowerCase())
                      return -1;
                    return 0;
                  });
                acc.push({
                  id: cur.id,
                  title: cur.name || '< root >',
                  key: `folder.${cur.id}`,
                  children,
                  source: cur,
                  selectable: false,
                  checkable: false,
                });

                return acc;
              }, [])
              .sort((folder1, folder2) => {
                if (folder1.title.toLowerCase() > folder2.title.toLowerCase())
                  return 1;
                if (folder1.title.toLowerCase() < folder2.title.toLowerCase())
                  return -1;
                return 0;
              })
          : [];
      setFilesData(filesData);

      analytics.track(analytics.EVENT_TYPES.FILE_COUNT)({
        files: filesData.reduce((acc, cur) => {
          return acc + cur.children.length;
        }, 0),
      });

      if (explorerPanelExpandedKeys.files.length === 0) {
        setExpandedKeys(['data.files', ...filesData.map(folder => folder.key)]);
      } else {
        setExpandedKeys(explorerPanelExpandedKeys.files);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objects, explorerPanelExpandedKeys]
  );

  const treeData = [
    {
      title: 'Folders',
      key: 'data.files',
      children: filterFile(filesData, entityFilterStr),
      selectable: false,
      checkable: false,
    },
  ];

  const handleFolderCreateCallback = (err, resp) => {
    if (resp) {
      refetchFolders().then(resp => {
        setShowFolderCreateModal(false);
      });

      analytics.track(analytics.EVENT_TYPES.CREATED_KIFS_FOLDER)({});
    } else {
      console.error(err);
    }
  };

  const handleFileUploadCallback = (err, resp) => {
    if (resp) {
      refetchFolders().then(resp => {
        setShowFileUploadModal(false);
      });
      analytics.track(analytics.EVENT_TYPES.UPLOADED_KIFS_FILE)({});
    } else {
      console.error(err);
    }
  };

  const handleAddMenuClick = type => {
    switch (type.key) {
      case 'folder': {
        setShowFolderCreateModal(true);
        return;
      }
      case 'file': {
        const folder = FREE_SAAS
          ? objects.folders.find(
              folder => folder.name === `~${userMe.username}`
            )
          : null;
        if (folder) {
          setContextFolder(folder);
        }
        setShowFileUploadModal(true);
        return;
      }
      default: {
        return;
      }
    }
  };

  const handleFileDownload = useCallback(file => {
    setIsDownloadingFile(file.path);

    axios({
      url: `${APP_URL_API}/proxy/dbapi/get/file/${file.path}`,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', file.name); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setIsDownloadingFile('');
    });
  }, []);

  const handleFileImport = useCallback(
    file => {
      history.push('/importexport/kifs', {
        datasource: { datasource_name: '' },
        filepath: `kifs://${file.path}`,
        fileformat: getFileFormat(file.name),
        tableName: getTableFromFile(file.name),
      });
    },
    [history]
  );

  const handleFileDelete = useCallback(
    file => {
      confirm({
        title: `Do you want to delete file ${file.name} in ${file.folder}?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteFileByNames({
            variables: {
              names: [file.path],
            },
          })
            .then(resp => {
              refetchFolders();
            })
            .catch(err => {
              console.error(err);
            });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [deleteFileByNames, refetchFolders]
  );

  const handleOpenFileUploadModal = useCallback(
    folder => {
      setContextFolder(folder);
      setShowFileUploadModal(true);
    },
    [setContextFolder, setShowFileUploadModal]
  );

  const handleFolderDelete = useCallback(
    folder => {
      confirm({
        title: `Do you want to delete folder ${folder.name} and all files within?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteFolderByName({
            variables: {
              name: folder.name,
            },
          })
            .then(resp => {
              refetchFolders();
            })
            .catch(err => {
              console.error(err);
            });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [deleteFolderByName, refetchFolders]
  );

  const handleTreeCheck = (checkedKeys, e) => {
    setCheckedKeys(checkedKeys);
  };

  const handleMultiSelect = useCallback(
    node => {
      setCheckedKeys([node.key]);
      setIsMultiSelect(!isMultiSelect);
    },
    [isMultiSelect]
  );

  const handleCancelMultiSelect = _ => {
    setCheckedKeys([]);
    setIsMultiSelect(false);
  };

  const handleDeleteSelected = _ => {
    deleteFileByNames({
      variables: {
        names: checkedKeys
          .filter(key => {
            const [prefix] = key.match(/[^.]+(\.[^.]+)?/gi);
            const type = prefix.split('.')[0];
            return type === 'file';
          })
          .map(key => {
            const matches = key.match(/[^.]+(\.[^.]+)?/gi);
            const [, ...file] = matches;
            return file.join('.');
          }),
      },
    })
      .then(responses => {
        notification.success({
          message: 'Success',
          description: `Selected file(s) deleted successfully!`,
        });
        refetchFolders();
        handleCancelMultiSelect();
      })
      .catch(errors => {
        console.error(errors);
      });
  };

  const handleTitleRender = useCallback(
    nodeData => {
      if (nodeData.key.startsWith('data.')) {
        return <TypeObject nodeData={nodeData} />;
      } else if (nodeData.key.startsWith('file.')) {
        return (
          <FileObject
            nodeData={nodeData}
            handleFileDownload={handleFileDownload}
            handleFileImport={handleFileImport}
            handleFileDelete={handleFileDelete}
            isDownloadingFile={isDownloadingFile}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('folder.')) {
        return (
          <FolderObject
            nodeData={nodeData}
            handleFolderPermissions={_ => {}}
            handleFolderDelete={handleFolderDelete}
            handleOpenFileUploadModal={handleOpenFileUploadModal}
          />
        );
      }
    },
    [
      handleFileDownload,
      handleFileImport,
      handleFileDelete,
      isDownloadingFile,
      handleFolderDelete,
      handleOpenFileUploadModal,
      handleMultiSelect,
    ]
  );

  const addMenu = (
    <Menu onClick={handleAddMenuClick}>
      {!FREE_SAAS && <Menu.Item key="folder">Add New Folder</Menu.Item>}
      <Menu.Item key="file">Upload New File</Menu.Item>
    </Menu>
  );

  const unableToFetch = useMemo(
    _ => {
      return objects && objects?.folders === null;
    },
    [objects]
  );

  return (
    <div
      className="files-explorer"
      style={{
        padding: '15px',
        height: `calc(100vh - ${100 + offset}px)`,
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div style={{ width: `${EXPLORER_PANEL_WIDTH - 38}px` }}>
          <Space>
            <Search
              placeholder="File search"
              value={entityFilterStr}
              onChange={e => setEntityFilterStr(e.target.value)}
              allowClear
              disabled={unableToFetch}
            />
            <Tooltip title="Refresh">
              <Button
                icon={<RedoOutlined spin={foldersLoading} />}
                onClick={() => {
                  refetchFolders();
                  refetchUsage();
                }}
              />
            </Tooltip>
            <Dropdown overlay={addMenu} disabled={unableToFetch}>
              <Button icon={<PlusOutlined />} />
            </Dropdown>
          </Space>
        </div>
        {isMultiSelect && (
          <div style={{ width: `${EXPLORER_PANEL_WIDTH - 38}px` }}>
            <Space>
              <Popconfirm
                title="Are you sure you want to delete selected file(s)?"
                placement="topRight"
                onConfirm={() => handleDeleteSelected()}
                disabled={checkedKeys.length === 0 || removingFile}
              >
                <Button
                  type="primary"
                  icon={<DeleteOutlined />}
                  style={{ width: `${EXPLORER_PANEL_WIDTH - 160}px` }}
                  loading={removingFile}
                  disabled={checkedKeys.length === 0 || removingFile}
                  danger
                >
                  Delete Selected
                </Button>
              </Popconfirm>
              <Button
                onClick={() => handleCancelMultiSelect()}
                style={{ width: `${EXPLORER_PANEL_WIDTH - 236}px` }}
                disabled={removingFile}
                danger
              >
                Cancel
              </Button>
            </Space>
          </div>
        )}
        <Spin
          indicator={<Spinner />}
          spinning={foldersLoading}
          style={{ opacity: '20%' }}
        >
          <div
            ref={ref}
            style={{
              height: isMultiSelect
                ? `calc(100vh - ${220 + offset}px)`
                : `calc(100vh - ${180 + offset}px)`,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {treeData.length > 0 &&
            treeData[0].children.length > 0 &&
            height !== undefined &&
            height > 0 ? (
              <MultiSelectContext.Provider value={isMultiSelect}>
                <Tree
                  showLine={{ showLeafIcon: false }}
                  switcherIcon={<DownOutlined />}
                  expandedKeys={expandedKeys}
                  onExpand={handleTreeExpand}
                  onCheck={handleTreeCheck}
                  checkedKeys={checkedKeys}
                  selectedKeys={selectedKeys}
                  onSelect={handleTreeSelect}
                  treeData={treeData}
                  height={height}
                  blockNode={true}
                  multiple={true}
                  titleRender={handleTitleRender}
                  checkable={isMultiSelect}
                />
              </MultiSelectContext.Provider>
            ) : unableToFetch ? (
              <Alert message={'Unable to connect to the database.'} banner />
            ) : null}
          </div>
        </Spin>
      </Space>
      {showFolderCreateModal && (
        <FolderCreateModal
          visible={showFolderCreateModal}
          close={_ => {
            setShowFolderCreateModal(false);
          }}
          callback={handleFolderCreateCallback}
        />
      )}
      {showFileUploadModal && (
        <FileUploadModal
          defaultFolder={contextFolder?.id ?? null}
          visible={showFileUploadModal}
          close={_ => {
            setContextFolder(null);
            setShowFileUploadModal(false);
          }}
          callback={handleFileUploadCallback}
        />
      )}
    </div>
  );
};

export default FileExplorer;
