// Imports
import React from 'react';
import { Dropdown, Menu, notification, Tooltip } from 'antd';
import {
  FileOutlined,
  DownloadOutlined,
  DeleteOutlined,
  SyncOutlined,
  ImportOutlined,
  DiffOutlined,
} from '@ant-design/icons';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import {
  EXPLORER_PANEL_WIDTH,
  FILES_PERMISSION_WRITE,
} from '../../../constants';
import { KIFS_DOWNLOAD_LIMIT, FREE_SAAS } from '../../../setup/config';
import { humanFileSize } from '../../../helper';

const FileObject = ({
  nodeData,
  handleFileDownload,
  handleFileImport,
  handleFileDelete,
  handleMultiSelect,
  isDownloadingFile,
}) => {
  const handleDownloadClick = node => () => {
    if (FREE_SAAS && node.size >= KIFS_DOWNLOAD_LIMIT) {
      notification.error({
        message: 'Error has occurred',
        description: `Download exceeds size limit of ${humanFileSize(
          KIFS_DOWNLOAD_LIMIT,
          true
        )}`,
        duration: 0,
      });
      return;
    }
    handleFileDownload(node);
  };

  const fileContextMenu = node => {
    const hasWrite = node.folder.permission === FILES_PERMISSION_WRITE.value;
    return (
      <Menu>
        <Menu.Item key="rename" onClick={handleDownloadClick(node.source)}>
          <DownloadOutlined /> Download
        </Menu.Item>
        <Menu.Item key="import" onClick={_ => handleFileImport(node.source)}>
          <ImportOutlined /> Import
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="delete"
          onClick={_ => handleFileDelete(node.source)}
          style={{ color: hasWrite ? '#C62828' : undefined }}
          disabled={!hasWrite}
        >
          <DeleteOutlined /> Delete
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="multiselect"
          onClick={_ => handleMultiSelect(node)}
          style={{ color: hasWrite ? '#3700b3' : undefined }}
          disabled={!hasWrite}
        >
          <DiffOutlined /> Multi-Select
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          overlay={fileContextMenu(nodeData)}
          trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
        >
          <div>
            {isDownloadingFile === nodeData.source.path ? (
              <SyncOutlined spin />
            ) : (
              <FileOutlined />
            )}{' '}
            <span
              style={{
                width: `${
                  EXPLORER_PANEL_WIDTH - 123 - (isMultiSelect ? 24 : 0)
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              <i style={{ fontSize: '11px', color: '#cccccc', float: 'right' }}>
                {humanFileSize(nodeData.source.size, true)}
              </i>
              <Tooltip
                title={
                  isDownloadingFile === nodeData.source.path
                    ? 'Preparing download..'
                    : ''
                }
                visible={isDownloadingFile === nodeData.source.path}
                placement="right"
              >
                {nodeData.title}{' '}
              </Tooltip>
            </span>
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default FileObject;
