// Imports
import React, { useMemo } from 'react';
import { Dropdown, Menu } from 'antd';
import {
  InsertRowAboveOutlined,
  EyeOutlined,
  SettingOutlined,
  DeleteOutlined,
  ConsoleSqlOutlined,
  BarChartOutlined,
  FileAddOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  SwapOutlined,
  DiffOutlined,
  EditOutlined,
  ReloadOutlined,
  ExportOutlined,
} from '@ant-design/icons';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const TableObject = ({
  nodeData,
  handleTableInfo,
  handleTablePreview,
  handleTableCreateSql,
  handleTableRename,
  handleTableDelete,
  handleTableStats,
  handleTableWms,
  handleTableAddRecord,
  handleTableExport,
  handleTableConfigure,
  handleTableMove,
  handleTableRefresh,
  handleMultiSelect,
}) => {
  const disableWmsPreview = useMemo(
    _ => {
      return nodeData?.source?.type.includes('logical_view');
    },
    [nodeData]
  );
  const disableConfigure = useMemo(
    _ => {
      return (
        nodeData?.source?.type.includes('logical_view') ||
        nodeData?.source?.type.includes('logical_external_table')
      );
    },
    [nodeData]
  );
  const disableAddRecord = useMemo(
    _ => {
      return (
        nodeData?.source?.type.includes('logical_view') ||
        nodeData?.source?.type.includes('logical_external_table')
      );
    },
    [nodeData]
  );
  const disableStatistics = useMemo(
    _ => {
      return nodeData?.source?.type.includes('logical_view');
    },
    [nodeData]
  );
  const disableRename = useMemo(
    _ => {
      return nodeData?.source?.type.includes('logical_view');
    },
    [nodeData]
  );
  const enableRefresh = useMemo(
    _ => {
      return (
        nodeData?.source?.type.includes('materialized_view') ||
        nodeData?.source?.type.includes('materialized_external_table')
      );
    },
    [nodeData]
  );

  const tableContextMenu = node => {
    return (
      <Menu>
        <Menu.Item key="info" onClick={_ => handleTableInfo(node.source)}>
          <InfoCircleOutlined /> Info
        </Menu.Item>
        <Menu.Item key="preview" onClick={_ => handleTablePreview(node.source)}>
          <EyeOutlined /> Data Preview
        </Menu.Item>
        <Menu.Item
          key="wms"
          onClick={_ => handleTableWms(node.source)}
          disabled={disableWmsPreview}
        >
          <GlobalOutlined /> WMS Preview
        </Menu.Item>
        <Menu.Item
          key="configure"
          onClick={_ => handleTableConfigure(node.id)}
          disabled={disableConfigure}
        >
          <SettingOutlined /> Configure
        </Menu.Item>
        <Menu.Item
          key="addrecord"
          onClick={_ => handleTableAddRecord(node.source)}
          disabled={disableAddRecord}
        >
          <FileAddOutlined /> Add Record
        </Menu.Item>
        <Menu.Item key="export" onClick={_ => handleTableExport(node.source)}>
          <ExportOutlined /> Export File
        </Menu.Item>
        <Menu.Item key="sqlddl" onClick={_ => handleTableCreateSql(node.id)}>
          <ConsoleSqlOutlined /> SQL DDL
        </Menu.Item>
        <Menu.Item
          key="stats"
          onClick={_ => handleTableStats(node.source)}
          disabled={disableStatistics}
        >
          <BarChartOutlined /> Statistics
        </Menu.Item>
        <Menu.Item
          key="refresh"
          onClick={_ => handleTableRefresh(node.source)}
          disabled={!enableRefresh}
        >
          <ReloadOutlined /> Refresh
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="rename"
          onClick={_ => handleTableRename(node.source)}
          disabled={disableRename}
        >
          <EditOutlined /> Rename
        </Menu.Item>
        <Menu.Item key="move" onClick={_ => handleTableMove(node.source)}>
          <SwapOutlined /> Move
        </Menu.Item>
        <Menu.Item
          key="delete"
          onClick={_ => handleTableDelete(node.source)}
          style={{ color: '#C62828' }}
        >
          <DeleteOutlined /> Delete
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="multiselect"
          onClick={_ => handleMultiSelect(node)}
          style={{ color: '#3700b3' }}
        >
          <DiffOutlined /> Multi-Select
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          overlay={tableContextMenu(nodeData)}
          trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
        >
          <div>
            <InsertRowAboveOutlined />{' '}
            <span
              style={{
                width: `${
                  EXPLORER_PANEL_WIDTH - 125 - (isMultiSelect ? 24 : 0)
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              {nodeData.title}
            </span>
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default TableObject;
