// Imports
import { useApolloClient } from '@apollo/client';
import { notification, Progress } from 'antd';
import React, { useCallback } from 'react';

// App Imports
import { GET_IMPORT_JOB_BY_ID } from '../../graphql/schema/import_jobs';
import GraphQLServices from '../../graphql/services';
import ClientError from '../../lib/ClientError';

function convertFormatValue(value) {
  if (typeof value === 'string') {
    return `'${value.replace(/'/g, "''")}'`;
  } else if (typeof value === 'boolean') {
    return value ? 'TRUE' : 'FALSE';
  }

  return '<VALUE>';
}

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export function buildImportSQLStatement(vars, isCDATA = false) {
  const { type } = vars;
  const qualifiedName = vars.schemaName
    ? `"${vars.schemaName}"."${vars.tableName}"`
    : `"${vars.tableName}"`;
  const formatOptionEntries = vars.formatOptions
    ? Object.entries(vars.formatOptions).filter(
        entry => entry[0] && entry[1] != null
      )
    : [];

  const table = `${qualifiedName ?? '<TABLE>'}`;
  const filePaths =
    type !== 'jdbc' && type !== 'kafka' && !isCDATA
      ? ` FROM FILE PATHS '${vars?.filePath?.replace("'", "''") || ''}'`
      : '';
  const remoteQuery = vars.remoteQuery
    ? ` FROM REMOTE QUERY '${vars.remoteQuery}'`
    : '';
  const format = vars.fileFormat ? ` FORMAT ${vars.fileFormat}` : '';
  const datasource = vars.dataSource
    ? `, DATA SOURCE = '${vars.dataSource ?? '<DATASOURCE_NAME>'}'`
    : '';
  const formatOptions =
    formatOptionEntries.length > 0
      ? ` (${formatOptionEntries
          .map(entry => `${entry[0]} = ${convertFormatValue(entry[1])}`)
          .join(', ')})`
      : '';
  const errorOptions = `ON ERROR = ${
    vars.abortOnError ? 'ABORT' : 'PERMISSIVE'
  }`;
  const jsonOptions = ['JSON', 'SHAPEFILE'].includes(vars.fileFormat)
    ? `, STORE_POINTS_AS_XY = '${vars.storePointsAsXY === true}'`
    : '';
  const subscribeOptions = type === 'kafka' ? ", SUBSCRIBE = 'true'" : '';
  const badRecordOptions = vars.badRecordTable
    ? vars.badRecordSchema
      ? `, BAD RECORD TABLE = '${vars.badRecordSchema}.${vars.badRecordTable}'`
      : `, BAD RECORD TABLE = '${vars.badRecordTable}'`
    : '';

  const columnFormatsOptions =
    vars.columnFormats && Object.keys(vars.columnFormats).length > 0
      ? `, COLUMN FORMATS = '${JSON.stringify(vars.columnFormats)}'`
      : '';

  const batchSizeOption = vars.batchSize
    ? `, BATCH_SIZE = ${vars.batchSize}`
    : '';

  return `LOAD DATA INTO ${table}${filePaths}${remoteQuery}${format}${formatOptions} WITH OPTIONS (${errorOptions}${datasource}${subscribeOptions}${jsonOptions}${badRecordOptions}${columnFormatsOptions}${batchSizeOption})`;
}

export const DEFAULT_FORM_ITEM_PROPS = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

export const getWarnings = record => {
  try {
    const { info } = JSON.parse(record.response);
    return Object.keys(info)
      .filter(key => key.toLowerCase().includes('warning_'))
      .map(key => info[key]);
  } catch (err) {
    return [];
  }
};

// NOTE: Decreasing this interval will not necessarily decrease latency of
// status updating because the backend also has a polling interval that it
// uses to update the job's status. You should look at changing both these
// intervals for the desired effect.
const JOB_STATUS_POLLING_INTERVAL = 5000; // ms

// This is a wrapper around createImportJob that includes job status polling.
export function useCreateImportJob() {
  const apolloClient = useApolloClient();
  const [createImportJob, { loading: creatingImportJob }] =
    GraphQLServices.ImportJobs.useCreateImportJob();
  const { refetch: refetchTables } =
    GraphQLServices.DataObjects.useGetDataTables();
  const { refetch: refetchImportJobs } =
    GraphQLServices.ImportJobs.useGetImportJobs();

  // NOTE: This function needs the SQLITE import_job id, not the job_id that
  // corresponds to the Kinetica job it is tracking.
  const updateJobStatus = useCallback(
    jobId => {
      apolloClient
        .query({
          query: GET_IMPORT_JOB_BY_ID,
          variables: {
            id: jobId,
          },
        })
        .then(resp => {
          if (resp?.errors?.length === 0 || !resp?.errors) {
            const status = resp.data.import_job.status;
            // let progressType = 'normal';
            let notificationType = 'info';
            let title = '';
            switch (status) {
              case 'RUNNING':
                // progressType = 'active';
                notificationType = 'info';
                title = 'Import Job Running';
                break;
              case 'DONE':
                // progressType = 'success';
                notificationType = 'success';
                title = 'Import Job Finished';
                break;
              case 'ERROR':
                // progressType = 'exception';
                notificationType = 'error';
                title = 'Import Job Error Occurred';
                break;
              case 'CANCELLED':
                // progressType = 'normal';
                notificationType = 'warning';
                title = 'Import Job Cancelled';
                break;
              default:
                console.warn(`Job ${jobId} has unknown status of ${status}`);
                // progressType = 'normal';
                notificationType = 'info';
                title = 'Import Job Running';
                break;
            }

            notification.open({
              key: jobId,
              message: title,
              description: (
                <>
                  {/* <Progress
                    percent={resp.data.import_job.progress}
                    size="small"
                    status={progressType}
                  /> */}
                  {resp?.data?.import_job?.status === 'ERROR' &&
                    resp?.data?.import_job?.response !== '' && (
                      <p style={{ marginTop: '10px' }}>
                        {truncateString(resp?.data?.import_job?.response, 1000)}
                      </p>
                    )}
                  {resp?.data?.import_job?.status === 'DONE' &&
                    getWarnings(resp?.data?.import_job).length > 0 && (
                      <pre
                        className="console warning"
                        style={{ marginTop: '10px' }}
                      >
                        Warnings:
                        <br />
                        {getWarnings(resp?.data?.import_job).map(
                          (warning, idx) => (
                            <span key={idx}>
                              {warning}
                              <br />
                            </span>
                          )
                        )}
                      </pre>
                    )}
                </>
              ),
              type: notificationType,
              duration: status === 'DONE' ? 30 : 0,
            });

            if (status === 'RUNNING') {
              setTimeout(updateJobStatus, JOB_STATUS_POLLING_INTERVAL, jobId);
            } else if (status === 'DONE') {
              try {
                // If user changes screen, refetch will
                // fail but OK at this point
                refetchTables();
              } catch (err) {
                console.error(err.toString());
              }
            }
          }
        })
        .catch(err => {
          console.error(err);
          notification.open({
            key: jobId,
            message: 'Import Job Error Occurred',
            description: err.toString(),
            type: 'error',
            duration: 0,
          });
        });
    },
    [apolloClient, refetchTables]
  );

  const createImportJobWrapper = useCallback(
    (...args) => {
      return createImportJob(...args)
        .then(resp => {
          if (resp.errors && resp.errors.length > 0) {
            throw new ClientError({
              title: 'Import Error Occurred',
              details: resp.errors.map(e => e.message).join('\n'),
            });
          } else {
            notification.open({
              key: resp.data.createImportJob.id,
              message: 'Import Job Started',
              description: <Progress percent={0} size="small" />,
              type: 'success',
              duration: 0,
            });
            updateJobStatus(resp.data.createImportJob.id);
            refetchTables();
            refetchImportJobs();
          }
        })
        .catch(err => {
          if (err) {
            if (err.errorFields) {
              err.errorFields
                .map(e => e.errors.join('\n'))
                .forEach(e =>
                  notification.open({
                    message: 'Validation Error Occurred',
                    description: e,
                    type: 'error',
                    duration: 0,
                  })
                );

              console.error(
                err.errorFields.map(e => e.errors.join('\n')).join('\n')
              );
            } else if (err.title || err.details) {
              console.error(err.details);
              notification.open({
                message: err.title,
                description: err.details,
                type: 'error',
                duration: 0,
              });
            }
          }
        });
    },
    [createImportJob, refetchTables, refetchImportJobs, updateJobStatus]
  );

  return [createImportJobWrapper, creatingImportJob];
}

export const FILE_FORMATS = {
  TEXT: 'TEXT',
  PARQUET: 'PARQUET',
  JSON: 'JSON',
};
export const TEXT_FILE_EXTENSIONS = ['csv', 'tsv', 'psv'];
export const PARQUET_FILE_EXTENSIONS = ['parquet'];
export const JSON_FILE_EXTENSIONS = ['json'];
export const getFileFormat = fileName => {
  const extension = fileName.split('.')[fileName.split('.').length - 1];
  if (TEXT_FILE_EXTENSIONS.includes(extension.toLowerCase())) {
    return FILE_FORMATS.TEXT;
  }
  if (JSON_FILE_EXTENSIONS.some(ext => extension.toLowerCase().includes(ext))) {
    return FILE_FORMATS.JSON;
  }
  if (
    PARQUET_FILE_EXTENSIONS.some(ext => extension.toLowerCase().includes(ext))
  ) {
    return FILE_FORMATS.PARQUET;
  }
  return null;
};

export const getTableFromFile = fileName => {
  return fileName.split('.').length > 1
    ? fileName
        .split('.')
        [fileName.split('.').length - 2].replaceAll(' ', '_')
        .toLowerCase()
    : fileName;
};
