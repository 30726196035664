import React, { useMemo, useState } from 'react';
import {
  Form,
  AutoComplete,
  Input,
  InputNumber,
  Checkbox,
  Modal,
  Button,
  Row,
  Col,
  Select,
  Radio,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import GraphQLServices from '../../graphql/services';
import { FREE_SAAS } from '../../setup/config';

const DEFAULT_FORM_ITEM_PROPS = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

export default function TableInfoForm({ form, isEditing }) {
  const { loading, error, data } = GraphQLServices.Schemas.useGetSchemas();
  const [showAdvancedModal, setShowAdvancedModal] = useState(false);

  const schemas = useMemo(
    _ => {
      return data && data.schemas
        ? data.schemas.map(s => ({ value: s.name }))
        : [];
    },
    [data]
  );
  let schemaNotFoundText = 'No schemas available';
  if (loading) {
    schemaNotFoundText = 'Retrieving...';
  } else if (error) {
    schemaNotFoundText = 'Error while retrieving';
    console.error(error);
  }

  return (
    <div id="basic_info_form">
      <Form.Item
        {...DEFAULT_FORM_ITEM_PROPS}
        name="tableSchema"
        label="Schema"
        extra="Enter an existing schema"
        rules={[
          {
            required: true,
            message: 'Please enter a schema name.',
          },
        ]}
      >
        <AutoComplete
          options={schemas}
          notFoundContent={schemaNotFoundText}
          disabled={isEditing || FREE_SAAS}
          filterOption={true}
        />
      </Form.Item>
      <Form.Item
        {...DEFAULT_FORM_ITEM_PROPS}
        name="tableName"
        label="Name"
        rules={[
          {
            required: true,
            message: 'Please enter a table name.',
          },
          {
            pattern: /^[a-zA-Z0-9_][a-zA-Z0-9_{}[\]:\-()#]*$/,
            message:
              'Table name must start with alphanumeric or underscore and only contain alphanumerics and the following: _ { } [ ] : -  ( ) #',
          },
          {
            min: 1,
            message: 'Table must be between 1-200 characters',
          },
          {
            max: 200,
            message: 'Table must be between 1-200 characters',
          },
        ]}
      >
        <Input placeholder="my_table"></Input>
      </Form.Item>
      <Row>
        <Col span={4}></Col>
        <Col span={20}>
          <Button
            icon={<SettingOutlined />}
            onClick={_ => setShowAdvancedModal(true)}
          >
            Advanced
          </Button>
        </Col>
      </Row>
      <Modal
        visible={showAdvancedModal}
        onCancel={_ => setShowAdvancedModal(false)}
        footer={[
          <Button key="close" onClick={_ => setShowAdvancedModal(false)}>
            Close
          </Button>,
        ]}
        width={600}
        title="Advanced Options"
        forceRender={true}
        getContainer={_ => document.getElementById('basic_info_form')}
      >
        <Row>
          <Col span={24}>
            <Form layout="inline" form={form} style={{ marginBottom: '20px' }}>
              <Form.Item name={['options', 'chunk_size']} label="Chunk Size">
                <InputNumber
                  placeholder="8000000"
                  min={1000}
                  disabled={isEditing}
                ></InputNumber>
              </Form.Item>
              <Form.Item
                name={['options', 'is_replicated']}
                label="Dimension Table"
                valuePropName="checked"
              >
                <Checkbox disabled={isEditing}></Checkbox>
              </Form.Item>
              <Form.Item
                name={['options', 'is_result_table']}
                label="Temporary Table"
                valuePropName="checked"
              >
                <Checkbox disabled={isEditing}></Checkbox>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form layout="inline" form={form} style={{ marginBottom: '20px' }}>
              <Form.Item name={['options', 'ttl']} label="Time To Live">
                <InputNumber min={-1} disabled={isEditing}></InputNumber>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Form layout="vertical" form={form}>
          <Form.Item name={['options', 'foreign_keys']} label="Foreign Keys">
            <Input.TextArea disabled={isEditing}></Input.TextArea>
          </Form.Item>
          <Form.Item
            name={['options', 'foreign_shard_key']}
            label="Foreign Shard Key"
          >
            <Input.TextArea disabled={isEditing}></Input.TextArea>
          </Form.Item>
          <h3>Partitioning</h3>
          <Form.Item
            name={['options', 'partition_type']}
            label="Type"
            initialValue=""
            shouldUpdate={true}
          >
            <Select disabled={isEditing}>
              <Select.Option value="">None</Select.Option>
              <Select.Option value="RANGE">Range</Select.Option>
              <Select.Option value="LIST">List</Select.Option>
              <Select.Option value="INTERVAL">Interval</Select.Option>
              <Select.Option value="HASH">Hash</Select.Option>
              <Select.Option value="SERIES">Series</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Keys"
            shouldUpdate={(prevValues, curValues) =>
              prevValues?.options?.partition_type !==
              curValues?.options?.partition_type
            }
          >
            {_ => {
              const { options = {} } = form.getFieldsValue();
              return (
                <Form.Item
                  name={['options', 'partition_keys']}
                  style={{ marginBottom: '0px' }}
                >
                  <Input.TextArea
                    disabled={!options.partition_type || isEditing}
                  ></Input.TextArea>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            label="Definitions"
            shouldUpdate={(prevValues, curValues) =>
              prevValues?.options?.partition_type !==
              curValues?.options?.partition_type
            }
          >
            {_ => {
              const { options = {} } = form.getFieldsValue();
              return (
                <Form.Item
                  name={['options', 'is_automatic_partition']}
                  // FIXME: initialValue causes an error with form.resetFields()
                  // in it's current configuration.
                  // initialValue="false"
                  style={{ marginBottom: '0px' }}
                >
                  <Radio.Group
                    disabled={options?.partition_type !== 'LIST' || isEditing}
                  >
                    <Radio value="false">Manual</Radio>
                    <Radio value="true">Automatic</Radio>
                  </Radio.Group>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues?.options?.partition_type !==
                curValues?.options?.partition_type ||
              prevValues?.options?.is_automatic_partition !==
                curValues?.options?.is_automatic_partition
            }
          >
            {_ => {
              const { options = {} } = form.getFieldsValue();
              return (
                <Form.Item
                  name={['options', 'partition_definitions']}
                  style={{ marginBottom: '0px' }}
                >
                  <Input.TextArea
                    disabled={
                      !options.partition_type ||
                      (options.partition_type === 'LIST' &&
                        options.is_automatic_partition === 'true') ||
                      isEditing
                    }
                  ></Input.TextArea>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
