// Imports
import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Spin,
  Tabs,
  Form,
  Divider,
  Row,
  Col,
  Input,
  Radio,
  Button,
  Select,
  Switch,
  Empty,
} from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import {
  DATASOURCE_LOCATIONS,
  DATASOURCE_LOCATION_HDFS,
  DATASOURCE_HDFS_AUTH_TYPES,
  DATASOURCE_HDFS_AUTH_PASSWORD,
  DATASOURCE_HDFS_AUTH_KERBEROS_TOKEN,
  DATASOURCE_HDFS_AUTH_KERBEROS_KEYTAB,
  DATASOURCE_DEFAULT_AUTH_TYPE,
  DATASOURCE_HDFS_PREFIX,
} from '../../constants';
import Spinner from '../../components/common/Spinner';
import ImportForm from './ImportForm';
import ImportHistory from './ImportHistory';
import HdfsImage from '../../images/import/hdfs.svg';
import { DEFAULT_FORM_ITEM_PROPS } from './utils';

const { TabPane } = Tabs;

const Setup = ({
  form,
  setupForm,
  setFormValues: setParentFormValues,
  setStep,
}) => {
  const { data: { datasources = [] } = {}, refetch: refetchDatasources } =
    GraphQLServices.Datasources.useGetDatasources();
  const [createDatasource] = GraphQLServices.Datasources.useCreateDatasource();

  const [isCreating, setIsCreating] = useState(false);
  const [formValues, setFormValues] = useState({
    hdfs_auth_type: DATASOURCE_DEFAULT_AUTH_TYPE,
  });

  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.datasource) {
          setupForm.setFieldsValue({
            mode: 'existing',
            datasource: location.state.datasource.datasource_name,
          });
        }
      }
    },
    [location, setupForm]
  );

  const onFinish = values => {
    const variables = Object.keys(values).reduce((acc, cur) => {
      if (values[cur]) {
        acc[cur] = values[cur];
      }
      return acc;
    }, {});

    if (variables.location?.includes(DATASOURCE_LOCATION_HDFS)) {
      variables.location = DATASOURCE_HDFS_PREFIX + variables.location_hdfs;
    }

    setIsCreating(true);
    createDatasource({
      variables,
    })
      .then(resp => {
        const { datasource_name: datasource } = resp?.data?.datasourceCreate;
        setIsCreating(false);
        refetchDatasources().then(_ => {
          if (datasource) {
            const mode = setupForm.getFieldValue('mode');
            setupForm.resetFields();
            setupForm.setFieldsValue({
              mode,
              datasource,
            });
            form.setFieldsValue({
              dataSource: datasource,
            });

            analytics.track(analytics.EVENT_TYPES.CREATED_DATA_SOURCE)({});

            setStep(1);
          }
        });
      })
      .catch(err => {
        setIsCreating(false);
        refetchDatasources();
      });
  };

  const create = _ => {
    setupForm.submit();
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.mode) {
      setupForm.setFieldsValue({
        datasource: undefined,
      });
    }
    setFormValues({
      ...allValues,
      hdfs_auth_type: allValues.hdfs_auth_type || DATASOURCE_DEFAULT_AUTH_TYPE,
    });
    setParentFormValues({
      ...allValues,
    });
  };

  const handleDatasourceChange = datasource => {
    form.setFieldsValue({
      dataSource: datasource,
    });
  };

  const datasourceOptions = useMemo(
    _ => {
      return datasources
        ? datasources
            .filter(datasource => {
              return (
                datasource.storage_provider_type.toLowerCase() ===
                DATASOURCE_LOCATION_HDFS
              );
            })
            .map(datasource => ({
              label: datasource.datasource_name,
              value: datasource.datasource_name,
            }))
        : [];
    },
    [datasources]
  );

  return (
    <Form
      form={setupForm}
      name="file"
      layout="horizontal"
      initialValues={{ mode: '', location: DATASOURCE_LOCATION_HDFS }}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      preserve={false}
      {...DEFAULT_FORM_ITEM_PROPS}
    >
      <Row gutter={0}>
        <Col span={4}>
          <img
            src={HdfsImage}
            style={{
              height: '60px',
              float: 'right',
              margin: '10px 40px 0px 0px',
            }}
            alt="HDFS"
          />
        </Col>
        <Col span={18}>
          <p>
            <strong>Directions</strong>
            <br />
            Optionally create a new HDFS data source, select a data source (new
            or existing), and click next to continue.
          </p>
          <Divider dashed />
          <h3>What would you like to do?</h3>
          <Form.Item label="" name="mode">
            <Radio.Group>
              <Radio.Button value="new">Create New Data Source</Radio.Button>
              <Radio.Button value="existing">
                Select Existing Data Source
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {setupForm.getFieldValue('mode') === 'new' && (
        <>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input datasource name!',
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: 'Please select datasource location!',
              },
            ]}
            hidden
          >
            <Radio.Group options={DATASOURCE_LOCATIONS} optionType="button" />
          </Form.Item>
          <Form.Item
            label="URL"
            name="location_hdfs"
            rules={[
              {
                required: true,
                message: 'Please input HDFS host:port!',
              },
            ]}
          >
            <Input
              addonBefore={DATASOURCE_HDFS_PREFIX}
              placeholder="<host>:<port>"
            />
          </Form.Item>
          <Form.Item
            label="Auth Type"
            name="hdfs_auth_type"
            initialValue={DATASOURCE_DEFAULT_AUTH_TYPE}
          >
            <Radio.Group
              options={DATASOURCE_HDFS_AUTH_TYPES}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          {formValues?.hdfs_auth_type === DATASOURCE_HDFS_AUTH_PASSWORD && (
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Username"
                  name="user_name"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Input placeholder="<hdfs username>" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Password"
                  name="password"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Input type="password" placeholder="<hdfs username>" />
                </Form.Item>
              </Col>
            </Row>
          )}
          {formValues?.hdfs_auth_type ===
            DATASOURCE_HDFS_AUTH_KERBEROS_TOKEN && (
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Username"
                  name="user_name"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Input placeholder="<hdfs kerberos token username>" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Use Kerberos"
                  name="hdfs_use_kerberos"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Switch checked={formValues?.hdfs_use_kerberos} />
                </Form.Item>
              </Col>
            </Row>
          )}
          {formValues?.hdfs_auth_type ===
            DATASOURCE_HDFS_AUTH_KERBEROS_KEYTAB && (
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Username"
                  name="user_name"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Input placeholder="<hdfs kerberos keytab username>" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Keytab File"
                  name="hdfs_kerberos_keytab"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Input placeholder="<keytab file/path>" />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={4}></Col>
            <Col span={18}>
              <Button
                key="create"
                type="primary"
                onClick={create}
                loading={isCreating}
                block
              >
                Create
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={4}></Col>
            <Col span={18}>
              <Divider dashed />
            </Col>
          </Row>
          <Form.Item
            label="Datasource"
            name="datasource"
            {...DEFAULT_FORM_ITEM_PROPS}
          >
            <Select
              onChange={handleDatasourceChange}
              placeholder="Will default to newly created datasource"
              disabled
            >
              {datasourceOptions.map(option => {
                return (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </>
      )}
      {setupForm.getFieldValue('mode') === 'existing' && (
        <>
          <Row>
            <Col span={4}></Col>
            <Col span={18}>
              <Divider dashed />
            </Col>
          </Row>
          <Form.Item
            label="Data Source"
            name="datasource"
            {...DEFAULT_FORM_ITEM_PROPS}
          >
            <Select
              onChange={handleDatasourceChange}
              placeholder="Select a data source"
              notFoundContent={
                <Empty
                  description="No HDFS data source available"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              }
            >
              {datasourceOptions.map(option => {
                return (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

const SourceHdfs = () => {
  const [activeTabKey, setActiveTabKey] = useState('import');

  const handleTabClick = key => {
    setActiveTabKey(key);
  };

  return (
    <div>
      <img
        src={HdfsImage}
        style={{
          height: '35px',
          display: 'inline-block',
          verticalAlign: 'top',
          marginRight: '10px',
        }}
        alt="HDFS"
      />
      <h2 style={{ display: 'inline-block' }}>HDFS Import</h2>
      <Spin indicator={<Spinner />} spinning={false}>
        <Tabs type="card" activeKey={activeTabKey} onTabClick={handleTabClick}>
          <TabPane tab="Import" key="import">
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: 'calc(100vh - 250px)',
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportForm setActiveTabKey={setActiveTabKey}>
                  {props => {
                    return <Setup {...props} />;
                  }}
                </ImportForm>
              </div>
            </div>
          </TabPane>
          <TabPane tab="History" key="history">
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: 'calc(100vh - 250px)',
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportHistory
                  pageSize={Math.floor((window.innerHeight - 400) / 50)}
                />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};

export default SourceHdfs;
