// Imports
import React, { useMemo } from 'react';
import { Layout, Tooltip } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import { DEPLOYMENT_TYPE } from '../../setup/config';

const { Footer: AntFooter } = Layout;

const Footer = () => {
  const { data: { k8s_kineticaworkbenches } = {} } =
    GraphQLServices.K8sKineticaWorkbenches.useGetK8sKineticaWorkbenches({
      variables: {
        deployment_type: DEPLOYMENT_TYPE,
      },
    });

  const workbench = useMemo(
    _ => {
      if (k8s_kineticaworkbenches) {
        return k8s_kineticaworkbenches[0];
      }
      return null;
    },
    [k8s_kineticaworkbenches]
  );

  return (
    <AntFooter className="footer">
      <Tooltip title={workbench?.spec?.image.split('-')[0]}>
        Workbench ©{new Date().getFullYear()} Created by Kinetica
      </Tooltip>
    </AntFooter>
  );
};

export default Footer;
