// Imports
import React, { useState, useMemo } from 'react';
import { Space, Button, Popconfirm, Alert, Empty } from 'antd';
import { SettingOutlined, CloseOutlined } from '@ant-design/icons';
import { ResponsiveLine } from '@nivo/line';

// App Imports
import VizConfigDrawer from './VizConfigDrawer';
import { useIsReadOnly } from './utils';
import { VIZ_X_AXIS_DATA_LIMIT } from '../../constants';

const VizLineChart = ({
  viz,
  data,
  columns,
  handleUpdate,
  handleRemove,
  minHeight = 180,
}) => {
  const { fields } = viz?.visualization_type?.params;
  const { config } = viz;

  const [isVizConfigOpen, setIsVizConfigOpen] = useState(false);
  const readOnly = useIsReadOnly();

  const seriesField = fields.find(field => field.name === 'series_column');

  const chartData = useMemo(
    _ => {
      return config[seriesField.name]
        ? data.column_1
            .map((column_1, colIdx) => {
              return data.column_headers.reduce((acc, cur, headerIdx) => {
                if (cur === config.x_axis_column) {
                  acc['x'] = data[`column_${headerIdx + 1}`][colIdx];
                } else if (cur === config.y_axis_column) {
                  acc['y'] = data[`column_${headerIdx + 1}`][colIdx];
                } else if (cur === config.series_column) {
                  acc['series'] = data[`column_${headerIdx + 1}`][colIdx];
                }
                return acc;
              }, {});
            })
            .map(record => {
              return {
                x: record.x || '',
                y: record.y || 0,
                series: record.series || '',
              };
            })
            .reduce((acc, cur) => {
              const series = acc.find(item => item.id === cur.series);
              if (!series) {
                acc.push({
                  id: cur.series,
                  data: [],
                });
              }
              return acc.map(item => {
                if (item.id === cur.series) {
                  return {
                    ...item,
                    data: [...item.data, cur],
                  };
                }
                return item;
              });
            }, [])
        : [
            {
              id: 'series',
              data: data.column_1
                .map((column_1, colIdx) => {
                  return data.column_headers.reduce((acc, cur, headerIdx) => {
                    if (cur === config.x_axis_column) {
                      acc['x'] = data[`column_${headerIdx + 1}`][colIdx];
                    } else if (cur === config.y_axis_column) {
                      acc['y'] = data[`column_${headerIdx + 1}`][colIdx];
                    }
                    return acc;
                  }, {});
                })
                .map(record => {
                  return {
                    x: record.x || '',
                    y: record.y || 0,
                  };
                }),
            },
          ];
    },
    [data, config, seriesField]
  );

  const handleOpenVizConfig = _ => {
    setIsVizConfigOpen(true);
  };

  const handleCloseVizConfig = _ => {
    setIsVizConfigOpen(false);
  };

  const handleUpdateVizConfig = values => {
    handleUpdate(values, _ => {
      setIsVizConfigOpen(false);
    });
  };

  const limitExceeded = useMemo(
    _ => {
      return chartData.some(item => item.data.length > VIZ_X_AXIS_DATA_LIMIT);
    },
    [chartData]
  );

  return (
    <div style={{ position: 'relative', minHeight }}>
      {!readOnly && (
        <div style={{ height: 30 }}>
          <Space style={{ float: 'right' }}>
            <Button
              icon={<SettingOutlined />}
              onClick={handleOpenVizConfig}
              size="small"
            >
              Configure
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this visualization?"
              onConfirm={handleRemove}
            >
              <Button icon={<CloseOutlined />} size="small"></Button>
            </Popconfirm>
          </Space>
          {limitExceeded && (
            <Alert
              message={`X-Axis data points limit exceeded. Only ${VIZ_X_AXIS_DATA_LIMIT} will be displayed.`}
              style={{
                padding: '3px 10px',
                fontSize: '12px',
                width: 'calc(100% - 150px)',
              }}
              banner
            />
          )}
        </div>
      )}
      <div style={{ height: minHeight - 20 }}>
        {config?.x_axis_column && config?.y_axis_column ? (
          <ResponsiveLine
            data={chartData.map(item => {
              return {
                ...item,
                data: item.data.slice(0, VIZ_X_AXIS_DATA_LIMIT),
              };
            })}
            margin={{ top: 15, right: 25, bottom: 55, left: 70 }}
            padding={0.1}
            enableGridX={true}
            colors={{ scheme: config.color_scheme || 'spectral' }}
            colorBy="index"
            axisTop={null}
            axisRight={null}
            useMesh={true}
            enableCrosshair={false}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 25,
              legend: config.x_axis_label || '',
              legendPosition: 'middle',
              legendOffset: 45,
              renderTick: props => {
                const {
                  opacity,
                  textAnchor,
                  textBaseline,
                  textX,
                  textY,
                  lineX,
                  lineY,
                  value,
                  rotate,
                  x,
                  y,
                } = props;
                return (
                  <g transform={`translate(${x},${y})`} style={{ opacity }}>
                    <line
                      x1="0"
                      x2={lineX}
                      y1="0"
                      y2={lineY}
                      style={{
                        stroke: 'rgb(119, 119, 119)',
                        strokeWidth: '1px',
                      }}
                    ></line>
                    <text
                      dominantBaseline={textBaseline}
                      textAnchor={textAnchor}
                      transform={`translate(${textX},${textY}) rotate(${rotate})`}
                      style={{ fontSize: '11px' }}
                    >
                      <title>{value}</title>
                      {value.length > 10 ? `${value.substring(0, 8)}..` : value}
                    </text>
                  </g>
                );
              },
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: config.y_axis_label || '',
              legendOffset: -65,
              legendPosition: 'middle',
            }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
            }}
            legends={
              config[seriesField.name]
                ? [
                    {
                      anchor: 'top-right',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -15,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 80,
                      itemHeight: 14,
                      itemOpacity: 0.75,
                      symbolSize: 8,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]
                : []
            }
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Please Configure Chart"
          />
        )}
        {!readOnly && (
          <VizConfigDrawer
            title="Line Chart Configuration"
            fields={viz?.visualization_type?.params?.fields}
            config={viz?.config}
            options={{
              columns,
            }}
            isOpen={isVizConfigOpen}
            handleClose={handleCloseVizConfig}
            handleUpdate={handleUpdateVizConfig}
          />
        )}
      </div>
    </div>
  );
};

export default VizLineChart;
