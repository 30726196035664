// Imports
import React, { useMemo } from 'react';
import { Dropdown, Menu } from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  FileAddOutlined,
} from '@ant-design/icons';

// App Imports
import {
  EXPLORER_PANEL_WIDTH,
  FILES_PERMISSION_WRITE,
} from '../../../constants';
import { humanFileSize } from '../../../helper';

const FolderObject = ({
  nodeData,
  handleFolderPermissions,
  handleFolderDelete,
  handleOpenFileUploadModal,
}) => {
  const folderContextMenu = node => {
    const hasWrite = node.source.permission === FILES_PERMISSION_WRITE.value;
    return (
      <Menu>
        <Menu.Item
          key="upload"
          onClick={_ => handleOpenFileUploadModal(node.source)}
          disabled={!hasWrite}
        >
          <PlusOutlined /> Upload New File
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="delete"
          onClick={_ => handleFolderDelete(node.source)}
          style={{ color: hasWrite ? '#C62828' : undefined }}
          disabled={!hasWrite}
        >
          <DeleteOutlined /> Delete
        </Menu.Item>
      </Menu>
    );
  };

  const folderUsage = useMemo(
    _ => {
      return Number(nodeData.source.data_usage);
    },
    [nodeData]
  );

  const folderLimit = useMemo(
    _ => {
      return Number(nodeData.source.data_limit);
    },
    [nodeData]
  );

  return (
    <Dropdown
      overlay={folderContextMenu(nodeData)}
      trigger={['click', 'contextMenu']}
    >
      <div>
        <span
          style={{
            width: `${EXPLORER_PANEL_WIDTH - 86}px`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            lineHeight: '16px',
            verticalAlign: 'text-top',
          }}
        >
          <i
            style={{
              fontSize: '11px',
              color: folderUsage > 0 ? '#888888' : '#cccccc',
              float: 'right',
            }}
          >
            {humanFileSize(folderUsage, true)}
            {folderLimit >= 0 ? '/' + humanFileSize(folderLimit, true) : null}
          </i>
          {nodeData.title}{' '}
          {nodeData.source.permission === FILES_PERMISSION_WRITE.value && (
            <FileAddOutlined style={{ color: '#cccccc' }} />
          )}
        </span>
      </div>
    </Dropdown>
  );
};

export default FolderObject;
