// Imports
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { Modal, Form, Button, AutoComplete, Input } from 'antd';
import { FilePond } from 'react-filepond';
import { useApolloClient } from '@apollo/client';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import 'filepond/dist/filepond.min.css';

// App Imports
import GPUdb from '../../lib/GPUdb';
import { displayError } from '../../helper';
import GraphQLServices from '../../graphql/services';
import { GET_FOLDER_BY_NAME } from '../../graphql/schema/files';
import {
  FILE_STATUSES,
  FILES_PERMISSION_WRITE,
  KIFS_UPLOAD_CHUNK_SIZE,
} from '../../constants';
import { APP_URL_API, FREE_SAAS } from '../../setup/config';

const { confirm } = Modal;

const FileUploadModal = ({ defaultFolder, visible, close, callback }) => {
  const { data: { folders = [] } = {}, refetch: refetchFolders } =
    GraphQLServices.Files.useGetFolders();
  const [createFolder] = GraphQLServices.Files.useCreateFolder();

  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [folder, setFolder] = useState(defaultFolder);

  const graphqlClient = useApolloClient();

  const [form] = Form.useForm();
  const uploaderRef = useRef(null);

  useEffect(() => {
    if (FREE_SAAS && !folder && folders.length > 0) {
      setFolder(folders[0].id);
      form.setFieldsValue({ folder: folders[0].id });
    }
  }, [folders, folder, form]);

  const folderOptions = useMemo(
    _ => {
      return folders
        ? folders
            .filter(
              folder => folder.permission === FILES_PERMISSION_WRITE.value
            )
            .map(folder => {
              return {
                value: folder.name,
              };
            })
            .sort((folder1, folder2) => {
              if (folder1.value.toLowerCase() > folder2.value.toLowerCase())
                return 1;
              if (folder1.value.toLowerCase() < folder2.value.toLowerCase())
                return -1;
              return 0;
            })
        : [];
    },
    [folders]
  );

  const onFinish = async values => {
    const { folder } = values;
    try {
      setIsUploading(true);
      setFolder(folder);
      const processedFiles = await uploaderRef.current.processFiles();
      setFiles(processedFiles);
    } catch (err) {
      displayError(err?.error?.body);
    } finally {
      setIsUploading(false);
      setFolder(null);
    }
  };

  const upload = useCallback(
    _ => {
      const filenames = files.map(
        (file, idx) => form.getFieldValue(`file_${idx}`) || file.filename
      );
      const existing = files.filter(file => {
        const currentFolder = folders.find(item => item.name === folder);
        if (currentFolder) {
          return currentFolder.files.find(file =>
            filenames.includes(file.name)
          );
        }
        return false;
      });
      if (existing.length > 0) {
        confirm({
          title: 'Duplicate Filename(s) Detected',
          icon: <ExclamationCircleOutlined />,
          content: `The following filename(s) in '${folder}' are already in use: ${existing
            .map(file => `'${file.filename}'`)
            .join(
              ', '
            )}. If you continue with the upload, those files will be overwritten and the previous data will be lost. Do you want to continue?`,
          okText: 'Yes',
          onOk() {
            form.submit();
          },
          cancelText: 'No',
          centered: true,
        });
      } else {
        form.submit();
      }
    },
    [folder, folders, files, form]
  );

  const handleInit = e => {
    uploaderRef.current.browse();
  };

  const handleFilesUpdate = fileItems => {
    setFiles(fileItems);
  };

  const handleProcess = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options
  ) => {
    // Create a FileHandler
    const apiUrl = `${APP_URL_API}/proxy/dbapi`;
    const gpudb = new GPUdb(apiUrl, {
      timeout: 0,
    });
    const fileHandler = new GPUdb.FileHandler(gpudb);
    fileHandler.chunkSize = KIFS_UPLOAD_CHUNK_SIZE;

    const filename = files.map(
      (file, idx) => form.getFieldValue(`file_${idx}`) || file.filename
    )[files.findIndex(thisFile => thisFile.filename === file.name)];

    const resp = await graphqlClient.query({
      query: GET_FOLDER_BY_NAME,
      variables: {
        name: folder.split('/')[0],
      },
    });

    // Create folder if missing
    if (!resp?.data?.folder) {
      await createFolder({
        variables: {
          name: folder.split('/')[0],
        },
      });
    }

    Object.defineProperty(file, 'name', {
      writable: true,
      value: filename,
    });

    fileHandler.upload(
      file,
      folder,
      { file_encoding: 'base64' },
      status => {
        progress(true, status || 0, 100);
      },
      (err3, resp3) => {
        if (err3) {
          error(err3.message);
        } else {
          load(resp3);
          refetchFolders();
        }
      }
    );

    return {
      abort: () => {
        abort();
      },
    };
  };

  const hasIncomplete = useMemo(
    _ => {
      return files.some(file => {
        return file.status !== FILE_STATUSES.PROCESSING_COMPLETE;
      });
    },
    [files]
  );

  return (
    <Modal
      title="Upload File"
      visible={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Close
        </Button>,
        hasIncomplete && (
          <Button
            key="upload"
            type="primary"
            onClick={upload}
            loading={isUploading}
          >
            Upload
          </Button>
        ),
      ]}
      onCancel={close}
      maskClosable={false}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="file"
        layout="vertical"
        initialValues={{ folder }}
        onFinish={onFinish}
        colon={false}
        preserve={false}
      >
        <Form.Item
          label="Folder"
          name="folder"
          rules={[
            {
              required: true,
              message: 'Please select a folder!',
            },
          ]}
        >
          <AutoComplete
            options={folderOptions}
            placeholder="New or existing folder name"
            notFoundContent="No folders found"
            disabled={FREE_SAAS}
          />
        </Form.Item>
        <FilePond
          ref={uploaderRef}
          files={files}
          instantUpload={false}
          oninit={handleInit}
          onupdatefiles={handleFilesUpdate}
          allowMultiple={true}
          allowProcess={false}
          allowRemove={true}
          allowRevert={false}
          itemInsertLocation={'after'}
          chunkForce={true}
          chunkUploads={true}
          chunkSize={5000000}
          name="files"
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          credits={false}
          // server="/api/upload"
          server={{
            process: handleProcess,
          }}
        />
        {files.length > 0 &&
          files.some(
            file => file.status !== FILE_STATUSES.PROCESSING_COMPLETE
          ) && (
            <div style={{ padding: '0px 20px' }}>
              {files.map((file, idx) => {
                return (
                  <Form.Item
                    key={idx}
                    label={file.filename}
                    name={`file_${idx}`}
                    noStyle={file.status === FILE_STATUSES.PROCESSING_COMPLETE}
                  >
                    <Input
                      title={file.status}
                      placeholder="File name override"
                      type={
                        file.status !== FILE_STATUSES.PROCESSING_COMPLETE
                          ? 'text'
                          : 'hidden'
                      }
                    />
                  </Form.Item>
                );
              })}
            </div>
          )}
      </Form>
    </Modal>
  );
};

export default FileUploadModal;
