// Imports
import React from 'react';
import { Dropdown, Menu } from 'antd';
import {
  ImportOutlined,
  DeleteOutlined,
  SettingOutlined,
  AuditOutlined,
  DiffOutlined,
} from '@ant-design/icons';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const DatasourceObject = ({
  nodeData,
  handleDatasourceEdit,
  handleDatasourceDelete,
  handleDatasourceImport,
  handleDatasourceHistory,
  handleMultiSelect,
}) => {
  const datasourceContextMenu = node => {
    const { additional_info } = node.source;
    return (
      <Menu>
        {additional_info && (
          <Menu.Item
            key="configure"
            onClick={_ => handleDatasourceEdit(node.source)}
          >
            <SettingOutlined /> Configure
          </Menu.Item>
        )}
        <Menu.Item
          key="import"
          onClick={_ => handleDatasourceImport(node.source)}
        >
          <ImportOutlined /> Import
        </Menu.Item>
        <Menu.Item
          key="history"
          onClick={_ => handleDatasourceHistory(node.source)}
        >
          <AuditOutlined /> History
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="delete"
          onClick={_ => handleDatasourceDelete(node.source)}
          style={{ color: '#C62828' }}
          disabled={!additional_info}
        >
          <DeleteOutlined /> Delete
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="multiselect"
          onClick={_ => handleMultiSelect(node)}
          style={{ color: '#3700b3' }}
        >
          <DiffOutlined /> Multi-Select
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          overlay={datasourceContextMenu(nodeData)}
          trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
        >
          <div>
            <ImportOutlined />{' '}
            <span
              style={{
                width: `${
                  EXPLORER_PANEL_WIDTH - 105 - (isMultiSelect ? 24 : 0)
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              {nodeData.title}
            </span>
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default DatasourceObject;
