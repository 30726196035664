// Imports
import React, { useCallback, useState } from 'react';
import { CloseOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

// App Imports

const ResultInfo = ({ clearResult, queryResponse }) => {
  const [showMessage, setShowMessage] = useState(true);

  const handleClear = useCallback(
    _ => {
      if (queryResponse?.response?.total_number_of_records >= 0) {
        setShowMessage(false);
      } else {
        clearResult();
      }
    },
    [queryResponse, clearResult]
  );

  const showWarnings = info => {
    const warnings = Object.keys(info)
      .filter(key => key.toLowerCase().includes('warning_'))
      .map(key => info[key]);
    return warnings.length > 0 ? (
      <pre
        className="console warning"
        style={{ marginTop: '10px', marginRight: '10px' }}
      >
        Warnings:
        <br />
        {warnings.map((warning, idx) => (
          <span key={idx}>
            {warning}
            <br />
          </span>
        ))}
      </pre>
    ) : null;
  };

  const handleAnimate = useCallback(
    _ => {
      if (queryResponse?.response?.info?.svg) {
        try {
          const regex = /svg width="(.*)" height="(.*)"/gim;
          const match = regex.exec(queryResponse?.response?.info?.svg);

          const width = match.length === 3 ? parseInt(match[1]) : 600;
          const height = match.length === 3 ? parseInt(match[2]) : 400;
          const left = (window.innerWidth - (width + 20)) / 2 + window.screenX;
          const top = (window.innerHeight - (height + 20)) / 2 + window.screenY;

          const blob = new Blob([queryResponse?.response?.info?.svg], {
            type: 'text/html',
          });
          const url = URL.createObjectURL(blob);
          const modal = window.open(
            url,
            'animate',
            `width=${width + 20},height=${
              height + 20
            },resizable=0,top=${top},left=${left}`
          );
          modal.onload = evt => URL.revokeObjectURL(url);
          modal.focus();
          return false;
        } catch (error) {
          console.error('Error trying to parse and display SVG', error);
        }
      }
    },
    [queryResponse]
  );

  return showMessage ? (
    <pre
      className="console success"
      style={{
        margin: '10px 0px 0px',
        borderRadius: '5px',
        padding: '10px 5px 10px 15px',
        color: '#7BA47E',
        fontWeight: 200,
        lineHeight: '18px',
      }}
    >
      <Button
        type="text"
        icon={<CloseOutlined />}
        onClick={handleClear}
        style={{
          float: 'right',
          height: '24px',
          width: '24px',
          lineHeight: '20px',
          top: '-3px',
          color: '#7BA47E',
        }}
      ></Button>
      {queryResponse?.responses.length > 1 ? (
        queryResponse?.responses.map((response, index) => {
          return (
            <span key={index}>
              <span
                style={{
                  display: 'inline-block',
                  opacity:
                    index < queryResponse?.responses.length - 1 ? 0.5 : 1,
                }}
              >
                {index === queryResponse?.responses.length - 1
                  ? response?.total_number_of_records >= 0
                    ? `Number of Records: ${response?.total_number_of_records}, `
                    : `Count Affected: ${response?.count_affected}, `
                  : ''}
                Completed in {response?.request_time_secs} seconds
                {showWarnings(response?.info)}
              </span>
              <br />
            </span>
          );
        })
      ) : (
        <span>
          {queryResponse?.response?.total_number_of_records >= 0
            ? `Number of Records: ${queryResponse?.response?.total_number_of_records}`
            : `Count Affected: ${queryResponse?.response?.count_affected}`}
          , Completed in {queryResponse?.response?.request_time_secs} seconds
          {showWarnings(queryResponse?.response?.info)}
        </span>
      )}
      {queryResponse?.response?.info?.svg && (
        <Button
          icon={<PlayCircleOutlined />}
          size="small"
          onClick={handleAnimate}
          style={{ marginTop: '10px' }}
        >
          Animate
        </Button>
      )}
    </pre>
  ) : null;
};

export default ResultInfo;
