// Imports
import React from 'react';
import { Modal, Form, Input, Button, Col, Row } from 'antd';

// App Imports
// import GraphQLServices from '../../graphql/services';

const SnapshotConfigureModal = ({ visible, close, callback }) => {
  // const [isUpdating, setIsUpdating] = useState(false);

  const [form] = Form.useForm();

  const onFinish = values => {
    // const { retain, frequency } = values;
  };

  const update = _ => {
    form.submit();
  };

  return (
    <Modal
      title="Configure Snapshot"
      visible={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="update"
          type="primary"
          onClick={update}
          // loading={isUpdating}
        >
          Update
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="configure"
        layout="vertical"
        initialValues={{ retain: 30, frequency: 'weekly' }}
        onFinish={onFinish}
        colon={false}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Retain"
              name="retain"
              rules={[
                {
                  required: true,
                  message: 'Please input snapshots to retain!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Frequency"
              name="frequency"
              rules={[
                {
                  required: true,
                  message: 'Please input snapshot frequency!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SnapshotConfigureModal;
