// Imports
import React from 'react';
import { Modal, Button, Spin, Descriptions, Table } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';
import {
  RESOURCE_GROUP_MAX_ALIAS,
  RESOURCE_GROUP_MAX_VALUE,
} from '../../constants';
import { capitalizeSnakecase } from '../../formatter';

const formatBytes = (value, decimals = 2) => {
  const bytes = Number(value);
  if (isNaN(bytes)) {
    return value;
  }
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const checkUnlimited = value => {
  if (`${value}` === RESOURCE_GROUP_MAX_VALUE) {
    return capitalizeSnakecase(RESOURCE_GROUP_MAX_ALIAS);
  }
  return value;
};

const UserUsageModal = ({ visible, close }) => {
  const { data: { userMe = {} } = {}, loading } =
    GraphQLServices.Users.useGetUserMeKineticaUsage();

  return (
    <Modal
      title="Resource Usage"
      visible={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
      ]}
      onCancel={close}
      width={window.innerWidth - 200}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={loading}>
        <div style={{ minHeight: '400px' }}>
          <Descriptions size="small" column={1} bordered>
            {userMe?.kinetica_user?.resource_group &&
              Object.keys(userMe?.kinetica_user?.resource_group)
                .filter(
                  key =>
                    !key.includes('__') &&
                    !['name', 'alias', 'max_data', 'tier_attributes'].includes(
                      key
                    )
                )
                .map(key => {
                  if (
                    Array.isArray(userMe?.kinetica_user?.resource_group[key])
                  ) {
                    return userMe?.kinetica_user?.resource_group[key].map(
                      tier => {
                        return (
                          <Descriptions.Item
                            key={`${tier.name}`}
                            label={`${tier.name} Max Memory`}
                          >
                            {checkUnlimited(tier.max_memory)}
                          </Descriptions.Item>
                        );
                      }
                    );
                  } else if (
                    typeof userMe?.kinetica_user?.resource_group[key] ===
                    'object'
                  ) {
                    const { ranks } =
                      userMe?.kinetica_user?.resource_group[key];

                    const metrics = Object.keys(ranks)
                      .reduce((acc, rank) => {
                        const columns = Object.keys(ranks[rank]).filter(
                          column => column !== 'thread_running_count'
                        );
                        return [...new Set([...acc, ...columns])];
                      }, [])
                      .sort(function (a, b) {
                        return a
                          .replaceAll('.used', '')
                          .toLowerCase()
                          .localeCompare(
                            b.replaceAll('.used', '').toLowerCase()
                          );
                      });

                    const columns = [
                      {
                        title: 'Rank',
                        dataIndex: 'rank',
                        key: 'rank',
                        render: text => (
                          <div style={{ textAlign: 'center' }}>{text}</div>
                        ),
                      },
                      ...metrics.map(column => {
                        let max = RESOURCE_GROUP_MAX_VALUE;
                        if (column.toLowerCase() === 'data') {
                          max = userMe?.kinetica_user?.resource_group.max_data;
                        } else if (column.includes('.')) {
                          const { tier_attributes } =
                            userMe?.kinetica_user?.resource_group;
                          const tier = column.split('.')[0];
                          const match = tier_attributes.find(attr => {
                            return attr.name === tier;
                          });
                          if (match) {
                            max = match['max_memory'];
                          }
                        }
                        const title = column.includes('.')
                          ? column.substring(0, column.lastIndexOf('.'))
                          : column;
                        return {
                          title: (
                            <span style={{ float: 'right' }}>
                              {title.includes('.')
                                ? capitalizeSnakecase(title)
                                    .split('.')
                                    .map(str => {
                                      return (
                                        <span key={str}>
                                          {str}
                                          <br />
                                        </span>
                                      );
                                    })
                                : capitalizeSnakecase(title)}
                            </span>
                          ),
                          dataIndex: column,
                          key: column,
                          render: text => {
                            return text !== undefined ? (
                              <span
                                style={{ float: 'right', textAlign: 'right' }}
                              >
                                {formatBytes(text)}
                                <br />
                                <span
                                  style={{
                                    fontSize: '13px',
                                    color: '#999999',
                                  }}
                                >
                                  {formatBytes(checkUnlimited(max))}
                                </span>
                              </span>
                            ) : (
                              ''
                            );
                          },
                        };
                      }),
                      {
                        title: (
                          <span style={{ float: 'right' }}>
                            Running
                            <br />
                            Threads
                          </span>
                        ),
                        dataIndex: 'thread_running_count',
                        key: 'thread_running_count',
                        render: text => (
                          <span style={{ float: 'right' }}>{text}</span>
                        ),
                      },
                    ];
                    const data = Object.keys(ranks).map(rank => {
                      return {
                        rank,
                        ...ranks[rank],
                      };
                    });
                    return (
                      <Descriptions.Item
                        key={key}
                        label={
                          key !== 'usage' ? (
                            capitalizeSnakecase(key)
                          ) : (
                            <span>
                              Usage
                              <br />
                              <span
                                style={{
                                  fontSize: '13px',
                                  color: '#999999',
                                }}
                              >
                                Max
                              </span>
                            </span>
                          )
                        }
                      >
                        <Table
                          columns={columns}
                          dataSource={data}
                          pagination={{ position: ['none', 'none'] }}
                          rowKey={'rank'}
                          size="small"
                        />
                      </Descriptions.Item>
                    );
                  }
                  return (
                    <Descriptions.Item
                      key={key}
                      label={capitalizeSnakecase(key)}
                    >
                      {checkUnlimited(
                        userMe?.kinetica_user?.resource_group[key]
                      )}
                    </Descriptions.Item>
                  );
                })}
          </Descriptions>
        </div>
      </Spin>
    </Modal>
  );
};

export default UserUsageModal;
