import KifsUploadImage from './images/import/kifs_upload.svg';
import KiFSImage from './images/import/kifs.svg';
import AzureFilesImage from './images/import/azure_files.svg';
import S3Image from './images/import/s3.svg';
import HDFSImage from './images/import/hdfs.svg';
import KafkaImage from './images/import/kafka.svg';
import JDBCImage from './images/import/java_jdbc.svg';

export const NAV_ROUTE_HOME = '/home';
export const NAV_ROUTE_DATAEXPLORE = '/dataexplore';
export const NAV_ROUTE_DATAEXPLORE_WORKBOOK = '/dataexplore/workbook';
export const NAV_ROUTE_IMPORTEXPORT = '/importexport';
export const NAV_ROUTE_CREATE_EXTERNAL_TABLE = '/createexternaltable';
export const NAV_ROUTE_JOBS = '/jobs';
export const NAV_ROUTE_WAREHOUSE = '/warehouse';
export const NAV_ROUTE_USERS = '/users';
export const NAV_ROUTE_USER = '/user';
export const NAV_ROUTE_MAPPING = {
  '/': NAV_ROUTE_HOME,
  '/home': NAV_ROUTE_HOME,
  '/dataexplore': NAV_ROUTE_DATAEXPLORE,
  '/dataexplore/workbook': NAV_ROUTE_DATAEXPLORE,
  '/importexport': NAV_ROUTE_IMPORTEXPORT,
  '/createexternaltable': NAV_ROUTE_CREATE_EXTERNAL_TABLE,
  '/jobs': NAV_ROUTE_JOBS,
  '/warehouse': NAV_ROUTE_WAREHOUSE,
  '/users': NAV_ROUTE_USERS,
  '/preferences': NAV_ROUTE_USER,
};

export const SQL_BLOCK_TYPE = '84b9233c-14e3-11eb-adc1-0242ac120002';
export const TEXT_BLOCK_TYPE = 'a9949a1a-14e3-11eb-adc1-0242ac120002';
export const MAP_BLOCK_TYPE = 'e8ccc4e2-793f-47ed-9af2-f0a58a840006';
export const IMAGE_BLOCK_TYPE = '016d9471-1913-4c85-894a-101e82909fd3';
export const HTML_BLOCK_TYPE = 'd7d34b22-2aa2-484f-9e8e-106f62612caf';
export const BLOCK_TYPES = {
  SQL: SQL_BLOCK_TYPE,
  TEXT: TEXT_BLOCK_TYPE,
  MAP: MAP_BLOCK_TYPE,
  IMAGE: IMAGE_BLOCK_TYPE,
  HTML: HTML_BLOCK_TYPE,
};

export const CODE_SLATE_TYPE = 'code';
export const PARAGRAPH_SLATE_TYPE = 'paragraph';
export const MAP_SLATE_TYPE = 'map';
export const IMAGE_SLATE_TYPE = 'image';
export const HTML_SLATE_TYPE = 'html';
export const SLATE_TYPES = {
  CODE: CODE_SLATE_TYPE,
  PARAGRAPH: PARAGRAPH_SLATE_TYPE,
  MAP: MAP_SLATE_TYPE,
  IMAGE: IMAGE_SLATE_TYPE,
  HTML: HTML_SLATE_TYPE,
};

export const MAP_VIZ_TYPE = '4728179b-cd50-4d44-8d70-9350d39fd07b';
export const IMAGE_VIZ_TYPE = '61ec39b0-3295-4354-b92c-831fec4dacb3';
export const HTML_VIZ_TYPE = '757cd7cb-6f08-4416-9e9a-291cd651246f';
export const BAR_CHART_VIZ_TYPE = '561a39ca-80d4-4c53-9133-62d2c0e82405';
export const LINE_CHART_VIZ_TYPE = 'b565ab86-0964-4e75-aed9-a11ba79fae43';
export const PIE_CHART_VIZ_TYPE = 'f2e713c1-32b2-46ef-86a8-f547a6e33f52';
export const SCATTER_PLOT_CHART_VIZ_TYPE =
  'f5ce2cd6-96ff-4119-8120-7f104696d2c4';
export const VIZ_TYPES = {
  MAP: MAP_VIZ_TYPE,
  IMAGE: IMAGE_VIZ_TYPE,
  HTML: HTML_VIZ_TYPE,
  BAR_CHART: BAR_CHART_VIZ_TYPE,
  LINE_CHART: LINE_CHART_VIZ_TYPE,
  PIE_CHART: PIE_CHART_VIZ_TYPE,
  SCATTER_PLOT_CHART: SCATTER_PLOT_CHART_VIZ_TYPE,
};

export const NULLABLE_TYPE = 'nullable';
export const NUMERIC_TYPES = [
  'int',
  'long',
  'float',
  'double',
  'decimal',
  'ulong',
];

export const EXPLORER_PANEL_WIDTH = 350;
export const MIN_WMS_PREVIEW_MODAL_WIDTH = 900;
export const MAX_WMS_PREVIEW_MODAL_WIDTH_RATIO = 4 / 3;

export const USER_INTERNAL_TYPE = 'internal_user';
export const USER_EXTERNAL_TYPE = 'external_user';
export const USER_TYPES = [USER_INTERNAL_TYPE, USER_EXTERNAL_TYPE];
export const ROLE_TYPES = ['role'];

export const NIVO_COLOR_SCHEMES = [
  'nivo',
  'category10',
  'accent',
  'dark2',
  'paired',
  'pastel1',
  'pastel2',
  'set1',
  'set2',
  'set3',
  'brown_blueGreen',
  'purpleRed_green',
  'pink_yellowGreen',
  'purple_orange',
  'red_blue',
  'red_grey',
  'red_yellow_blue',
  'red_yellow_green',
  'spectral',
  'blues',
  'greens',
  'greys',
  'oranges',
  'purples',
  'reds',
  'blue_green',
  'blue_purple',
  'green_blue',
  'orange_red',
  'purple_blue_green',
  'purple_blue',
  'purple_red',
  'red_purple',
  'yellow_green_blue',
  'yellow_green',
  'yellow_orange_brown',
  'yellow_orange_red',
];

export const CREDENTIAL_TYPE_DOCKER = 'docker';
export const CREDENTIAL_TYPES = [
  { label: 'Docker', value: CREDENTIAL_TYPE_DOCKER },
];

export const DATASOURCE_LOCATION_AZURE = 'azure';
export const DATASOURCE_LOCATION_S3 = 's3';
export const DATASOURCE_LOCATION_KAFKA = 'kafka';
export const DATASOURCE_LOCATION_HDFS = 'hdfs';
export const DATASOURCE_LOCATION_JDBC = 'jdbc';
export const DATASOURCE_LOCATIONS = [
  { label: 'Azure', value: DATASOURCE_LOCATION_AZURE },
  { label: 'S3', value: DATASOURCE_LOCATION_S3 },
  { label: 'Kafka', value: DATASOURCE_LOCATION_KAFKA },
  { label: 'HDFS', value: DATASOURCE_LOCATION_HDFS },
  { label: 'JDBC', value: DATASOURCE_LOCATION_JDBC },
];

export const DATASOURCE_JDBC_JAR_PATH_MANUAL = 'manual';
export const DATASOURCE_JDBC_JAR_PATH_KIFS = 'kifs';
export const DATASOURCE_JDBC_JAR_PATH_TYPES = [
  { label: 'Manual', value: DATASOURCE_JDBC_JAR_PATH_MANUAL },
  { label: 'KIFS', value: DATASOURCE_JDBC_JAR_PATH_KIFS },
];

export const DATASOURCE_JDBC_AUTH_PASSWORD = 'access_key';
export const DATASOURCE_JDBC_AUTH_TYPES = [
  { label: 'Password', value: DATASOURCE_JDBC_AUTH_PASSWORD },
];

export const DATASOURCE_S3_AUTH_NO_AUTH = 'no_auth';
export const DATASOURCE_S3_AUTH_ACCESS_KEY = 'access_key';
export const DATASOURCE_S3_AUTH_TYPES = [
  { label: 'No Auth', value: DATASOURCE_S3_AUTH_NO_AUTH },
  { label: 'Access Key', value: DATASOURCE_S3_AUTH_ACCESS_KEY },
];

export const DATASOURCE_AZURE_AUTH_NO_AUTH = 'no_auth';
export const DATASOURCE_AZURE_AUTH_PASSWORD = 'password';
export const DATASOURCE_AZURE_AUTH_SAS_TOKEN = 'sas_token';
export const DATASOURCE_AZURE_AUTH_OAUTH_TOKEN = 'oauth_token';
export const DATASOURCE_AZURE_AUTH_ACTIVE_DIRECTORY = 'active_directory';
export const DATASOURCE_AZURE_AUTH_TYPES = [
  { label: 'No Auth', value: DATASOURCE_AZURE_AUTH_NO_AUTH },
  { label: 'Password', value: DATASOURCE_AZURE_AUTH_PASSWORD },
  { label: 'SAS Token', value: DATASOURCE_AZURE_AUTH_SAS_TOKEN },
  { label: 'OAuth Token', value: DATASOURCE_AZURE_AUTH_OAUTH_TOKEN },
  { label: 'Active Directory', value: DATASOURCE_AZURE_AUTH_ACTIVE_DIRECTORY },
];

export const DATASOURCE_KAFKA_AUTH_NO_AUTH = 'no_auth';
export const DATASOURCE_KAFKA_AUTH_SASL_SSL = 'sasl_ssl';
export const DATASOURCE_KAFKA_AUTH_TYPES = [
  { label: 'No Auth', value: DATASOURCE_KAFKA_AUTH_NO_AUTH },
  { label: 'SASL SSL', value: DATASOURCE_KAFKA_AUTH_SASL_SSL },
];

export const DATASOURCE_HDFS_AUTH_PASSWORD = 'password';
export const DATASOURCE_HDFS_AUTH_KERBEROS_TOKEN = 'kerberos_token';
export const DATASOURCE_HDFS_AUTH_KERBEROS_KEYTAB = 'kerberos_keytab';
export const DATASOURCE_HDFS_AUTH_TYPES = [
  { label: 'Password', value: DATASOURCE_HDFS_AUTH_PASSWORD },
  { label: 'Kerberos Token', value: DATASOURCE_HDFS_AUTH_KERBEROS_TOKEN },
  { label: 'Kerberos Keytab', value: DATASOURCE_HDFS_AUTH_KERBEROS_KEYTAB },
];
export const DATASOURCE_HDFS_PREFIX = 'hdfs://';
export const DATASOURCE_KAFKA_PREFIX = 'kafka://';
export const DATASOURCE_DEFAULT_AUTH_TYPE = 'password';

export const DATASINK_LOCATION_HTTP = 'http';
export const DATASINK_LOCATION_HTTPS = 'https';
export const DATASINK_LOCATION_KAFKA = 'kafka';
export const DATASINK_LOCATION_JDBC = 'jdbc';
export const DATASINK_LOCATIONS = [
  { label: 'HTTP', value: DATASINK_LOCATION_HTTP },
  { label: 'HTTPS', value: DATASINK_LOCATION_HTTPS },
  { label: 'Kafka', value: DATASINK_LOCATION_KAFKA },
  { label: 'JDBC', value: DATASINK_LOCATION_JDBC },
];

export const DATASINK_JDBC_AUTH_PASSWORD = 'password';
export const DATASINK_JDBC_AUTH_TYPES = [
  { label: 'Password', value: DATASINK_JDBC_AUTH_PASSWORD },
];

export const DATASINK_KAFKA_AUTH_NO_AUTH = 'no_auth';
export const DATASINK_KAFKA_AUTH_SASL_SSL = 'sasl_ssl';
export const DATASINK_KAFKA_AUTH_TYPES = [
  { label: 'No Auth', value: DATASINK_KAFKA_AUTH_NO_AUTH },
  { label: 'SASL SSL', value: DATASINK_KAFKA_AUTH_SASL_SSL },
];

export const DATASINK_HTTP_PREFIX = 'http://';
export const DATASINK_HTTPS_PREFIX = 'https://';
export const DATASINK_KAFKA_PREFIX = 'kafka://';
export const DATASINK_DEFAULT_AUTH_TYPE = 'no_auth';

export const ImportSource = (
  route,
  title,
  description,
  imgSrc,
  categories,
  docs
) => ({
  route: `${NAV_ROUTE_IMPORTEXPORT}/${route}`,
  title,
  description,
  img: {
    src: imgSrc,
    alt: title,
  },
  categories,
  docs,
});

export const IMPORT_CATALOG = [
  ImportSource(
    'upload',
    'File Upload',
    'Upload a file to KiFS',
    KifsUploadImage,
    ['File & API']
  ),
  ImportSource('kifs', 'KiFS', 'A file that exists on KiFS', KiFSImage, [
    'File & API',
  ]),
  ImportSource(
    'azure',
    'Azure Blob Storage',
    'A file on Azure Blob Storage',
    AzureFilesImage,
    ['File & API']
  ),
  ImportSource('s3', 'Amazon S3', 'A file on Amazon S3', S3Image, [
    'File & API',
  ]),
  ImportSource('kafka', 'Kafka Stream', 'A Kafka streaming feed', KafkaImage, [
    'Big Data & NoSQL',
  ]),
  ImportSource('hdfs', 'HDFS', 'A file on HDFS', HDFSImage, ['File & API']),
  ImportSource('jdbc', 'Generic JDBC', 'A JDBC data source', JDBCImage, [
    'All',
  ]),
];

// console.log({IMPORT_CATALOG})

// https://stackoverflow.com/questions/42118296/dynamically-import-images-from-a-directory-using-webpack
// const importAll = require => (
//   require.keys().reduce((acc, next) => {
//     acc[next.replace("./", "")] = require(next);
//     return acc;
//   }, {})
// );

// const normalizeCdataRoute = (driverName) => driverName.toLowerCase().replace(/\s+/g, '-').replace('.', '-');

// export const CDATA_IMAGES = importAll(
//   require.context("./images/cdata", false, /\.(png|jpe?g|svg)$/)
// );

// export const CDATA_CATALOG = CDATA_JSON.map(({
//   categories,
//   driverDocUrl,
//   driverImg,
//   driverName,
//   jdbcClassName,
//   jdbcDocUrl,
//   properties,
//   vendor_name
// }) => ImportSource(normalizeCdataRoute(driverName), driverName, 'CData connector', require(`./images/cdata/${driverImg}`).default, categories, jdbcDocUrl));
// export const CDATA_ROUTES = CDATA_JSON.map(({driverName}) => normalizeCdataRoute(driverName));
// console.log({CDATA_ROUTES});

export const MAP_RENDER_TYPE_HEATMAP = 'heatmap';
export const MAP_RENDER_TYPE_RASTER = 'raster';
export const MAP_RENDER_TYPE_CLASSBREAK = 'cb_raster';
export const MAP_RENDER_TYPE_TRACK = 'track';
export const MAP_RENDER_TYPE_CONTOUR = 'contour';
export const MAP_RENDER_TYPES = [
  {
    label: 'Heatmap',
    value: MAP_RENDER_TYPE_HEATMAP,
    render: MAP_RENDER_TYPE_HEATMAP,
  },
  {
    label: 'Raster',
    value: MAP_RENDER_TYPE_RASTER,
    render: MAP_RENDER_TYPE_RASTER,
  },
  {
    label: 'Class Break',
    value: MAP_RENDER_TYPE_CLASSBREAK,
    render: MAP_RENDER_TYPE_CLASSBREAK,
  },
  {
    label: 'Track',
    value: MAP_RENDER_TYPE_TRACK,
    render: MAP_RENDER_TYPE_RASTER,
  },
  {
    label: 'Contour',
    value: MAP_RENDER_TYPE_CONTOUR,
    render: MAP_RENDER_TYPE_CONTOUR,
  },
];

export const CB_TYPE_UNIQUE_VALUES = 'unique_values';
export const CB_TYPE_MANUAL_INTERVAL = 'manual_interval';
export const CB_TYPE_EQUAL_INTERVAL = 'equal_interval';
export const CB_TYPE_DEFINED_INTERVAL = 'defined_interval';
export const CB_TYPE_QUANTILE = 'quantile';
export const CB_TYPE_STANDARD_DEVIATION = 'standard_deviation';
export const CB_TYPES = [
  { label: 'Unique Values', value: CB_TYPE_UNIQUE_VALUES },
  { label: 'Manual Interval', value: CB_TYPE_MANUAL_INTERVAL },
  { label: 'Equal Interval', value: CB_TYPE_EQUAL_INTERVAL },
  { label: 'Defined Interval', value: CB_TYPE_DEFINED_INTERVAL },
  { label: 'Quantile', value: CB_TYPE_QUANTILE },
  { label: 'Standard Deviation', value: CB_TYPE_STANDARD_DEVIATION },
];

export const SHAPES = [
  'none',
  'circle',
  'square',
  'diamond',
  'hollowcircle',
  'hollowsquare',
  'hollowdiamond',
  'oriented_arrow',
  'oriented_triangle',
];

export const CB_NUM_CLASSES = [
  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
];
export const CB_STANDARD_DEVIATIONS = [1, 0.5];
export const CB_ROUND_CLASSES = [0.1, 1, 10, 100, 1000];

export const CB_COLORMAPS = [
  'jet',
  'hsv',
  'hot',
  'cool',
  'spring',
  'summer',
  'autumn',
  'winter',
  'bone',
  'copper',
  'greys',
  'YIGnBu',
  'greens',
  'YIOrRd',
  'bluered',
  'RdBu',
  'picnic',
  'rainbow',
  'portland',
  'blackbody',
  'earth',
  'electric',
  'viridis',
  'inferno',
  'magma',
  'plasma',
  'warm',
  'rainbow-soft',
  'bathymetry',
  'cdom',
  'chlorophyll',
  'density',
  'freesurface-blue',
  'freesurface-red',
  'oxygen',
  'par',
  'phase',
  'salinity',
  'temperature',
  'turbidity',
  'velocity-blue',
  'velocity-green',
  'cubehelix',
].sort(function (a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
});

export const GEOMETRY_TYPE_LONLAT = 'lon_lat';
export const GEOMETRY_TYPE_WKT = 'wkt';
export const GEOMETRY_TYPES = [
  { label: 'Lon/Lat', value: GEOMETRY_TYPE_LONLAT },
  { label: 'WKT', value: GEOMETRY_TYPE_WKT },
];

export const MAP_COLORMAPS = [
  'viridis',
  'inferno',
  'plasma',
  'magma',
  'Blues',
  'BuGn',
  'BuPu',
  'GnBu',
  'Greens',
  'Greys',
  'Oranges',
  'OrRd',
  'PuBu',
  'PuBuGn',
  'PuRd',
  'Purples',
  'RdPu',
  'Reds',
  'YlGn',
  'YlGnBu',
  'YlOrBr',
  'YlOrRd',
  'afmhot',
  'autumn',
  'bone',
  'cool',
  'copper',
  'gist_heat',
  'gray',
  'gist_gray',
  'gist_yarg',
  'binary',
  'hot',
  'pink',
  'spring',
  'summer',
  'winter',
  'BrBG',
  'bwr',
  'coolwarm',
  'PiYG',
  'PRGn',
  'PuOr',
  'RdBu',
  'RdGy',
  'RdYlBu',
  'RdYlGn',
  'Spectral',
  'seismic',
  'Accent',
  'Dark2',
  'Paired',
  'Pastel1',
  'Pastel2',
  'Set1',
  'Set2',
  'Set3',
  'gist_earth',
  'terrain',
  'ocean',
  'gist_stern',
  'brg',
  'CMRmap',
  'cubehelix',
  'gnuplot',
  'gnuplot2',
  'gist_ncar',
  'spectral',
  'nipy_spectral',
  'jet',
  'rainbow',
  'gist_rainbow',
  'hsv',
  'flag',
  'prism',
].sort(function (a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
});

export const DEFAULT_COLORMAP = 'jet';
export const DEFAULT_FILL_COLOR = 'ca2c92';
export const DEFAULT_BORDER_COLOR = '3700b3';
export const DEFAULT_BLUR_RADIUS = '2';
export const DEFAULT_POINT_SIZE = '2';
export const DEFAULT_WIDTH = '2';
export const DEFAULT_OPACITY = 90;

export const WMS_DEFAULT_PARAMS = {
  SERVICE: 'WMS',
  VERSION: '1.3.0',
  REQUEST: 'GetMap',
  FORMAT: 'image/png',
  TRANSPARENT: 'true',
  DOPOINTS: 'true',
  DOSHAPES: 'true',
  DOSYMBOLOGY: 'false',
  DOTRACKS: 'false',
  BLUR_RADIUS: DEFAULT_BLUR_RADIUS,
  POINTSIZES: DEFAULT_POINT_SIZE,
  POINTSHAPES: 'circle',
  SRS: 'EPSG:3857',
  CRS: 'EPSG:3857',
  USE_POINT_RENDERER: 'true',
  COLORMAP: DEFAULT_COLORMAP,
  POINTCOLORS: DEFAULT_FILL_COLOR,
  SHAPELINECOLORS: DEFAULT_BORDER_COLOR,
  SHAPEFILLCOLORS: DEFAULT_FILL_COLOR,
  SHAPELINEWIDTHS: DEFAULT_WIDTH,
  TRACKHEADCOLORS: DEFAULT_FILL_COLOR,
  TRACKHEADSIZES: '1',
  TRACKMARKERCOLORS: DEFAULT_FILL_COLOR,
  TRACKLINECOLORS: DEFAULT_BORDER_COLOR,
  TRACKLINEWIDTHS: DEFAULT_WIDTH,
  ANTIALIASING: 'true',
  BBOX: '-100000,-100000,100000,100000',
  HEIGHT: 200,
  WIDTH: 200,
};

export const SYSTEM_ROLES = ['authenticated', 'public'];

export const SYSTEM_PERMISSION_SYSTEM_ADMIN = 'system_admin';
export const SYSTEM_PERMISSION_SYSTEM_USER_ADMIN = 'system_user_admin';
export const SYSTEM_PERMISSION_SYSTEM_WRITE = 'system_write';
export const SYSTEM_PERMISSION_SYSTEM_READ = 'system_read';
export const SYSTEM_PERMISSIONS = [
  {
    label: 'Admin',
    value: SYSTEM_PERMISSION_SYSTEM_ADMIN,
    description: 'Full access to all data and system functions.',
  },
  {
    label: 'User Admin',
    value: SYSTEM_PERMISSION_SYSTEM_USER_ADMIN,
    description:
      'Access to administer users and roles that do not have system_admin permission.',
  },
  {
    label: 'Write',
    value: SYSTEM_PERMISSION_SYSTEM_WRITE,
    description: 'Read and write access to all tables.',
  },
  {
    label: 'Read',
    value: SYSTEM_PERMISSION_SYSTEM_READ,
    description: 'Read-only access to all tables.',
  },
];

export const PROC_PERMISSION_EXECUTE = 'proc_execute';
export const PROC_PERMISSIONS = [
  {
    label: 'Allow Execute for All Procs',
    value: PROC_PERMISSION_EXECUTE,
    description: '',
  },
];

export const RESOURCE_GROUP_DEFAULT_ALIAS = '<DEFAULT>';
export const RESOURCE_GROUP_DEFAULT_NAME = 'kinetica_default_resource_group';
export const RESOURCE_GROUP_SYSTEM_ALIAS = '<SYSTEM>';
export const RESOURCE_GROUP_SYSTEM_NAME = 'kinetica_system_resource_group';
export const RESOURCE_NATIVE_GROUPS = {
  [RESOURCE_GROUP_DEFAULT_NAME]: RESOURCE_GROUP_DEFAULT_ALIAS,
  [RESOURCE_GROUP_SYSTEM_NAME]: RESOURCE_GROUP_SYSTEM_ALIAS,
};
export const RESOURCE_GROUP_MAX_ALIAS = 'unlimited';
export const RESOURCE_GROUP_MAX_VALUE = '9223372036854775807';
export const RESOURCE_GROUP_TIER_DELIMITER = '.';

export const DEFAULT_SCHEMA = 'ki_home';

export const DATASOURCE_PERMISSION_ADMIN = {
  label: 'Admin',
  value: 'datasource_admin',
  content: ['full read/write and administrative access'],
};
export const DATASOURCE_PERMISSION_CONNECT = {
  label: 'Connect',
  value: 'datasource_connect',
  content: ['connect access on the given data source'],
};
export const DATASOURCE_PERMISSIONS = [
  // DATASOURCE_PERMISSION_ADMIN,
  DATASOURCE_PERMISSION_CONNECT,
];

export const DATASINK_PERMISSION_ADMIN = {
  label: 'Admin',
  value: 'datasink_admin',
  content: ['full read/write and administrative access'],
};
export const DATASINK_PERMISSION_CONNECT = {
  label: 'Connect',
  value: 'datasink_connect',
  content: ['connect access on the given data sink'],
};
export const DATASINK_PERMISSIONS = [
  // DATASINK_PERMISSION_ADMIN,
  DATASINK_PERMISSION_CONNECT,
];

export const TABLE_PERMISSION_ADMIN = {
  label: 'Admin',
  value: 'table_admin',
  content: [
    'has all four table-level permissions',
    'can alter & drop the granted table',
    'when granted on a schema, can create tables under that schema, as well as employ table-level permissions on any table in the schema',
    'when granted at the root level, can employ all other table-level permissions on any table in the system',
  ],
};
export const TABLE_PERMISSION_READ = {
  label: 'Read',
  value: 'table_read',
  content: [
    'can read (select) records (both row-level security & column-level security can apply to this access type)',
  ],
};
export const TABLE_PERMISSION_INSERT = {
  label: 'Insert',
  value: 'table_insert',
  content: [
    'can insert records (upserting, via /insert/records using the update_on_existing_pk parameter, requires the table_update permission, as well)',
  ],
};
export const TABLE_PERMISSION_UPDATE = {
  label: 'Update',
  value: 'table_update',
  content: [
    'can update records (upserting, via /insert/records using the update_on_existing_pk parameter, requires the table_insert permission, as well)',
  ],
};
export const TABLE_PERMISSION_DELETE = {
  label: 'Delete',
  value: 'table_delete',
  content: ['can delete records'],
};
export const TABLE_PERMISSIONS = [
  TABLE_PERMISSION_ADMIN,
  TABLE_PERMISSION_READ,
  TABLE_PERMISSION_INSERT,
  TABLE_PERMISSION_UPDATE,
  TABLE_PERMISSION_DELETE,
];

export const FILES_PERMISSION_READ = {
  label: 'Read',
  value: 'directory_read',
  content: [
    'allows a user to list, download, and use files within the directory in Kinetica features that make use of KiFS',
  ],
};
export const FILES_PERMISSION_WRITE = {
  label: 'Write',
  value: 'directory_write',
  content: [
    'allows a user to upload and delete files within the directory, as well as the permissions granted by directory_read',
  ],
};
export const FILES_PERMISSIONS = [
  FILES_PERMISSION_READ,
  FILES_PERMISSION_WRITE,
];

export const API_SAMPLE_JAVASCRIPT = (
  url = 'http://127.0.0.1:9191'
) => `<script type="text/javascript" src="GPUdb.js"></script>
<script type="text/javascript">
var gpudb = new GPUdb(
    "${url}",
    {
        username: "myusername",
        password: "mysecret"
    }
);
</script>
`;

export const API_SAMPLE_NODEJS = (
  url = 'http://127.0.0.1:9191'
) => `// To install Kinetica, first run this
// from the CLI: npm i @kinetica/gpudb
var GPUdb = require("@kinetica/gpudb");
var gpudb = new GPUdb(
    ["${url}"],
    {
        username: "myusername",
        password: "mysecret"
    }
);`;

export const API_SAMPLE_PYTHON = (url = 'http://127.0.0.1:9191') => {
  const isIP = address => {
    const r = RegExp(
      '^http[s]?://((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])'
    );
    return r.test(address);
  };

  if (isIP(url)) {
    return `import gpudb

options = gpudb.GPUdb.Options()
options.username = "myusername"
options.password = "mysecret"

h_db = gpudb.GPUdb(
  host=["${url}"],
  options = options
)`;
  }
  return `import gpudb

options = gpudb.GPUdb.Options()
options.username = "myusername"
options.password = "mysecret"

h_db = gpudb.GPUdb(
  host=["${url}"],
  options = options
)`;
};

export const API_SAMPLE_CPP = (
  url = 'http://127.0.0.1:9191'
) => `#include "gpudb/GPUdb.hpp"

gpudb::GPUdb::Options opts = gpudb::GPUdb::Options();
opts.setUsername("myusername");
opts.setPassword("mysecret");

gpudb::GPUdb gpudb("${url}", opts);`;

export const API_SAMPLE_CS = (url = 'http://127.0.0.1:9191') => `using kinetica;

Kinetica.Options options = new Kinetica.Options();
options.Username = "myusername";
options.Password = "mysecret";

Kinetica kdb = new Kinetica("${url}", options);`;

export const API_SAMPLE_JAVA = (
  url = 'http://127.0.0.1:9191'
) => `import com.gpudb.GPUdb;

GPUdb.Options options = new GPUdb.Options()
  .setUsername("myusername")
  .setPassword("mysecret");

GPUdb gpudb = new GPUdb(
  "${url}", options
);`;

export const API_SAMPLE_JDBC = (url = 'http://127.0.0.1:9191') =>
  `# See your client documentation for configuring username/password
jdbc:kinetica:URL=${url};CombinePrepareAndExecute=1;`;

export const API_SAMPLE_JDBC_JAVA = (url = 'http://127.0.0.1:9191') => {
  const jdbcUrl = url.split('//').length > 1 ? url.split('//')[1] : url;
  return `Class<Driver> _class = com.kinetica.jdbc.Driver.class;

Properties props = new Properties();
props.put("UID", "myusername");
props.put("PWD", "mysecret");

Connection kdb = DriverManager.getConnection("jdbc:kinetica://${jdbcUrl}", props);`;
};

export const API_SAMPLE_REST = (url = 'http://127.0.0.1:9191') =>
  `curl --user myusername:mysecret --location --request POST '${url}' \\
  --header 'cache-control: no-cache' \\
  --header 'content-type: application/json'`;

export const API_SAMPLE_ZMQ = (
  triggerUrl = 'tcp://127.0.0.1:9001',
  monitorUrl = 'tcp://127.0.0.1:9002'
) =>
  `# Trigger
${triggerUrl}

# Table Monitor
${monitorUrl}`;

export const API_SAMPLE_POSTGRES = (proxyUrl = 'tcp://127.0.0.1:5432') => {
  const postgresUrl =
    proxyUrl.split('//').length > 1 ? proxyUrl.split('//')[1] : proxyUrl;
  return `# Proxy
# Note: Passwords must be URL encoded. See docs for more information.
postgres://myusername:mysecret@${postgresUrl}`;
};

export const EMBED_SAMPLE_URL = (url, context, workbookId) =>
  `${url}/${context}/dataexplore/embed/${workbookId}`;
export const EMBED_SAMPLE_HTML = (url, context, workbookId) => `<iframe
  src="${url}/${context}/dataexplore/embed/${workbookId}"
  width="800"
  height="600"
/>`;

// this looks stupid bc the values have changed a few times, it wasn't 1:1 the whole time
export const WAREHOUSE_TYPES = {
  SmallCPU: 'SmallCPU',
  LargeCPU: 'LargeCPU',
  GPU: 'SmallGPU',
};

const _WAREHOUSE_SIZES = {
  XS: 'Extra Small (XS)',
  S: 'Small (S)',
  M: 'Medium (M)',
  L: 'Large (L)',
  XL: 'Extra Large (XL)',
  XXL: 'Extra Extra Large (2XL)',
  XXXL: 'Extra Extra Extra Large (3XL)',
};

export const WAREHOUSE_SIZES = _WAREHOUSE_SIZES;

export const WAREHOUSE_SIZES_PAYG = {
  XS: _WAREHOUSE_SIZES.XS,
  S: _WAREHOUSE_SIZES.S,
  M: _WAREHOUSE_SIZES.M,
};

export const WAREHOUSE_SIZE_TYPES = Object.fromEntries(
  Object.entries(WAREHOUSE_SIZES).map(([key, val]) => [
    val,
    {
      tshirtSize: key,
      tshirtType: val === 'Extra Small (XS)' ? 'SmallCPU' : 'LargeCPU',
    },
  ])
);

export const AUTO_PAUSE_DURATIONS = [
  '5m',
  '10m',
  '15m',
  '20m',
  '30m',
  '45m',
  '1h',
  '3h',
  '6h',
  '9h',
  '12h',
  '15h',
  '18h',
  '21h',
  '24h',
];

export const RESIZING_PHASE = 'Resizing';

export const FILE_STATUSES = {
  INIT: 1,
  IDLE: 2,
  PROCESSING_QUEUED: 9,
  PROCESSING: 3,
  PROCESSING_COMPLETE: 5,
  PROCESSING_ERROR: 6,
  PROCESSING_REVERT_ERROR: 10,
  LOADING: 7,
  LOAD_ERROR: 8,
};

export const TEST_IDS = {
  LOGIN_USERNAME: 'login-username',
  LOGIN_PASSWORD: 'login-password',
  LOGIN_SUBMIT: 'login-submit',
  NAV_LOGO: 'nav-logo',
  NAV_HOME: 'nav-home',
  NAV_DATA_EXPLORATION: 'nav-data-exploration',
  NAV_IMPORT: 'nav-import',
  NAV_JOBS: 'nav-jobs',
  NAV_MANAGEMENT: 'nav-management',
  NAV_MANAGEMENT_WAREHOUSE: 'nav-management-warehouse',
  NAV_METRICS: 'nav-metrics',
  NAV_USER: 'nav-user',
  NAV_USER_PREFERENCES: 'nav-user-preferences',
  NAV_USER_LOGOUT: 'nav-user-logout',
  NAV_USER_PASSWORD: 'nav-user-password',
  NAV_USER_USAGE: 'nav-user-usage',
  TRY_EXAMPLE_WORKBOOK: 'try-example-workbook',
};

export const USERNAME_BLACKLIST = [
  'admin',
  'planner',
  'graph',
  'anonymous',
  'public',
  'authenticated',
];

export const K8S_USER_CR_CHECK_MAXWAIT = 60000;
export const K8S_USER_CR_CHECK_WAITINTERVAL = 5000;
export const K8S_ROLE_CR_CHECK_MAXWAIT = 60000;
export const K8S_ROLE_CR_CHECK_WAITINTERVAL = 5000;

export const VIZ_X_AXIS_DATA_LIMIT = 100;
export const VIZ_SCATTER_PLOT_DATA_LIMIT = 10000;

export const KINETICA_TYPES = [
  {
    value: 'int',
    label: 'Integer',
    children: [
      {
        value: '',
        label: 'Default',
      },
      {
        value: 'int8',
        label: 'Int8',
      },
      {
        value: 'int16',
        label: 'Int16',
      },
    ],
  },
  {
    value: 'float',
    label: 'Float',
  },
  {
    value: 'double',
    label: 'Double',
  },
  {
    value: 'long',
    label: 'Long',
    children: [
      {
        value: '',
        label: 'Default',
      },
      {
        value: 'timestamp',
        label: 'Timestamp',
      },
    ],
  },
  {
    value: 'string',
    label: 'String',
    children: [
      {
        value: '',
        label: 'Default',
      },
      {
        value: 'char1',
        label: 'Char1',
      },
      {
        value: 'char2',
        label: 'Char2',
      },
      {
        value: 'char4',
        label: 'Char4',
      },
      {
        value: 'char8',
        label: 'Char8',
      },
      {
        value: 'char16',
        label: 'Char16',
      },
      {
        value: 'char32',
        label: 'Char32',
      },
      {
        value: 'char64',
        label: 'Char64',
      },
      {
        value: 'char128',
        label: 'Char128',
      },
      {
        value: 'char256',
        label: 'Char256',
      },
      {
        value: 'ipv4',
        label: 'IPv4',
      },
      {
        value: 'date',
        label: 'Date',
      },
      {
        value: 'time',
        label: 'Time',
      },
      {
        value: 'datetime',
        label: 'Datetime',
      },
      {
        value: 'decimal',
        label: 'Decimal',
      },
      {
        value: 'ulong',
        label: 'ULong',
      },
      {
        value: 'uuid',
        label: 'UUID',
      },
      {
        value: 'wkt',
        label: 'WKT',
      },
    ],
  },
  {
    value: 'bytes',
    label: 'Bytes',
    children: [
      {
        value: '',
        label: 'Default',
      },
      {
        value: 'wkt',
        label: 'WKB',
      },
    ],
  },
];

export const KINETICA_PROPERTIES = [
  {
    label: 'Primary Key',
    value: 'primary_key',
  },
  {
    label: 'Shard Key',
    value: 'shard_key',
  },
  {
    label: 'Dictionary Encode',
    value: 'dict',
  },
  {
    label: 'Init with Now',
    value: 'init_with_now',
  },
  {
    label: 'Init with UUID',
    value: 'init_with_uuid',
  },
  {
    label: 'Text Search',
    value: 'text_search',
  },
];

export const TELEMETRY_EVENT_TYPES = {
  LOGGED_IN: 'Logged In',
  LOGGED_OUT: 'Logged Out',
  DATA_OBJECT_COUNT: 'Data Object Count',
  WORKBOOK_COUNT: 'Workbook Count',
  FILE_COUNT: 'File Count',
  OPENED_EXAMPLE_WORKBOOK: 'Opened Example Workbook',
  CREATED_WORKBOOK: 'Created Workbook',
  COPIED_WORKBOOK: 'Copied Workbook',
  RUN_ALL_BLOCKS: 'Run All Blocks',
  RUN_BLOCK: 'Run Block',
  IMPORTED_DATA: 'Imported Data',
  CREATED_SCHEMA: 'Created Schema',
  CREATED_TABLE: 'Created Table',
  CREATED_DATA_SOURCE: 'Created Data Source',
  CREATED_KIFS_FOLDER: 'Created KiFS Folder',
  UPLOADED_KIFS_FILE: 'Uploaded KiFS File',
  OPENED_VIDEO_TUTORIAL: 'Opened Video Tutorial',
  UPGRADE_INITIATED: 'Upgrade Initiated',
  SUSPEND_INITIATED: 'Suspend Initiated',
  RESUME_INITIATED: 'Resume Initiated',
  BACKUP_INITIATED: 'Backup Initiated',
  RESTORE_INITIATED: 'Restore Initiated',
};

export const CONNECTOR_DOWNLOADS = [
  {
    name: 'ODBC Windows',
    url_path: 'windows-odbc-client.zip',
  },
  {
    name: 'ODBC Linux',
    url_path: 'linux-odbc-client.tgz',
  },
  {
    name: 'JDBC',
    url_path: 'kinetica-connector-jdbc.zip',
  },
];

export const API_DOWNLOADS = [
  {
    type: 'cpp',
    url_path: 'gpudb-api-cpp.zip',
  },
  {
    type: 'cs',
    url_path: 'gpudb-api-cs.zip',
  },
  {
    type: 'javascript',
    url_path: 'gpudb-api-javascript.zip',
  },
  {
    type: 'nodejs',
    url_path: 'gpudb-api-javascript.zip',
  },
  {
    type: 'python',
    url_path: 'gpudb-api-python.zip',
  },
  {
    type: 'java',
    url_path: 'gpudb-api-java.zip',
  },
];

export const KISQL_DOWNLOADS = [
  {
    name: 'Windows',
    url_path: 'v7.1/kisql.exe',
  },
  {
    name: 'Mac/Linux',
    url_path: 'v7.1/kisql',
  },
];

export const WMS_PARAMS = [
  {
    type: 'REQUIRED',
    params: ['REQUEST', 'SRS', 'LAYERS', 'BBOX', 'WIDTH', 'HEIGHT'],
  },
  {
    type: 'OPTIONAL',
    params: [
      'VERSION',
      'STYLES',
      'FORMAT',
      'TRANSPARENT',
      'X_ATTR',
      'Y_ATTR',
      'GEO_ATTR',
      'SYMBOL_ATTR',
    ],
  },
  {
    type: 'RASTER',
    params: [
      'ANTIALIASING',
      'DOPOINTS',
      'DOSHAPES',
      'DOSYMBOLOGY',
      'DOTRACKS',
      'HASHLINEANGLES',
      'HASHLINECOLORS',
      'HASHLINEINTERVALS',
      'HASHLINELENS',
      'HASHLINEWIDTHS',
      'ORDER_LAYERS',
      'POINTCOLORS',
      'POINTOFFSET_X',
      'POINTOFFSET_Y',
      'POINTSHAPES',
      'POINTSIZES',
      'SHAPEFILLCOLORS',
      'SHAPELINECOLORS',
      'SHAPELINEPATTERNLENS',
      'SHAPELINEPATTERNS',
      'SHAPELINEWIDTHS',
      'SYMBOLROTATIONS',
      'TRACKHEADCOLORS',
      'TRACKHEADSHAPES',
      'TRACKHEADSIZES',
      'TRACKLINECOLORS',
      'TRACKLINEWIDTHS',
      'TRACKMARKERCOLORS',
      'TRACKMARKERSHAPES',
      'TRACKMARKERSIZES',
    ],
  },
  {
    type: 'CB_RASTER',
    params: [
      'CB_ATTR',
      'CB_VALS',
      'CB_DELIMITER',
      'ORDER_CLASSES',
      'USE_POINT_RENDERER',
      'CB_POINTCOLOR_ATTR',
      'CB_POINTCOLOR_VALS',
      'CB_POINTSIZE_ATTR',
      'CB_POINTSIZE_VALS',
      'CB_POINTSHAPE_ATTR',
      'CB_POINTSHAPE_VALS',
      'ALPHA_BLENDING',
    ],
  },
  {
    type: 'HEATMAP',
    params: [
      'BLUR_RADIUS',
      'COLORMAP',
      'GRADIENT_START_COLOR',
      'GRADIENT_END_COLOR',
      'VAL_ATTR',
    ],
  },
  {
    type: 'CONTOUR',
    params: [
      'COLORMAP',
      'COLOR',
      'BG_COLOR',
      'TEXT_COLOR',
      'MIN_LEVEL',
      'MAX_LEVEL',
      'NUM_LEVELS',
      'ADJUST_LEVEL',
      'VAL_ATTR',
      'SEARCH_RADIUS',
      'GRIDDING_METHOD',
      'SMOOTHING_FACTOR',
      'MAX_SEARCH_CELLS',
      'RENDER_OUTPUT_GRID',
      'GRID_SIZE',
      'ADJUST_GRID',
      'ADJUST_GRID_NEIGH',
      'ADJUST_GRID_SIZE',
      'MIN_GRID_SIZE',
      'MAX_GRID_SIZE',
      'ADD_LABELS',
      'LABELS_FONT_SIZE',
      'LABELS_FONT_FAMILY',
      'LABELS_SEARCH_WINDOW',
      'LABELS_INTRALEVEL_SEPARATION',
      'LABELS_INTERLEVEL_SEPARATION',
      'LABELS_MAX_ANGLE',
    ],
  },
  {
    type: 'LABELS',
    params: [
      'LABEL_LAYER',
      'LABEL_X_ATTR',
      'LABEL_Y_ATTR',
      'LABEL_TEXT_STRING',
      'LABEL_FONT',
      'LABEL_TEXT_COLOR',
      'LABEL_TEXT_SCALE',
      'LABEL_TEXT_ANGLE',
      'LABEL_DRAW_BOX',
      'LABEL_DRAW_LEADER',
      'LABEL_LINE_WIDTH',
      'LABEL_LINE_COLOR',
      'LABEL_FILL_COLOR',
      'LABEL_LEADER_X_ATTR',
      'LABEL_LEADER_Y_ATTR',
      'LABEL_FILTER',
      'LABEL_X_OFFSET',
      'LABEL_Y_OFFSET',
    ],
  },
  {
    type: 'ISOCHRONES',
    params: [
      'ADD_LABELS',
      'BG_COLOR',
      'COLOR',
      'COLOR_ISOLINES',
      'CONCAVITY_LEVEL',
      'DATA_MAX_X',
      'DATA_MAX_Y',
      'DATA_MIN_X',
      'DATA_MIN_Y',
      'GENERATE_IMAGE',
      'GRAPH_NAME',
      'GRID_SIZE',
      'HEIGHT',
      'IS_REPLICATED',
      'LABELS_FONT_FAMILY',
      'LABELS_FONT_SIZE',
      'LABELS_INTRALEVEL_SEPARATION',
      'LABELS_INTERLEVEL_SEPARATION',
      'LABELS_MAX_ANGLE',
      'LABELS_SEARCH_WINDOW',
      'LEVELS_TABLE',
      'LINE_SIZE',
      'MAX_SOLUTION_RADIUS',
      'NUM_LEVELS',
      'PROJECTION',
      'REMOVE_PREVIOUS_RESTRICTIONS',
      'RESTRICTIONS',
      'RESTRICTION_THRESHOLD_VALUE',
      'SEARCH_RADIUS',
      'SOLVE_DIRECTION',
      'SOLVE_TABLE',
      'SOURCE_NODE',
      'TEXT_COLOR',
      'UNIFORM_WEIGHTS',
      'WEIGHTS_ON_EDGES',
      'WIDTH',
    ],
  },
];

export const COLUMN_TYPES = {
  DATA: 'data',
  STORE_ONLY: 'store_only',
  DISK_OPTIMIZED: 'disk_optimized',
  PRIMARY_KEY: 'primary_key',
  SHARD_KEY: 'shard_key',
  TEXT_SEARCH: 'text_search',
  NULL: 'null',
  INT: 'int',
  INT16: 'int16',
  INT8: 'int8',
  LONG: 'long',
  FLOAT: 'float',
  TIMESTAMP: 'timestamp',
  INIT_WITH_NOW: 'init_with_now',
  DOUBLE: 'double',
  STRING: 'string',
  CHAR256: 'char256',
  CHAR128: 'char128',
  CHAR64: 'char64',
  CHAR32: 'char32',
  CHAR16: 'char16',
  CHAR8: 'char8',
  CHAR4: 'char4',
  CHAR2: 'char2',
  CHAR1: 'char1',
  IPV4: 'ipv4',
  BYTES: 'bytes',
  TIME: 'time',
  DATE: 'date',
  DATETIME: 'datetime',
  DECIMAL: 'decimal',
  ULONG: 'ulong',
  WKT: 'wkt',
  DICT: 'dict',
  UUID: 'uuid',
  INIT_WITH_UUID: 'init_with_uuid',
};

export const DICT_ENCODING_SUBTYPES = [
  COLUMN_TYPES.CHAR256,
  COLUMN_TYPES.CHAR128,
  COLUMN_TYPES.CHAR64,
  COLUMN_TYPES.CHAR32,
  COLUMN_TYPES.CHAR16,
  COLUMN_TYPES.CHAR8,
  COLUMN_TYPES.CHAR4,
  COLUMN_TYPES.CHAR2,
  COLUMN_TYPES.CHAR1,
  COLUMN_TYPES.DATE,
];

export const CHAR_TYPE_LEN = {
  char1: 1,
  char2: 2,
  char4: 4,
  char8: 8,
  char16: 16,
  char32: 32,
  char64: 64,
  char128: 128,
  char256: 256,
};

export const CDATA_CONFIG = {
  FEATURED: [
    'azuredatalake',
    'amazons3',
    'kafka',
    'avro',
    'azuresynapse',
    'cassandra',
    'csv',
    'elasticsearch',
    'excel',
    'bigquery',
    'json',
    'mariadb',
    'mongodb',
    'mysql',
    'oracledb',
    'parquet',
    'presto',
    'postgresql',
    'redshift',
    'redis',
    'salesforce',
    'saphana',
    'servicenow',
    'singlestore',
    'snowflake',
    'spark',
    'splunk',
    'sql',
    'sybaseiq',
    'teradata',
  ],
};

export const KIFS_UPLOAD_CHUNK_SIZE = 16 * 1024 * 1024;
