// Imports
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Breadcrumb } from 'antd';
import * as monaco from 'monaco-editor';

// App Imports
import GraphQLServices from '../../graphql/services';
import NavBar from '../../components/navbar/NavBar';
import Footer from '../../components/footer/Footer';
import ExplorerSider from '../common/ExplorerSider';
import {
  language as kineticaSql,
  conf,
  defaultSuggestions,
} from '../../components/editor/kinetica-sql';
import WorkbookEditor from './WorkbookEditor';
import WorkbookCatalog from './WorkbookCatalog';

const { Header, Content } = Layout;

monaco.languages.register({
  id: 'kinetica-sql',
});
monaco.languages.setMonarchTokensProvider('kinetica-sql', kineticaSql);
monaco.languages.setLanguageConfiguration('kinetica-sql', conf);

// Component
const DataExplore = () => {
  const { workbookId } = useParams();

  const { data: { tables } = [] } = GraphQLServices.Tables.useGetTables();
  const {
    loading: worksbooksLoading,
    data: { workbooks = undefined } = {},
    refetch: refetchWorkbooks,
  } = GraphQLServices.Workbooks.useGetWorkbooks();

  useEffect(
    _ => {
      let disposable;
      if (tables) {
        if (disposable && disposable.dispose) {
          disposable.dispose();
        }

        disposable = monaco.languages.registerCompletionItemProvider(
          'kinetica-sql',
          {
            provideCompletionItems() {
              let added = new Set();
              const dynamicSuggestions = [];
              tables.forEach(entry => {
                // Add fully qualified name
                if (!added.has(entry.full)) {
                  dynamicSuggestions.push({
                    label: entry.full,
                    kind: monaco.languages.CompletionItemKind.Value,
                    insertText: entry.full,
                  });
                  added.add(entry.full);
                }

                // Add schema name only
                if (!added.has(entry.schema)) {
                  dynamicSuggestions.push({
                    label: entry.schema,
                    kind: monaco.languages.CompletionItemKind.Value,
                    insertText: entry.schema,
                  });
                  added.add(entry.schema);
                }

                // Add column names
                entry.columns.forEach(column => {
                  if (!added.has(column.name)) {
                    dynamicSuggestions.push({
                      label: column.name,
                      kind: monaco.languages.CompletionItemKind.Value,
                      insertText: column.name,
                    });
                    added.add(column.name);
                  }
                });
              });

              return {
                suggestions: [...defaultSuggestions, ...dynamicSuggestions],
              };
            },
          }
        );
      }

      return _ => {
        if (disposable && disposable.dispose) {
          disposable.dispose();
        }
      };
    },
    [tables]
  );

  return (
    <Layout className="dataexplore" style={{ height: '100vh' }}>
      <Header className="header" style={{ padding: '0px', minWidth: '1280px' }}>
        <NavBar />
      </Header>
      <Layout hasSider>
        <ExplorerSider />
        <Content>
          <Layout style={{ height: 'calc(100vh - 55px)', minWidth: '930px' }}>
            <Content
              style={{
                padding: '0px 20px',
                height: 'calc(100vh - 190px)',
                overflowY: 'scroll',
              }}
            >
              <Breadcrumb style={{ margin: '9px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Explore</Breadcrumb.Item>
              </Breadcrumb>
              {workbookId && (
                <WorkbookEditor
                  workbookId={workbookId}
                  refetchWorkbooks={refetchWorkbooks}
                />
              )}
              {workbooks && !workbookId && (
                <WorkbookCatalog
                  workbooks={workbooks}
                  loading={worksbooksLoading}
                  refetch={refetchWorkbooks}
                />
              )}
            </Content>
            <Footer />
          </Layout>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DataExplore;
