// Imports
import React, { useMemo } from 'react';
import { Tabs } from 'antd';
import {
  DatabaseOutlined,
  BookOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// App Imports
import DataExplorer from './DataExplorer';
import WorkbookExplorer from './WorkbookExplorer';
import FileExplorer from './FileExplorer';
import Usage from './Usage';
import useUsage from '../../hooks/useUsage';
import { setExplorerCurrentTab } from '../../store/app/actions';
import { FREE_SAAS } from '../../setup/config';

const { TabPane } = Tabs;

const noop = () => {};

// Component
const Explorer = () => {
  const { explorerCurrentTab } = useSelector(state => state.app);
  const dispatch = useDispatch();

  const { usage, refetch } = useUsage();

  const callback = current => {
    dispatch(setExplorerCurrentTab(current));
  };

  const tabStyle = {
    padding: '0px 15px',
  };

  const offset = useMemo(() => {
    const filtered = usage.filter(rank => rank.limit !== 'Unlimited');
    return filtered.length > 0 ? filtered.length * 46 + 10 : 0;
  }, [usage]);

  return (
    <div className="explorer">
      {FREE_SAAS && <Usage usage={usage} />}
      <Tabs
        activeKey={explorerCurrentTab}
        onChange={callback}
        animated={{ inkBar: true, tabPane: false }}
        centered
      >
        <TabPane
          tab={
            <div style={tabStyle}>
              <DatabaseOutlined />
              Data
            </div>
          }
          key="data"
        >
          <DataExplorer
            offset={offset}
            refetchUsage={FREE_SAAS ? refetch : noop}
          />
        </TabPane>
        <TabPane
          tab={
            <div style={tabStyle}>
              <BookOutlined />
              Workbooks
            </div>
          }
          key="workbooks"
        >
          <WorkbookExplorer
            offset={offset}
            refetchUsage={FREE_SAAS ? refetch : noop}
          />
        </TabPane>
        <TabPane
          tab={
            <div style={tabStyle}>
              <CopyOutlined />
              Files
            </div>
          }
          key="files"
        >
          <FileExplorer
            offset={offset}
            refetchUsage={FREE_SAAS ? refetch : noop}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Explorer;
