// Imports
import React, { useMemo, useState } from 'react';
import { Modal, Button, Spin } from 'antd';

// App Imports
import VizMap from '../../containers/dataexplore/VizMap';
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';
import { MAP_VIZ_TYPE } from '../../constants';

const TableWmsModal = ({
  table,
  defaults = {},
  visible,
  setVisible,
  width,
  height,
  initWithConfig = true,
}) => {
  const {
    loading,
    data,
  } = GraphQLServices.VisualizationTypes.useGetVisualizationTypeById({
    variables: {
      id: MAP_VIZ_TYPE,
    },
  });

  const [config, setConfig] = useState([
    {
      table_schema: table?.schema,
      table_name: table?.name,
    },
  ]);

  const handleUpdateMapViz = (update, callback) => {
    setConfig([
      {
        ...config[0],
        ...update,
      },
    ]);
    if (callback) {
      callback();
    }
  };

  const viz = useMemo(
    _ => {
      return {
        ...data,
        config: [
          {
            ...config[0],
            table_schema: table?.schema,
            table_name: table?.name,
            ...defaults,
          },
        ],
      };
    },
    [table, config, data, defaults]
  );

  return (
    <Modal
      title={`Table WMS Preview: ${table.full}`}
      visible={visible || loading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={loading}>
        <div
          style={{
            maxHeight: height - 100,
            minHeight: 400,
            overflow: 'hidden',
          }}
        >
          {data && !loading && (
            <VizMap
              viz={viz}
              handleUpdate={handleUpdateMapViz}
              minHeight={Math.max(height - 100, 400)}
              isPreview={true}
              initWithConfig={initWithConfig}
              disabledFields={['table_schema', 'table_name']}
            />
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default TableWmsModal;
