// Import React dependencies.
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlayCircleOutlined } from '@ant-design/icons';
import * as monaco from 'monaco-editor';
import { useApolloClient } from '@apollo/client';
import { format as formatSQL } from 'sql-formatter';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import MonacoEditor from '../../components/editor/MonacoEditor';
import Spinner from '../../components/common/Spinner';
import ResultView from './ResultView';
import BlockToolbar from './BlockToolbar';
import { addOrUpdateBlockResult } from '../../store/worksheet/actions';
import BlockIndex from './BlockIndex';
import { EXECUTE_SQL_LIMIT } from '../../setup/config';
import ExplainPlanModal from '../../components/modal/ExplainPlanModal';
import { useIsReadOnly } from './utils';
import ReadOnlyDivider from './ReadOnlyDivider';

const EDITOR_MIN_HEIGHT = 80;

export default function CodeBlock({
  element,
  attributes,
  children,
  blocks,
  runAllFromBlock,
  toggleSqlVisibility,
  toggleBlockVisibility,
  workbook,
}) {
  const dispatch = useDispatch();
  const slateEditor = useSlate();
  const editorRef = useRef();
  const graphqlClient = useApolloClient();
  const analytics = useAnalytics();
  const readOnly = useIsReadOnly();
  const [executeSql] = GraphQLServices.SqlQueries.useExecuteSql();
  const [explainPlan, { loading: explainPlanLoading }] =
    GraphQLServices.SqlQueries.useExplainPlan();
  const [editorHeight, setEditorHeight] = useState(EDITOR_MIN_HEIGHT);
  const ignoreEvent = useRef();
  const {
    queryResponse = null,
    queryRunning = false,
    queryError = null,
  } = useSelector(({ worksheet }) => worksheet.blockResults[element.id] || {});

  const [showExplainPlan, setShowExplainPlan] = useState(false);
  const [plan, setPlan] = useState(undefined);
  const [showLegend, setShowLegend] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(_ => {
    let changeSubscription = null;
    if (editorRef.current) {
      // Initialize editor to initial code state.
      editorRef.current.editor.setValue(element.content);

      // Resize editor to size of content. This is so editor never has to scroll.
      changeSubscription = editorRef.current.editor.onDidContentSizeChange(
        e => {
          if (ignoreEvent.current || !editorRef.current) {
            return;
          }

          const editor = editorRef.current.editor;
          const height = Math.max(EDITOR_MIN_HEIGHT, editor.getContentHeight());

          try {
            ignoreEvent.current = true;
            setEditorHeight(height);
          } finally {
            ignoreEvent.current = false;
          }
        }
      );
    }

    return _ => {
      if (changeSubscription) {
        changeSubscription.dispose();
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(
    _ => {
      if (editorRef.current) {
        editorRef.current.editor.updateOptions({ readOnly });
      }
    },
    [readOnly]
  );

  useEffect(
    _ => {
      let changeSubscription = null;
      if (editorRef.current) {
        changeSubscription = editorRef.current.editor.onDidChangeModelContent(
          e => {
            if (editorRef.current) {
              const content = editorRef.current.editor.getModel().getValue();
              const path = ReactEditor.findPath(slateEditor, element);
              Transforms.setNodes(slateEditor, { content }, { at: path });
            }
          }
        );
      }

      return _ => {
        if (changeSubscription) {
          changeSubscription.dispose();
        }
      };
    },
    [slateEditor, element]
  );

  const handleClose = useCallback(
    _ => {
      const path = ReactEditor.findPath(slateEditor, element);
      Transforms.delete(slateEditor, { at: path });
      Transforms.deselect(slateEditor);
    },
    [slateEditor, element]
  );

  useEffect(
    _ => {
      let subscription = null;
      if (editorRef.current) {
        subscription = editorRef.current.editor.addAction({
          id: 'delete-block',
          label: 'Delete Block',
          keybindings: [
            monaco.KeyMod.WinCtrl | monaco.KeyCode.Backspace,
            monaco.KeyMod.WinCtrl | monaco.KeyCode.Delete,
          ],
          run: handleClose,
        });
      }

      return _ => {
        if (subscription) {
          subscription.dispose();
        }
      };
    },
    [handleClose]
  );

  const handleRun = useCallback(
    _ => {
      analytics.track(analytics.EVENT_TYPES.RUN_BLOCK)(
        workbook.is_example ? { title: workbook.name } : {}
      );

      dispatch(
        addOrUpdateBlockResult(element.id, {
          queryError: null,
          queryResponse: null,
          queryRunning: true,
        })
      )
        .then(_ =>
          executeSql({
            variables: {
              statement: element.content,
              limit: EXECUTE_SQL_LIMIT,
            },
          })
        )
        .then(async res => {
          if (res?.data?.executeSql?.response?.total_number_of_records === -1) {
            await graphqlClient.query({
              query: GraphQLServices.DataObjects.GET_DATA_OBJECTS,
            });
          }
          return dispatch(
            addOrUpdateBlockResult(element.id, {
              queryError: res?.errors?.[0],
              queryResponse: res.data.executeSql,
              queryRunning: false,
            })
          );
        })
        .catch(err =>
          dispatch(
            addOrUpdateBlockResult(element.id, {
              queryError: err,
              queryResponse: null,
              queryRunning: false,
            })
          )
        );
    },
    [element, executeSql, dispatch, graphqlClient, analytics, workbook]
  );

  const handleFormatSQL = _ => {
    if (editorRef.current) {
      const current = editorRef.current.editor.getValue();
      editorRef.current.editor.setValue(
        formatSQL(current, { indent: '    ', language: 'mysql' })
      );
    }
  };

  const handleExplainPlan = useCallback(
    async run => {
      setShowLegend(run);
      setShowExplainPlan(true);
      const resp = await explainPlan({
        variables: {
          statement: element.content,
          limit: EXECUTE_SQL_LIMIT,
          run,
        },
      });
      try {
        if (resp?.errors) {
          throw new Error(resp?.errors[0]?.message);
        }
        const { data } = resp?.data?.explainPlan?.response;
        const plan = JSON.parse(data?.column_1[data?.column_1.length - 1]);
        setPlan(plan);
      } catch (err) {
        setPlan({ error: err?.message });
      }
    },
    [element, setPlan, explainPlan]
  );

  useEffect(
    _ => {
      let subscription = null;
      if (editorRef.current) {
        subscription = editorRef.current.editor.addAction({
          id: 'run-block',
          label: 'Run Block',
          keybindings: [
            monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
            monaco.KeyMod.WinCtrl | monaco.KeyCode.Enter,
          ],
          run: _ => {
            handleRun();
          },
        });
      }

      return _ => {
        if (subscription) {
          subscription.dispose();
        }
      };
    },
    [handleRun]
  );

  const index = useMemo(
    _ => {
      const { id } = element;
      return blocks.findIndex(e => e.id === id) + 1;
    },
    [blocks, element]
  );

  const monacoScrollbar = useMemo(_ => {
    return { alwaysConsumeMouseWheel: false };
  }, []);

  const monacoMinimap = useMemo(_ => {
    return {
      enabled: false,
    };
  }, []);

  const { isSqlVisible, isBlockVisible } = useMemo(
    _ => {
      const { isSqlVisible = true, isBlockVisible = true } =
        element?.config ?? {};
      return { isSqlVisible, isBlockVisible };
    },
    [element]
  );

  if (readOnly && !isBlockVisible) {
    return null;
  }

  return (
    <div
      {...attributes}
      contentEditable={false}
      style={
        readOnly
          ? {
              margin: '0px',
              position: 'relative',
              padding: '0px 5px',
            }
          : {
              margin: '8px 0px 26px',
              position: 'relative',
              border: '1px dotted #3700b322',
              borderLeft: '4px solid #3700b333',
              padding: '5px',
            }
      }
    >
      {readOnly && <ReadOnlyDivider index={index} />}
      {!readOnly && <BlockIndex index={index} />}
      <BlockToolbar
        element={element}
        blocks={blocks}
        showExplainPlan={handleExplainPlan}
        formatSQL={handleFormatSQL}
        runAllFromBlock={runAllFromBlock}
        toggleSqlVisibility={toggleSqlVisibility}
        toggleBlockVisibility={toggleBlockVisibility}
        setLoading={setLoading}
      />
      <Spin indicator={<Spinner />} spinning={loading || queryRunning}>
        {!readOnly || isSqlVisible ? (
          <MonacoEditor
            ref={editorRef}
            language="kinetica-sql"
            contextmenu={false}
            renderLineHighlight={'none'}
            scrollBeyondLastLine={false}
            scrollbar={monacoScrollbar}
            overviewRulerLanes={0}
            automaticLayout={true}
            minimap={monacoMinimap}
            style={{
              width: 'calc(100% - 50px)',
              height: `${editorHeight}px`,
              marginTop: '10px',
              marginLeft: '10px',
              backgroundColor: '#f3f3f3',
              pointerEvents: 'all',
            }}
          ></MonacoEditor>
        ) : (
          <div
            style={{
              margin: '3px 5px 0px 40px',
              padding: '1px 8px',
              color: '#cccccc',
              backgroundColor: '#f6f6f6',
              borderRadius: '5px',
            }}
          >
            SQL Hidden
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            top: '-5px',
            height: `${editorHeight}px`,
            left: '0px',
          }}
        >
          <div
            style={{
              position: 'sticky',
              float: 'right',
              width: 'auto',
              top: '0px',
              left: '0px',
              zIndex: 1,
              pointerEvents: 'all',
            }}
          >
            <Button
              type="text"
              onClick={handleRun}
              icon={
                <PlayCircleOutlined
                  style={{ fontSize: '25px', color: '#3700b399' }}
                />
              }
            ></Button>
          </div>
        </div>
        {element?.id && (
          <div style={{ padding: '5px', pointerEvents: 'all' }}>
            <ResultView
              element={element}
              queryResponse={queryResponse}
              queryError={queryError}
              readOnly={readOnly}
            ></ResultView>
          </div>
        )}
        <div
          contentEditable={false}
          style={{
            display: 'none',
            userSelect: 'none',
            pointerEvents: 'none',
          }}
        >
          {children}
        </div>
      </Spin>
      {showExplainPlan && (
        <ExplainPlanModal
          visible={showExplainPlan}
          setVisible={visible => {
            setPlan(undefined);
            setShowLegend(false);
            setShowExplainPlan(visible);
          }}
          plan={plan}
          legend={showLegend}
          loading={explainPlanLoading}
          width={window.innerWidth - 100}
          height={window.innerHeight - 200}
        />
      )}
    </div>
  );
}
