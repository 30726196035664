// Imports
import React from 'react';
import { Modal, Button, Spin, Descriptions, Row, Col } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';

const ModelDDLModal = ({ model, visible, setVisible, width, height }) => {
  const { model_name } = model;
  const { loading: modelLoading, data } =
    GraphQLServices.Models.useDescribeModelByName({
      variables: {
        model_name,
      },
    });

  return (
    <Modal
      title={`Model DDL: ${model_name}`}
      visible={visible || modelLoading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={modelLoading}>
        <div
          style={{
            maxHeight: height + 20,
            minHeight: '200px',
            overflow: 'scroll',
          }}
        >
          {data?.modelDescribeByName && (
            <Row gutter={20}>
              <Col span={12}>
                <Descriptions
                  title="Input Schema"
                  size="small"
                  column={1}
                  bordered
                >
                  {data?.modelDescribeByName?.input_schema?.fields.map(
                    field => {
                      return (
                        <Descriptions.Item key={field.name} label={field.name}>
                          {field.type}
                        </Descriptions.Item>
                      );
                    }
                  )}
                </Descriptions>
              </Col>
              <Col span={12}>
                <Descriptions
                  title="Output Schema"
                  size="small"
                  column={1}
                  bordered
                >
                  {data?.modelDescribeByName?.output_schema?.fields.map(
                    field => {
                      return (
                        <Descriptions.Item key={field.name} label={field.name}>
                          {field.type}
                        </Descriptions.Item>
                      );
                    }
                  )}
                </Descriptions>
              </Col>
            </Row>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default ModelDDLModal;
