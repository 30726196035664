// Import
import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
  useReducer,
} from 'react';
import {
  Button,
  Space,
  Tooltip,
  Dropdown,
  Menu,
  Divider,
  Popconfirm,
  Anchor,
  Tag,
} from 'antd';
import {
  CodeOutlined,
  FileTextOutlined,
  EditOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
  GlobalOutlined,
  CopyOutlined,
  CloseOutlined,
  ClearOutlined,
  SyncOutlined,
  FileImageOutlined,
  ClockCircleOutlined,
  WarningOutlined,
  PlusOutlined,
  Html5Outlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { createEditor, Transforms } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { v4 as uuid } from 'uuid';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import { EXECUTE_SQL } from '../../graphql/schema/sqlqueries';
import CodeBlock from './CodeBlock';
import TextBlock from './TextBlock';
import MapBlock from './MapBlock';
import ImageBlock from './ImageBlock';
import HtmlBlock from './HtmlBlock';
import WorksheetEditModal from '../../components/modal/WorksheetEditModal';
import BlockFullscreenModal from '../../components/modal/BlockFullscreenModal';
import { BLOCK_TYPES, VIZ_TYPES } from '../../constants';
import { addOrUpdateBlockResult } from '../../store/worksheet/actions';
import ErrorBlock from './ErrorBlock';
import {
  useExportPerfReportWorkbook,
  validateProcessedBlocks,
  validateBlockContent,
  useIsReadOnly,
} from './utils';
import { displaySuccess, displayWarning } from '../../helper';

const { Link } = Anchor;

const TIME_TO_ACCUMULATE_CHANGES = 4000; // ms

const withCodeVoids = editor => {
  const { isVoid } = editor;
  editor.isVoid = element =>
    element.type === BLOCK_TYPES.SQL ||
    element.type === BLOCK_TYPES.TEXT ||
    element.type === BLOCK_TYPES.IMAGE ||
    element.type === BLOCK_TYPES.HTML ||
    element.type === BLOCK_TYPES.MAP
      ? true
      : isVoid(element);
  return editor;
};

const runIdMapInitialState = {};

function runIdMapReducer(state, action) {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        [action.id]: action.newRunId,
      };
    }
    default:
      return state;
  }
}

const WorksheetEditor = ({
  worksheetId,
  workbook,
  apolloClient,
  moveWorksheetUp,
  moveWorksheetDown,
  copyToThisWorkbook,
  copyToAnotherWorkbook,
  // setAutoRefresh,
  embed = false,
}) => {
  const { id: workbookId } = workbook;

  // Flag for tracking when blocks from backend are loaded and initialize intital state of worksheet.
  const uninitialized = useRef(true);
  const worksheetBottomRef = useRef(null);
  const [runIdMap, runIdDispatch] = useReducer(
    runIdMapReducer,
    runIdMapInitialState
  );

  // Get results from store
  const results = useSelector(({ worksheet }) => worksheet.blockResults || {});

  const exportPerfReportWorkbook = useExportPerfReportWorkbook();
  const analytics = useAnalytics();
  const readOnly = useIsReadOnly();
  const dispatch = useDispatch();
  const { data: { worksheet = undefined } = {} } =
    GraphQLServices.Worksheets.useGetWorksheetOnlyById({
      variables: {
        id: worksheetId,
      },
    });
  const { data: { blocksByWorksheetId: blocks } = {}, refetch: refetchBlocks } =
    GraphQLServices.Blocks.useGetBlocksByWorksheetId({
      variables: {
        worksheet_id: worksheetId,
      },
    });
  const [createBlock] = GraphQLServices.Blocks.useCreateBlock();
  const [updateBlock] = GraphQLServices.Blocks.useUpdateBlockById();
  const [removeBlock] = GraphQLServices.Blocks.useRemoveBlockById();

  const [createVisualization] =
    GraphQLServices.Visualizations.useCreateVisualization();

  const editor = useMemo(
    _ => withHistory(withCodeVoids(withReact(createEditor()))),
    []
  );
  const [value, setValue] = useState([]);
  const [showWorksheetEditModal, setShowWorksheetEditModal] = useState(false);

  const [clearingAll, setClearingAll] = useState(false);
  const clearAllResults = useCallback(
    async _ => {
      setClearingAll(true);
      let clearResultPromiseChain = Promise.resolve();
      for (let block of value.filter(b => b.type === BLOCK_TYPES.SQL)) {
        clearResultPromiseChain = clearResultPromiseChain.then(_ =>
          dispatch(
            addOrUpdateBlockResult(block.id, {
              queryError: null,
              queryResponse: null,
              queryRunning: false,
            })
          )
        );
      }

      return clearResultPromiseChain
        .then(_ => {
          setClearingAll(false);
        })
        .catch(_ => {
          setClearingAll(false);
        });
    },
    [value, dispatch]
  );
  const clearAllResultsFromBlock = useCallback(
    async id => {
      setClearingAll(true);
      let marker = false;
      let clearResultPromiseChain = Promise.resolve();
      for (let block of value.filter(b => b.type === BLOCK_TYPES.SQL)) {
        // Find start block by ID and then start
        // clearing results from there
        if (block.id === id) {
          marker = true;
        }
        if (marker) {
          clearResultPromiseChain = clearResultPromiseChain.then(_ =>
            dispatch(
              addOrUpdateBlockResult(block.id, {
                queryError: null,
                queryResponse: null,
                queryRunning: false,
              })
            )
          );
        }
      }

      return clearResultPromiseChain
        .then(_ => {
          setClearingAll(false);
        })
        .catch(_ => {
          setClearingAll(false);
        });
    },
    [value, dispatch]
  );

  const queryResults = useSelector(({ worksheet }) => worksheet.blockResults);
  const showPerfReport = useCallback(
    _ => {
      exportPerfReportWorkbook(workbookId, worksheetId, queryResults);
    },
    [workbookId, worksheetId, queryResults, exportPerfReportWorkbook]
  );

  const cancelTokenRef = useRef({});
  const [runningAll, setRunningAll] = useState(false);
  const [currentRunningId, setCurrentRunningId] = useState(null);
  const runAllBlocks = useCallback(
    _ => {
      analytics.track(analytics.EVENT_TYPES.RUN_ALL_BLOCKS)(
        workbook.is_example ? { title: workbook.name } : {}
      );

      setRunningAll(true);
      let runAllPromiseChain = clearAllResults();
      for (let block of value.filter(
        b => b.type === BLOCK_TYPES.SQL || b.type === BLOCK_TYPES.MAP
      )) {
        runAllPromiseChain = runAllPromiseChain.then(_ => {
          if (block.type === BLOCK_TYPES.SQL) {
            return dispatch(
              addOrUpdateBlockResult(block.id, {
                queryError: null,
                queryResponse: null,
                queryRunning: true,
              })
            )
              .then(_ => {
                setCurrentRunningId(block.id);
                const cancelPromise = new Promise(
                  resolve =>
                    (cancelTokenRef.current = _ => resolve('Cancelled'))
                );
                return Promise.race([
                  cancelPromise,
                  apolloClient.mutate({
                    mutation: EXECUTE_SQL,
                    variables: {
                      statement: block.content,
                    },
                  }),
                ]);
              })
              .then(async res => {
                if (
                  res?.data?.executeSql?.response?.total_number_of_records ===
                  -1
                ) {
                  await apolloClient.query({
                    query: GraphQLServices.DataObjects.GET_DATA_OBJECTS,
                  });
                }
                dispatch(
                  addOrUpdateBlockResult(block.id, {
                    queryError: res?.errors?.[0],
                    queryResponse: res?.data?.executeSql,
                    queryRunning: false,
                  })
                );

                // Re-throw error to stop promise chain from continuing.
                if (res?.errors?.[0] || res === 'Cancelled') {
                  throw new Error(
                    res?.errors?.[0]?.message || 'Result Cancelled'
                  );
                }
              })
              .catch(err => {
                dispatch(
                  addOrUpdateBlockResult(block.id, {
                    queryError: err,
                    queryResponse: null,
                    queryRunning: false,
                  })
                );

                // Re-throw error to stop promise chain from continuing.
                throw err;
              })
              .finally(_ => {
                setCurrentRunningId(null);
              });
          } else if (block.type === BLOCK_TYPES.MAP) {
            return runIdDispatch({
              type: 'update',
              newRunId: uuid(),
              id: block.id,
            });
          }
        });
      }
      runAllPromiseChain
        .then(_ => {
          setRunningAll(false);
          cancelTokenRef.current = null;
          displaySuccess('Run All finished!');
        })
        .catch(_ => {
          setRunningAll(false);
          cancelTokenRef.current = null;
          displayWarning('Run All finished with one or more errors!');
        })
        .finally(_ => {
          setCurrentRunningId(null);
        });
    },
    [apolloClient, value, dispatch, clearAllResults, analytics, workbook]
  );

  const toggleSqlVisibility = useCallback(
    block => async _ => {
      const { id, config } = block;
      const { isSqlVisible = true, ...rest } = config;
      uninitialized.current = true;
      await updateBlock({
        variables: {
          id,
          config: {
            ...rest,
            isSqlVisible: !isSqlVisible,
          },
        },
      });
      refetchBlocks();
    },
    [updateBlock, refetchBlocks]
  );

  const toggleBlockVisibility = useCallback(
    block => async _ => {
      const { id, config } = block;
      const { isBlockVisible = true, ...rest } = config;
      uninitialized.current = true;
      await updateBlock({
        variables: {
          id,
          config: {
            ...rest,
            isBlockVisible: !isBlockVisible,
          },
        },
      });
      refetchBlocks();
    },
    [updateBlock, refetchBlocks]
  );

  const runAllFromBlock = useCallback(
    id => _ => {
      analytics.track(analytics.EVENT_TYPES.RUN_ALL_BLOCKS)(
        workbook.is_example ? { title: workbook.name } : {}
      );

      setRunningAll(true);
      let runAllPromiseChain = clearAllResultsFromBlock(id);
      const idx = value.findIndex(b => b.id === id);
      const restOfBlocks = value.slice(idx);
      for (let block of restOfBlocks.filter(
        b => b.type === BLOCK_TYPES.SQL || b.type === BLOCK_TYPES.MAP
      )) {
        runAllPromiseChain = runAllPromiseChain.then(_ => {
          if (block.type === BLOCK_TYPES.SQL) {
            return dispatch(
              addOrUpdateBlockResult(block.id, {
                queryError: null,
                queryResponse: null,
                queryRunning: true,
              })
            )
              .then(_ => {
                setCurrentRunningId(block.id);
                const cancelPromise = new Promise(
                  resolve =>
                    (cancelTokenRef.current = _ => resolve('Cancelled'))
                );
                return Promise.race([
                  cancelPromise,
                  apolloClient.mutate({
                    mutation: EXECUTE_SQL,
                    variables: {
                      statement: block.content,
                    },
                  }),
                ]);
              })
              .then(async res => {
                if (
                  res?.data?.executeSql?.response?.total_number_of_records ===
                  -1
                ) {
                  await apolloClient.query({
                    query: GraphQLServices.DataObjects.GET_DATA_OBJECTS,
                  });
                }
                dispatch(
                  addOrUpdateBlockResult(block.id, {
                    queryError: res?.errors?.[0],
                    queryResponse: res?.data?.executeSql,
                    queryRunning: false,
                  })
                );

                // Re-throw error to stop promise chain from continuing.
                if (res?.errors?.[0] || res === 'Cancelled') {
                  throw new Error(
                    res?.errors?.[0]?.message || 'Result Cancelled'
                  );
                }
              })
              .catch(err => {
                dispatch(
                  addOrUpdateBlockResult(block.id, {
                    queryError: err,
                    queryResponse: null,
                    queryRunning: false,
                  })
                );

                // Re-throw error to stop promise chain from continuing.
                throw err;
              })
              .finally(_ => {
                setCurrentRunningId(null);
              });
          } else if (block.type === BLOCK_TYPES.MAP) {
            return runIdDispatch({
              type: 'update',
              newRunId: uuid(),
              id: block.id,
            });
          }
        });
      }
      runAllPromiseChain
        .then(_ => {
          setRunningAll(false);
          cancelTokenRef.current = null;
          displaySuccess('Run All from here finished!');
        })
        .catch(_ => {
          setRunningAll(false);
          cancelTokenRef.current = null;
          displayWarning('Run All from here finished with one or more errors!');
        })
        .finally(_ => {
          setCurrentRunningId(null);
        });
    },
    [
      apolloClient,
      value,
      dispatch,
      clearAllResultsFromBlock,
      analytics,
      workbook,
    ]
  );

  const cancelRunAll = useCallback(_ => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current();
      cancelTokenRef.current = null;
    }
  }, []);

  // This is for throttling updates for content so that every key press doesn't
  // trigger an update.
  const latestContentUpdateMapRef = useRef({});
  const updateContent = useCallback(
    (id, content) => {
      if (latestContentUpdateMapRef.current[id]) {
        // Update the reference with the latest content update.
        latestContentUpdateMapRef.current[id] = content;
      } else {
        latestContentUpdateMapRef.current[id] = content;
        // After enough time has passed, update the block with the latest content.
        setTimeout(
          id => {
            updateBlock({
              variables: {
                id,
                content: latestContentUpdateMapRef.current[id],
              },
            });
            // Clear latest content update so a new setTimeout will be called.
            latestContentUpdateMapRef.current[id] = null;
          },
          TIME_TO_ACCUMULATE_CHANGES,
          id
        );
      }
    },
    [updateBlock]
  );

  // const autoRefreshInterval = useMemo(
  //   _ => {
  //     const { autoRefreshInterval = 0 } = worksheet?.config ?? {};
  //     return autoRefreshInterval;
  //   },
  //   [worksheet]
  // );

  useEffect(
    _ => {
      const processedBlocks = [];
      if (blocks && uninitialized.current) {
        // Start with first block
        let curBlock = blocks.find(b => b.previous_block_id === null);

        // Keep track of visited blocks so there is no possibility of infinite loops.
        const visitedBlocks = [];

        // Visit blocks by following next_block_id and creating Slate objects.
        while (curBlock && !visitedBlocks.includes(curBlock.id)) {
          const type = curBlock.block_type.id;

          let content = '';
          try {
            if (curBlock.content) {
              content = JSON.parse(curBlock.content);
            }
          } catch (err) {
            console.error(curBlock.content);
            console.error(err);
          }

          const config = curBlock.config ?? {};
          const newBlock = {
            id: curBlock.id,
            type: validateBlockContent(type, content) ? type : null,
            content,
            config,
            children: [{ text: '' }],
          };

          // Include what is stored in database for block so it can be accessed by error block.
          if (newBlock.type === null) {
            newBlock.storedBlock = { ...curBlock };
          }

          processedBlocks.push(newBlock);
          visitedBlocks.push(curBlock.id);
          const nextBlockId = curBlock.next_block_id;
          curBlock = blocks.find(b => b.id === nextBlockId);
        }
        uninitialized.current = false;
        validateProcessedBlocks(blocks, processedBlocks);
        setValue(
          processedBlocks.map((processedBlock, idx) => ({
            ...processedBlock,
            seq: idx + 1,
          }))
        );
      }
    },
    [blocks]
  );

  const [blockFullscreen, setBlockFullscreen] = useState(undefined);
  const [showBlockFullscreenModal, setShowBlockFullscreenModal] =
    useState(false);
  const handleFullscreen = block => _ => {
    setBlockFullscreen(block);
    setShowBlockFullscreenModal(true);
  };
  const handleBlockEditCallback = newValue => {
    setValue(
      value.map(item => {
        if (item.id === newValue[0].id) {
          return newValue[0];
        }
        return item;
      })
    );
  };

  const renderElement = useCallback(
    (props, fullscreen = false) => {
      switch (props.element.type) {
        case BLOCK_TYPES.SQL:
          return (
            <CodeBlock
              blocks={value}
              runAllFromBlock={runAllFromBlock}
              toggleSqlVisibility={toggleSqlVisibility}
              toggleBlockVisibility={toggleBlockVisibility}
              workbook={{
                name: workbook.name,
                is_example: workbook.is_example,
              }}
              {...props}
            />
          );
        case BLOCK_TYPES.MAP:
          return (
            <MapBlock
              blocks={value}
              runId={runIdMap[props.element.id]}
              toggleBlockVisibility={toggleBlockVisibility}
              handleFullscreen={handleFullscreen(props.element)}
              fullscreen={fullscreen}
              {...props}
            />
          );
        case BLOCK_TYPES.TEXT:
          return (
            <TextBlock
              blocks={value}
              {...props}
              toggleBlockVisibility={toggleBlockVisibility}
            />
          );
        case BLOCK_TYPES.IMAGE:
          return props.element.id ? (
            <ImageBlock
              blocks={value}
              toggleBlockVisibility={toggleBlockVisibility}
              {...props}
            />
          ) : (
            <></>
          );
        case BLOCK_TYPES.HTML:
          return props.element.id ? (
            <HtmlBlock
              blocks={value}
              toggleBlockVisibility={toggleBlockVisibility}
              {...props}
            />
          ) : (
            <></>
          );
        default:
          return <ErrorBlock blocks={value} {...props} />;
      }
    },
    [
      value,
      runAllFromBlock,
      runIdMap,
      toggleSqlVisibility,
      toggleBlockVisibility,
      workbook,
    ]
  );

  const renderElementFullscreen = props => renderElement(props, true);

  const handleSlateChange = useCallback(
    newValue => {
      // Check for deletion and delete from db
      value
        .map((block, idx, blocks) => {
          const previous_block_id = idx > 0 ? blocks[idx - 1].id : null;
          const next_block_id =
            idx < blocks.length - 1 ? blocks[idx + 1].id : null;
          return {
            ...block,
            previous_block_id,
            next_block_id,
          };
        })
        .filter(prevBlock => {
          return (
            prevBlock.id &&
            !newValue.some(newBlock => newBlock.id === prevBlock.id)
          );
        })
        .forEach(block => {
          const { id, previous_block_id, next_block_id } = block;
          removeBlock({
            variables: {
              id,
            },
          }).then(resp => {
            if (previous_block_id) {
              updateBlock({
                variables: {
                  id: previous_block_id,
                  next_block_id: next_block_id || null,
                },
              }).then(resp => {
                // Do nothing for now
              });
            }
            if (next_block_id) {
              updateBlock({
                variables: {
                  id: next_block_id,
                  previous_block_id: previous_block_id || null,
                },
              }).then(resp => {
                // Do nothing for now
              });
            }
          });
        });

      newValue.forEach((block, idx, blocks) => {
        const prevBlockId = idx > 0 ? blocks[idx - 1].id : null;
        const nextBlockId = idx < blocks.length - 1 ? blocks[idx + 1].id : null;
        const content = JSON.stringify(block.content);
        if (block.id) {
          // Find block in old state
          const oldBlockIndex = value.findIndex(prevBlock => {
            return prevBlock.id === block.id;
          });
          const oldBlock = oldBlockIndex > -1 ? value[oldBlockIndex] : null;
          const oldPrevBlockId =
            oldBlockIndex > 0 ? value[oldBlockIndex - 1].id : null;
          const oldNextBlockId =
            oldBlockIndex < value.length - 1
              ? value[oldBlockIndex + 1].id
              : null;
          const oldContent = oldBlock ? JSON.stringify(oldBlock.content) : null;
          let needsUpdate = false;
          const updatedBlock = {
            id: block.id,
          };

          if (oldPrevBlockId !== prevBlockId) {
            updatedBlock.previous_block_id = prevBlockId;
            needsUpdate = true;
          }

          if (oldNextBlockId !== nextBlockId) {
            updatedBlock.next_block_id = nextBlockId;
            needsUpdate = true;
          }

          if (needsUpdate) {
            updateBlock({
              variables: updatedBlock,
            });
          }

          // Content updates are handled separately.
          if (oldBlock && content !== oldContent) {
            updateContent(block.id, content);
          }
        } else {
          createBlock({
            variables: {
              name: 'Block',
              description: 'Description for Block',
              content,
              config: {},
              previous_block_id: prevBlockId,
              next_block_id: nextBlockId,
              block_type_id: block.type,
              worksheet_id: worksheetId,
            },
          }).then(resp => {
            const createdId = resp.data.blockCreate.id;
            const path = ReactEditor.findPath(editor, block);
            Transforms.setNodes(editor, { id: createdId }, { at: path });

            const update = {};
            if (prevBlockId) {
              update.id = prevBlockId;
              update.next_block_id = createdId;
            } else {
              update.id = nextBlockId;
              update.previous_block_id = createdId;
            }

            // If image block add viz
            if (block.type === BLOCK_TYPES.IMAGE) {
              const { IMAGE: visualization_type_id } = VIZ_TYPES;
              createVisualization({
                variables: {
                  name: 'Image Visualization',
                  description: 'Description for image visualization',
                  config: { type: 'image' },
                  block_id: createdId,
                  visualization_type_id,
                },
              }).then(resp => {
                const { id: visualization_id } =
                  resp?.data?.visualizationCreate;
                updateBlock({
                  variables: {
                    ...update,
                    visualization_id,
                  },
                });
              });
            } else if (block.type === BLOCK_TYPES.HTML) {
              const { HTML: visualization_type_id } = VIZ_TYPES;
              createVisualization({
                variables: {
                  name: 'HTML Visualization',
                  description: 'Description for html visualization',
                  config: { type: 'html' },
                  block_id: createdId,
                  visualization_type_id,
                },
              }).then(resp => {
                const { id: visualization_id } =
                  resp?.data?.visualizationCreate;
                updateBlock({
                  variables: {
                    ...update,
                    visualization_id,
                  },
                });
              });
            } else {
              updateBlock({
                variables: update,
              });
            }
          });
        }
      });
      setValue(newValue);

      // Only need to refetch blocks if blocks were added or removed
      if (value.length !== newValue.length) {
        refetchBlocks();
      }
    },
    [
      editor,
      worksheetId,
      updateBlock,
      createBlock,
      removeBlock,
      createVisualization,
      value,
      updateContent,
      refetchBlocks,
    ]
  );

  const handleMoveWorksheetUp = _ => {
    moveWorksheetUp(worksheetId);
  };

  const handleMoveWorksheetDown = _ => {
    moveWorksheetDown(worksheetId);
  };

  const handleEditWorksheet = _ => {
    setShowWorksheetEditModal(true);
  };

  const handleWorksheetEditCallback = (err, resp) => {
    if (resp) {
      setShowWorksheetEditModal(false);
    } else {
      console.error(err);
    }
  };

  const handleCopyThisWorkbook = useCallback(
    _ => {
      copyToThisWorkbook(worksheet.id);
    },
    [worksheet, copyToThisWorkbook]
  );

  const handleCopyAnotherWorkbook = useCallback(
    _ => {
      copyToAnotherWorkbook(worksheet.id);
    },
    [worksheet, copyToAnotherWorkbook]
  );

  // const handleAutoRefresh = useCallback(
  //   time => _ => {
  //     setAutoRefresh(worksheetId, time);
  //   },
  //   [worksheetId, setAutoRefresh]
  // );

  const copyMenu = useMemo(
    _ => (
      <Menu>
        <Menu.Item
          key="this"
          onClick={handleCopyThisWorkbook}
          disabled={readOnly}
        >
          To This Workbook
        </Menu.Item>
        <Menu.Item key="another" onClick={handleCopyAnotherWorkbook}>
          To Another Workbook
        </Menu.Item>
      </Menu>
    ),
    [handleCopyThisWorkbook, handleCopyAnotherWorkbook, readOnly]
  );

  // const refreshMenu = useMemo(
  //   _ => (
  //     <Menu selectedKeys={[`${worksheet?.config?.autoRefreshInterval ?? 0}`]}>
  //       <Menu.Item key={0} onClick={handleAutoRefresh(0)}>
  //         Off
  //       </Menu.Item>
  //       <Menu.Item key={5} onClick={handleAutoRefresh(5)}>
  //         5 s
  //       </Menu.Item>
  //       <Menu.Item key={10} onClick={handleAutoRefresh(10)}>
  //         10 s
  //       </Menu.Item>
  //       <Menu.Item key={15} onClick={handleAutoRefresh(15)}>
  //         15 s
  //       </Menu.Item>
  //       <Menu.Item key={30} onClick={handleAutoRefresh(30)}>
  //         30 s
  //       </Menu.Item>
  //       <Menu.Item key={60} onClick={handleAutoRefresh(60)}>
  //         60 s
  //       </Menu.Item>
  //     </Menu>
  //   ),
  //   [worksheet, handleAutoRefresh]
  // );

  const addSQLBlock = useCallback(
    _ => {
      Transforms.insertNodes(
        editor,
        {
          type: BLOCK_TYPES.SQL,
          content: '',
          children: [{ text: '' }],
        },
        { at: [value.length] }
      );
      setTimeout(_ => {
        if (worksheetBottomRef.current) {
          worksheetBottomRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 50);
    },
    [editor, value]
  );

  const addTextBlock = useCallback(
    _ => {
      Transforms.insertNodes(
        editor,
        {
          type: BLOCK_TYPES.TEXT,
          content: [{ type: 'paragraph', children: [{ text: '' }] }],
          children: [{ text: '' }],
        },
        { at: [value.length] }
      );
      setTimeout(_ => {
        if (worksheetBottomRef.current) {
          worksheetBottomRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 50);
    },
    [editor, value]
  );

  const addImageBlock = useCallback(
    _ => {
      Transforms.insertNodes(
        editor,
        {
          type: BLOCK_TYPES.IMAGE,
          content: {},
          children: [{ text: '' }],
        },
        { at: [value.length] }
      );
      setTimeout(_ => {
        if (worksheetBottomRef.current) {
          worksheetBottomRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 50);
    },
    [editor, value]
  );

  const addHtmlBlock = useCallback(
    _ => {
      Transforms.insertNodes(
        editor,
        {
          type: BLOCK_TYPES.HTML,
          content: {},
          children: [{ text: '' }],
        },
        { at: [value.length] }
      );
      setTimeout(_ => {
        if (worksheetBottomRef.current) {
          worksheetBottomRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 50);
    },
    [editor, value]
  );

  const addMapBlock = useCallback(
    _ => {
      Transforms.insertNodes(
        editor,
        {
          type: BLOCK_TYPES.MAP,
          content: {},
          children: [{ text: '' }],
        },
        { at: [value.length] }
      );
      setTimeout(_ => {
        if (worksheetBottomRef.current) {
          worksheetBottomRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 50);
    },
    [editor, value]
  );

  const canReportPerf = useMemo(
    _ => {
      return blocks
        ? blocks.some(block => {
            return queryResults[block.id]?.queryResponse?.responses.length > 0;
          })
        : false;
    },
    [queryResults, blocks]
  );

  const addMenu = (
    <Menu>
      <Menu.Item key="sql" onClick={addSQLBlock}>
        <CodeOutlined style={{ marginRight: '10px' }} />
        SQL
      </Menu.Item>
      <Menu.Item key="text" onClick={addTextBlock}>
        <FileTextOutlined style={{ marginRight: '10px' }} />
        Text
      </Menu.Item>
      <Menu.Item key="image" onClick={addImageBlock}>
        <FileImageOutlined style={{ marginRight: '10px' }} />
        Image
      </Menu.Item>
      <Menu.Item key="html" onClick={addHtmlBlock}>
        <Html5Outlined style={{ marginRight: '10px' }} />
        HTML
      </Menu.Item>
      <Menu.Item key="map" onClick={addMapBlock}>
        <GlobalOutlined style={{ marginRight: '10px' }} />
        Map
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="worksheet_editor">
      <div
        style={{
          marginBottom: '10px',
          top: '0px',
          left: '0px',
          padding: '0px',
          zIndex: 2,
          textAlign: 'right',
        }}
      >
        <Tag
          icon={<SyncOutlined spin />}
          style={{
            float: 'left',
            borderWidth: '0px',
            borderRadius: '4px',
            fontSize: '14px',
            backgroundColor: '#eb2f9611',
            color: '#eb2f96',
            padding: '2px 8px',
          }}
          visible={runningAll}
        >
          Running All
        </Tag>
        <Space>
          {!readOnly && (
            <>
              <Tooltip title="Move Sheet Up">
                <Button
                  icon={<LeftOutlined />}
                  onClick={handleMoveWorksheetUp}
                  disabled={worksheet && !worksheet.previous_worksheet_id}
                  size="small"
                />
              </Tooltip>
              <Tooltip title="Move Sheet Down">
                <Button
                  icon={<RightOutlined />}
                  onClick={handleMoveWorksheetDown}
                  disabled={worksheet && !worksheet.next_worksheet_id}
                  size="small"
                />
              </Tooltip>
              <Tooltip title="Edit Worksheet">
                <Button
                  icon={<EditOutlined />}
                  onClick={handleEditWorksheet}
                  size="small"
                />
              </Tooltip>
            </>
          )}
          {!embed && (
            <>
              <Tooltip title="Copy Worksheet">
                <Dropdown overlay={copyMenu} trigger={['click']}>
                  <Button icon={<CopyOutlined />} size="small" />
                </Dropdown>
              </Tooltip>
              <Divider type="vertical" />
            </>
          )}
          {!readOnly && (
            <>
              <Dropdown overlay={addMenu} trigger={['click']}>
                <Button icon={<PlusOutlined />} size="small">
                  Add New Block
                </Button>
              </Dropdown>
              <Divider type="vertical" />
            </>
          )}
          {/* <Tooltip title="Auto-Refresh">
            <Dropdown overlay={refreshMenu} trigger={['click']}>
              <Button
                icon={<ClockCircleOutlined />}
                style={
                  (worksheet?.config?.autoRefreshInterval ?? 0) > 0
                    ? {
                        width: '50px',
                        color: '#eb2f96',
                        border: '1px solid #eb2f96',
                      }
                    : { width: '50px', textAlign: 'center' }
                }
                size="small"
              >
                {(worksheet?.config?.autoRefreshInterval ?? 0) > 0 ? (
                  <label style={{ fontSize: '12px', marginLeft: '5px' }}>
                    {worksheet?.config?.autoRefreshInterval}
                  </label>
                ) : (
                  ''
                )}
              </Button>
            </Dropdown>
          </Tooltip> */}
          <Tooltip
            title={
              canReportPerf
                ? 'Download Performance Report'
                : 'Run at least one query to Download Performance Report'
            }
          >
            <Button
              onClick={showPerfReport}
              icon={<ClockCircleOutlined />}
              size="small"
              disabled={!canReportPerf}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Clear All Query Results">
            <Button
              onClick={clearAllResults}
              icon={<ClearOutlined />}
              size="small"
              loading={clearingAll}
              disabled={runningAll}
            >
              Clear
            </Button>
          </Tooltip>
          {runningAll && (
            <Popconfirm
              title="Are you sure you want to cancel all remaining queries?"
              onConfirm={cancelRunAll}
              placement="topRight"
            >
              <Button
                icon={<CloseOutlined />}
                size="small"
                style={{ width: '105px' }}
                danger
              >
                Cancel All
              </Button>
            </Popconfirm>
          )}
          {!runningAll && (
            <Tooltip title="Run All Blocks">
              <Button
                type="primary"
                onClick={runAllBlocks}
                icon={<DoubleRightOutlined />}
                size="small"
                style={{ width: '105px' }}
                ghost
              >
                Run All
              </Button>
            </Tooltip>
          )}
        </Space>
      </div>
      <div
        id="worksheet-container"
        style={{
          width: 'calc(100% + 10px)',
          height: embed ? 'calc(100vh - 140px)' : 'calc(100vh - 325px)',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        {!embed && (
          <Anchor
            getContainer={() => document.querySelector('#worksheet-container')}
            bounds={20}
            targetOffset={5}
            style={{
              marginTop: '15px',
              width: '70px',
              marginRight: '0px',
              float: 'right',
              maxHeight: 'calc(100vh - 330px)',
            }}
          >
            {value.map((block, idx) => {
              const result = results[block.id] ?? { queryError: null };
              const hasError =
                result.queryError !== undefined && result.queryError !== null;
              return (
                <Link
                  key={idx}
                  href={`#block-anchor-${idx + 1}`}
                  title={
                    <span
                      style={{
                        color:
                          block.id === currentRunningId ? '#eb2f96' : '#000000',
                        opacity: 0.85,
                        fontWeight:
                          block.id === currentRunningId ? 'bold' : 'normal',
                      }}
                    >
                      {hasError && (
                        <WarningOutlined
                          style={{
                            fontSize: '18px',
                            float: 'left',
                            position: 'absolute',
                            marginLeft: '20px',
                            marginTop: '2px',
                            color: '#ff0000',
                          }}
                        />
                      )}
                      {block.id === currentRunningId && (
                        <SyncOutlined
                          style={{
                            fontSize: '12px',
                            float: 'left',
                            position: 'absolute',
                            marginLeft: '22px',
                            marginTop: '6px',
                            color: '#eb2f96',
                          }}
                          spin
                        />
                      )}
                      {hasError ? (
                        <span style={{ color: '#ff0000', fontWeight: 'bold' }}>
                          {idx + 1}
                        </span>
                      ) : (
                        <>{idx + 1}</>
                      )}
                    </span>
                  }
                />
              );
            })}
          </Anchor>
        )}
        <div id="block-anchor-1" style={{ height: '5px' }}></div>
        <div
          style={{
            width: embed ? 'calc(100% - 10px)' : 'calc(100% - 75px)',
            float: 'left',
          }}
        >
          <Slate editor={editor} value={value} onChange={handleSlateChange}>
            <Editable
              readOnly={true}
              renderElement={renderElement}
              style={{ pointerEvents: 'none' }}
            ></Editable>
            <div ref={worksheetBottomRef}></div>
          </Slate>
        </div>
      </div>
      {worksheet && (
        <WorksheetEditModal
          worksheet={worksheet}
          visible={showWorksheetEditModal}
          close={_ => {
            setShowWorksheetEditModal(false);
          }}
          callback={handleWorksheetEditCallback}
        />
      )}
      {blockFullscreen && (
        <BlockFullscreenModal
          renderElementFullscreen={renderElementFullscreen}
          block={blockFullscreen}
          updateContent={updateContent}
          visible={showBlockFullscreenModal}
          close={_ => {
            setBlockFullscreen(undefined);
            setShowBlockFullscreenModal(false);
          }}
          width={window.innerWidth - 100}
          height={window.innerHeight - 100}
          callback={handleBlockEditCallback}
        />
      )}
    </div>
  );
};

export default WorksheetEditor;
