// Imports
import React from 'react';
import { CloseOutlined, CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, message } from 'antd';

// App Imports

const ResultError = ({ clearResult, queryError }) => {
  return (
    <pre
      className="console error"
      style={{
        margin: '10px 0px 0px',
        borderRadius: '5px',
        padding: '10px 5px 10px 15px',
        color: '#B6818B',
        fontWeight: 200,
        lineHeight: '18px',
      }}
    >
      <Button
        type="text"
        icon={<CloseOutlined></CloseOutlined>}
        onClick={clearResult}
        style={{
          float: 'right',
          height: '24px',
          width: '24px',
          lineHeight: '20px',
          top: '-3px',
          color: '#B6818B',
        }}
      ></Button>
      {queryError?.message}
      <CopyToClipboard
        text={queryError?.message}
        onCopy={() => {
          message.success('Copied to clipboard!');
        }}
      >
        <span
          style={{ color: '#888888', marginLeft: '10px', cursor: 'pointer' }}
        >
          <CopyOutlined style={{ marginRight: '5px' }} />
          Copy
        </span>
      </CopyToClipboard>
    </pre>
  );
};

export default ResultError;
