// Imports
import { useMemo, useState } from 'react';
import { Row, Col, Tag, Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import WorkbookCreateModal from '../../components/modal/WorkbookCreateModal';
import { NAV_ROUTE_DATAEXPLORE_WORKBOOK } from '../../constants';
import WorkbookExample from '../../images/workbook_example.png';
import GraphShortestPath from '../../images/workbooks/graph_shortest_path.png';
import OptimalChargingStation from '../../images/workbooks/optimal_charging_station.png';
import QuickStartGuide from '../../images/workbooks/quick_start_guide.png';
import RealTimeFinancialRisk from '../../images/workbooks/real_time_financial_risk.png';
import RealTimeTruckMonitoring from '../../images/workbooks/real_time_truck_monitoring.png';
import RealTimeEntityTracking from '../../images/workbooks/entity_tracking.png';
import Background from '../../images/wallpaper.jpg';
import BackgroundClean from '../../images/wallpaper_clean.jpg';

const Workbooks = () => {
  const { data: { workbooks = [] } = {}, refetch: refetchWorkbooks } =
    GraphQLServices.Workbooks.useGetWorkbooks();

  const [showWorkbookCreateModal, setShowWorkbookCreateModal] = useState(false);

  const analytics = useAnalytics();
  const history = useHistory();

  const handleSampleWorkbook = workbook => e => {
    if (workbook.is_example) {
      analytics.track(analytics.EVENT_TYPES.OPENED_EXAMPLE_WORKBOOK)({
        title: workbook.name,
      });
    }
    history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${workbook.id}`);
  };

  const handleWorkbookCreateCallback = (err, resp) => {
    if (resp) {
      const block = resp?.data?.blockCreate;
      const { worksheet_id } = block;
      refetchWorkbooks().then(resp => {
        const currentWorkbook = resp?.data?.workbooks.find(workbook =>
          workbook.worksheets.find(worksheet => worksheet.id === worksheet_id)
        );
        setShowWorkbookCreateModal(false);

        analytics.track(analytics.EVENT_TYPES.CREATED_WORKBOOK)({});

        history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${currentWorkbook.id}`);
      });
    } else {
      console.error(err);
    }
  };

  const examples = useMemo(() => {
    if (workbooks) {
      // See if there is a quick start workbook
      const quickstart = workbooks.find(
        workbook =>
          workbook.name.toLowerCase() === 'quick start guide' &&
          workbook.is_example
      );
      if (quickstart) {
        const rest = workbooks.filter(
          workbook =>
            workbook.name.toLowerCase() !== 'quick start guide' &&
            workbook.is_example
        );
        return [quickstart, ...rest];
      }
      return workbooks.filter(workbook => workbook.is_example);
    }
    return [];
  }, [workbooks]);

  const recent = useMemo(() => {
    return workbooks
      ? workbooks
          .filter(workbook => !workbook.is_example)
          .slice(0, 5)
          .sort((wb1, wb2) => {
            if (wb1.last_update < wb2.last_update) return 1;
            if (wb1.last_update > wb2.last_update) return -1;
            return 0;
          })
      : [];
  }, [workbooks]);

  const getCoverImage = workbook => {
    const { name, metadata } = workbook;
    const key =
      metadata?.legacy_name ||
      name.toLowerCase().replace(/ /g, '_').replace(/-/g, '_');
    switch (key) {
      case 'graph_shortest_path': {
        return GraphShortestPath;
      }
      case 'optimal_charging_station': {
        return OptimalChargingStation;
      }
      case 'quick_start_guide': {
        return QuickStartGuide;
      }
      case 'real_time_financial_risk': {
        return RealTimeFinancialRisk;
      }
      case 'real_time_truck_monitoring': {
        return RealTimeTruckMonitoring;
      }
      case 'real_time_entity_tracking': {
        return RealTimeEntityTracking;
      }
      default: {
        return WorkbookExample;
      }
    }
  };

  return (
    <div
      style={{
        padding: '20px 30px 10px',
        backgroundColor: '#ffffff',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        borderRadius: 10,
        width: '100%',
      }}
    >
      <Row gutter={0}>
        {recent.length === 0 ? (
          <Col span={18}>
            <h3
              style={{
                fontSize: '30px',
                color: '#3700b399',
                marginBottom: 0,
              }}
            >
              Getting Started
            </h3>
            <h3
              style={{
                color: '#3700b388',
                marginBottom: 20,
                fontWeight: 300,
              }}
            >
              Learn more about Kinetica's real-time analytic capabilities by
              trying the following sample workbooks!
            </h3>
            {examples.map(workbook => {
              return (
                <Popover
                  key={workbook.id}
                  title={workbook.name}
                  content={
                    workbook.metadata?.example_id && (
                      <div style={{ width: 320 }}>
                        <p
                          style={{
                            fontSize: '12px',
                            display: 'block',
                            fontWeight: 200,
                            textAlign: 'justify',
                          }}
                        >
                          {workbook.description}
                        </p>
                        <div>
                          {workbook.metadata?.features.map(feature => (
                            <Tag
                              key={feature}
                              color="blue"
                              style={{ margin: 2 }}
                            >
                              {feature}
                            </Tag>
                          ))}
                        </div>
                        <i style={{ fontSize: '12px', fontWeight: 200 }}>
                          Approx. Time: {workbook.metadata?.approx_time}
                        </i>
                      </div>
                    )
                  }
                >
                  <div
                    className="example-workbook"
                    onClick={handleSampleWorkbook(workbook)}
                    style={{
                      borderRadius: 5,
                      backgroundColor: '#f6f6f6',
                      width: '255px',
                      margin: '0px 20px 20px 0px',
                      float: 'left',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                    <div
                      style={{
                        height: 120,
                        overflow: 'hidden',
                        padding: 10,
                        textAlign: 'center',
                        backgroundImage: `url(${BackgroundClean})`,
                        backgroundSize: 'cover',
                      }}
                    >
                      <img
                        src={getCoverImage(workbook)}
                        alt="Workbook Example"
                        style={{ height: 100 }}
                      />
                    </div>
                    <div
                      style={{
                        padding: '10px 20px',
                        backgroundColor: '#ffffff',
                        color: '#3700b399',
                        textAlign: 'center',
                      }}
                    >
                      {workbook.name}
                    </div>
                  </div>
                </Popover>
              );
            })}
            <div
              key="create"
              className="example-workbook"
              onClick={_ => setShowWorkbookCreateModal(true)}
              style={{
                borderRadius: 5,
                backgroundColor: '#ffffff',
                width: '255px',
                margin: '0px 20px 20px 0px',
                float: 'left',
                overflow: 'hidden',
                cursor: 'pointer',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <PlusOutlined
                  style={{
                    fontSize: 50,
                    color: '#3700b399',
                    margin: 35,
                    opacity: 0.5,
                  }}
                />
              </div>
              <div
                style={{
                  padding: '10px 20px',
                  color: '#3700b399',
                  textAlign: 'center',
                }}
              >
                Create New Workbook
              </div>
            </div>
          </Col>
        ) : (
          <Col span={18}>
            <h3
              style={{
                fontSize: '30px',
                color: '#3700b399',
                marginBottom: 10,
              }}
            >
              Recent Workbooks
            </h3>
            {recent.map(workbook => {
              return (
                <div
                  key={workbook.id}
                  className="example-workbook"
                  onClick={handleSampleWorkbook(workbook)}
                  style={{
                    borderRadius: 5,
                    backgroundColor: '#ffffff',
                    margin: '0px 20px 20px 0px',
                    padding: '10px 20px',
                    float: 'left',
                    cursor: 'pointer',
                  }}
                >
                  <div
                    style={{
                      display: '-webkit-box',
                      color: '#3700b399',
                      textAlign: 'center',
                      width: '215px',
                      height: '44px',
                      overflow: 'hidden',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {workbook.name}
                  </div>
                </div>
              );
            })}
            <div
              key="create"
              className="example-workbook"
              onClick={_ => setShowWorkbookCreateModal(true)}
              style={{
                borderRadius: 5,
                backgroundColor: '#ffffff',
                margin: '0px 20px 20px 0px',
                padding: '10px 20px',
                float: 'left',
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  display: '-webkit-box',
                  color: '#3700b399',
                  textAlign: 'center',
                  width: '215px',
                  height: '44px',
                  overflow: 'hidden',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                <PlusOutlined /> Create New Workbook
              </div>
            </div>
            <div style={{ clear: 'both' }}></div>
            <h3
              style={{
                fontSize: '22px',
                color: '#3700b399',
                marginBottom: 10,
              }}
            >
              Getting Started
            </h3>
            {examples.map(workbook => {
              return (
                <Popover
                  key={workbook.id}
                  title={workbook.name}
                  content={
                    workbook.metadata?.example_id && (
                      <div style={{ width: 320 }}>
                        <p
                          style={{
                            fontSize: '12px',
                            display: 'block',
                            fontWeight: 200,
                            textAlign: 'justify',
                          }}
                        >
                          {workbook.description}
                        </p>
                        <div>
                          {workbook.metadata?.features.map(feature => (
                            <Tag
                              key={feature}
                              color="blue"
                              style={{ margin: 2 }}
                            >
                              {feature}
                            </Tag>
                          ))}
                        </div>
                        <i style={{ fontSize: '12px', fontWeight: 200 }}>
                          Approx. Time: {workbook.metadata?.approx_time}
                        </i>
                      </div>
                    )
                  }
                >
                  <div
                    className="example-workbook"
                    onClick={handleSampleWorkbook(workbook)}
                    style={{
                      borderRadius: 5,
                      backgroundColor: '#f6f6f6',
                      width: '255px',
                      margin: '0px 20px 20px 0px',
                      float: 'left',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                    <div
                      style={{
                        height: 120,
                        overflow: 'hidden',
                        padding: 10,
                        textAlign: 'center',
                        backgroundImage: `url(${BackgroundClean})`,
                        backgroundSize: 'cover',
                      }}
                    >
                      <img
                        src={getCoverImage(workbook)}
                        alt="Workbook Example"
                        style={{ height: 100 }}
                      />
                    </div>
                    <div
                      style={{
                        padding: '10px 20px',
                        backgroundColor: '#ffffff',
                        color: '#3700b399',
                        textAlign: 'center',
                      }}
                    >
                      {workbook.name}
                    </div>
                  </div>
                </Popover>
              );
            })}
          </Col>
        )}
      </Row>
      <WorkbookCreateModal
        visible={showWorkbookCreateModal}
        close={_ => {
          setShowWorkbookCreateModal(false);
        }}
        callback={handleWorkbookCreateCallback}
      />
    </div>
  );
};

export default Workbooks;
