// Improts
import React, { useState, useMemo } from 'react';
import { Col, Form, Input, Row, Select, Checkbox, Modal, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// App Imports
import { DEFAULT_FORM_ITEM_PROPS } from './utils';
import GraphQLServices from '../../graphql/services';
import DataSourceFileBrowserModal from '../../components/modal/DatasourceFileBrowserModal';

const { Option, OptGroup } = Select;

export default function SourceStep({ form, showDatasource, cdata_types = [] }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => setIsModalVisible(!isModalVisible);

  const { loading: objectsLoading, data } =
    GraphQLServices.DataObjects.useGetDataObjects();

  const datasources = useMemo(
    _ => {
      if (objectsLoading || !data?.datasources) {
        return [];
      } else {
        return data.datasources.map(datasource => ({
          label: datasource.datasource_name,
          value: datasource.datasource_name,
          datasource,
        }));
      }
    },
    [objectsLoading, data]
  );

  const openDelimiterHelp = _ => {
    Modal.info({
      title: 'Help',
      content: (
        <div>
          <p style={{ marginBottom: '10px' }}>
            The default delimiter is a comma, unless a source file has one of
            these extensions:
          </p>
          <ul style={{ paddingLeft: '20px' }}>
            <li>.psv - will cause | to be the delimiter</li>
            <li>
              .tsv - will cause the tab character to be the delimiter{' '}
              <i>
                (If your file is not .tsv and you want to specify a tab, enter
                \t)
              </i>
            </li>
          </ul>
        </div>
      ),
      onOk() {},
    });
  };

  // Check if CDATA type
  const isCDATA = cdata_types.includes(form.getFieldValue('type'));

  const isJdbc = form.getFieldValue('type') === 'jdbc' || isCDATA;
  const isKafka = form.getFieldValue('type') === 'kafka';
  const isKifs = form.getFieldValue('type') === 'kifs';
  const isKifsCustom =
    form.getFieldValue('filePath') &&
    !form.getFieldValue('filePath').includes('.');
  const isUpload = form.getFieldValue('type') === 'upload';

  const isUploadMulti = form.getFieldValue('filePaths')
    ? form.getFieldValue('filePaths').length > 1
    : false;

  const isBrowsable = !(
    (isKifs && !isKifsCustom) ||
    (isUpload && !isUploadMulti)
  );

  return (
    <div>
      <Row>
        <Col span={20} offset={4}>
          <p>Configure the options for the data file to be imported.</p>
        </Col>
      </Row>
      <Form layout="horizontal" form={form}>
        <Form.Item name="type" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          name="dataSource"
          label="Source"
          initialValue=""
          {...DEFAULT_FORM_ITEM_PROPS}
        >
          <Select loading={objectsLoading} disabled={!showDatasource}>
            <Option value="">Kinetica Filesystem</Option>
            {datasources.length > 0 && (
              <OptGroup label="Data Sources">
                {datasources.map(datasource => (
                  <Option key={datasource.value} value={datasource.value}>
                    {datasource.label}
                  </Option>
                ))}
              </OptGroup>
            )}
          </Select>
        </Form.Item>
        {!isJdbc && !isKafka && (
          <Form.Item
            name="filePath"
            label="File Path"
            rules={[
              {
                required: false,
                message:
                  'Please enter a data source file path to the data you want to import.',
              },
            ]}
            {...DEFAULT_FORM_ITEM_PROPS}
          >
            <Input.Group compact>
              <Input
                value={form.getFieldValue('filePath')}
                style={isBrowsable ? { width: 'calc(100% - 91.2px)' } : {}}
                disabled={!isBrowsable}
              />
              {isBrowsable ? (
                <>
                  <Button type="primary" onClick={toggleModal}>
                    Browse...
                  </Button>
                  <DataSourceFileBrowserModal
                    visible={isModalVisible}
                    toggleFn={toggleModal}
                    form={form}
                  />
                </>
              ) : null}
            </Input.Group>
          </Form.Item>
        )}
        {!isJdbc && (
          <Form.Item
            name="fileFormat"
            label="Format"
            rules={[
              {
                required: !isKafka,
                message: 'Please select a format for the data.',
              },
            ]}
            {...DEFAULT_FORM_ITEM_PROPS}
          >
            <Select disabled={isKafka}>
              <Option value="TEXT">CSV/TSV/PSV</Option>
              <Option value="PARQUET">Parquet</Option>
              <Option value="SHAPEFILE">Shapefile</Option>
              <Option value="JSON">JSON</Option>
              <Option value="AVRO">AVRO</Option>
            </Select>
          </Form.Item>
        )}
        {isJdbc && (
          <Form.Item
            name="remoteQuery"
            label="Remote Query"
            rules={[
              {
                required: true,
                message:
                  'Please specify a SQL Query to select data from data source.',
              },
            ]}
            {...DEFAULT_FORM_ITEM_PROPS}
          >
            <Input placeholder="E,g. SELECT * FROM <remote_table>" />
          </Form.Item>
        )}
        <Form.Item
          shouldUpdate={(prevValues, values) =>
            prevValues.fileFormat !== values.fileFormat
          }
          style={{ marginBottom: '0px' }}
        >
          {_ => {
            const values = form.getFieldsValue();
            const { fileFormat } = values;
            return (
              <div>
                {fileFormat === 'TEXT' && (
                  <div>
                    <Row gutter={0}>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'DELIMITER']}
                          label="Delimiter"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 14 }}
                        >
                          <Input
                            maxLength={4}
                            addonAfter={
                              <InfoCircleOutlined onClick={openDelimiterHelp} />
                            }
                          ></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'ESCAPE']}
                          label="Escape Character"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 12 }}
                        >
                          <Input maxLength={4}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'COMMENT']}
                          label="Comment Identifier"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 14 }}
                          initialValue={'#'}
                        >
                          <Input maxLength={4}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'QUOTE']}
                          label="Quote Character"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 12 }}
                          initialValue={'"'}
                        >
                          <Input maxLength={4}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'NULL']}
                          label="Null Identifier"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 14 }}
                          initialValue={'\\N'}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'INCLUDES HEADER']}
                          label="Has Header"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 12 }}
                          valuePropName="checked"
                          colon={false}
                          initialValue={true}
                        >
                          <Checkbox></Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}
                {['JSON', 'SHAPEFILE'].includes(fileFormat) && (
                  <Row gutter={0}>
                    <Col span={12}>
                      <Form.Item
                        name="storePointsAsXY"
                        label="Store Points As X/Y"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        valuePropName="checked"
                      >
                        <Checkbox></Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </div>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
}
