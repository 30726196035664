// Imports
import React from 'react';
import { Dropdown, Menu } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

// App Imports
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const SchemaObject = ({
  nodeData,
  handleSchemaEdit,
  handleSchemaDelete,
  handleAddTableClick,
}) => {
  const tableContextMenu = node => {
    return (
      <Menu>
        <Menu.Item
          key="addtable"
          onClick={_ =>
            handleAddTableClick({ key: 'table', schema: node.source.name })
          }
        >
          <PlusOutlined /> Add New Table
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="rename" onClick={_ => handleSchemaEdit(node.source)}>
          <EditOutlined /> Rename
        </Menu.Item>
        <Menu.Item
          key="delete"
          onClick={_ => handleSchemaDelete(node.source)}
          style={{ color: '#C62828' }}
        >
          <DeleteOutlined /> Delete
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Dropdown
      overlay={tableContextMenu(nodeData)}
      trigger={['click', 'contextMenu']}
    >
      <div>
        <span
          style={{
            width: `${EXPLORER_PANEL_WIDTH - 80}px`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            lineHeight: '16px',
            verticalAlign: 'text-top',
          }}
        >
          {nodeData.title}{' '}
          {nodeData?.children && (
            <i
              style={{
                display: 'inline-block',
                fontSize: '12px',
                color: '#bbbbbb',
              }}
            >
              {nodeData?.children.length}
            </i>
          )}
        </span>
      </div>
    </Dropdown>
  );
};

export default SchemaObject;
