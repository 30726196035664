// Imports
import React from 'react';
import { Modal, Button, Spin } from 'antd';

// App Imports
import Spinner from '../common/Spinner';
import PlanGraph from '../../components/explain/PlanGraph';

const ExplainPlanModal = ({
  plan,
  visible,
  setVisible,
  loading,
  width,
  height,
  legend = false,
}) => {
  return (
    <Modal
      title="Explain Plan"
      visible={visible}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={loading}>
        <div
          style={{
            height: height - 40,
            overflow: 'hidden',
          }}
        >
          {plan ? plan?.error || <PlanGraph plan={plan} legend={legend} /> : ''}
        </div>
      </Spin>
    </Modal>
  );
};

export default ExplainPlanModal;
