// Imports
import React, { useCallback, useEffect, useMemo, useState } from 'react'; // useCallback
import { Button, Form, notification, Popconfirm, Steps } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import merge from 'lodash.merge';
import { useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import TableInfoForm from './TableInfoForm';
import ColumnDefinitionForm from './ColumnDefinitionForm';
import TableSummary from './TableSummary';
import { validObfuscateColumn, validMaskColumn } from './utils';
import ClientError from '../../lib/ClientError';
import { FREE_SAAS } from '../../setup/config';

const KINETICA_SUBTYPES = [
  'int8',
  'int16',
  'char1',
  'char2',
  'char4',
  'char8',
  'char16',
  'char32',
  'char64',
  'char128',
  'char256',
  'date',
  'datetime',
  'time',
  'ipv4',
  'decimal',
  'ulong',
  'uuid',
  'wkt',
  'timestamp',
];

const { Step } = Steps;

const STEPS = [
  {
    title: 'Name and Options',
    StepComponent: TableInfoForm,
  },
  {
    title: 'Column Definitions',
    StepComponent: ColumnDefinitionForm,
  },
  {
    title: 'Summary',
    StepComponent: TableSummary,
  },
];

function extractFields(columns) {
  return columns.map(col => ({
    name: col.columnName,
    type: col.nullable ? [col?.type?.[0], 'null'] : col?.type?.[0],
  }));
}

function extractProperties(columns) {
  const propertyMap = {};
  for (let col of columns) {
    const properties = [];

    if (col.storage) {
      properties.push(col.storage);
    }

    if (col.type && col.type.length > 1 && col.type[1]) {
      properties.push(col.type[1]);
    }

    if (
      col.type &&
      col.type.length > 1 &&
      col.type[0] === 'bytes' &&
      col.type[1] === ''
    ) {
      if (properties.indexOf('data') > -1) {
        properties.splice(properties.indexOf('data'), 1, 'store_only');
      } else {
        properties.push('store_only');
      }
    } else if (properties.indexOf('store_only') > -1) {
      properties.splice(properties.indexOf('store_only'), 1, 'data');
    }

    if (col.properties) {
      for (let prop of col.properties) {
        // Make sure to only add properties when applicable.
        // Check docs for most up to date information about what the
        // restrictions are.
        switch (prop) {
          case 'primary_key':
          case 'shard_key':
            if (col?.storage !== 'store_only') {
              properties.push(prop);
            }
            break;
          case 'disk_optimized':
            if (
              col?.storage === 'data' &&
              col?.type?.[0] === 'string' &&
              col?.type?.[1] === ''
            ) {
              properties.push(prop);
            }
            break;
          case 'dict':
            if (
              ((col?.type?.[0] === 'int' && col?.type?.[1] === '') ||
                col?.type?.[0] === 'long' ||
                col?.type?.[1] === 'date' ||
                (col?.type?.[1] || '').startsWith('char')) &&
              col?.storage !== 'store_only'
            ) {
              properties.push(prop);
            }
            break;
          case 'init_with_now':
            if (
              col?.type?.[0] === 'string' &&
              (col?.type?.[1] === 'date' ||
                col?.type?.[1] === 'datetime' ||
                col?.type?.[1] === 'time')
            ) {
              properties.push(prop);
            }
            break;
          case 'text_search':
            if (
              col?.type?.[0] === 'string' &&
              (col?.type?.[1] === '' ||
                (col?.type?.[1] || '').startsWith('char') ||
                col?.type?.[1] === 'date' ||
                col?.type?.[1] === 'datetime' ||
                col?.type?.[1] === 'time' ||
                col?.type?.[1] === 'ipv4' ||
                col?.type?.[1] === 'uuid')
            ) {
              properties.push(prop);
            }
            break;
          case 'init_with_uuid':
            if (col.type[1] === 'uuid') {
              properties.push(prop);
            }
            break;
          default:
            properties.push(prop);
            break;
        }
      }
    }

    if (col.nullable) {
      properties.push('nullable');
    } else {
      const index = properties.indexOf('nullable');
      if (index !== -1) {
        properties.splice(index, 1);
      }
    }

    propertyMap[col.columnName] = properties;
  }

  return propertyMap;
}

function genColumnAdditions(cols) {
  const adds = [];
  const fields = extractFields(cols);
  const props = extractProperties(cols);
  for (let col of cols) {
    const field = fields.find(f => f.name === col.columnName);
    adds.push({
      action: 'add_column',
      column_name: col.columnName,
      column_type: Array.isArray(field.type)
        ? field.type.join(',')
        : field.type,
      column_properties: props[col.columnName].join(','),
    });
  }

  return adds;
}

function genColumnRemovals(cols) {
  const removals = [];
  for (let col of cols) {
    removals.push({
      action: 'delete_column',
      column_name: col.columnName,
    });
  }

  return removals;
}

function genColumnChanges(cols, oldCols) {
  const changes = [];
  const fields = extractFields(cols);
  const props = extractProperties(cols);
  for (let col of cols) {
    const oldCol = oldCols.find(c => c.id === col.id);
    const field = fields.find(f => f.name === col.columnName);
    const hasRename = col.columnName !== oldCol.columnName;
    changes.push({
      action: 'change_column',
      column_name: hasRename ? oldCol.columnName : col.columnName,
      column_type: Array.isArray(field.type)
        ? field.type.join(',')
        : field.type,
      column_properties: props[col.columnName].join(','),
      ...(hasRename ? { rename_column: col.columnName } : {}),
    });
  }

  return changes;
}

function cleanOptions(options) {
  const cleanedOptions = {};
  Object.entries(options).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      if (typeof value !== 'string') {
        cleanedOptions[key] = String(value);
      } else {
        cleanedOptions[key] = value;
      }
    }
  });

  if (!cleanedOptions.partition_type) {
    delete cleanedOptions.partition_type;
    delete cleanedOptions.partition_keys;
    delete cleanedOptions.partition_definitions;
    delete cleanedOptions.is_automatic_partition;
  } else if (cleanedOptions.partition_type !== 'LIST') {
    delete cleanedOptions.is_automatic_partition;
  } else if (
    cleanedOptions.partition_type === 'LIST' &&
    cleanedOptions.is_automatic_partition === 'true'
  ) {
    delete cleanedOptions.partition_definitions;
  }

  return cleanedOptions;
}

function buildGrantPermissionSQL(
  username,
  qualifiedTableName,
  rowEnabled,
  rowFilter,
  columnPerms,
  columns
) {
  let statements = [];
  if (rowFilter && rowEnabled) {
    statements.push(
      `GRANT SELECT ON ${qualifiedTableName} TO ${username} WHERE ${rowFilter}`
    );
  }

  if (columnPerms) {
    Object.entries(columnPerms).forEach(([columnId, columnParams]) => {
      const column = columns.find(c => c.id === columnId);
      if (column && columnParams.enabled) {
        if (columnParams.transformType === 'obfuscate') {
          if (validObfuscateColumn(column.type)) {
            statements.push(
              `GRANT SELECT ON ${qualifiedTableName}(OBFUSCATE(${
                column.columnName
              })) TO ${username} ${
                columnParams.columnFilter
                  ? `WHERE ${columnParams.columnFilter}`
                  : ''
              }`
            );
          }
        } else if (columnParams.transformType === 'mask') {
          if (validMaskColumn(column.type)) {
            statements.push(
              `GRANT SELECT ON ${qualifiedTableName}(MASK(${
                column.columnName
              }, ${columnParams.maskStart}, ${columnParams.maskLength}, '${
                columnParams.maskCharacter
              }')) TO ${username} ${
                columnParams.columnFilter
                  ? `WHERE ${columnParams.columnFilter}`
                  : ''
              }`
            );
          }
        } else {
          statements.push(
            `GRANT SELECT ON ${qualifiedTableName}(${
              column.columnName
            }) TO ${username} ${
              columnParams.columnFilter
                ? `WHERE ${columnParams.columnFilter}`
                : ''
            }`
          );
        }
      }
    });
  }

  return statements;
}

const MASK_REGEX =
  /^\s*MASK\(\s*([a-zA-Z0-9_{}[\].:]+)\s*,\s*(\d+),\s*(\d+)\s*(?:,\s*['"](.)['"]\s*)?\)\s*$/;
const HASH_REGEX = /^\s*(?:HASH|OBFUSCATE)\(\s*([a-zA-Z0-9_{}[\].:]+)\s*\)\s*$/;

export default function CreateTableForm() {
  const { data: { userMe = {} } = {} } =
    GraphQLServices.Users.useGetUserMeKineticaOnly();
  const [createTable, { loading }] =
    GraphQLServices.Tables.useTableCreateTable();
  const [renameTable, { loading: isRenaming }] =
    GraphQLServices.Tables.useTableRenameTable();
  const [alterColumns, { loading: isAltering }] =
    GraphQLServices.Tables.useTableAlterColumns();
  const [executeSql] = GraphQLServices.SqlQueries.useExecuteSql();
  const { loading: usersLoading, data: usersData } =
    GraphQLServices.Users.useGetUsers();
  const { loading: rolesLoading, data: rolesData } =
    GraphQLServices.Roles.useGetRoles();

  const kineticaUsersAndRoles = useMemo(
    _ => {
      let users = [];
      if (usersLoading || !usersData.users) {
        users = [];
      } else {
        users = usersData.users.map(user => user?.kinetica_user);
      }

      let roles = [];
      if (rolesLoading || !rolesData.roles) {
        roles = [];
      } else {
        roles = rolesData.roles.map(role => role?.kinetica_role);
      }

      return users.concat(roles).filter(item => item != null);
    },
    [usersLoading, usersData, rolesLoading, rolesData]
  );

  const { refetch: refetchTables } =
    GraphQLServices.DataObjects.useGetDataTables();
  const history = useHistory();
  const graphqlClient = useApolloClient();
  const analytics = useAnalytics();

  const [step, setStep] = useState(0);
  const [columns, setColumns] = useState([]);
  const [oldColumns, setOldColumns] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [oldPermissions, setOldPermissions] = useState({});
  const [form] = Form.useForm();
  const { tableName, schemaName } = useParams();
  const [tableData, setTableData] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const isEditing = !!(tableName && schemaName);
  const location = useLocation();

  useEffect(() => {
    if (FREE_SAAS) {
      const { tableSchema = '' } = form.getFieldsValue();
      if (
        tableSchema === '' &&
        userMe &&
        userMe?.kinetica_user?.default_schema
      ) {
        form.setFieldsValue({
          tableSchema: userMe?.kinetica_user?.default_schema,
        });
      }
    }
  }, [userMe, form]);

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.schema) {
          form.setFieldsValue({
            tableSchema: location.state.schema,
          });
        }
      }
    },
    [location, form]
  );

  useEffect(
    _ => {
      if (tableName && schemaName) {
        setTableLoading(true);
        graphqlClient
          .query({
            query: GraphQLServices.Tables.GET_TABLE_BY_NAME,
            variables: {
              name: tableName,
              schema: schemaName,
            },
          })
          .then(resp => {
            setTableData(resp.data);
            setTableLoading(false);
          })
          .catch(err => {
            setTableData(null);
            setTableLoading(false);
          });
      }
    },
    [tableName, schemaName, graphqlClient]
  );

  useEffect(
    _ => {
      if (!tableLoading && tableData) {
        const initialColumns = tableData.table.columns.map(col => {
          const properties = col.properties;
          const subtype =
            properties.find(prop => KINETICA_SUBTYPES.includes(prop)) || '';
          const storage = properties.includes('data') ? 'data' : 'store_only';
          const id = uuid();
          return {
            key: id,
            id,
            columnName: col.name,
            nullable: properties.includes('nullable'),
            properties: properties.filter(
              prop =>
                !KINETICA_SUBTYPES.includes(prop) &&
                prop !== 'data' &&
                prop !== 'store_only'
            ),
            storage,
            type: [Array.isArray(col.type) ? col.type[0] : col.type, subtype],
          };
        });

        form.setFieldsValue({
          tableName: tableData.table.name,
          tableSchema: tableData.table.schema,
          options: {
            ...tableData.table.additional_info,
            partition_type:
              tableData.table.additional_info.partition_type === 'NONE'
                ? ''
                : tableData.table.additional_info.partition_type,
          },
        });
        setColumns(initialColumns);
        setOldColumns(initialColumns);
      }
    },
    [form, tableData, tableLoading]
  );

  useEffect(
    _ => {
      const currentPermissions = {};
      const values = form.getFieldsValue();
      kineticaUsersAndRoles.forEach(user => {
        user.permissions.forEach(perm => {
          if (
            perm.permission === 'table_read' &&
            perm.table_name === `${values.tableSchema}.${values.tableName}`
          ) {
            const permObj = {};
            let hasPermObj = false;
            if (perm.columns) {
              try {
                const columnJSON = JSON.parse(perm.columns);
                const columnSet = {};
                columnJSON.forEach(col => {
                  let matches = col[0].match(MASK_REGEX);
                  let colName = col[0];
                  if (matches) {
                    colName = matches[1];
                    const columnObj = columns.find(
                      c => c.columnName === colName
                    );
                    const start = Number(matches[2]);
                    const numOfChars = Number(matches[3]);
                    const maskChar = matches[4];
                    if (columnObj) {
                      columnSet[columnObj.id] = {
                        enabled: true,
                        transformType: 'mask',
                        maskStart: start,
                        maskLength: numOfChars,
                        maskCharacter: maskChar,
                        columnFilter: col[1],
                      };
                    }
                  } else {
                    matches = col[0].match(HASH_REGEX);
                    if (matches) {
                      colName = matches[1];
                      const columnObj = columns.find(
                        c => c.columnName === colName
                      );
                      if (columnObj) {
                        columnSet[columnObj.id] = {
                          enabled: true,
                          transformType: 'obfuscate',
                          columnFilter: col[1],
                        };
                      }
                    } else {
                      const columnObj = columns.find(
                        c => c.columnName === colName
                      );
                      if (columnObj) {
                        columnSet[columnObj.id] = {
                          enabled: true,
                          transformType: '',
                          columnFilter: col[1],
                        };
                      }
                    }
                  }
                });
                permObj.columns = columnSet;
                hasPermObj = true;
              } catch (err) {
                console.error(err);
              }
            }

            if (perm.sql_where !== 'false') {
              permObj.rowFilter = perm.sql_where;
              permObj.rowEnabled = true;
              hasPermObj = true;
            }

            if (hasPermObj) {
              currentPermissions[user.name] = permObj;
            }
          }
        });
      });
      setOldPermissions(currentPermissions);
      setPermissions(currentPermissions);
    },
    [kineticaUsersAndRoles, form, columns, oldColumns]
  );

  const handleAddColumn = useCallback(
    _ => {
      const id = uuid();
      setColumns(prevColumns => [
        ...prevColumns,
        { id, key: id, storage: 'data' },
      ]);
    },
    [setColumns]
  );

  const handleClearTable = useCallback(
    _ => {
      form.resetFields();
      setColumns([]);
      setPermissions({});
      setStep(0);
      handleAddColumn();
    },
    [form, handleAddColumn]
  );

  const handleCreateTable = useCallback(
    _ => {
      form
        .validateFields()
        .then(({ tableSchema, tableName, options }) =>
          createTable({
            variables: {
              schema: tableSchema,
              name: tableName,
              fields: extractFields(columns),
              properties: extractProperties(columns),
              options: cleanOptions(options),
            },
          })
        )
        .then(resp => {
          const qualifiedTableName = resp?.data?.tableCreateTable?.table_name;
          if (qualifiedTableName) {
            notification.open({
              message: 'Table Created',
              description: 'Created new table: ' + qualifiedTableName,
              type: 'success',
            });

            analytics.track(analytics.EVENT_TYPES.CREATED_TABLE)({});

            handleClearTable();

            if (Object.keys(permissions).length > 0) {
              let statements = [];
              Object.entries(permissions).forEach(([username, perm]) => {
                const stmts = buildGrantPermissionSQL(
                  username,
                  qualifiedTableName,
                  perm.rowEnabled,
                  perm.rowFilter,
                  perm.columns,
                  columns
                );

                // We have to revoke previous permissions because we don't have the
                // granularity to remove specific permissions.
                statements.push(
                  `REVOKE SELECT ON ${qualifiedTableName} FROM ${username}`
                );
                statements = statements.concat(stmts);
              });

              let promiseChain = null;
              statements.forEach(statement => {
                if (!promiseChain) {
                  promiseChain = executeSql({
                    variables: {
                      statement,
                    },
                  });
                } else {
                  promiseChain = promiseChain.then(resp => {
                    if (resp.errors && resp.errors.length > 0) {
                      console.error(resp.errors);
                      throw new ClientError({
                        title: 'Creation Error Occurred',
                        details: resp.errors.map(e => e.message).join('\n'),
                      });
                    } else {
                      return executeSql({
                        variables: {
                          statement,
                        },
                      });
                    }
                  });
                }
              });

              return promiseChain.then(resp => {
                if (resp.errors && resp.errors.length > 0) {
                  console.error(resp.errors);
                  throw new ClientError({
                    title: 'Creation Error Occurred',
                    details: resp.errors.map(e => e.message).join('\n'),
                  });
                }
              });
            }
          } else if (!resp.errors) {
            notification.open({
              message: 'Table Created',
              description: 'Created new table',
              type: 'success',
            });
          }

          refetchTables();
        })
        .catch(err => {
          if (err) {
            if (err.errorFields) {
              err.errorFields
                .map(e => e.errors)
                .forEach(e =>
                  e.forEach(msg =>
                    notification.open({
                      message: 'Validation Error Occurred',
                      description: msg,
                      type: 'error',
                    })
                  )
                );

              console.log(
                err.errorFields.map(e => e.errors.join('\n')).join('\n')
              );
            } else if (err.title && err.details) {
              notification.open({
                message: err.title,
                description: err.details,
                type: 'error',
              });
            }
          }
        });
    },
    [
      createTable,
      form,
      refetchTables,
      columns,
      permissions,
      executeSql,
      handleClearTable,
      analytics,
    ]
  );

  const handleEditTable = useCallback(
    _ => {
      form
        .validateFields()
        .then(({ tableSchema, tableName }) => {
          let promise = Promise.resolve({});
          if (JSON.stringify(columns) !== JSON.stringify(oldColumns)) {
            const addColumns = columns.filter(
              col => !oldColumns.some(c => c.id === col.id)
            );
            const removeColumns = oldColumns.filter(
              col => !columns.some(c => c.id === col.id)
            );
            const modifyColumns = columns.filter(col => {
              const oldCol = oldColumns.find(c => c.id === col.id);
              return oldCol && JSON.stringify(col) !== JSON.stringify(oldCol);
            });

            const adds = genColumnAdditions(addColumns);
            const removals = genColumnRemovals(removeColumns);
            const changes = genColumnChanges(modifyColumns, oldColumns);
            const alterations = []
              .concat(adds)
              .concat(removals)
              .concat(changes);

            promise = promise.then(_ =>
              alterColumns({
                variables: {
                  schema: tableSchema,
                  name: tableData.table.name,
                  alterations,
                },
              })
            );
          }

          if (tableName !== tableData.table.name) {
            promise = promise
              .then(_ =>
                renameTable({
                  variables: {
                    schema: tableSchema,
                    oldName: tableData.table.name,
                    newName: tableName,
                  },
                })
              )
              .then(resp => {
                // Only redirect if rename succeeds
                if (!resp.errors) {
                  history.replace(`/edittable/${tableSchema}/${tableName}`);
                }
                return resp;
              });
          }

          if (JSON.stringify(permissions) !== JSON.stringify(oldPermissions)) {
            let statements = [];
            const qualifiedTableName = tableSchema
              ? `${tableSchema}.${tableName}`
              : tableName;
            Object.entries(permissions).forEach(([username, perm]) => {
              const stmts = buildGrantPermissionSQL(
                username,
                qualifiedTableName,
                perm.rowEnabled,
                perm.rowFilter,
                perm.columns,
                columns
              );

              // We have to revoke previous permissions because we don't have the
              // granularity to remove specific permissions.
              statements.push(
                `REVOKE SELECT ON ${qualifiedTableName} FROM ${username}`
              );
              statements = statements.concat(stmts);
            });

            statements.forEach(statement => {
              promise = promise.then(resp => {
                if (resp.errors && resp.errors.length > 0) {
                  console.error(resp.errors);
                  throw new ClientError({
                    title: 'Modification Error Occurred',
                    details: resp.errors.map(e => e.message).join('\n'),
                  });
                } else {
                  return executeSql({
                    variables: {
                      statement,
                    },
                  });
                }
              });
            });
          }

          return promise;
        })
        .then(resp => {
          if (resp?.data?.tableAlterColumns?.table_name) {
            notification.open({
              message: 'Table Modified',
              description:
                'Modifed table: ' + resp?.data?.tableAlterColumns?.table_name,
              type: 'success',
            });
          } else if (resp?.data?.tableRenameTable?.table_name) {
            notification.open({
              message: 'Table Modified',
              description:
                'Renamed table: ' + resp?.data?.tableRenameTable?.table_name,
              type: 'success',
            });
          } else if (!resp.errors) {
            notification.open({
              message: 'Table Modified',
              description: 'Table has been modified',
              type: 'success',
            });
          }

          refetchTables();
          history.go(-1);
        })
        .catch(err => {
          if (err) {
            if (err.errorFields) {
              err.errorFields
                .map(e => e.errors)
                .forEach(e =>
                  e.forEach(msg =>
                    notification.open({
                      message: 'Validation Error Occurred',
                      description: msg,
                      type: 'error',
                    })
                  )
                );

              console.log(
                err.errorFields.map(e => e.errors.join('\n')).join('\n')
              );
            } else if (err.title && err.details) {
              notification.open({
                message: err.title,
                description: err.details,
                type: 'error',
              });
            }
          }
        });
    },
    [
      form,
      refetchTables,
      columns,
      tableData,
      history,
      alterColumns,
      oldColumns,
      renameTable,
      permissions,
      oldPermissions,
      executeSql,
    ]
  );

  const handleResetTable = useCallback(
    _ => {
      form.setFieldsValue({
        tableName: tableData.table.name,
        tableSchema: tableData.table.schema,
        options: {
          ...tableData.table.additional_info,
          partition_type:
            tableData.table.additional_info.partition_type === 'NONE'
              ? ''
              : tableData.table.additional_info.partition_type,
        },
      });
      setColumns(oldColumns);
      setPermissions(oldPermissions);
    },
    [form, tableData, oldColumns, oldPermissions]
  );

  useEffect(
    _ => {
      handleAddColumn();
    },
    [handleAddColumn]
  );

  const handleRemoveColumn = useCallback(
    id => {
      setColumns(prevColumns => [...prevColumns].filter(col => col.id !== id));
    },
    [setColumns]
  );

  const handleUpdateColumn = useCallback(
    (id, payload) => {
      setColumns(prevColumns => {
        const columns = [...prevColumns];
        const index = prevColumns.findIndex(col => col.id === id);
        columns[index] = { ...columns[index], ...payload };
        return columns;
      });
    },
    [setColumns]
  );

  const handlePermissionUpdate = useCallback(
    (username, payload) => {
      setPermissions(prevPermissions => ({
        ...prevPermissions,
        [username]: merge({}, prevPermissions[username], payload),
      }));
    },
    [setPermissions]
  );

  const handleCancel = e => {
    history.push('/');
  };

  const handleStepClick = step => {
    setStep(step);
  };

  return (
    <Form form={form} layout="horizontal" initialValues={{ columns: [] }}>
      <Steps
        current={step}
        onChange={handleStepClick}
        style={{ marginBottom: '20px' }}
      >
        {STEPS.map(({ title }, idx) => (
          <Step key={idx} title={title}></Step>
        ))}
      </Steps>
      <div
        style={{
          height: 'calc(100vh - 390px)',
          margin: '30px 0px',
          overflow: 'auto',
        }}
      >
        {STEPS.map(({ StepComponent }, idx) => (
          <div key={idx} style={{ display: step === idx ? 'block' : 'none' }}>
            <StepComponent
              form={form}
              columns={columns}
              addColumn={handleAddColumn}
              removeColumn={handleRemoveColumn}
              updateColumn={handleUpdateColumn}
              permissions={permissions}
              updatePermission={handlePermissionUpdate}
              isEditing={isEditing}
            ></StepComponent>
          </div>
        ))}
      </div>
      <div>
        <Button
          icon={<LeftOutlined />}
          onClick={_ => setStep(Math.max(step - 1, 0))}
          style={{ float: 'left' }}
          disabled={step === 0}
        >
          Back
        </Button>
        <Button
          onClick={_ => setStep(Math.min(step + 1, 2))}
          style={{ float: 'right' }}
          disabled={step === 2}
        >
          Next <RightOutlined />
        </Button>
        {!isEditing && (
          <div style={{ float: 'right', marginRight: '10px' }}>
            <Form.Item shouldUpdate={true} style={{ marginRight: '0px' }}>
              {_ => {
                const values = form.getFieldsValue();
                const isValid =
                  values.tableSchema && values.tableName && columns.length > 0;
                return (
                  <Button
                    type="primary"
                    onClick={handleCreateTable}
                    loading={loading}
                    disabled={!isValid}
                  >
                    Create
                  </Button>
                );
              }}
            </Form.Item>
          </div>
        )}
        {isEditing && (
          <Button
            type="primary"
            onClick={handleEditTable}
            style={{ float: 'right', marginRight: '10px' }}
            loading={isAltering || isRenaming}
          >
            Apply Changes
          </Button>
        )}
        {!isEditing && (
          <Popconfirm
            title="Are you sure you want to clear all your input?"
            onConfirm={handleClearTable}
          >
            <Button style={{ float: 'right', marginRight: '10px' }}>
              Clear
            </Button>
          </Popconfirm>
        )}
        <Popconfirm
          title="Are you sure you want to cancel?"
          onConfirm={handleCancel}
        >
          <Button style={{ float: 'right', marginRight: '10px' }} danger>
            Cancel
          </Button>
        </Popconfirm>
        {isEditing && (
          <Popconfirm
            title="Are you sure you want to reset all your changes?"
            onConfirm={handleResetTable}
          >
            <Button style={{ float: 'right', marginRight: '10px' }}>
              Reset
            </Button>
          </Popconfirm>
        )}
      </div>
    </Form>
  );
}
