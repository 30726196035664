// Imports
import React, { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';

const TableRenameModal = ({ table, visible, close, callback }) => {
  const [renameTable] = GraphQLServices.Tables.useTableRenameTable();

  const [isUpdating, setIsUpdating] = useState(false);

  const [form] = Form.useForm();

  const onFinish = values => {
    const { name: newName } = values;
    const { schema, name: oldName } = table;

    setIsUpdating(true);
    renameTable({
      variables: {
        schema,
        oldName,
        newName,
      },
    })
      .then(resp => {
        setIsUpdating(false);
        if (callback) {
          callback(null, resp);
        }
      })
      .catch(err => {
        setIsUpdating(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const update = _ => {
    form.submit();
  };

  return (
    <Modal
      title="Rename Table"
      visible={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={update}
          loading={isUpdating}
        >
          Update
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="table"
        layout="vertical"
        initialValues={{
          current: table.name,
          name: table.name,
        }}
        onFinish={onFinish}
        colon={false}
      >
        <Form.Item label="Current" name="current">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="New"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input table name!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TableRenameModal;
