// Imports
import React, { useMemo, useState, useCallback } from 'react';
import { Spin } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import BlockToolbar from './BlockToolbar';
import BlockIndex from './BlockIndex';
import VizHtml from './VizHtml';
import Spinner from '../../components/common/Spinner';
import { useIsReadOnly } from './utils';
import ReadOnlyDivider from './ReadOnlyDivider';
import { VIZ_TYPES } from '../../constants';

const RESULT_PANEL_MIN_HEIGHT = 210;

const HtmlBlock = ({
  attributes,
  children,
  element,
  blocks,
  toggleBlockVisibility,
}) => {
  const {
    data: { visualizationsByBlockIds: visualizations } = [],
    refetch: refetchVisualizations,
  } = GraphQLServices.Visualizations.useGetVisualizationsByBlockIds({
    variables: {
      block_ids: [element.id],
    },
  });
  const [updateVisualizationById] =
    GraphQLServices.Visualizations.useUpdateVisualizationById();

  const readOnly = useIsReadOnly();
  const [loading, setLoading] = useState(false);

  const handleUpdateViz = useCallback(
    (config, callback) => {
      updateVisualizationById({
        variables: {
          id: visualizations?.length > 0 && visualizations[0].id,
          config,
        },
      }).then(resp => {
        refetchVisualizations();
        if (callback) {
          callback();
        }
      });
    },
    [updateVisualizationById, refetchVisualizations, visualizations]
  );

  const index = useMemo(
    _ => {
      return blocks.findIndex(e => e.id === element.id) + 1;
    },
    [element, blocks]
  );

  const { isBlockVisible } = useMemo(
    _ => {
      const { isBlockVisible = true } = element?.config ?? {};
      return { isBlockVisible };
    },
    [element]
  );

  if (readOnly && !isBlockVisible) {
    return null;
  }

  return (
    <div
      {...attributes}
      contentEditable={false}
      style={
        readOnly
          ? {
              margin: '0px 0px',
              position: 'relative',
              padding: '0px 5px',
              pointerEvents: 'none',
            }
          : {
              margin: '16px 0px 26px',
              position: 'relative',
              border: '1px dotted #3700b322',
              borderLeft: '4px solid #3700b333',
              padding: '5px',
              pointerEvents: 'none',
            }
      }
    >
      {readOnly && <ReadOnlyDivider index={index} />}
      {!readOnly && <BlockIndex index={index} />}
      <BlockToolbar
        element={element}
        blocks={blocks}
        setLoading={setLoading}
        toggleBlockVisibility={toggleBlockVisibility}
      />
      <Spin indicator={<Spinner />} spinning={loading}>
        <div style={{ padding: '5px', pointerEvents: 'all' }}>
          <div
            style={{
              padding: '1px',
              backgroundColor: '#fcfcfc',
              margin: '10px 0px 0px',
            }}
          >
            <div style={{ padding: '10px', overflow: 'hidden' }}>
              {visualizations?.length > 0 &&
                visualizations?.map(visualization => {
                  if (visualization.visualization_type.id === VIZ_TYPES.HTML) {
                    return (
                      <VizHtml
                        key={visualization.id}
                        viz={visualization}
                        handleUpdate={handleUpdateViz}
                        minHeight={!readOnly && RESULT_PANEL_MIN_HEIGHT}
                      />
                    );
                  }
                  return '';
                })}
            </div>
          </div>
        </div>
      </Spin>
      <div
        contentEditable={false}
        style={{ userSelect: 'none', display: 'none' }}
      >
        {children}
      </div>
    </div>
  );
};

export default HtmlBlock;
