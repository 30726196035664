// Imports
import React from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

// App Imports
import Explorer from '../../components/explorer/Explorer';
import ExplorerCollapsed from '../../components/explorer/ExplorerCollapsed';
import { setExplorerPanelCollapsed } from '../../store/app/actions';
import { EXPLORER_PANEL_WIDTH } from '../../constants';

const { Sider } = Layout;

const ExplorerSider = () => {
  const { explorerPanelCollapsed } = useSelector(state => state.app);

  const dispatch = useDispatch();

  const handleSetCollapsed = current => {
    dispatch(setExplorerPanelCollapsed(current));
  };

  const setCollapsed = collapsed => _ => {
    dispatch(setExplorerPanelCollapsed(collapsed));
  };

  return (
    <Sider
      collapsible
      collapsed={explorerPanelCollapsed}
      onCollapse={handleSetCollapsed}
      width={EXPLORER_PANEL_WIDTH}
      theme="light"
      trigger={null}
    >
      {!explorerPanelCollapsed ? (
        <>
          <LeftCircleOutlined
            onClick={setCollapsed(true)}
            style={{
              position: 'absolute',
              right: '-12px',
              top: '65px',
              fontSize: '24px',
              backgroundColor: '#ffffff',
              borderRadius: '12px',
              color: '#dddddd',
            }}
          />
          <Explorer />
        </>
      ) : (
        <>
          <RightCircleOutlined
            onClick={setCollapsed(false)}
            style={{
              position: 'absolute',
              right: '-12px',
              top: '65px',
              fontSize: '24px',
              backgroundColor: '#ffffff',
              borderRadius: '12px',
              color: '#dddddd',
            }}
          />
          <ExplorerCollapsed setCollapsed={handleSetCollapsed} />
        </>
      )}
    </Sider>
  );
};

export default ExplorerSider;
