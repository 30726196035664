// Imports
import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Spin,
  Space,
  Tabs,
  Button,
  Layout,
  Modal,
  Tooltip,
  Menu,
  Dropdown,
  Switch,
  notification,
  Divider,
  Tag,
} from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  RedoOutlined,
  ExportOutlined,
  CopyOutlined,
  PicLeftOutlined,
} from '@ant-design/icons';
import { ApolloConsumer, useApolloClient } from '@apollo/client';

// App Imports
import { setCurrentWorksheetId } from '../../store/workbook/actions';
import useAnalytics from '../../hooks/useAnalytics';
import GraphQLServices from '../../graphql/services';
import Spinner from '../../components/common/Spinner';
import WorksheetEditor from './WorksheetEditor';
import WorkbookEditModal from '../../components/modal/WorkbookEditModal';
import WorkbookEmbedModal from '../../components/modal/WorkbookEmbedModal';
import WorksheetCopyModal from '../../components/modal/WorksheetCopyModal';
import { NAV_ROUTE_DATAEXPLORE } from '../../constants';
import {
  useExportJsonWorkbook,
  useExportSqlWorkbook,
  validateBlockContent,
  sortWorksheets,
  ReadOnlyContext,
} from './utils';
import { FREE_SAAS } from '../../setup/config';

const { TabPane } = Tabs;
const { Content } = Layout;
const { confirm } = Modal;

const WorkbookEditor = ({ workbookId, refetchWorkbooks, embed = false }) => {
  const { data: { userMe = {} } = {} } =
    GraphQLServices.Users.useGetLocalUserMe();
  const {
    loading: workbookLoading,
    data: { workbook = undefined } = {},
    refetch: refetchWorkbook,
  } = GraphQLServices.Workbooks.useGetWorkbookById({
    variables: {
      id: workbookId,
    },
  });
  const [createWorksheet, { loading: creatingWorksheet }] =
    GraphQLServices.Worksheets.useCreateWorksheet();
  const [removeWorkbookById] =
    GraphQLServices.Workbooks.useRemoveWorkbookById();
  const [removeWorksheetById] =
    GraphQLServices.Worksheets.useRemoveWorksheetById();
  const [createBlock] = GraphQLServices.Blocks.useCreateBlock();
  const [updateBlock] = GraphQLServices.Blocks.useUpdateBlockById();
  const [updateWorksheetById] =
    GraphQLServices.Worksheets.useUpdateWorksheetById();
  const exportJsonWorkbook = useExportJsonWorkbook();
  const exportSqlWorkbook = useExportSqlWorkbook();
  const [updateWorkbookById] =
    GraphQLServices.Workbooks.useUpdateWorkbookById();
  const [shareLoading, setShareLoading] = useState(false);
  const [copyWorkbook] = GraphQLServices.Workbooks.useCopyWorkbook();
  const [copying, setCopying] = useState(false);
  const [createVisualization] =
    GraphQLServices.Visualizations.useCreateVisualization();

  const analytics = useAnalytics();
  const graphqlClient = useApolloClient();
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentWorksheetId } = useSelector(state => state.workbook);
  const [showWorkbookEditModal, setShowWorkbookEditModal] = useState(false);
  const [showWorkbookEmbedModal, setShowWorkbookEmbedModal] = useState(false);

  const [copyWorksheet, setCopyWorksheet] = useState(undefined);
  const [showWorksheetCopyModal, setShowWorksheetCopyModal] = useState(false);

  const worksheetId = useMemo(
    _ => {
      if (workbook) {
        if (
          workbook.worksheets.some(
            worksheet => worksheet.id === currentWorksheetId
          )
        ) {
          return currentWorksheetId;
        } else {
          return undefined;
        }
      }
    },
    [workbook, currentWorksheetId]
  );

  const processedWorksheets = useMemo(
    _ => {
      if (workbook && workbook.worksheets) {
        return sortWorksheets(workbook.worksheets);
      }
      return [];
    },
    [workbook]
  );

  const handleResetCurrentWorkbookId = _ => {
    history.push(NAV_ROUTE_DATAEXPLORE);
  };

  const removeWorkbook = useCallback(
    workbook => {
      removeWorkbookById({
        variables: {
          id: workbook.id,
        },
      })
        .then(resp => {
          refetchWorkbooks();
          history.push(NAV_ROUTE_DATAEXPLORE);
        })
        .catch(err => {
          console.error(err);
        });
    },
    [refetchWorkbooks, history, removeWorkbookById]
  );

  const handleDeleteWorkbook = useCallback(
    _ => {
      if (workbook) {
        confirm({
          title: `Do you want to delete workbook ${workbook.name}?`,
          icon: <ExclamationCircleOutlined />,
          onOk() {
            removeWorkbook(workbook);
          },
          onCancel() {
            // Do nothing
          },
          width: 600,
        });
      }
    },
    [workbook, removeWorkbook]
  );

  const handleExportJsonWorkbook = useCallback(
    _ => {
      if (workbook) {
        confirm({
          title: `Do you want to export ${workbook.name} as Workbook JSON?`,
          icon: <ExclamationCircleOutlined />,
          okText: 'Yes',
          onOk() {
            exportJsonWorkbook(workbook);
          },
          cancelText: 'No',
          onCancel() {
            // Do nothing
          },
          width: 600,
        });
      }
    },
    [workbook, exportJsonWorkbook]
  );

  const handleExportSqlWorkbook = useCallback(
    _ => {
      if (workbook) {
        confirm({
          title: `Do you want to export ${workbook.name} as Standard SQL?`,
          icon: <ExclamationCircleOutlined />,
          okText: 'Yes',
          onOk() {
            exportSqlWorkbook(workbook);
          },
          cancelText: 'No',
          onCancel() {
            // Do nothing
          },
          width: 600,
        });
      }
    },
    [workbook, exportSqlWorkbook]
  );

  const handleCopyWorkbook = useCallback(
    _ => {
      if (workbook) {
        confirm({
          title: `Do you want to copy workbook ${workbook.name}?`,
          icon: <ExclamationCircleOutlined />,
          okText: 'Yes',
          onOk() {
            setCopying(true);
            copyWorkbook({
              variables: {
                id: workbook.id,
                overrides: {
                  workbook: {
                    name: `${workbook.name} Copy`,
                    is_shared: false,
                    is_example: false,
                  },
                },
              },
            })
              .then(_ => {
                notification.success({
                  message: 'Success',
                  description: `Successfully copied ${workbook.name}!`,
                });
                refetchWorkbooks();
                setCopying(false);

                analytics.track(analytics.EVENT_TYPES.COPIED_WORKBOOK)({});
              })
              .catch(err => {
                notification.error({
                  message: 'Error While Copying Workbook',
                  description: err.toString(),
                  duration: 0,
                });
                setCopying(false);
              });
          },
          cancelText: 'No',
          onCancel() {
            // Do nothing
          },
          width: 600,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workbook, copyWorkbook, refetchWorkbooks]
  );

  const handleEditWorkbook = _ => {
    setShowWorkbookEditModal(true);
  };

  const handleEmbedWorkbook = _ => {
    setShowWorkbookEmbedModal(true);
  };

  const handleWorksheetsChange = activeKey => {
    dispatch(setCurrentWorksheetId(activeKey));
  };

  const getWorkbookPosition = useCallback(
    worksheetId => {
      const worksheetIds = processedWorksheets.map(worksheet => worksheet.id);
      return worksheetIds.indexOf(worksheetId) + 1;
    },
    [processedWorksheets]
  );

  const getWorksheetAtPosition = useCallback(
    position => {
      return processedWorksheets[position - 1];
    },
    [processedWorksheets]
  );

  const handleWorksheetsEdit = useCallback(
    (targetKey, action) => {
      if (action === 'add' && workbook?.id) {
        const lastWorksheet = workbook.worksheets.find(
          worksheet => !worksheet.next_worksheet_id
        );
        const nextSheet = workbook.worksheets.length + 1;
        createWorksheet({
          variables: {
            name: `Sheet ${nextSheet}`,
            description: `Description for sheet ${nextSheet}`,
            config: {},
            previous_worksheet_id: lastWorksheet.id,
            next_worksheet_id: null,
            workbook_id: workbook.id,
          },
        })
          .then(resp => {
            const { id: worksheet_id } = resp.data.worksheetCreate;
            return createBlock({
              variables: {
                name: 'Block 1',
                description: 'Description for Block 1',
                content: '',
                config: {},
                previous_block_id: null,
                next_block_id: null,
                block_type_id: '84b9233c-14e3-11eb-adc1-0242ac120002',
                worksheet_id,
              },
            });
          })
          .then(resp => {
            const { worksheet_id: next_worksheet_id } = resp.data.blockCreate;
            return updateWorksheetById({
              variables: {
                id: lastWorksheet.id,
                next_worksheet_id,
              },
            });
          })
          .then(resp => {
            const { next_worksheet_id: worksheet_id } =
              resp.data.worksheetUpdateById;
            dispatch(setCurrentWorksheetId(worksheet_id));
            refetchWorkbook();
          });
      } else if (action === 'remove') {
        confirm({
          title: `Do you want to delete this worksheet?`,
          icon: <ExclamationCircleOutlined />,
          onOk() {
            const position = getWorkbookPosition(targetKey);
            const previous = getWorksheetAtPosition(position - 1);
            const next = getWorksheetAtPosition(position + 1);

            if (previous && next) {
              updateWorksheetById({
                variables: {
                  id: previous.id,
                  next_worksheet_id: next.id,
                },
              });
              updateWorksheetById({
                variables: {
                  id: next.id,
                  previous_worksheet_id: previous.id,
                },
              });
            } else if (previous) {
              updateWorksheetById({
                variables: {
                  id: previous.id,
                  next_worksheet_id: null,
                },
              });
            } else if (next) {
              updateWorksheetById({
                variables: {
                  id: next.id,
                  previous_worksheet_id: null,
                },
              });
            }

            removeWorksheetById({
              variables: {
                id: targetKey,
              },
            }).then(resp => {
              dispatch(setCurrentWorksheetId(workbook.worksheets[0].id));
              refetchWorkbook();
            });
          },
          onCancel() {
            // Do nothing
          },
          width: 600,
        });
      }
    },
    [
      workbook,
      createWorksheet,
      createBlock,
      removeWorksheetById,
      refetchWorkbook,
      dispatch,
      getWorkbookPosition,
      getWorksheetAtPosition,
      updateWorksheetById,
    ]
  );

  const handleWorkbookShare = useCallback(
    checked => {
      setShareLoading(true);
      updateWorkbookById({
        variables: {
          id: workbook.id,
          is_shared: checked,
        },
      })
        .then(_ => setShareLoading(false))
        .catch(err => {
          console.error(err);
          setShareLoading(false);
        });
    },
    [workbook, updateWorkbookById]
  );

  const handleWorkbookEditCallback = (err, resp) => {
    if (resp) {
      setShowWorkbookEditModal(false);
    } else {
      console.error(err);
    }
  };

  const handleWorkbookEmbedCallback = (err, resp) => {
    if (resp) {
      setShowWorkbookEmbedModal(false);
    } else {
      console.error(err);
    }
  };

  const handleSetAutoRefresh = async (worksheetId, time) => {
    // Update worksheet auto refresh time
    const position = getWorkbookPosition(worksheetId);
    const worksheet = getWorksheetAtPosition(position);
    const config = worksheet?.config ?? {};
    await updateWorksheetById({
      variables: {
        id: worksheetId,
        config: {
          ...config,
          autoRefreshInterval: time,
        },
      },
    });
  };

  const handleMoveWorksheetUp = worksheetId => {
    const position = getWorkbookPosition(worksheetId);
    const from = getWorksheetAtPosition(position - 1);
    const to = getWorksheetAtPosition(position);
    swapWorksheetPosition(from, to);
  };

  const handleMoveWorksheetDown = worksheetId => {
    const position = getWorkbookPosition(worksheetId);
    const from = getWorksheetAtPosition(position);
    const to = getWorksheetAtPosition(position + 1);
    swapWorksheetPosition(from, to);
  };

  const handleCopyToThisWorkbook = useCallback(
    async worksheetId => {
      const {
        data: { worksheet },
      } = await graphqlClient.query({
        query: GraphQLServices.Worksheets.GET_WORKSHEET_BY_ID,
        variables: {
          id: worksheetId,
        },
      });
      const lastWorksheet = workbook.worksheets.find(
        worksheet => !worksheet.next_worksheet_id
      );
      const { blocks } = worksheet;
      const processedBlocks = [];
      const visitedBlocks = [];
      let curBlock = blocks.find(b => b.previous_block_id === null);
      while (curBlock && !visitedBlocks.includes(curBlock.id)) {
        const type = curBlock.block_type.id;
        const content = curBlock.content ? JSON.parse(curBlock.content) : '';
        const config = curBlock.config ?? {};
        const newBlock = {
          id: curBlock.id,
          type: validateBlockContent(type, content) ? type : null,
          content,
          config,
          children: [{ text: '' }],
        };
        if (newBlock.type === null) {
          newBlock.storedBlock = { ...curBlock };
        }
        processedBlocks.push(newBlock);
        visitedBlocks.push(curBlock.id);
        const nextBlockId = curBlock.next_block_id;
        curBlock = blocks.find(b => b.id === nextBlockId);
      }

      const createWorksheetRep = await createWorksheet({
        variables: {
          name: `${worksheet.name} Copy`,
          description: worksheet.description,
          config: worksheet.config ?? {},
          previous_worksheet_id: lastWorksheet.id,
          next_worksheet_id: null,
          workbook_id: workbook.id,
        },
      });

      const { id: worksheet_id } = createWorksheetRep.data.worksheetCreate;

      await updateWorksheetById({
        variables: {
          id: lastWorksheet.id,
          next_worksheet_id: worksheet_id,
        },
      });

      await processedBlocks.reduce(
        async (promisedPreviousBlock, processedBlock) => {
          const previousBlockId = await promisedPreviousBlock;
          const blockIndex = blocks.findIndex(
            block => block.id === processedBlock.id
          );
          const block = blocks[blockIndex];
          const {
            id: block_id,
            name,
            description,
            content,
            config,
            block_type,
          } = block;
          const payload = {
            variables: {
              name,
              description,
              content,
              config,
              previous_block_id: previousBlockId,
              next_block_id: null,
              block_type_id: block_type.id,
              worksheet_id,
            },
          };
          const createBlockResp = await createBlock(payload);
          const { id } = createBlockResp?.data?.blockCreate;

          const vizByBlockResp = await graphqlClient.query({
            query:
              GraphQLServices.Visualizations.GET_VISUALIZATIONS_BY_BLOCK_ID,
            variables: {
              block_id,
            },
          });
          const visualizations = vizByBlockResp?.data?.visualizationsByBlockId;

          visualizations.forEach(async visualization => {
            const { name, description, config, visualization_type } =
              visualization;
            const payload = {
              variables: {
                name,
                description,
                config,
                block_id: id,
                visualization_type_id: visualization_type.id,
              },
            };
            await createVisualization(payload);
          });

          if (previousBlockId) {
            await updateBlock({
              variables: {
                id: previousBlockId,
                next_block_id: id,
              },
            });
          }

          return id;
        },
        null
      );

      dispatch(setCurrentWorksheetId(worksheet_id));
      refetchWorkbook();
    },
    [
      workbook,
      createBlock,
      createVisualization,
      createWorksheet,
      dispatch,
      graphqlClient,
      refetchWorkbook,
      updateBlock,
      updateWorksheetById,
    ]
  );

  const handleCopyToAnotherWorkbook = async worksheetId => {
    const {
      data: { worksheet },
    } = await graphqlClient.query({
      query: GraphQLServices.Worksheets.GET_WORKSHEET_BY_ID,
      variables: {
        id: worksheetId,
      },
    });
    setCopyWorksheet(worksheet);
    setShowWorksheetCopyModal(true);
  };

  const handleWorksheetCopyCallback = (err, resp) => {
    if (resp) {
      setCopyWorksheet(undefined);
      setShowWorksheetCopyModal(false);
    } else {
      console.error(err);
    }
  };

  const swapWorksheetPosition = (from, to) => {
    if (from.previous_worksheet_id) {
      updateWorksheetById({
        variables: {
          id: from.previous_worksheet_id,
          next_worksheet_id: to.id,
        },
      });
    }
    updateWorksheetById({
      variables: {
        id: from.id,
        previous_worksheet_id: to.id,
        next_worksheet_id: to.next_worksheet_id,
      },
    });
    updateWorksheetById({
      variables: {
        id: to.id,
        previous_worksheet_id: from.previous_worksheet_id,
        next_worksheet_id: from.id,
      },
    });
    if (to.next_worksheet_id) {
      updateWorksheetById({
        variables: {
          id: to.next_worksheet_id,
          previous_worksheet_id: from.id,
        },
      });
    }
  };

  const handleExportMenuClick = type => {
    switch (type.key) {
      case 'sql': {
        handleExportSqlWorkbook();
        return;
      }
      case 'json': {
        handleExportJsonWorkbook();
        return;
      }
      default: {
        handleExportJsonWorkbook();
        return;
      }
    }
  };

  const exportMenu = (
    <Menu onClick={handleExportMenuClick}>
      <Menu.Item key="json">Workbook JSON</Menu.Item>
      <Menu.Item key="sql">Standard SQL</Menu.Item>
    </Menu>
  );

  const readOnly = useMemo(
    _ => {
      return workbook?.is_example || workbook?.user?.id !== userMe?.id || embed;
    },
    [workbook, userMe, embed]
  );

  return (
    <div className="workbook_editor">
      <Spin indicator={<Spinner />} spinning={workbookLoading}>
        {workbook && (
          <>
            <div style={{ float: 'right' }}>
              <Space size="small">
                {!readOnly && !FREE_SAAS && (
                  <>
                    <Tooltip title="Shares workbook for all other users in a read-only mode">
                      <div
                        style={{
                          display: 'inline-block',
                          marginRight: '10px',
                          verticalAlign: 'middle',
                        }}
                      >
                        Share
                      </div>
                      <Switch
                        loading={shareLoading || workbookLoading}
                        checked={workbook.is_shared}
                        onChange={handleWorkbookShare}
                        style={{
                          display: 'inline-block',
                          backgroundColor: workbook.is_shared
                            ? '#1890FF'
                            : undefined,
                        }}
                      ></Switch>
                    </Tooltip>
                    <Divider type="vertical" />
                  </>
                )}
                {!embed && (
                  <>
                    <Tooltip title="Copy Workbook">
                      <Button
                        icon={<CopyOutlined />}
                        loading={copying}
                        onClick={handleCopyWorkbook}
                      />
                    </Tooltip>
                    <Tooltip title="Share Workbook">
                      <Button
                        icon={<PicLeftOutlined />}
                        onClick={handleEmbedWorkbook}
                      />
                    </Tooltip>
                    <Tooltip title="Export Workbook">
                      <Dropdown overlay={exportMenu}>
                        <Button icon={<ExportOutlined />} />
                      </Dropdown>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip title="Refresh Workbook">
                      <Button
                        icon={<RedoOutlined spin={workbookLoading} />}
                        onClick={() => refetchWorkbook()}
                      />
                    </Tooltip>
                  </>
                )}
                {!readOnly && (
                  <>
                    <Tooltip title="Edit Workbook">
                      <Button
                        icon={<EditOutlined />}
                        onClick={handleEditWorkbook}
                      />
                    </Tooltip>
                    <Tooltip title="Delete Workbook">
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={handleDeleteWorkbook}
                      />
                    </Tooltip>
                  </>
                )}
                {!embed && (
                  <Tooltip title="Close Workbook">
                    <Button
                      icon={<CloseOutlined />}
                      onClick={handleResetCurrentWorkbookId}
                    />
                  </Tooltip>
                )}
              </Space>
            </div>
            <h2
              style={{
                width: embed ? '100%' : 'calc(100% - 500px)',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {workbook.name}
              {workbook.is_example && (
                <Tag
                  color="magenta"
                  style={{ marginLeft: '10px', verticalAlign: 'middle' }}
                >
                  Example
                </Tag>
              )}
              {workbook.is_shared && workbook?.user?.id !== userMe?.id && (
                <Tag
                  color="blue"
                  style={{ marginLeft: '10px', verticalAlign: 'middle' }}
                >
                  {workbook.user.username}
                </Tag>
              )}
            </h2>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <ReadOnlyContext.Provider value={readOnly}>
                <ApolloConsumer>
                  {client =>
                    embed && processedWorksheets.length === 1 ? (
                      <Content
                        style={{
                          backgroundColor: '#ffffff',
                          padding: '20px',
                          height: 'calc(100vh - 75px)',
                          overflow: 'auto',
                        }}
                      >
                        {processedWorksheets.map(worksheet => (
                          <WorksheetEditor
                            key={worksheet.id}
                            embed={embed}
                            apolloClient={client}
                            worksheetId={worksheet.id}
                            workbook={workbook}
                            refetchWorkbook={refetchWorkbook}
                            moveWorksheetUp={handleMoveWorksheetUp}
                            moveWorksheetDown={handleMoveWorksheetDown}
                            copyToThisWorkbook={handleCopyToThisWorkbook}
                            copyToAnotherWorkbook={handleCopyToAnotherWorkbook}
                            setAutoRefresh={handleSetAutoRefresh}
                          />
                        ))}
                      </Content>
                    ) : (
                      <Tabs
                        type="editable-card"
                        onChange={handleWorksheetsChange}
                        activeKey={worksheetId}
                        onEdit={handleWorksheetsEdit}
                        hideAdd={readOnly}
                        addIcon={
                          creatingWorksheet ? (
                            <LoadingOutlined />
                          ) : (
                            <PlusOutlined />
                          )
                        }
                        destroyInactiveTabPane={true}
                      >
                        {processedWorksheets.map(worksheet => {
                          return (
                            <TabPane
                              tab={worksheet.name}
                              key={worksheet.id}
                              closable={
                                !readOnly && processedWorksheets.length > 1
                              }
                            >
                              <Content
                                style={{
                                  backgroundColor: '#ffffff',
                                  padding: '20px',
                                  height: embed
                                    ? 'calc(100vh - 115px)'
                                    : 'calc(100vh - 250px)',
                                  overflow: 'auto',
                                }}
                              >
                                <WorksheetEditor
                                  embed={embed}
                                  apolloClient={client}
                                  worksheetId={worksheet.id}
                                  workbook={workbook}
                                  refetchWorkbook={refetchWorkbook}
                                  moveWorksheetUp={handleMoveWorksheetUp}
                                  moveWorksheetDown={handleMoveWorksheetDown}
                                  copyToThisWorkbook={handleCopyToThisWorkbook}
                                  copyToAnotherWorkbook={
                                    handleCopyToAnotherWorkbook
                                  }
                                  setAutoRefresh={handleSetAutoRefresh}
                                />
                              </Content>
                            </TabPane>
                          );
                        })}
                      </Tabs>
                    )
                  }
                </ApolloConsumer>
              </ReadOnlyContext.Provider>
            </Space>
            {showWorkbookEditModal && (
              <WorkbookEditModal
                workbook={workbook}
                visible={showWorkbookEditModal}
                close={_ => {
                  setShowWorkbookEditModal(false);
                }}
                callback={handleWorkbookEditCallback}
              />
            )}
            {showWorkbookEmbedModal && (
              <WorkbookEmbedModal
                workbook={workbook}
                visible={showWorkbookEmbedModal}
                close={_ => {
                  setShowWorkbookEmbedModal(false);
                }}
                callback={handleWorkbookEmbedCallback}
              />
            )}
            {showWorksheetCopyModal && (
              <WorksheetCopyModal
                worksheet={copyWorksheet}
                visible={showWorksheetCopyModal}
                close={_ => {
                  setShowWorksheetCopyModal(false);
                }}
                callback={handleWorksheetCopyCallback}
              />
            )}
          </>
        )}
      </Spin>
    </div>
  );
};

export default WorkbookEditor;
