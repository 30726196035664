// Imports
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu, Modal, Tag } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  DiffOutlined,
  DesktopOutlined,
} from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../../graphql/services';
import {
  NAV_ROUTE_DATAEXPLORE_WORKBOOK,
  NAV_ROUTE_DATAEXPLORE,
  EXPLORER_PANEL_WIDTH,
} from '../../../constants';
import { routes } from '../../../setup/routes';
import {
  useExportJsonWorkbook,
  useExportSqlWorkbook,
} from '../../../containers/dataexplore/utils';
import MultiSelectContext from '../MultiSelectContext';
import useAnalytics from '../../../hooks/useAnalytics';

const { confirm } = Modal;

const WorkbookObject = ({
  nodeData,
  refresh,
  editClick,
  current,
  handleMultiSelect,
}) => {
  const [removeWorkbookById] =
    GraphQLServices.Workbooks.useRemoveWorkbookById();
  const exportJsonWorkbook = useExportJsonWorkbook();
  const exportSqlWorkbook = useExportSqlWorkbook();

  const history = useHistory();

  const analytics = useAnalytics();

  const handleWorkbookContextOpen =
    (workbook, isMultiSelect = false) =>
    e => {
      if (!isMultiSelect) {
        if (workbook.is_example) {
          analytics.track(analytics.EVENT_TYPES.OPENED_EXAMPLE_WORKBOOK)({
            title: workbook.name,
          });
        }
        history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${workbook.id}`);
      }
    };

  const handleWorkbookContextOpenNew =
    (workbook, isMultiSelect = false) =>
    e => {
      if (!isMultiSelect) {
        if (workbook.is_example) {
          analytics.track(analytics.EVENT_TYPES.OPENED_EXAMPLE_WORKBOOK)({
            title: workbook.name,
          });
        }
        window.open(
          `${routes.context}${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${workbook.id}`,
          `${workbook.id}`,
          'noopener'
        );
      }
    };

  const removeWorkbook = useCallback(
    workbook => {
      removeWorkbookById({
        variables: {
          id: workbook.id,
        },
      })
        .then(resp => {
          refresh();
          if (current && current === workbook.id) {
            history.push(NAV_ROUTE_DATAEXPLORE);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    [refresh, removeWorkbookById, current, history]
  );

  const handleWorkbookContextDelete = workbook => e => {
    confirm({
      title: `Do you want to delete workbook ${workbook.name}?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        removeWorkbook(workbook);
      },
      onCancel() {
        // Do nothing
      },
      width: 600,
      centered: true,
    });
  };

  const handleWorkbookContextExportJson = workbook => e => {
    confirm({
      title: `Do you want to export ${workbook.name} as Workbook JSON?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      onOk() {
        exportJsonWorkbook(workbook);
      },
      cancelText: 'No',
      onCancel() {
        // Do nothing
      },
      width: 600,
      centered: true,
    });
  };

  const handleWorkbookContextExportSql = workbook => e => {
    confirm({
      title: `Do you want to export ${workbook.name} as Standard SQL?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      onOk() {
        exportSqlWorkbook(workbook);
      },
      cancelText: 'No',
      onCancel() {
        // Do nothing
      },
      width: 600,
      centered: true,
    });
  };

  const workbookContextMenu = node => {
    return (
      <Menu>
        <Menu.Item key="open" onClick={handleWorkbookContextOpen(node.source)}>
          <EyeOutlined /> Open
        </Menu.Item>
        <Menu.Item
          key="openNew"
          onClick={handleWorkbookContextOpenNew(node.source)}
        >
          <DesktopOutlined /> New Window
        </Menu.Item>
        <Menu.SubMenu
          key="export"
          title={
            <>
              <ExportOutlined /> Export &nbsp;&nbsp;
            </>
          }
        >
          <Menu.Item
            key="exportJson"
            onClick={handleWorkbookContextExportJson(node.source)}
          >
            Workbook JSON
          </Menu.Item>
          <Menu.Item
            key="exportSql"
            onClick={handleWorkbookContextExportSql(node.source)}
          >
            Standard SQL
          </Menu.Item>
        </Menu.SubMenu>
        {!node.sharedSection && !node.source.is_example && (
          <>
            <Menu.Divider />
            <Menu.Item key="rename" onClick={editClick(node.source)}>
              <EditOutlined /> Edit
            </Menu.Item>
            <Menu.Item
              key="delete"
              onClick={handleWorkbookContextDelete(node.source)}
              style={{ color: '#C62828' }}
            >
              <DeleteOutlined /> Delete
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="multiselect"
              onClick={_ => handleMultiSelect(node)}
              style={{ color: '#3700b3' }}
            >
              <DiffOutlined /> Multi-Select
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          overlay={workbookContextMenu(nodeData)}
          trigger={['contextMenu']}
        >
          <div>
            <span
              onClick={handleWorkbookContextOpen(
                nodeData.source,
                isMultiSelect
              )}
              style={{
                maxWidth: `${
                  EXPLORER_PANEL_WIDTH -
                  // Reserve width based on if open or shared tag is being shown.
                  ((current && current === nodeData.id) ||
                  (nodeData.source.is_shared && !nodeData.sharedSection)
                    ? 140 - (isMultiSelect ? 24 : 0)
                    : 80 - (isMultiSelect ? 24 : 0))
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              {nodeData.title}
            </span>
            {!(current && current === nodeData.id) &&
              nodeData.source.is_shared &&
              !nodeData.sharedSection && (
                <Tag
                  color="#1890FF"
                  style={{
                    marginLeft: '5px',
                    padding: '2px 5px 2px 4px',
                    lineHeight: '11px',
                  }}
                >
                  Shared
                </Tag>
              )}
            {current && current === nodeData.id && (
              <Tag
                color="#3700b366"
                style={{
                  marginLeft: '5px',
                  padding: '2px 5px 2px 4px',
                  lineHeight: '11px',
                }}
              >
                Open
              </Tag>
            )}
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default WorkbookObject;
