// Imports
import React, { useMemo } from 'react';
import { Modal, Button, Table, Spin } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import { formatTimestampShort } from '../../formatter';
import Spinner from '../common/Spinner';

const DatasourceHistoryModal = ({
  datasource,
  visible,
  setVisible,
  width,
  height,
  pageSize,
}) => {
  const {
    data: datasourceResp,
    loading,
    error,
  } = GraphQLServices.Datasources.useGetDatasourceByName({
    variables: {
      datasource_name: datasource.datasource_name,
    },
  });

  const load_history = useMemo(
    _ => {
      if (datasourceResp) {
        const { datasource } = datasourceResp;
        const { load_history = [] } = datasource;
        return load_history.map((record, idx) => {
          return {
            ...record,
            __record_id: idx,
          };
        });
      }
      return null;
    },
    [datasourceResp]
  );

  const renderColumn = text => {
    return text !== undefined && text !== null ? (
      isNaN(text) ? (
        <div
          style={{
            textAlign: text.toString().length === 1 ? 'center' : 'left',
          }}
        >
          {text}
        </div>
      ) : (
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {text.length !== 13
            ? text
            : formatTimestampShort(`${Math.round(parseInt(text) / 1000)}`)}
        </div>
      )
    ) : (
      ''
    );
  };

  const columns = useMemo(
    _ => {
      if (datasourceResp) {
        const { datasource } = datasourceResp;
        const { load_history = [] } = datasource;
        if (load_history.length > 0) {
          return Object.keys(load_history[0])
            .filter(column => column !== '__typename')
            .map(column => {
              return {
                key: column,
                title: column,
                dataIndex: column,
                render: renderColumn,
              };
            });
        }
      }
      return [];
    },
    [datasourceResp]
  );

  return (
    <Modal
      title={`Data Source Load History: ${datasource.datasource_name}`}
      visible={visible || loading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={loading}>
        <div
          style={{
            height,
            overflow: 'scroll',
          }}
        >
          {load_history && (
            <Table
              rowKey="__record_id"
              columns={columns}
              dataSource={load_history}
              pagination={{
                pageSize,
                pageSizeOptions: [pageSize],
                showSizeChanger: false,
              }}
              scroll={{
                x: 'max-content',
              }}
              size="small"
            />
          )}
          {error && (
            <pre className="console">Query failed: {error.message}</pre>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default DatasourceHistoryModal;
