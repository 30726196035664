// Imports
import { useQuery, useMutation, gql } from '@apollo/client';

// App Imports
import GraphQLServices from '../services';
import { SQL_BLOCK_TYPE } from '../../constants';

export const GET_WORKBOOKS = gql`
  query {
    workbooks {
      id
      name
      description
      metadata
      config
      user {
        id
        username
      }
      worksheets {
        id
        name
        description
        config
        previous_worksheet_id
        next_worksheet_id
        workbook_id
        created_at
        updated_at
      }
      last_update
      is_example
      is_shared
      created_at
      updated_at
    }
  }
`;

export const GET_WORKBOOKS_ALL = gql`
  query {
    workbooksAll {
      id
      name
      description
      metadata
      config
      user {
        id
        username
      }
      worksheets {
        id
        name
        description
        config
        previous_worksheet_id
        next_worksheet_id
        workbook_id
        created_at
        updated_at
      }
      last_update
      is_example
      is_shared
      created_at
      updated_at
    }
  }
`;

export const GET_WORKBOOK_BY_ID = gql`
  query GetWorkbookById($id: ID!) {
    workbook(id: $id) {
      id
      name
      description
      metadata
      config
      user {
        id
        username
      }
      worksheets {
        id
        name
        description
        config
        previous_worksheet_id
        next_worksheet_id
        workbook_id
        created_at
        updated_at
      }
      last_update
      is_example
      is_shared
      created_at
      updated_at
    }
  }
`;

export const CREATE_WORKBOOK = gql`
  mutation CreateWorkbook(
    $name: String!
    $description: String
    $config: JSON!
    $user_id: ID!
  ) {
    workbookCreate(
      name: $name
      description: $description
      config: $config
      user_id: $user_id
    ) {
      id
      name
      description
      metadata
      config
      user {
        id
        username
      }
      worksheets {
        id
        name
        description
        config
        previous_worksheet_id
        next_worksheet_id
        workbook_id
        created_at
        updated_at
      }
      last_update
      is_example
      is_shared
      created_at
      updated_at
    }
  }
`;

export const UPDATE_WORKBOOK_BY_ID = gql`
  mutation UpdateWorkbookById(
    $id: ID!
    $name: String
    $description: String
    $config: JSON
    $is_shared: Boolean
  ) {
    workbookUpdateById(
      id: $id
      name: $name
      description: $description
      config: $config
      is_shared: $is_shared
    ) {
      id
      name
      description
      metadata
      config
      user {
        id
        username
      }
      worksheets {
        id
        name
        description
        config
        previous_worksheet_id
        next_worksheet_id
        workbook_id
        created_at
        updated_at
      }
      last_update
      is_example
      is_shared
      created_at
      updated_at
    }
  }
`;

export const REMOVE_WORKBOOK_BY_ID = gql`
  mutation RemoveWorkbookById($id: ID!) {
    workbookRemoveById(id: $id) {
      id
    }
  }
`;

export const IMPORT_WORKBOOK = gql`
  mutation ImportWorkbook($data: JSON!, $overrides: JSON) {
    workbookImport(data: $data, overrides: $overrides) {
      id
      name
      description
    }
  }
`;

export const EXPORT_WORKBOOK = gql`
  mutation ExportWorkbook($id: ID!) {
    workbookExport(id: $id)
  }
`;

export const EXPORT_ALL_WORKBOOKS = gql`
  mutation ExportAllWorkbooks {
    workbookExportAll
  }
`;

export const COPY_WORKBOOK = gql`
  mutation CopyWorkbook($id: ID!, $overrides: JSON) {
    workbookCopy(id: $id, overrides: $overrides) {
      id
      name
      description
    }
  }
`;

export const useGetWorkbooks = () => {
  return useQuery(GET_WORKBOOKS);
};

export const useGetWorkbooksAll = () => {
  return useQuery(GET_WORKBOOKS_ALL);
};

export const useGetWorkbookById = options => {
  return useQuery(GET_WORKBOOK_BY_ID, options);
};

export const useCreateWorkbook = () => {
  return useMutation(CREATE_WORKBOOK);
};

export const useUpdateWorkbookById = () => {
  return useMutation(UPDATE_WORKBOOK_BY_ID);
};

export const useRemoveWorkbookById = () => {
  return useMutation(REMOVE_WORKBOOK_BY_ID);
};

export const useCreateWorkbookComplete = () => {
  const [createWorkbook] = GraphQLServices.Workbooks.useCreateWorkbook();
  const [createWorksheet] = GraphQLServices.Worksheets.useCreateWorksheet();
  const [createBlock] = GraphQLServices.Blocks.useCreateBlock();

  return [
    args => {
      return createWorkbook(args)
        .then(resp => {
          const { id: workbook_id } = resp?.data?.workbookCreate;
          return createWorksheet({
            variables: {
              name: 'Sheet 1',
              description: 'Description for Sheet 1',
              config: {},
              previous_worksheet_id: null,
              next_worksheet_id: null,
              workbook_id,
            },
          });
        })
        .then(resp => {
          const { id: worksheet_id } = resp?.data?.worksheetCreate;
          return createBlock({
            variables: {
              name: 'Block 1',
              description: 'Description for Block 1',
              content: '',
              config: {},
              previous_block_id: null,
              next_block_id: null,
              block_type_id: SQL_BLOCK_TYPE,
              worksheet_id,
            },
          });
        });
    },
  ];
};

export const useImportWorkbook = () => {
  return useMutation(IMPORT_WORKBOOK);
};

export const useExportWorkbook = () => {
  return useMutation(EXPORT_WORKBOOK);
};

export const useExportAllWorkbooks = () => {
  return useMutation(EXPORT_ALL_WORKBOOKS);
};

export const useCopyWorkbook = () => {
  return useMutation(COPY_WORKBOOK);
};
