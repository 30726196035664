// Imports
import React, { useMemo, useState, useEffect } from 'react';
import { Form, Button, Popconfirm, Input, Select, Empty } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

// App Imports
import GraphQLServices from '../../graphql/services';
import { displaySuccess } from '../../helper';

const DEFAULT_FORM_ITEM_PROPS = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

const ImportModelForm = () => {
  const { refetch: refetchModels } = GraphQLServices.Models.useGetModels();
  const { data: { container_registries = [] } = {} } =
    GraphQLServices.ContainerRegistries.useGetContainerRegistries();
  const [importModel] = GraphQLServices.Models.useImportModel();

  const [isImporting, setIsImporting] = useState(false);

  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  useEffect(
    _ => {
      if (location?.state?.registry_name) {
        form.setFieldsValue({
          registry_name: location.state.registry_name,
        });
      }
    },
    [location, form]
  );

  const resetForm = _ => {
    form.resetFields();
  };

  const onFinish = async values => {
    const { model_name, registry_name, path_to_container, model_run_function } =
      values;

    try {
      setIsImporting(true);
      const importModelResp = await importModel({
        variables: {
          model_name,
          registry_name,
          path_to_container,
          model_run_function,
        },
      });

      if (importModelResp?.errors) {
        throw new Error(importModelResp?.errors[0]?.message);
      }

      displaySuccess(`Model ${model_name} imported.`);
      resetForm();
      refetchModels();
    } catch (error) {
      // Graphql client should display error
    } finally {
      setIsImporting(false);
    }
  };

  const create = _ => {
    form.submit();
  };

  const handleCancel = e => {
    history.push('/');
  };

  const handleContainerRegistryChange = container_registry => {
    form.setFieldsValue({
      container_registry,
    });
  };

  const containerRegistryOptions = useMemo(
    _ => {
      return container_registries
        ? container_registries.map(container_registry => ({
            label: container_registry.registry_name,
            value: container_registry.registry_name,
          }))
        : [];
    },
    [container_registries]
  );

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={{}}
      onFinish={onFinish}
      colon={false}
    >
      <div
        style={{
          height: 'calc(100vh - 300px)',
          overflow: 'auto',
        }}
      >
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          name="model_name"
          label="Model Name"
          rules={[
            {
              required: true,
              message: 'Please enter a model name.',
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          label="Container Registry"
          name="registry_name"
          extra="New container registry must first be created from the data explorer."
          rules={[
            {
              required: true,
              message: 'Please select a container registry.',
            },
          ]}
        >
          <Select
            onChange={handleContainerRegistryChange}
            placeholder="Select an existing container registry"
            notFoundContent={
              <Empty
                description="No container registry available"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            }
          >
            {containerRegistryOptions.map(option => {
              return (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          name="path_to_container"
          label="Container Path"
          rules={[
            {
              required: true,
              message: 'Please enter a container path.',
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          name="model_run_function"
          label="Run Function"
          rules={[
            {
              required: true,
              message: 'Please enter a run function.',
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
      </div>
      <div>
        <Button
          type="primary"
          onClick={create}
          style={{ float: 'right' }}
          loading={isImporting}
        >
          Import Model
        </Button>
        <Popconfirm
          title="Are you sure you want to cancel?"
          onConfirm={handleCancel}
        >
          <Button style={{ float: 'right', marginRight: '10px' }} danger>
            Cancel
          </Button>
        </Popconfirm>
      </div>
    </Form>
  );
};

export default ImportModelForm;
