// Imports
import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_FOLDERS = gql`
  query Folders {
    folders {
      id
      name
      user
      permission
      data_usage
      data_limit
      files {
        id
        name
        folder
        path
        size
        user
        creation_time
      }
      creation_time
    }
  }
`;

export const GET_FOLDER_BY_NAME = gql`
  query GetFolderByName($name: String!) {
    folder(name: $name) {
      id
      name
      user
      permission
      data_usage
      data_limit
      files {
        id
        name
        folder
        path
        size
        user
        creation_time
      }
      creation_time
    }
  }
`;

export const GET_FILES_BY_DATA_SOURCE = gql`
  query GetFilesByDataSource(
    $source_type: String!
    $path: String!
    $options: JSON
  ) {
    files(source_type: $source_type, path: $path, options: $options) {
      id
      name
      folder
      path
    }
  }
`;

export const GET_FILES_BY_PATHS = gql`
  query GetFilesByPaths($paths: [String]!, $options: JSON) {
    filesByPaths(paths: $paths, options: $options) {
      id
      name
      folder
      path
      size
      user
      creation_time
    }
  }
`;

export const DELETE_BY_NAMES = gql`
  mutation DeleteByNames($names: [String]!) {
    deleteByNames(names: $names) {
      name
    }
  }
`;

export const CREATE_FOLDER = gql`
  mutation CreateFolder($name: String!) {
    folderCreate(name: $name) {
      name
    }
  }
`;

export const DELETE_FOLDER_BY_NAME = gql`
  mutation DeleteFolderByName($name: String!) {
    folderDeleteByName(name: $name) {
      name
    }
  }
`;

export const GRANT_FOLDER_PERMISSION = gql`
  mutation GrantFolderPermission(
    $name: String!
    $permission: String!
    $directory_name: String!
  ) {
    folderPermissionGrant(
      name: $name
      permission: $permission
      directory_name: $directory_name
    ) {
      name
      permission
      directory_name
    }
  }
`;

export const REVOKE_FOLDER_PERMISSION = gql`
  mutation RevokeFolderPermission(
    $name: String!
    $permission: String!
    $directory_name: String!
  ) {
    folderPermissionRevoke(
      name: $name
      permission: $permission
      directory_name: $directory_name
    ) {
      name
      permission
      directory_name
    }
  }
`;

export const useGetFolders = () => {
  return useQuery(GET_FOLDERS);
};

export const useGetFolderByName = options => {
  return useQuery(GET_FOLDER_BY_NAME, options);
};

export const useGetFilesByDataSource = options => {
  return useQuery(GET_FILES_BY_DATA_SOURCE, options);
};

export const useGetFilesByPaths = options => {
  return useQuery(GET_FILES_BY_PATHS, options);
};

export const useDeleteByNames = () => {
  return useMutation(DELETE_BY_NAMES);
};

export const useCreateFolder = () => {
  return useMutation(CREATE_FOLDER);
};

export const useDeleteFolderByName = () => {
  return useMutation(DELETE_FOLDER_BY_NAME);
};

export const useGrantFolderPermission = () => {
  return useMutation(GRANT_FOLDER_PERMISSION);
};

export const useRevokeFolderPermission = () => {
  return useMutation(REVOKE_FOLDER_PERMISSION);
};
