// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_DATA_TABLES = gql`
  query DataObjects {
    schemas {
      name
    }
    tables {
      schema
      name
      full
      type
      columns {
        name
      }
    }
  }
`;

export const GET_DATA_OBJECTS = gql`
  query DataObjects {
    graphs {
      graph_name
      directed
      num_nodes
      num_edges
      num_bytes
      is_persisted
      is_partitioned
      is_sync_db
      has_insert_table_monitor
      graph_server_id
      original_request
    }
    datasources {
      datasource_name
      storage_provider_type
      additional_info
    }
    datasinks {
      datasink_name
      destination_type
      additional_info
    }
    table_monitors {
      monitor_id
      table_name
      event
      increasing_column
      filter_expression
      refresh_method
      refresh_period
      refresh_start_time
      datasink_name
      additional_info
    }
    container_registries {
      registry_name
      registry_uri
      credential_name
    }
    models {
      model_name
      registry_name
      path_to_container
      model_run_function
    }
  }
`;

export const useGetDataObjects = () => {
  return useQuery(GET_DATA_OBJECTS);
};

export const useGetDataTables = () => {
  return useQuery(GET_DATA_TABLES);
};
