// Imports
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout, Breadcrumb } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import NavBar from '../../components/navbar/NavBar';
import Footer from '../../components/footer/Footer';
import ExplorerSider from '../common/ExplorerSider';
import ImportWizard from './ImportWizard';
import ImportCatalog from './ImportCatalog';
import SourceUpload from './SourceUpload';
import SourceKifs from './SourceKifs';
import SourceAzure from './SourceAzure';
import SourceS3 from './SourceS3';
import SourceHdfs from './SourceHdfs';
import SourceKafka from './SourceKafka';
import SourceJdbc from './SourceJdbc';
import SourceCData from './SourceCData';

const { Header, Content } = Layout;

// Component
const ImportExport = () => {
  const { data: { cdata_drivers = [] } = {} } =
    GraphQLServices.CDataDrivers.useGetCDataDrivers();

  return (
    <Layout className="importexport" style={{ height: '100vh' }}>
      <Header className="header" style={{ padding: '0px', minWidth: '1280px' }}>
        <NavBar />
      </Header>
      <Layout hasSider>
        <ExplorerSider />
        <Content>
          <Layout style={{ height: 'calc(100vh - 55px)', minWidth: '930px' }}>
            <Content
              style={{
                padding: '0px 20px',
                height: 'calc(100vh - 210px)',
                overflowY: 'scroll',
              }}
            >
              <Route path="/importexport">
                <Breadcrumb style={{ margin: '9px 0' }}>
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                  <Breadcrumb.Item>Import</Breadcrumb.Item>
                </Breadcrumb>
              </Route>
              <Switch>
                <Route path="/importexport/wizard" exact>
                  <ImportWizard />
                </Route>
                <Route path="/importexport/upload" exact>
                  <SourceUpload />
                </Route>
                <Route path="/importexport/kifs" exact>
                  <SourceKifs />
                </Route>
                <Route path="/importexport/azure" exact>
                  <SourceAzure />
                </Route>
                <Route path="/importexport/s3" exact>
                  <SourceS3 />
                </Route>
                <Route path="/importexport/kafka" exact>
                  <SourceKafka />
                </Route>
                <Route path="/importexport/jdbc" exact>
                  <SourceJdbc />
                </Route>
                <Route path="/importexport/hdfs" exact>
                  <SourceHdfs />
                </Route>
                {cdata_drivers.map(driver => (
                  <Route key={driver.route} path={driver.route} exact>
                    <SourceCData driver={driver} drivers={cdata_drivers} />
                  </Route>
                ))}
                <Route path="/importexport" exact>
                  <ImportCatalog />
                </Route>
              </Switch>
            </Content>
            <Footer />
          </Layout>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ImportExport;
