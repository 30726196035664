// Imports
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// App Imports
import Loading from '../../containers/Loading';
import { logout } from '../../store/auth/actions';
import { routes } from '../../setup/routes';
import useAnalytics from '../../hooks/useAnalytics';

// Component
const Logout = () => {
  const dispatch = useDispatch();

  const analytics = useAnalytics();

  // On mount
  useEffect(() => {
    dispatch(logout()).then(success => {
      analytics.track(analytics.EVENT_TYPES.LOGGED_OUT)(_ => {
        window.location.href = routes.login;
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return <Loading />;
};

export default Logout;
