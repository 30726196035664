// Imports
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Alert, Spin } from 'antd';
import { useIdleTimer } from 'react-idle-timer';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

// App Imports
import { UserContext } from '../context';
import useWebappStatus from '../hooks/useWebappStatus';
import { USER_IDLE_TIMEOUT } from '../setup/config';
import GraphQLServices from '../graphql/services';
import { routes } from '../setup/routes';
import { getUser } from '../store/auth/actions';
import ProtectedRoute from '../components/common/ProtectedRoute';
import Login from '../components/auth/Login';
import Logout from '../components/auth/Logout';
import Landing from '../containers/landing/Landing';
import DataExplore from '../containers/dataexplore/DataExplore';
import DataExploreEmbed from '../containers/dataexplore/DataExploreEmbed';
import ImportExport from '../containers/importexport/ImportExport';
import Jobs from '../containers/jobs/Jobs';
import Warehouse from '../containers/warehouse/Warehouse';
import Metrics from '../containers/metrics/Metrics';
import Debug from '../containers/debug/Debug';
import Preferences from '../containers/preferences/Preferences';
import Users from '../containers/users/Users';
import CreateTable from '../containers/createtable/CreateTable';
import CreateExternalTable from '../containers/createexternaltable/CreateExternalTable';
import CreateGraph from '../containers/creategraph/CreateGraph';
import SolveGraph from '../containers/solvegraph/SolveGraph';
import ImportModel from '../containers/importmodel/ImportModel';
import CreateStream from '../containers/createstream/CreateStream';
import ConfigureModel from '../containers/configuremodel/ConfigureModel';
import ExecuteModel from '../containers/executemodel/ExecuteModel';
import Permission from '../components/security/Permission';
import Loading from './Loading';
import { logout } from '../store/auth/actions';
import { formatTimestamp } from '../formatter';
import { getPermissionList, sleep } from '../helper';
import { INTERCOM_APP_ID, CUSTOMER_INFO_JSON } from '../setup/config';
import useAnalytics from '../hooks/useAnalytics';
import Spinner from '../components/common/Spinner';
import './App.less';

Spin.setDefaultIndicator(Spinner);

const IntercomBoot = ({ user, children }) => {
  const { boot } = useIntercom();
  if (INTERCOM_APP_ID) {
    const { username, cloud_user_id = '', cloud_group_id = '' } = user;
    try {
      const customerInfo = JSON.parse(CUSTOMER_INFO_JSON);
      if (Object.keys(customerInfo).length > 0) {
        delete customerInfo.name;
        boot({
          userId: cloud_user_id || customerInfo.managed_resource_group_id,
          email_addr: customerInfo.customer_email,
          customAttributes: {
            ...customerInfo,
            kinetica_username: username,
            group_id: cloud_group_id,
          },
        });
      } else {
        throw new Error('No customer info');
      }
    } catch (err) {
      if (cloud_user_id && cloud_group_id) {
        boot({
          userId: cloud_user_id,
          customAttributes: {
            kinetica_username: username,
            group_id: cloud_group_id,
          },
        });
      } else {
        boot({
          customAttributes: {
            kinetica_username: username,
          },
        });
      }
    }
  }
  return children;
};

const IntercomWrapper = ({ children }) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
      <UserContext.Consumer>
        {userMe => {
          return <IntercomBoot user={userMe}>{children}</IntercomBoot>;
        }}
      </UserContext.Consumer>
    </IntercomProvider>
  );
};

// Wrapper for analytics
const Analytics = ({ children }) => {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    if (!location.pathname.toLowerCase().includes('login')) {
      analytics.page();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return children;
};

// Component
const App = () => {
  const { data: { userMe = {} } = {} } =
    GraphQLServices.Users.useGetLocalUserMe();

  const { isUserLoaded } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const analytics = useAnalytics();

  const callOnActivate = useCallback(
    setActive => {
      history.push(routes.root);
      window.location.reload();
    },
    [history]
  );
  const { isActive: isWebappActive } = useWebappStatus(callOnActivate);

  // On mount
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const handleOnIdle = event => {
    console.debug(
      'User became idle since',
      formatTimestamp(getLastActiveTime() / 1000)
    );
    dispatch(logout()).then(success => {
      if (success) {
        console.debug('User logged out', success);
        analytics.track(analytics.EVENT_TYPES.LOGGED_OUT)(_ => {
          window.location.reload();
        });
      }
    });
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: USER_IDLE_TIMEOUT * 60 * 1000,
    onIdle: handleOnIdle,
    debounce: 500,
    eventsThrottle: 500,
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  useEffect(
    _ => {
      const init = async () => {
        if (userMe?.username) {
          const {
            username,
            isGlobalAdmin: isAdmin,
            isDbStatusOk: isGpudbUser,
            cloud_user_id = '',
            cloud_group_id = '',
          } = userMe;
          console.groupCollapsed('User Info');
          console.table({
            username,
            isAdmin,
            isGpudbUser,
            cloud_user_id,
            cloud_group_id,
          });
          console.table(getPermissionList(userMe).map(perm => perm.name));
          console.groupEnd();

          if (userMe.cloud_user_id && userMe.cloud_group_id) {
            analytics.identify(userMe.cloud_user_id);
            await sleep(200);
            analytics.group(userMe.cloud_group_id);
          } else if (window.WORKBENCH_ENV.CUSTOMER_INFO_JSON) {
            try {
              var customerInfo = JSON.parse(
                window.WORKBENCH_ENV.CUSTOMER_INFO_JSON
              );
              if (Object.keys(customerInfo).length > 0) {
                analytics.identify(customerInfo.managed_resource_group_id);
              }
            } catch (err) {
              console.error(err);
            }
          }
        }
      };
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userMe]
  );

  return isUserLoaded &&
    isWebappActive &&
    (!userMe || Object.keys(userMe).length > 0) ? (
    <UserContext.Provider value={userMe}>
      <div
        style={{
          height: '100vh',
        }}
      >
        <Analytics>
          <Switch>
            {/* Auth */}
            <Route path={routes.login} exact>
              <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
                <Login />
              </IntercomProvider>
            </Route>
            <Route path={routes.logout} exact>
              <Logout />
            </Route>

            {/* Home */}
            <ProtectedRoute path={routes.root} exact>
              <IntercomWrapper>
                <Landing />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Home */}
            <ProtectedRoute path={routes.home} exact>
              <IntercomWrapper>
                <Landing />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Explore */}
            <ProtectedRoute path={routes.dataexplore.home} exact>
              <IntercomWrapper>
                <DataExplore />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Workbook */}
            <ProtectedRoute path={routes.dataexplore.workbook}>
              <IntercomWrapper>
                <DataExplore />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Workbook Embed */}
            <ProtectedRoute path={routes.dataexplore.embed}>
              <DataExploreEmbed />
            </ProtectedRoute>

            {/* Create Table */}
            <ProtectedRoute path={routes.dataexplore.createtable} exact>
              <IntercomWrapper>
                <CreateTable />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Edit Table */}
            <ProtectedRoute path={routes.dataexplore.edittable} exact>
              <IntercomWrapper>
                <CreateTable />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Create External Table */}
            <ProtectedRoute path={routes.dataexplore.createexternaltable} exact>
              <IntercomWrapper>
                <CreateExternalTable />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Create Graph */}
            <ProtectedRoute path={routes.dataexplore.creategraph} exact>
              <IntercomWrapper>
                <CreateGraph />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Solve Graph */}
            <ProtectedRoute path={routes.dataexplore.solvegraph} exact>
              <IntercomWrapper>
                <SolveGraph />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Import Model */}
            <ProtectedRoute path={routes.dataexplore.importmodel} exact>
              <IntercomWrapper>
                <ImportModel />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Configure Model */}
            <ProtectedRoute path={routes.dataexplore.configuremodel} exact>
              <IntercomWrapper>
                <ConfigureModel />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Execute Model */}
            <ProtectedRoute path={routes.dataexplore.executemodel} exact>
              <IntercomWrapper>
                <ExecuteModel />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Create Stream */}
            <ProtectedRoute path={routes.dataexplore.createstream} exact>
              <IntercomWrapper>
                <CreateStream />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Import/Export */}
            <ProtectedRoute path={routes.importexport.home} exact>
              <IntercomWrapper>
                <ImportExport />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Import/Export Sources */}
            <ProtectedRoute path={routes.importexport.source}>
              <IntercomWrapper>
                <ImportExport />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Jobs */}
            <ProtectedRoute path={routes.jobs.home} exact>
              <IntercomWrapper>
                <Jobs />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Warehouse */}
            <ProtectedRoute path={routes.warehouse.home} exact>
              <Permission everyKeys={['manage_warehouse']}>
                <IntercomWrapper>
                  <Warehouse />
                </IntercomWrapper>
              </Permission>
            </ProtectedRoute>

            {/* Warehouse Tabs */}
            <ProtectedRoute path={routes.warehouse.tab}>
              <Permission everyKeys={['manage_warehouse']}>
                <IntercomWrapper>
                  <Warehouse />
                </IntercomWrapper>
              </Permission>
            </ProtectedRoute>

            {/* Metrics */}
            <ProtectedRoute path={routes.metrics.home} exact>
              <Permission everyKeys={['manage_warehouse']}>
                <IntercomWrapper>
                  <Metrics />
                </IntercomWrapper>
              </Permission>
            </ProtectedRoute>

            {/* Users */}
            <ProtectedRoute path={routes.users.home} exact>
              <Permission everyKeys={['manage_user']}>
                <IntercomWrapper>
                  <Users />
                </IntercomWrapper>
              </Permission>
            </ProtectedRoute>

            {/* User Tabs */}
            <ProtectedRoute path={routes.users.tab}>
              <Permission everyKeys={['manage_user']}>
                <IntercomWrapper>
                  <Users />
                </IntercomWrapper>
              </Permission>
            </ProtectedRoute>

            {/* Preferences */}
            <ProtectedRoute path={routes.preferences.home} exact>
              <IntercomWrapper>
                <Preferences />
              </IntercomWrapper>
            </ProtectedRoute>

            {/* Debug */}
            <ProtectedRoute path={routes.debug.home} exact>
              <Permission everyKeys={['manage_warehouse']}>
                <IntercomWrapper>
                  <Debug />
                </IntercomWrapper>
              </Permission>
            </ProtectedRoute>

            {/* Default */}
            <Route>
              <Redirect to={routes.root} />
            </Route>
          </Switch>
        </Analytics>
      </div>
    </UserContext.Provider>
  ) : (
    <div
      style={{
        height: '100vh',
      }}
    >
      {!isWebappActive && (
        <Alert
          message={
            'Workbench is currently unavailable. ' +
            'This page will refresh once it becomes available.'
          }
          banner
        />
      )}
      <Loading />
    </div>
  );
};

export default App;
