// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_TABLES = gql`
  query Tables {
    tables {
      schema
      name
      additional_info
      full
      size
      columns {
        name
        type
        table_name
        properties
      }
    }
  }
`;

export const GET_TABLES_ONLY = gql`
  query Tables {
    tables {
      schema
      name
      additional_info
      full
      size
    }
  }
`;

export const GET_TABLE_BY_NAME = gql`
  query GetTableByName($schema: String, $name: String!) {
    table(schema: $schema, name: $name) {
      schema
      name
      type
      distribution
      features
      access
      keys
      ttl
      chunk_size
      additional_info
      full
      size
      memory
      columns {
        name
        type
        table_name
        properties
        compression
        memory
        index
        indexMemory
      }
    }
  }
`;

export const GET_TABLE_RECORDS_BY_NAME = gql`
  query GetTableRecordsByName(
    $schema: String!
    $name: String!
    $offset: Int
    $limit: Int
    $max_json_string_length: Int
  ) {
    table_records(
      schema: $schema
      name: $name
      offset: $offset
      limit: $limit
      max_json_string_length: $max_json_string_length
    ) {
      schema
      name
      offset
      limit
      info
      data
    }
  }
`;

export const GET_TABLE_COLUMN_STATS = gql`
  query GetTableColumnStats($schema: String!, $name: String!) {
    table(schema: $schema, name: $name) {
      schema
      name
      additional_info
      full
      size
      columns {
        name
        type
        table_name
        properties
        column_stats {
          stats
        }
      }
    }
  }
`;

export const GET_CUSTOM_COLUMN_STATS = gql`
  query GetCustomColumnStats(
    $table_name: String!
    $column_name: String!
    $stats: String!
  ) {
    column_custom_stat(
      table_name: $table_name
      column_name: $column_name
      stats: $stats
    ) {
      stats
    }
  }
`;

export const GET_COLUMN_UNIQUE_COUNTS = gql`
  query GetUniqueValueCounts($table_name: String!, $column_name: String!) {
    column_unique_counts(table_name: $table_name, column_name: $column_name) {
      counts
    }
  }
`;

export const GET_TABLE_GEO_EXTENT = gql`
  query GetTableGeoExtent(
    $schema: String!
    $name: String!
    $longitude: String
    $latitude: String
    $wkt: String
  ) {
    tableGeoExtent(
      schema: $schema
      name: $name
      longitude: $longitude
      latitude: $latitude
      wkt: $wkt
    ) {
      schema
      name
      extent
    }
  }
`;

export const DELETE_TABLE_BY_SCHEMA_AND_NAME = gql`
  mutation DeleteTableBySchemaAndName($schema: String!, $name: String!) {
    tableRemoveBySchemaAndName(schema: $schema, name: $name) {
      schema
      name
    }
  }
`;

export const TABLE_INSERT_RECORDS = gql`
  mutation TableInsertRecords(
    $schema: String!
    $name: String!
    $records: JSON!
  ) {
    tableInsertRecords(schema: $schema, name: $name, records: $records)
  }
`;

export const TABLE_CREATE_TABLE = gql`
  mutation TableCreateTable(
    $schema: String
    $name: String!
    $fields: JSON!
    $properties: JSON!
    $options: JSON!
  ) {
    tableCreateTable(
      schema: $schema
      name: $name
      fields: $fields
      properties: $properties
      options: $options
    )
  }
`;

export const TABLE_RENAME_TABLE = gql`
  mutation TableRenameTable(
    $schema: String!
    $oldName: String!
    $newName: String!
  ) {
    tableRenameTable(schema: $schema, oldName: $oldName, newName: $newName)
  }
`;

export const TABLE_MOVE_TABLE = gql`
  mutation TableMoveTable(
    $schema: String!
    $name: String!
    $newSchema: String!
  ) {
    tableMoveTable(schema: $schema, name: $name, newSchema: $newSchema)
  }
`;

export const TABLE_ALTER_TABLE = gql`
  mutation TableRenameTable(
    $schema: String!
    $name: String!
    $action: String!
    $value: String!
    $options: JSON!
  ) {
    tableRenameTable(schema: $schema, oldName: $oldName, newName: $newName)
  }
`;

export const TABLE_ALTER_COLUMNS = gql`
  mutation TableAlterColumns(
    $schema: String!
    $name: String!
    $alterations: JSON!
  ) {
    tableAlterColumns(schema: $schema, name: $name, alterations: $alterations)
  }
`;

export const TABLE_CREATE_EXTERNAL_TABLE = gql`
  mutation TableCreateExternalTable(
    $schema: String
    $name: String!
    $filepaths: JSON!
    $fields: JSON!
    $properties: JSON!
    $createOptions: JSON!
    $options: JSON!
  ) {
    tableCreateExternalTable(
      schema: $schema
      name: $name
      filepaths: $filepaths
      fields: $fields
      properties: $properties
      createOptions: $createOptions
      options: $options
    )
  }
`;

export const useGetTables = () => {
  return useQuery(GET_TABLES);
};

export const useGetTablesOnly = () => {
  return useQuery(GET_TABLES_ONLY);
};

export const useGetTableByName = options => {
  return useQuery(GET_TABLE_BY_NAME, options);
};

export const useGetTableRecordsByName = options => {
  return useQuery(GET_TABLE_RECORDS_BY_NAME, options);
};

export const useGetTableColumnStats = options => {
  return useQuery(GET_TABLE_COLUMN_STATS, options);
};

export const useGetCustomColumnStats = options => {
  return useQuery(GET_CUSTOM_COLUMN_STATS, options);
};

export const useGetTableGeoExtent = options => {
  return useQuery(GET_TABLE_GEO_EXTENT, options);
};

export const useDeleteTableBySchemaAndName = () => {
  return useMutation(DELETE_TABLE_BY_SCHEMA_AND_NAME);
};

export const useTableInsertRecords = () => {
  return useMutation(TABLE_INSERT_RECORDS);
};

export const useTableCreateTable = () => {
  return useMutation(TABLE_CREATE_TABLE);
};

export const useTableRenameTable = () => {
  return useMutation(TABLE_RENAME_TABLE);
};

export const useTableMoveTable = () => {
  return useMutation(TABLE_MOVE_TABLE);
};

export const useTableAlterColumns = () => {
  return useMutation(TABLE_ALTER_COLUMNS);
};

export const useTableCreateExternalTable = () => {
  return useMutation(TABLE_CREATE_EXTERNAL_TABLE);
};
