// Imports
import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_USERS = gql`
  query Users {
    users {
      id
      username
      passwordResetRequired
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
      }
      permissions {
        id
        name
        label
        description
      }
      preference {
        id
        params
      }
      kinetica_user {
        name
        type
        roles
        kinetica_roles {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        default_schema
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const GET_LOCAL_USERS = gql`
  query {
    users {
      id
      username
      passwordResetRequired
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
      }
      permissions {
        id
        name
        label
        description
      }
      preference {
        id
        params
      }
      created_at
      updated_at
    }
  }
`;

export const CHECK_USER_BY_USERNAME = gql`
  query CheckUserByUsername($username: String!) {
    user(username: $username) {
      id
      username
    }
  }
`;

export const GET_USER_BY_USERNAME = gql`
  query GetUserByUsername($username: String!) {
    user(username: $username) {
      id
      username
      passwordResetRequired
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
      }
      permissions {
        id
        name
        label
        description
      }
      preference {
        id
        params
      }
      kinetica_user {
        name
        type
        roles
        kinetica_roles {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        default_schema
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const GET_LOCAL_USER_ME = gql`
  query UserMe {
    userMe {
      id
      username
      passwordResetRequired
      isGlobalAdmin
      isDbStatusOk
      cloud_user_id
      cloud_group_id
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
      }
      permissions {
        id
        name
        label
        description
      }
      preference {
        id
        params
      }
      created_at
      updated_at
    }
  }
`;

export const GET_USER_ME = gql`
  query UserMeFull {
    userMe {
      id
      username
      passwordResetRequired
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
      }
      permissions {
        id
        name
        label
        description
      }
      preference {
        id
        params
      }
      kinetica_user {
        name
        type
        roles
        kinetica_roles {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        default_schema
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const GET_USER_ME_KINETICA_ONLY = gql`
  query UserMeKineticaOnly {
    userMe {
      id
      username
      kinetica_user {
        name
        type
        roles
        kinetica_roles {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        default_schema
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const GET_USER_ME_KINETICA_USAGE = gql`
  query UserMeKineticaUsage {
    userMe {
      id
      username
      kinetica_user {
        name
        type
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
      }
      created_at
      updated_at
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $username: String!
    $password: String!
    $first_name: String
    $last_name: String
    $type: String
    $auth_provider_id: ID!
    $ring: String!
  ) {
    userCreate(
      username: $username
      password: $password
      first_name: $first_name
      last_name: $last_name
      type: $type
      auth_provider_id: $auth_provider_id
      ring: $ring
    ) {
      id
      username
      passwordResetRequired
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
      }
      permissions {
        id
        name
        label
        description
      }
      preference {
        id
        params
      }
      kinetica_user {
        name
        type
        roles
        kinetica_roles {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        default_schema
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const UPDATE_PASSWORD_BY_NAME = gql`
  mutation UpdatePasswordByName(
    $username: String!
    $password: String!
    $ring: String!
  ) {
    userUpdatePasswordByUsername(
      username: $username
      password: $password
      ring: $ring
    ) {
      id
      username
      passwordResetRequired
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
      }
      permissions {
        id
        name
        label
        description
      }
      preference {
        id
        params
      }
      kinetica_user {
        name
        type
        roles
        kinetica_roles {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        default_schema
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const UPDATE_MY_PASSWORD = gql`
  mutation UpdateMyPassword($password: String!, $ring: String!) {
    userUpdateMyPassword(password: $password, ring: $ring) {
      id
      username
      passwordResetRequired
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
      }
      permissions {
        id
        name
        label
        description
      }
      preference {
        id
        params
      }
      kinetica_user {
        name
        type
        roles
        kinetica_roles {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        default_schema
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const ADD_ROLE_BY_NAME = gql`
  mutation AddRoleByName(
    $username: String!
    $role_name: String!
    $ring: String!
  ) {
    userAddRoleByName(username: $username, role_name: $role_name, ring: $ring) {
      id
      username
      passwordResetRequired
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
      }
      permissions {
        id
        name
        label
        description
      }
      preference {
        id
        params
      }
      kinetica_user {
        name
        type
        roles
        kinetica_roles {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        default_schema
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const REMOVE_ROLE_BY_NAME = gql`
  mutation RemoveRoleByName($username: String!, $role_name: String!) {
    userRemoveRoleByName(username: $username, role_name: $role_name) {
      id
    }
  }
`;

export const REMOVE_USER_BY_ID = gql`
  mutation RemoveUserById($id: ID!) {
    userRemoveById(id: $id) {
      id
    }
  }
`;

export const ALTER_USER_BY_NAME = gql`
  mutation AlterUserByName($name: String!, $action: String!, $value: String) {
    kineticaUserAlterByName(name: $name, action: $action, value: $value) {
      name
      type
      roles
      permissions
      resource_group {
        name
        alias
        max_data
        max_tier_priority
        max_scheduling_priority
        max_cpu_concurrency
        tier_attributes {
          name
          max_memory
        }
        usage
      }
    }
  }
`;

export const GRANT_PERMISSION_BY_NAME = gql`
  mutation AddPermissionByName($username: String!, $permission_name: String!) {
    userAddPermissionByName(
      username: $username
      permission_name: $permission_name
    ) {
      id
      username
      passwordResetRequired
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
      }
      permissions {
        id
        name
        label
        description
      }
      preference {
        id
        params
      }
      kinetica_user {
        name
        type
        roles
        kinetica_roles {
          name
          type
          permissions
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
        }
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        default_schema
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const REVOKE_PERMISSION_BY_NAME = gql`
  mutation RemovePermissionByName(
    $username: String!
    $permission_name: String!
  ) {
    userRemovePermissionByName(
      username: $username
      permission_name: $permission_name
    ) {
      id
    }
  }
`;

export const useGetUsers = () => {
  return useQuery(GET_USERS);
};

export const useGetLocalUsers = () => {
  return useQuery(GET_LOCAL_USERS);
};

export const useGetUserByUsername = variables => {
  return useQuery(GET_USER_BY_USERNAME, { variables });
};

export const useGetLocalUserMe = () => {
  return useQuery(GET_LOCAL_USER_ME);
};

export const useGetUserMe = () => {
  return useQuery(GET_USER_ME);
};

export const useGetUserMeKineticaOnly = () => {
  return useQuery(GET_USER_ME_KINETICA_ONLY);
};

export const useGetUserMeKineticaUsage = options => {
  return useQuery(GET_USER_ME_KINETICA_USAGE, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useCreateUser = () => {
  return useMutation(CREATE_USER);
};

export const useUpdatePasswordByUsername = () => {
  return useMutation(UPDATE_PASSWORD_BY_NAME);
};

export const useUpdateMyPassword = () => {
  return useMutation(UPDATE_MY_PASSWORD);
};

export const useAddRoleByName = () => {
  return useMutation(ADD_ROLE_BY_NAME);
};

export const useRemoveRoleByName = () => {
  return useMutation(REMOVE_ROLE_BY_NAME);
};

export const useRemoveUserById = () => {
  return useMutation(REMOVE_USER_BY_ID);
};

export const useAlterUserByName = () => {
  return useMutation(ALTER_USER_BY_NAME);
};

export const useGrantPermissionByName = () => {
  return useMutation(GRANT_PERMISSION_BY_NAME);
};

export const useRevokePermissionByName = () => {
  return useMutation(REVOKE_PERMISSION_BY_NAME);
};
