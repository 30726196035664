// Imports
import React, { useCallback, useState, useMemo } from 'react';
import { Alert, Button, Modal, Typography, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// App Imports
import BlockToolbar from './BlockToolbar';
import BlockIndex from './BlockIndex';
import Spinner from '../../components/common/Spinner';
import { useIsReadOnly } from './utils';

const ErrorBlock = ({ attributes, children, element, blocks }) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const readOnly = useIsReadOnly();

  const handleView = useCallback(_ => {
    setShowInfoModal(true);
  }, []);

  const closeModal = useCallback(_ => {
    setShowInfoModal(false);
  }, []);

  const index = useMemo(
    _ => {
      const { id } = element;
      return blocks.findIndex(e => e.id === id) + 1;
    },
    [blocks, element]
  );

  return (
    <div
      {...attributes}
      contentEditable={false}
      style={
        readOnly
          ? {
              margin: '0px',
              position: 'relative',
              padding: '0px 5px',
              pointerEvents: 'none',
            }
          : {
              margin: '16px 0px 26px',
              position: 'relative',
              border: '1px dotted #3700b322',
              borderLeft: '4px solid #3700b333',
              padding: '5px',
              pointerEvents: 'none',
            }
      }
    >
      <BlockIndex index={index} />
      <BlockToolbar element={element} blocks={blocks} setLoading={setLoading} />
      <Spin indicator={<Spinner />} spinning={loading}>
        <div style={{ pointerEvents: 'all' }}>
          <Alert
            type="error"
            message={
              <div>
                <Button
                  type="text"
                  onClick={handleView}
                  icon={<ExclamationCircleOutlined></ExclamationCircleOutlined>}
                ></Button>
                Error loading this block
              </div>
            }
          ></Alert>
        </div>
      </Spin>
      <div style={{ userSelect: 'none', display: 'none' }}>{children}</div>
      <Modal
        visible={showInfoModal}
        footer={[<Button onClick={closeModal}>Close</Button>]}
        onCancel={closeModal}
        title={`Block ${element.id}`}
      >
        <div>
          <Typography.Paragraph copyable={true}>
            <pre>{JSON.stringify(element, null, 4)}</pre>
          </Typography.Paragraph>
        </div>
      </Modal>
    </div>
  );
};

export default ErrorBlock;
