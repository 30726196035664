// Imports
import React from 'react';
import { Layout, Breadcrumb } from 'antd';

// App Imports
import NavBar from '../../components/navbar/NavBar';
import Footer from '../../components/footer/Footer';
import ExplorerSider from '../common/ExplorerSider';
import CreateGraphForm from './CreateGraphForm';

const { Header, Content } = Layout;

// Component
const CreateGraph = () => {
  return (
    <Layout style={{ height: '100vh' }}>
      <Header className="header" style={{ padding: '0px', minWidth: '1280px' }}>
        <NavBar />
      </Header>
      <Layout hasSider>
        <ExplorerSider />
        <Content>
          <Layout style={{ height: 'calc(100vh - 55px)', minWidth: '930px' }}>
            <Content
              style={{
                padding: '0px 20px',
                height: 'calc(100vh - 210px)',
                overflowY: 'scroll',
              }}
            >
              <Breadcrumb style={{ margin: '9px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Explore</Breadcrumb.Item>
                <Breadcrumb.Item>Create Graph</Breadcrumb.Item>
              </Breadcrumb>
              <h2>Create Graph</h2>
              <div
                style={{
                  padding: '20px 20px 0px',
                  backgroundColor: '#ffffff',
                  height: 'calc(100vh - 210px)',
                  overflow: 'auto',
                }}
              >
                <div
                  style={{
                    top: '0px',
                    left: '0px',
                    padding: '10px',
                  }}
                >
                  <CreateGraphForm />
                </div>
              </div>
            </Content>
            <Footer />
          </Layout>
        </Content>
      </Layout>
    </Layout>
  );
};

export default CreateGraph;
