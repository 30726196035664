// Imports
import React, { useMemo, useState } from 'react';
import {
  Layout,
  Breadcrumb,
  Button,
  Tooltip,
  Tabs,
  Spin,
  Table,
  Space,
} from 'antd';
import { RedoOutlined } from '@ant-design/icons';

// App Imports
import { useEveryPermission } from '../../context';
import GraphQLServices from '../../graphql/services';
import Spinner from '../../components/common/Spinner';
import { formatTimestamp } from '../../formatter';
import NavBar from '../../components/navbar/NavBar';
import Footer from '../../components/footer/Footer';
import ExplorerSider from '../common/ExplorerSider';
import {
  formatK8sTimestamp,
  formatCapitalizeFirstLetter,
} from '../../formatter';
import { DEPLOYMENT_TYPE } from '../../setup/config';

const { TabPane } = Tabs;
const { Header, Content } = Layout;

const JOB_FLAG_UNFLAGGED = {
  label: 'Running',
  value: '',
};
const JOB_FLAG_PERPETUAL = {
  label: 'Perpetual',
  value: 'perpetual',
};

const pageSize = 10;

// Component
const Jobs = () => {
  const {
    loading: jobsLoading,
    data: {
      endpoint_jobs: jobs,
      k8s_kineticaclusteradmins,
      k8s_kineticaclusterbackups,
      k8s_kineticaclusterrestores,
    } = {},
    refetch: refetchJobs,
  } = GraphQLServices.Jobs.useGetJobs({
    variables: {
      deployment_type: DEPLOYMENT_TYPE,
    },
  });
  const [cancelJobsByIds] =
    GraphQLServices.EndpointJobs.useAlterEndpointJobsById();

  const [isCancelling, setIsCancelling] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState([]);

  const [hasManagePermission] = useEveryPermission(['manage_warehouse']);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedJobs(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedJobs(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedJobs(selectedRows);
    },
    selectedRowKeys: selectedJobs.map(job => job.job_id),
  };

  const cancelSelected = _ => {
    setIsCancelling(true);

    const job_ids = selectedJobs.map(job => Number(job.job_id));
    cancelJobsByIds({
      variables: {
        job_ids,
        action: 'cancel',
      },
    })
      .then(resp => {
        refetchJobs({
          variables: {
            deployment_type: DEPLOYMENT_TYPE,
          },
        });
        setSelectedJobs([]);
      })
      .finally(_ => {
        setIsCancelling(false);
      });
  };

  const columns = [
    {
      key: 'job_id',
      title: 'Job ID',
      dataIndex: 'job_id',
      fixed: 'left',
    },
    {
      key: 'endpoint_name',
      title: 'Endpoint',
      dataIndex: 'endpoint_name',
      fixed: 'left',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
    },
    {
      key: 'time_received',
      title: 'Received',
      dataIndex: 'time_received',
      render: text => {
        return formatTimestamp(text);
      },
    },
    {
      key: 'auth_id',
      title: 'Auth ID',
      dataIndex: 'auth_id',
    },
    {
      key: 'source_ip',
      title: 'Source IP',
      dataIndex: 'source_ip',
    },
    {
      key: 'user_data',
      title: 'User Data',
      dataIndex: 'user_data',
    },
  ];

  const perpetualJobs = useMemo(
    _ => {
      return jobs
        ? jobs.filter(job => job.flag === JOB_FLAG_PERPETUAL.value)
        : [];
    },
    [jobs]
  );

  const runningJobs = useMemo(
    _ => {
      return jobs
        ? jobs.filter(
            job => job.flag === null || job.flag === JOB_FLAG_UNFLAGGED.value
          )
        : [];
    },
    [jobs]
  );

  const adminColumns = [
    {
      key: 'type',
      title: 'Activity',
      dataIndex: 'type',
      render: text => {
        return formatCapitalizeFirstLetter(text);
      },
    },
    {
      key: 'creationTimestamp',
      title: 'Initiated',
      dataIndex: 'creationTimestamp',
      width: 200,
      render: text => {
        return formatK8sTimestamp(text);
      },
    },
  ];

  const adminJobs = useMemo(
    _ => {
      return k8s_kineticaclusteradmins &&
        k8s_kineticaclusterbackups &&
        k8s_kineticaclusterrestores
        ? [
            ...k8s_kineticaclusteradmins,
            ...k8s_kineticaclusterbackups,
            ...k8s_kineticaclusterrestores,
          ]
            .map(cr => {
              let type = '';
              switch (cr?.kind) {
                case 'KineticaClusterBackup':
                  type = 'Backup';
                  break;
                case 'KineticaClusterRestore':
                  type = 'Restore';
                  break;
                case 'KineticaClusterAdmin':
                  type = cr?.spec?.offline?.offline ? 'suspend' : 'resume';
                  break;
                default:
                  type = 'Unknown';
              }
              const creationTimestamp = cr?.metadata?.creationTimestamp;
              return {
                id: `${type}_${creationTimestamp}`,
                name: cr?.metadata?.name,
                cluster: cr?.spec?.kineticaClusterName,
                type,
                creationTimestamp,
                source: cr,
              };
            })
            .sort((a, b) => {
              if (a.creationTimestamp > b.creationTimestamp) return -1;
              if (a.creationTimestamp < b.creationTimestamp) return 1;
              return 0;
            })
        : [];
    },
    [
      k8s_kineticaclusteradmins,
      k8s_kineticaclusterbackups,
      k8s_kineticaclusterrestores,
    ]
  );

  const isCloud = DEPLOYMENT_TYPE === 'cloud';

  return (
    <Layout style={{ height: '100vh' }}>
      <Header className="header" style={{ padding: '0px', minWidth: '1280px' }}>
        <NavBar />
      </Header>
      <Layout hasSider>
        <ExplorerSider />
        <Content>
          <Layout style={{ height: 'calc(100vh - 55px)', minWidth: '930px' }}>
            <Content
              style={{
                padding: '0px 20px',
                height: 'calc(100vh - 210px)',
                overflowY: 'scroll',
              }}
            >
              <Breadcrumb style={{ margin: '9px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Jobs</Breadcrumb.Item>
              </Breadcrumb>
              <h2>Jobs</h2>
              <div style={{ display: 'block', clear: 'both' }}>
                <Spin indicator={<Spinner />} spinning={jobsLoading}>
                  <div
                    style={{ right: '0px', position: 'absolute', zIndex: 1 }}
                  >
                    <Space>
                      <Button
                        key="cancel"
                        type="primary"
                        onClick={cancelSelected}
                        loading={isCancelling}
                        disabled={selectedJobs.length === 0}
                        danger
                      >
                        Cancel Selected
                      </Button>
                      <Tooltip title="Refresh Jobs">
                        <Button
                          icon={<RedoOutlined spin={jobsLoading} />}
                          onClick={() =>
                            refetchJobs({
                              variables: {
                                deployment_type: DEPLOYMENT_TYPE,
                              },
                            })
                          }
                        />
                      </Tooltip>
                    </Space>
                  </div>
                  <Tabs type="card">
                    <TabPane
                      tab={`${JOB_FLAG_UNFLAGGED.label} (${runningJobs.length})`}
                      key={JOB_FLAG_UNFLAGGED.value}
                    >
                      <div
                        style={{
                          padding: '20px',
                          backgroundColor: '#ffffff',
                          height: 'calc(100vh - 250px)',
                          overflowY: 'auto',
                        }}
                      >
                        <Table
                          columns={columns}
                          dataSource={runningJobs}
                          rowKey="job_id"
                          rowSelection={{ ...rowSelection }}
                          pagination={{
                            pageSize,
                          }}
                          scroll={{
                            x: 'max-content',
                          }}
                          size="small"
                        />
                      </div>
                    </TabPane>
                    <TabPane
                      tab={`${JOB_FLAG_PERPETUAL.label} (${perpetualJobs.length})`}
                      key={JOB_FLAG_PERPETUAL.value}
                    >
                      <div
                        style={{
                          padding: '20px',
                          backgroundColor: '#ffffff',
                          height: 'calc(100vh - 250px)',
                          overflowY: 'auto',
                        }}
                      >
                        <Table
                          columns={columns}
                          dataSource={perpetualJobs}
                          rowKey="job_id"
                          rowSelection={{ ...rowSelection }}
                          pagination={{
                            pageSize,
                          }}
                          scroll={{
                            x: 'max-content',
                          }}
                          size="small"
                        />
                      </div>
                    </TabPane>
                    {isCloud && hasManagePermission && (
                      <TabPane tab={`Admin (${adminJobs.length})`} key="admin">
                        <div
                          style={{
                            padding: '20px',
                            backgroundColor: '#ffffff',
                            height: 'calc(100vh - 250px)',
                            overflowY: 'auto',
                          }}
                        >
                          <Table
                            columns={adminColumns}
                            dataSource={adminJobs}
                            rowKey="id"
                            pagination={{
                              pageSize,
                            }}
                            scroll={{
                              x: 'max-content',
                            }}
                            size="small"
                          />
                        </div>
                      </TabPane>
                    )}
                  </Tabs>
                </Spin>
              </div>
            </Content>
            <Footer />
          </Layout>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Jobs;
