// Imports
import React, { useState } from 'react';
import {
  Space,
  Row,
  Col,
  Card,
  Spin,
  Tooltip,
  Button,
  Modal,
  Input,
  Tag,
  Empty,
  Avatar,
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
  DoubleRightOutlined,
  ClockCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Searcher } from 'fast-fuzzy';

// App Imports
import Spinner from '../../components/common/Spinner';
import ImportSplash from '../../images/import_splash.png';
import ImportHistory from './ImportHistory';
import CATEGORIES from './json/cdata_categories.json';
import GraphQLServices from '../../graphql/services';
import KifsUploadImage from '../../images/import/kifs_upload.svg';
import KiFSImage from '../../images/import/kifs.svg';
import AzureFilesImage from '../../images/import/azure_files.svg';
import S3Image from '../../images/import/s3.svg';
import HDFSImage from '../../images/import/hdfs.svg';
import KafkaImage from '../../images/import/kafka.svg';
import JDBCImage from '../../images/import/java_jdbc.svg';
import { NAV_ROUTE_IMPORTEXPORT } from '../../constants';

const { Meta } = Card;
const { CheckableTag } = Tag;

const CATEGORY_LABELS = Object.values(CATEGORIES);

const ImportSource = (route, title, description, imgSrc, categories, docs) => ({
  route: `${NAV_ROUTE_IMPORTEXPORT}/${route}`,
  title,
  description,
  img: {
    src: imgSrc,
    alt: title,
  },
  categories,
  docs,
});

const IMPORT_CATALOG = [
  ImportSource(
    'upload',
    'File Upload',
    'Upload a file to KiFS',
    KifsUploadImage,
    ['File & API']
  ),
  ImportSource('kifs', 'KiFS', 'File that exists on KiFS', KiFSImage, [
    'File & API',
  ]),
  ImportSource(
    'azure',
    'Azure Blob Storage',
    'File on Azure Blob Storage',
    AzureFilesImage,
    ['File & API']
  ),
  ImportSource('s3', 'Amazon S3', 'File on Amazon S3', S3Image, ['File & API']),
  ImportSource('kafka', 'Kafka Stream', 'Kafka streaming feed', KafkaImage, [
    'Big Data & NoSQL',
  ]),
  ImportSource('hdfs', 'HDFS', 'File on HDFS', HDFSImage, ['File & API']),
  ImportSource('jdbc', 'Generic JDBC', 'JDBC data source', JDBCImage, ['All']),
];

const TitleRow = text => (
  <Row gutter={16}>
    <Col>
      <h2 style={{ marginBottom: 0 }}>{text}</h2>
    </Col>
  </Row>
);

const ImportCatalog = () => {
  const [showImportHistoryModal, setShowImportHistoryModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([CATEGORIES['*']]);
  const [searchTerm, setSearchTerm] = useState('');

  const history = useHistory();
  const {
    loading: cdataSourcesLoading,
    data: { cdata_drivers: CDATA_CATALOG = [] } = {},
  } = GraphQLServices.CDataDrivers.useGetCDataDriversSummary();

  const categoryFilter = driver => driver.categories.includes(selectedTags[0]);

  const searcherOpts = { keySelector: obj => obj.title };
  const importCatalogSearcher = new Searcher(IMPORT_CATALOG, searcherOpts);
  const FILTERED_IMPORT_CATALOG = searchTerm
    ? importCatalogSearcher.search(searchTerm)
    : IMPORT_CATALOG;
  const CATEGORIZED_IMPORT_CATALOG =
    selectedTags[0] === 'All'
      ? FILTERED_IMPORT_CATALOG
      : FILTERED_IMPORT_CATALOG.filter(categoryFilter);

  const cdataCatalogSearcher = new Searcher(CDATA_CATALOG, searcherOpts);
  const FILTERED_CDATA_CATALOG = searchTerm
    ? cdataCatalogSearcher.search(searchTerm)
    : CDATA_CATALOG;
  const CATEGORIZED_CDATA_CATALOG =
    selectedTags[0] === 'All'
      ? FILTERED_CDATA_CATALOG
      : FILTERED_CDATA_CATALOG.filter(categoryFilter);

  const handleImportHistory = _ => {
    setShowImportHistoryModal(true);
  };

  const cardColStyle = { marginBottom: '16px', cursor: 'pointer' };
  const cardStyle = {
    cursor: 'pointer',
    height: 145,
  };
  const iconStyle = {
    margin: '20px 0px 0px',
  };
  const metaStyle = {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  };

  const handleTagChange = (tag, checked) => {
    // if checked, add tag to selected tags, otherwise, filter out tag
    setSelectedTags([tag]);
  };

  const EMPTY = (
    <Col span={24}>
      <Empty
        description="No Data Sources"
        style={{ paddingTop: 65, paddingBottom: 66 }}
      />
    </Col>
  );

  const LOADING = (
    <Col
      span={24}
      style={{
        height: 300,
        textAlign: 'center',
        paddingTop: 120,
        backgroundColor: '#ffffff33',
      }}
    >
      <Spinner position="static" />
    </Col>
  );

  return (
    <div className="import_catalog">
      <div style={{ float: 'right' }}>
        <Space>
          <Tooltip title="Import History">
            <Button
              icon={<ClockCircleOutlined />}
              onClick={handleImportHistory}
            >
              History
            </Button>
          </Tooltip>
        </Space>
      </div>
      <h2>Sources</h2>
      <Spin indicator={<Spinner />} spinning={false}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <div
            style={{
              backgroundColor: '#ffffff',
              padding: '20px 70px',
              width: '100%',
            }}
          >
            <Row gutter={40}>
              <Col span={13}>
                <h1
                  style={{
                    fontSize: '30px',
                    color: '#3700b399',
                    marginTop: '10px',
                  }}
                >
                  Importing Your Data
                </h1>
                <h2 style={{ fontWeight: 300 }}>
                  Kinetica can load data files supporting standard formats
                  located on the Kinetica cluster itself, or from an external
                  data source, into existing tables.
                </h2>
                <h2>
                  Choose your source{' '}
                  <DoubleRightOutlined
                    style={{
                      color: '#3700b399',
                      verticalAlign: 'middle',
                      fontSize: '20px',
                    }}
                  />
                </h2>
              </Col>
              <Col span={11} style={{ textAlign: 'center' }}>
                <img
                  src={ImportSplash}
                  style={{ height: '270px', marginTop: '10px' }}
                  alt="Workbook Splash"
                />
              </Col>
            </Row>
          </div>
          <Row gutter={16}>
            <Col span={6}>
              <Input
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                prefix={
                  <span style={{ marginRight: 5 }}>
                    <SearchOutlined />
                  </span>
                }
                placeholder="Filter..."
                size="large"
              />
              {/*</AutoComplete>*/}
            </Col>
            <Col span={18} align="right">
              <div style={{ padding: '8px 0px' }}>
                {CATEGORY_LABELS.map(label => (
                  <CheckableTag
                    key={label}
                    checked={selectedTags.indexOf(label) > -1}
                    onChange={checked => handleTagChange(label, checked)}
                  >
                    {label}
                  </CheckableTag>
                ))}
              </div>
            </Col>
          </Row>
          {CATEGORIZED_IMPORT_CATALOG.length > 0 && (
            <>
              {TitleRow('Data Sources')}
              <Row gutter={16}>
                {CATEGORIZED_IMPORT_CATALOG.length
                  ? CATEGORIZED_IMPORT_CATALOG.map(
                      ({ route, title, description, img }) => (
                        <Col key={route} xl={6} xxl={4} style={cardColStyle}>
                          <Card
                            cover={
                              <img
                                {...{
                                  ...img,
                                  style: {
                                    ...iconStyle,
                                    margin: '20px auto 0px',
                                    height: 50,
                                    width: 50,
                                  },
                                }}
                                alt={img.alt}
                              />
                            }
                            onClick={() => history.push(route)}
                            bordered={false}
                            style={cardStyle}
                          >
                            <div>
                              <Meta
                                {...{
                                  title,
                                  description: (
                                    <div style={{ lineHeight: '16px' }}>
                                      {description}
                                    </div>
                                  ),
                                  style: metaStyle,
                                }}
                              />
                            </div>
                          </Card>
                        </Col>
                      )
                    )
                  : EMPTY}
              </Row>
            </>
          )}
          {!cdataSourcesLoading
            ? CATEGORIZED_CDATA_CATALOG.length > 0 && (
                <>
                  {TitleRow('JDBC Data Sources')}
                  <Row gutter={16}>
                    {CATEGORIZED_CDATA_CATALOG.map(({ route, title, img }) => {
                      return (
                        <Col key={route} xl={8} xxl={6} style={cardColStyle}>
                          <Card
                            className="jdbc"
                            onClick={() => history.push(route)}
                          >
                            <Meta
                              avatar={
                                <Avatar
                                  src={img.src}
                                  style={{ height: 40, width: 40 }}
                                />
                              }
                              title={title}
                            />
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )
            : LOADING}
        </Space>
      </Spin>
      {showImportHistoryModal && (
        <Modal
          title="Import History"
          visible={showImportHistoryModal}
          width={window.innerWidth - 300}
          onCancel={_ => setShowImportHistoryModal(false)}
          footer={[
            <Button key="close" onClick={_ => setShowImportHistoryModal(false)}>
              Close
            </Button>,
          ]}
          destroyOnClose
          centered
        >
          <ImportHistory
            pageSize={Math.floor((window.innerHeight - 400) / 50)}
          />
        </Modal>
      )}
    </div>
  );
};

export default ImportCatalog;
