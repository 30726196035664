// Imports
import React, { useContext } from 'react';

// App Imports
import { getPermissionList } from './helper';

export const UserContext = React.createContext(null);

export const useEveryPermission = keys => {
  const userMe = useContext(UserContext) ?? {};
  const isAdmin = userMe?.isGlobalAdmin;
  const userPermissions = getPermissionList(userMe).map(perm => perm.name);
  const hasPermission = keys.every(key => userPermissions.includes(key));
  return [isAdmin || hasPermission];
};

export const useSomePermission = keys => {
  const userMe = useContext(UserContext) ?? {};
  const isAdmin = userMe?.isGlobalAdmin;
  const userPermissions = getPermissionList(userMe).map(perm => perm.name);
  const hasPermission = keys.some(key => userPermissions.includes(key));
  return [isAdmin || hasPermission];
};
