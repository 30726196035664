// Imports
import React, { createRef, useCallback, useEffect } from 'react';
import { Modal, Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// App Imports
import MonacoEditor from '../../components/editor/MonacoEditor';
import { EMBED_SAMPLE_URL, EMBED_SAMPLE_HTML } from '../../constants';

const WorkbookEmbedModal = ({ workbook, visible, close, callback }) => {
  const urlEditorRef = createRef();
  const htmlEditorRef = createRef();

  const getSampleUrl = useCallback(
    _ => {
      const url = window.location.origin;
      const context = window.location.pathname.split('/')[1];
      return EMBED_SAMPLE_URL(url, context, workbook.id);
    },
    [workbook]
  );

  const getSampleHtml = useCallback(
    _ => {
      const url = window.location.origin;
      const context = window.location.pathname.split('/')[1];
      return EMBED_SAMPLE_HTML(url, context, workbook.id);
    },
    [workbook]
  );

  useEffect(
    _ => {
      if (workbook) {
        if (urlEditorRef?.current) {
          const { editor: urlEditor } = urlEditorRef?.current;
          urlEditor.layout();
          urlEditor.setValue(getSampleUrl());
        }

        if (htmlEditorRef?.current) {
          const { editor: htmlEditor } = htmlEditorRef?.current;
          htmlEditor.layout();
          htmlEditor.setValue(getSampleHtml());
        }
      }
    },
    [urlEditorRef, htmlEditorRef, getSampleUrl, getSampleHtml, workbook]
  );

  const sampleStyle = {
    border: '1px solid #eeeeee',
    padding: '10px 5px',
  };

  return (
    <Modal
      title="Share Workbook"
      visible={visible}
      width={800}
      footer={[
        <Button key="cancel" onClick={close}>
          Done
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <CopyToClipboard
        text={getSampleUrl()}
        onCopy={() => {
          message.success('Copied to clipboard!');
        }}
      >
        <CopyOutlined style={{ float: 'right', marginTop: '8px' }} />
      </CopyToClipboard>
      <h3>URL</h3>
      <div style={sampleStyle}>
        <MonacoEditor
          ref={urlEditorRef}
          language="html"
          lineNumbers="off"
          readOnly="true"
          minimap={{ enabled: false }}
          fontSize={12}
          folding="false"
          renderLineHighlight="none"
          contextmenu={false}
          links={false}
          overviewRulerLanes={0}
          scrollBeyondLastLine={false}
          style={{
            height: '20px',
          }}
        ></MonacoEditor>
      </div>
      <br />
      <CopyToClipboard
        text={getSampleHtml()}
        onCopy={() => {
          message.success('Copied to clipboard!');
        }}
      >
        <CopyOutlined style={{ float: 'right', marginTop: '8px' }} />
      </CopyToClipboard>
      <h3>HTML</h3>
      <div style={sampleStyle}>
        <MonacoEditor
          ref={htmlEditorRef}
          language="html"
          lineNumbers="off"
          readOnly="true"
          minimap={{ enabled: false }}
          fontSize={12}
          folding="false"
          renderLineHighlight="none"
          contextmenu={false}
          links={false}
          overviewRulerLanes={0}
          scrollBeyondLastLine={false}
          style={{
            height: '90px',
          }}
        ></MonacoEditor>
      </div>
    </Modal>
  );
};

export default WorkbookEmbedModal;
